import React from "react";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

function PopupAddRequisition({ reqData, closepopup }) {

  return (
    <div
      style={{
        fontFamily: "sans-serif",
        height: "895px",
        width: "868px",
        borderRadius: "10px",
        backgroundColor: "#ECF6FF",
        border: "1px solid #CFDFED",
        margin: "-20px -24px -20px -24px",
      }}
    >        
      <div
        style={{
          backgroundColor: "#CEE3F6",
          color: "#155188",
          height: "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        REQUISITION DETAILS
      </div>
        <CloseIcon style={{ cursor: 'pointer', float:"right", marginTop:"-35px" }} onClick={closepopup} />
      <div style={{ fontFamily: "sans-serif", margin: "0px 56px 0px 56px" }}>
        <div
          style={{
            color: "#525252",
            fontFamily: "sans-serif",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "16px",
            display: "flex",
            marginTop: "24px",
            rowGap: "8px",
            flexDirection: "column",
          }}
        >
          <div
            style={{ marginLeft: "68px", display: "flex", columnGap: "16px" }}
          >
            <div style={{ display: "flex", columnGap: "16px" }}>
              <p style={{ marginTop: "10px" }}>Position Name</p>
              <TextField
                size="small"
                //required={true}
                name="positionName"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"

                value={reqData.positionName}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              style={{
                marginLeft: "22px",
                display: "flex",
                columnGap: "17px",
                marginLeft: "50px",
              }}
            >
              <p style={{ marginTop: "10px" }}>Select Division</p>
              <select
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
              >
                <option>{reqData.divisionName}</option>
              </select>
            </div>
          </div>
          <div style={{ display: "flex", columnGap: "44px" }}>
            <div
              style={{ marginLeft: "78px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px", width: "68px" }}>Client Name</p>
              <div
                style={{
                  width: "204px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                }}
              >
                {reqData.clientName}
              </div>
            </div>
            <div
              style={{ marginLeft: "68px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>SPOC</p>
              <select
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="spoc"
                placeholder="Enter Here"
                variant="outlined"
              >
                <option>{reqData.spoc}</option>
              </select>
            </div>
          </div>
          <div
            style={{ marginLeft: "78px", display: "flex", columnGap: "44px" }}
          >
            <div style={{ display: "flex", columnGap: "16px" }}>
              <p style={{ marginTop: "10px" }}>Monthly CTC </p>
              <TextField
                type="number"
                size="small"
                name="ctcOffered"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"

                value={reqData.ctcOffered}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              style={{ marginLeft: "22px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Accomodation</p>
              <select
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accomodation"
                value={reqData.accomodation}
                variant="outlined"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div
            style={{ marginLeft: "73px", display: "flex", columnGap: "44px" }}
          >
            <div style={{ display: "flex", columnGap: "16px" }}>
              <p style={{ marginTop: "10px" }}>TAT (IN Days)</p>
              <TextField
                size="small"
                name="tat"
                type="number"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                value={reqData.tat}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              style={{ marginLeft: "60px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Priority</p>
              <select
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="priority"
                value={reqData.priority}
                variant="outlined"
              >
                <option value="">Select</option>
                <option style={{ backgroundColor: "#FF4242" }} value="Burning">
                  Burning
                </option>
                <option style={{ backgroundColor: "#FF9270" }} value="Hot">
                  Hot
                </option>
                <option style={{ backgroundColor: "#FFCE70" }} value="Moderate">
                  Moderate{" "}
                </option>
              </select>
            </div>
          </div>
          <div
            style={{ marginLeft: "80px", display: "flex", columnGap: "44px" }}
          >
            <div
              style={{ marginLeft: "9px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Work Type</p>
              <select
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="localWorker"
                value={reqData.localWorker}
                variant="outlined"
              >
                <option value="Local">Local</option>
                <option value="Outstation">Outstation</option>
              </select>
            </div>
            <div
              style={{ marginLeft: "5px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Education Criteria</p>
              <TextField
                size="small"

                name="educationCriteria"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                value={reqData.educationCriteria}
                variant="outlined"
                autoComplete="off"
              />
            </div>
          </div>
          <div
            style={{ marginLeft: "85px", display: "flex", columnGap: "16px" }}
          >
            <p style={{ marginTop: "10px" }}>Hiring Type</p>
            <select
              style={{
                width: "204px",
                borderRadius: "5px",
                height: "40px",
                border: "1px solid #9A9A9A",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="hiring_type"
              value={reqData.hiringType}
              variant="outlined"
            >
              <option value="Permanent">Permanent</option>
              <option value="Contractual">Contractual</option>
            </select>
          </div>
          <div
            style={{
              marginLeft: "8px",
              marginRight: "20px",
              display: "flex",
              columnGap: "16px",
            }}
          >
            <p style={{ marginTop: "10px" }}>Any Important Instructions</p>
            <TextField
              size="small"
              name="instructions"
              style={{
                width: "571px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              value={reqData.instructions}
              variant="outlined"
              autoComplete="off"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            fontFamily: "sans-serif",
            justifyContent: "center",
            marginTop: "36px",
          }}
        >
          <div
            style={{
              width: "811px",
              height: "440px",
              backgroundColor: "#F4FAFF",
            }}
          >
            <div
              style={{
                color: "#525252",
                fontFamily: "sans-serif",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                margin: "0px 20px 0px 20px",
              }}
            >
              <div style={{ display: "flex", columnGap: "32px" }}>
                <div style={{ display: "flex", columnGap: "8px" }}>
                  <p style={{ marginTop: "15px" }}>Total Work Locations</p>
                  <p
                    style={{
                      backgroundColor: "#D3E5F4",
                      color: "#454545",
                      height: "24px",
                      width: "28px",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {reqData.totalWorkLocation}
                  </p>
                </div>
                <div style={{ display: "flex", columnGap: "8px" }}>
                  <p style={{ marginTop: "15px" }}>Total Positions</p>
                  <p
                    style={{
                      backgroundColor: "#D3E5F4",
                      color: "#454545",
                      height: "24px",
                      width: "28px",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {reqData.totalPositions}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  columnGap: "16px",
                  marginTop: "20px",
                }}
              >
                <div>
                  <p>Location Name</p>
                  <TextField
                    size="small"
                    name="locationName"
                    style={{
                      width: "204px",
                      borderRadius: "5px",
                      border: "1px solid #A3A3A3",
                      backgroundColor: "#ffffff",
                    }}
                    placeholder="Enter Here"
                    value={reqData.locationName}
                    variant="outlined"
                    autoComplete="off"
                  />
                </div>
                <div>
                  <p>No. of Position</p>
                  <TextField
                    size="small"
                    name=""
                    type="number"
                    style={{
                      width: "122px",
                      borderRadius: "5px",
                      border: "1px solid #A3A3A3",
                      backgroundColor: "#ffffff",
                    }}
                    placeholder="Enter Here"
                    value={reqData.noOfPosition}
                    variant="outlined"
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "136px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "44px",
                    justifyContent: "center",
                    border: "1px ridge",
                  }}
                >
                </div>
              </div>
              <div
                style={{
                  marginTop: "12px",
                  fontWeight: 550,
                  fontSize: "14px",
                }}
              >
                Tagged Recruiters:
                {reqData.tagRecruiter.map((data) => (
                  <p style={{ display: "inline", fontWeight: "500" }}>
                    {" "}
                    {data.userName}{" "}
                  </p>
                ))}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupAddRequisition;
