import React, { useState } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";
import IpsLogo from "./pages/IpsBussiness/IpsLogo.svg";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import axios from "axios";

const { URL } = require("../config.json");

function LoginPage() {
  let navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
   
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOpenUserAndClientIndex = () => {
    axios
      .post(`${URL}userList/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        console.log(response);
        localStorage.setItem("roleName", response.data.user.roleName);
        localStorage.setItem("employeeName", response.data.user.userName);
        localStorage.setItem("employeeId", response.data.user._id);
        localStorage.setItem(
          "organisation_id",
          response.data.user.organisation_id
        );
        localStorage.setItem("jwttoken", response.data.authtoken);
        navigate("/");
      })
      .catch((err) => {
        window.alert(err.response.data.message);
      });
  };

  return (
    <div className="w-full h-full font-sans">
      <div className=" items-center bg-gray-50 ">
        <div className=" w-full h-32 " style={{ background: "#1A5F9E" }}>
          <div style={{ display: "flex" }}>
            <img
              className=""
              style={{
                width: "100px",
                height: "95px",
                margin: "5px 30px 5px 58px",
              }}
              src={IpsLogo}
            />
            <p style={{ color: "#FFFFFF", marginTop: "auto" }}>
              Welcome to IPS Business Intelligence
            </p>
          </div>
        </div>
        <div
          className="flex justify-center pt-32"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          <div className="flex flex-col ">
            <div>
              <p className="mt-2" style={{ color: "#4594DE" }}>
                Username
              </p>
              <input
                type="email"
                style={{
                  width: "250px",
                  height: "50px",
                  paddingLeft: "20px",
                  borderRadius: "8px",
                  border: "1px solid #C0E1EB",
                }}
                size="small"
                name=""
                placeholder="Enter your username"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div style={{ position: "relative" }}>
              <p className="mt-8" style={{ color: "#4594DE" }}>
                {" "}
                Password{" "}
              </p>
              <input
                type={showPassword ? "text" : "password"}
                style={{
                  width: "250px",
                  height: "50px",
                  paddingLeft: "20px",
                  borderRadius: "8px",
                  border: "1px solid #C0E1EB",
                }}
                size="small"
                name=""
                placeholder="Enter your password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                style={{ position: "absolute", right: "15px", bottom: "15px",cursor:"pointer" }}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </div>
            </div>
            <div>
              <button
                onClick={handleOpenUserAndClientIndex}
                style={{
                  cursor: "pointer",
                  background: "#4594DE",
                  width: "270px",
                  borderRadius: "8px",
                  height: "50px",
                  color: "#FFFFFF",
                  marginTop: "20px",
                }}
                type="submit"
                color="primary"
                name="login_button"
              >
                LOGIN
              </button>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <input
                style={{ width: "24px", height: "24px" }}
                type="checkbox"
              />
              Remember me
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
