import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function UpdateStatus({
  updateRequisitionStatus,
  requisitionId,
  off,
}) {
  const [remark, setRemark] = useState('')

  return (
    <div
      style={{
        height: "200px",
        width: "400px",
        borderRadius: "10px",
        backgroundColor: "#ECF6FF",
        border: "1px solid #CFDFED",
        position: "absolute",
        left: "40%",
        top: "40%",
      }}
    >
      <div style={{ marginLeft: "16px", marginRight: "16px" }}>
        <CloseIcon
          fontSize="small"
          onClick={off}
          style={{ float: "right", cursor: "pointer" }}
        />
        <p
          style={{
            color: "#155188",
            display: "flex",
            justifyContent: "center",
            fontFamily: "sans-serif",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
          }}
        >
          STATUS UPDATE
        </p>
        <hr />

        <div style={{ display: "flex", columnGap: "18px", marginLeft: "12px" }}>
          <p style={{ marginTop: "18px", fontSize: "14px" }}>Remark</p>
          <input
            style={{
              marginTop: "10px",
              width: "226px",
              borderRadius: "6px",
              background: "#FFFFFF",
              border: "1px solid #DCE3EA",
              height: "35px",
            }}
            placeholder="Enter Here"
            type="text"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "14px",
          }}
        >
          <button
            style={{
              backgroundColor: "#3B82F6",
              color: "#EDF0F6",
              fontFamily: "sans-serif",
              width: "56px",
              height: "32px",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              borderRadius: "5px",
              padding: "0px 8px 0px 8px",
              border: "1px ridge",
              cursor: "pointer",
            }}
            onClick={() => updateRequisitionStatus(requisitionId, remark, "Closed")}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
}
