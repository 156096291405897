import { takeEvery, takeLatest, put } from 'redux-saga/effects'
import {
    CLIENT_LIST, SET_CLIENT_LIST, ADDROLE, SETADDROLE, ADDBRANCH, SETADDBRANCH, ADDTYPEHIRING, SETADDTYPEHIRING,
    ADDDIVISION, SETADDDIVISION, GET_ROLE, SET_ROLE, GET_BRANCH, SET_BRANCH, GET_TYPE_HIRING, SET_TYPE_HIRING, GET_DIVISION,
    SET_DIVISION, DELETE_ROLE, DELETE_BRANCH, DELETE_TYPE_HIRING, DELETE_DIVISION, GET_USER_LIST, SET_USER_LIST, ADDUSER,
    SETADDUSER, GETEMPLOYEELIST, SETEMPLOYEELIST, ADD_CLIENT, SET_CANDIDATE, GET_CANDIDATE, ADD_CANDIDATE,
    SET_REQUISITION, GET_REQUISITION, PUT_REQUISITION, POST_REQUISITION, UPDATE_CANDIDATE, GET_COMPANY, SET_COMPANY,
    ADD_COMPANY, DELETE_COMPANY,
    GET_DESIGNATION,
    SET_DESIGNATION,
    GET_REQUISITION_BY_TAG,
    SET_REQUISITION_BY_TAG,
    ADD_BILLING_STATUS,
    GET_BILLING_STATUS,
    SET_BILLING_STATUS,
    GETALLCANDIDATE,
    SETALLCANDIDATE,
    GET_CAND_FOR_REVENUE,
    SET_CAND_FOR_REVENUE,
    GET_ONE_TIME_SOURCE_CANDIDATE,
    SET_ONE_TIME_SOURCE_CANDIDATE,
    GET_PERMANENT_CAND_REVENUE,
    SET_PERMANENT_CAND_REVENUE,
    GSTOKEN, GET_BRANCHBYCLIENT, SET_BRANCHBYCLIENT,
    ADD_VENDER,GET_VENDER,SET_VENDER , GET_CLIENTBY_COMPANY , SET_CLIENTBY_COMPANY,GET_ALL_CLIENTBY_COMPANY,SET_ALL_CLIENTBY_COMPANY


} from './constant';
const { URL } = require('../config.json')

function* getClient(action) {
    let data = yield fetch(`${URL}clientlist/clientlist`)
    data = yield data.json()

    yield put({ type: SET_CLIENT_LIST, data })
}

function* getRole() {
    let data = yield fetch(`${URL}bi_role/bi_role`)
    data = yield data.json()

    yield put({ type: SET_ROLE, data })
}

function* getUserList() {
    let data = yield fetch(`${URL}userlist/userlist`)
    data = yield data.json()

    yield put({ type: SET_USER_LIST, data })
}

function* getBranch() {
    let data = yield fetch(`${URL}bi_branch/bi_branch`)
    data = yield data.json()

    yield put({ type: SET_BRANCH, data })
}

function* getTypeOfHiring() {
    let data = yield fetch(`${URL}bi_typeofhiring/bi_typeofhiring`)
    data = yield data.json()

    yield put({ type: SET_TYPE_HIRING, data })
}

function* getDivision() {
    let data = yield fetch(`${URL}bi_division/bi_division`)
    data = yield data.json()

    yield put({ type: SET_DIVISION, data })
}

function* getCompany() {
    let data = yield fetch(`${URL}bi_company/bi_company`)
    data = yield data.json()
    console.log("data is comming", data)
    yield put({ type: SET_COMPANY, data })
}

function* addRole(action) {
    let emp_id = localStorage.getItem('employeeId')
    const response = yield fetch(`${URL}bi_role/bi_role`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ roleName: action.payload, createdBy: emp_id }),
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: SETADDROLE, data })
}

function* addBranch(action) {
    let emp_id = localStorage.getItem('employeeId')
    const response = yield fetch(`${URL}bi_branch/bi_branch`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ branchName: action.payload, createdBy: emp_id }),
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: SETADDBRANCH, data })
}

function* addTypeHiring(action) {
    let emp_id = localStorage.getItem('employeeId')
    const response = yield fetch(`${URL}bi_typeofhiring/bi_typeofhiring`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ typeOfHiring: action.payload, createdBy: emp_id }),
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: SETADDTYPEHIRING, data })
}

function* addCompany(action) {
    let emp_id = localStorage.getItem('employeeId')
    const response = yield fetch(`${URL}bi_company/bi_company`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ createdBy: emp_id, company: action.payload }),
    });

    let data = yield response.json()
    window.alert(data.message)

}

function* addDivision(action) {
    let emp_id = localStorage.getItem('employeeId')
    const response = yield fetch(`${URL}bi_division/bi_division`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ divisionName: action.payload, createdBy: emp_id }),
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: SETADDDIVISION, data })
}

function* deleteRole(action) {
    const response = yield fetch(`${URL}bi_role/bi_role`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: action.payload }),
    });

}

function* deleteBranch(action) {
    const response = yield fetch(`${URL}bi_branch/bi_branch`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: action.payload }),
    });

}

function* deleteTypeOfHiring(action) {
    const response = yield fetch(`${URL}bi_typeofhiring/bi_typeofhiring`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: action.payload }),
    });

}

function* deleteDivision(action) {
    const response = yield fetch(`${URL}bi_division/bi_division`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: action.payload }),
    });

}

function* deleteCompany(action) {
    const response = yield fetch(`${URL}bi_company/bi_company`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: action.payload }),
    });

}

function* addUser(action) {
    const response = yield fetch(`${URL}userlist/userlist`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: SETADDUSER, data })
}

function* getEmployee() {
    let data = yield fetch(`${URL}employee/employee/organisation_id=62c51c804d0f4f001346fc24`)
    data = yield data.json()

    yield put({ type: SETEMPLOYEELIST, data })
}

function* addClient(action) {
    const response = yield fetch(`${URL}clientlist/clientlist`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload)
    });
    let data = yield response.json()
    window.alert(data.message)
}
//Requisiton
function* getRequisition() {
    let emp_id = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_requisition/bi_requisition/${emp_id}`)
    data = yield data.json()
    console.log("requisition", data)
    yield put({ type: SET_REQUISITION, data })
}

function* postRequisition(action) {
    console.log("postRequisition", action)
    const response = yield fetch(`${URL}bi_requisition/bi_requisition`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },

        body: JSON.stringify(action.payload)
    });
    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: GET_REQUISITION })
}
function* putRequisition(action) {
    const response = yield fetch(`${URL}bi_requisition/bi_requisition`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload)
    });
    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: GET_REQUISITION })
}

function* addCandidate(action) {
    const response = yield fetch(`${URL}bi_candidate/bi_candidate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload)
    });
    let data = yield response.json()
    window.alert(data.message)
}

function* getRequisitionByTag() {
    let emp_id = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_requisition/getRequisitionByTag/${emp_id}`)
    data = yield data.json()

    yield put({ type: SET_REQUISITION_BY_TAG, data })
}

function* getCandidate() {
    let emp_id = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_candidate/bi_candidate/${emp_id}`)
    data = yield data.json()

    yield put({ type: SET_CANDIDATE, data })
}

function* updateCandidateSaga(action) {
    const response = yield fetch(`${URL}bi_candidate/bi_candidate`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload)
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: GET_CANDIDATE })
}

function* getDesignation() {
    let data = yield fetch(`${URL}designation/designation`)
    data = yield data.json()

    yield put({ type: SET_DESIGNATION, data })
}

function* getBillingStatus() {
    let EmpId = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_billingStatus/bi_cadidateForBilling/${EmpId}`)
    data = yield data.json()

    yield put({ type: SET_BILLING_STATUS, data })
}

function* addBillingStatusSaga(action) {
    const response = yield fetch(`${URL}bi_billingStatus/bi_billingStatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload)
    });

    yield put({ type: GET_BILLING_STATUS })
}

function* getAllCandidate(action) {
    let data = yield fetch(`${URL}bi_candidate/bi_candidatealls/${action.payload}`)
    data = yield data.json()

    yield put({ type: SETALLCANDIDATE, data })
}
function* gST(a) {
    const employeeId = localStorage.getItem('employeeId')
    let x = { ...a.l, userId: employeeId }
    console.log("token 101", x)
    
    const response = yield fetch(`${URL}d/d`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(a.l)
    });
    let x1 = response.json()
    console.log("token res", x1)
    localStorage.setItem("dT", x1)
}


function* getAllCandForRevenue() {
    let EmpId = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_billingStatus/bi_contrctOnMontHBasisForRevenue/${EmpId}`)

    data = yield data.json()
    yield put({ type: SET_CAND_FOR_REVENUE, data })
}

function* getAllOneTimeSourceCandidate() {
    let EmpId = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_billingStatus/bi_contrctOnOneTimeSoueceForRevenue/${EmpId}`)

    data = yield data.json()
    yield put({ type: SET_ONE_TIME_SOURCE_CANDIDATE, data })
}

function* getAllPermanentCandForRevenue() {
    let EmpId = localStorage.getItem('employeeId')
    let data = yield fetch(`${URL}bi_billingStatus/bi_permanentCandidateForRevenue/${EmpId}`)

    data = yield data.json()
    yield put({ type: SET_PERMANENT_CAND_REVENUE, data })
}

function* getClientByCompany(action) {
   
    let data = yield fetch(`${URL}clientlist/getClientByCompany`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },

        body: JSON.stringify(action.payload)
    })
    
    data = yield data.json()

    yield put({ type: SET_CLIENTBY_COMPANY, data })
}


// function* gST(a) {
//     const employeeId = localStorage.getItem('employeeId')
//     let x = { ...a.l, userId: employeeId }
//     console.log("token 101", x)

//     const response = yield fetch(`${URL}d/d`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify(a.l)
//     });
//     let x1 = response.json()
//     console.log("token res", x1)
//     localStorage.setItem("dT", x1)
// }

function* getBranchByClient(action) {
    try {
        let data = yield fetch(`${URL}bi_budget/Bi_getBranchByClient`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(action.payload)
        });

        data = yield data.json()
        yield put({ type: SET_BRANCHBYCLIENT, data })
    } catch (error) {
        console.log(error)
    }
}

function* addVenderSaga(action) {
    const response = yield fetch(`${URL}bi_vender/bi_vender`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload),
    });

    let data = yield response.json()
    window.alert(data.message)
    yield put({ type: SET_VENDER, data })
}

function* getVenderSaga() {
    let data = yield fetch(`${URL}bi_vender/bi_vender`)
    data = yield data.json()

    yield put({ type: SET_VENDER, data })
}

function* getAllClientByCompany(action) {
  
    let data = yield fetch(`${URL}clientlist/getAllClientByCompany/${action.payload}`)

    data = yield data.json()
    yield put({ type: SET_ALL_CLIENTBY_COMPANY, data })
}

function* clientSaga() {
    yield takeEvery(CLIENT_LIST, getClient)
    yield takeEvery(ADDROLE, addRole)
    yield takeEvery(ADDBRANCH, addBranch)
    yield takeEvery(ADDTYPEHIRING, addTypeHiring)
    yield takeEvery(ADDDIVISION, addDivision)
    yield takeEvery(GET_USER_LIST, getUserList)
    yield takeEvery(GET_ROLE, getRole)
    yield takeEvery(GET_BRANCH, getBranch)
    yield takeEvery(GET_COMPANY, getCompany)
    yield takeEvery(GET_TYPE_HIRING, getTypeOfHiring)
    yield takeEvery(GET_DIVISION, getDivision)
    yield takeEvery(DELETE_ROLE, deleteRole)
    yield takeEvery(DELETE_BRANCH, deleteBranch)
    yield takeEvery(DELETE_COMPANY, deleteCompany)
    yield takeEvery(DELETE_TYPE_HIRING, deleteTypeOfHiring)
    yield takeEvery(DELETE_DIVISION, deleteDivision)
    yield takeEvery(ADDUSER, addUser)
    yield takeEvery(ADD_VENDER,addVenderSaga)
    yield takeEvery(GET_VENDER,getVenderSaga)
    yield takeEvery(ADD_COMPANY, addCompany)
    yield takeEvery(GETEMPLOYEELIST, getEmployee)
    yield takeEvery(ADD_CLIENT, addClient)
    yield takeEvery(ADD_CANDIDATE, addCandidate)
    yield takeEvery(GET_CANDIDATE, getCandidate)
    yield takeEvery(GET_REQUISITION, getRequisition)
    yield takeEvery(POST_REQUISITION, postRequisition)
    yield takeEvery(PUT_REQUISITION, putRequisition)
    yield takeEvery(UPDATE_CANDIDATE, updateCandidateSaga)
    yield takeEvery(GET_DESIGNATION, getDesignation)
    yield takeEvery(GET_REQUISITION_BY_TAG, getRequisitionByTag)
    yield takeEvery(GET_BILLING_STATUS, getBillingStatus)
    yield takeEvery(ADD_BILLING_STATUS, addBillingStatusSaga)
    yield takeEvery(GETALLCANDIDATE, getAllCandidate)    
    yield takeLatest(GSTOKEN, gST)
    yield takeEvery(GETALLCANDIDATE, getAllCandidate)
    yield takeEvery(GET_CAND_FOR_REVENUE, getAllCandForRevenue)
    yield takeEvery(GET_ONE_TIME_SOURCE_CANDIDATE, getAllOneTimeSourceCandidate)
    yield takeEvery(GET_PERMANENT_CAND_REVENUE, getAllPermanentCandForRevenue)
    yield takeEvery(GET_CLIENTBY_COMPANY,getClientByCompany)
    yield takeEvery(GET_BRANCHBYCLIENT, getBranchByClient)

    yield takeEvery(GET_ALL_CLIENTBY_COMPANY,getAllClientByCompany)
}

export default clientSaga