import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TablePagination } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";

import TextField from "@mui/material/TextField";
//import PopupForSelected from "./PopupForSelected";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import HorizontalScroll from 'react-scroll-horizontal';
import { useSelector, useDispatch } from "react-redux";
import { getCandidate, updateCandidate } from "../../../../redux/action";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./candstatus.css";
import { CgScrollV } from "react-icons/cg";

function CandidateStatus() {
  let dispatch = useDispatch();

  let candidateList = useSelector((state) => state.clientReducer.candidateList);
  // console.log('candidateList in candidatestatus', candidateList)
  let emp_id = localStorage.getItem("employeeId");

  useEffect(() => {
    dispatch(getCandidate());
  }, []);

  const [Interviewed, setInerviewed] = useState();
  const [selected, setSelected] = useState();
  const [offeredAccepted, setOfferedAccepted] = useState();
  const [joined, setJoined] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [acceptedCtc, setAccptedCtc] = useState();
  const [empCode, setEmpCode] = useState();
  console.log("empCode", empCode);
  const [InterviewedRemark, setInterviewedRemark] = useState();
  const [offeredAcceptedRemark, setOfferedAcceptedRemark] = useState();
  const [joinedRemark, setJoinedRemark] = useState();
  const [foundUsers, setFoundUsers] = useState(candidateList);
  const [clientName, setClientName] = useState();
  const [dropReason, setDropReason] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleChangePage = (event, newPage = 0) => {
    setPage(newPage);
    // console.log("newPage",newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setFoundUsers([...foundUsers]);
  };

  const handleClose = () => {
    setOpen(false);
    setFoundUsers([...foundUsers]);
  };
  const handleClickInterview = (event, i) => {
    // page * rowsPerPage, page * rowsPerPage + rowsPerPage
    console.log("handleClickInterview", page * rowsPerPage + i);
    // let obj = foundUsers[i];
    let obj = foundUsers[page * rowsPerPage + i];
    let obj1 = Object.assign(obj, { Inter: event.target.value });
    foundUsers.splice(page * rowsPerPage + i, 1, obj1);
    setInerviewed(event.target.value);
    console.log("handleClickInterview123", foundUsers);
    setFoundUsers([...foundUsers]);
  };

  const handleClickSelected = (event, i) => {
    // let obj = foundUsers[i];
    let obj = foundUsers[page * rowsPerPage + i];
    let obj11 = Object.assign(obj, { Sel: event.target.value });
    console.log("obj57", obj11);
    foundUsers.splice(page * rowsPerPage + i, 1, obj11);
    setSelected(event.target.value);
    setFoundUsers([...foundUsers]);
  };

  const handleClickAccepted = (event, i) => {
    // let obj = foundUsers[i];
    let obj = foundUsers[page * rowsPerPage + i];
    let obj2 = Object.assign(obj, { Off: event.target.value });
    console.log("obj67 check karna h", obj2);
    foundUsers.splice(page * rowsPerPage + i, 1, obj2);
    setOfferedAccepted(event.target.value);
    setFoundUsers([...foundUsers]);
  };

  const handleClickJoined = (event, i) => {
    // let obj = foundUsers[i];
    let obj = foundUsers[page * rowsPerPage + i];
    let obj3 = Object.assign(obj, { Join: event.target.value });
    console.log("obj77", obj3);
    foundUsers.splice(page * rowsPerPage + i, 1, obj3);
    setJoined(event.target.value);
    setFoundUsers([...foundUsers]);
  };
  const today = new Date();
  const maxDate = today.toISOString().split("T")[0];

  function handleJoiningDate(e, i) {
    console.log("handleJoiningDate", e.target.value, i);

    // let obj = foundUsers[i];
    let obj = foundUsers[page * rowsPerPage + i];
    let obj3 = Object.assign(obj, { joiningDate: e.target.value.slice(0, 10) });
    foundUsers.splice(page * rowsPerPage + i, 1, obj3);
    setFoundUsers([...foundUsers]);
  }

  function handleAcceptedCTC(e, i) {
    let obj = foundUsers[page * rowsPerPage + i];
    let obj3 = Object.assign(obj, { acceptedCtc: e.target.value.slice(0, 10) });
    foundUsers.splice(page * rowsPerPage + i, 1, obj3);
    setFoundUsers([...foundUsers]);
  }
  function handleEmpCode(e, i) {
    let obj = foundUsers[page * rowsPerPage + i];
    let obj3 = Object.assign(obj, { empCode: e.target.value });
    console.log("obj3jgsv", obj3);
    foundUsers.splice(page * rowsPerPage + i, 1, obj3);
    setFoundUsers([...foundUsers]);
  }

  const [dataForDrop, setDataForDrop] = useState("");
  function handleDropCandidate(data) {
    console.log("you want to drop this candidate", data);
    setDataForDrop(data);
    setOpen(true);
  }

  function confirmDrop(data) {
    console.log("ksdhkjdscsdhkk", dataForDrop);
    let obj = {
      ...dataForDrop,
      drop: "Yes",
      dropReason: dropReason,
      updatedBy: emp_id,
      // status: false
      // candidateStatus: "Dropped"
    };
    if (dropReason != undefined) {
      console.log("dropReason", dropReason, obj);
      dispatch(updateCandidate(obj));
      // alert("Status Updated Successfully");
      setOpen(false);
      setDataForDrop("");
      setClientName("");
    } else {
      alert("Enter A Valid Reason");
    }
  }
  // else if (data.Join === "Yes" && (data.joiningDate === undefined || ((data.empCode== undefined  && data.hiring_type == 'Contractual')|| (data.empCode== '' && data.hiring_type == 'Contractual')) || data.acceptedCtc === undefined || data.acceptedCtc == 0 || data.acceptedCtc == "")) {
  //   alert("Please fill Joining Data , Accepted CTC and Employee Code ");
  // }
  const saveCandidateStatus = (data) => {
    console.log("update can", data);
    // console.log("joiningDate",new Date(joiningDate).toDateString())
    if (data.Inter == "No" && InterviewedRemark == undefined) {
      alert("Please Select Reason");
    } else if (data.Off == "No" && offeredAcceptedRemark == undefined) {
      alert("Please Select Reason");
    } else if (data.Join == "No" && joinedRemark == undefined) {
      alert("Please Select Reason");
    } else if (
      data.Join === "Yes" &&
      (data.joiningDate === undefined ||
        data.acceptedCtc === undefined ||
        data.acceptedCtc == 0 ||
        data.acceptedCtc == "")
    ) {
      alert("Please fill Joining Data & Accepted CTC");
    }
    // else if (data.Join === "Yes" && data.hiring_type === "Contractual") {
    //   if (data.empCode === undefined || data.empCode.trim() === "") {
    //     alert("Please enter Employee Code for Contractual hiring");
    //   } else {
    //     let obj = {
    //       ...data,
    //       Interviewed: data.Inter,
    //       selected: data.Sel,
    //       offeredAccepted: data.Off,
    //       joined: data.Join,
    //       acceptedCtc: data.acceptedCtc,
    //       empCode: data.empCode,
    //       InterviewedRemark: InterviewedRemark,
    //       offeredAcceptedRemark: offeredAcceptedRemark,
    //       joinedRemark: joinedRemark,
    //       updatedBy: emp_id,
    //     };
    //     if (data.JoinDate) {
    //       obj.joiningDate = data.JoinDate.slice(0, 10);
    //     }
    //     console.log("in else part", obj);
    //     dispatch(updateCandidate(obj));
    //   }
    // }
    else {
      console.log("sdfgh");
      // alert("Ok")
      let obj = {
        ...data,
        Interviewed: data.Inter,
        selected: data.Sel,
        offeredAccepted: data.Off,
        joined: data.Join,
        // joiningDate: data.JoinDate.slice(0, 10),
        // joiningDate: data.JoinDate,
        acceptedCtc: data.acceptedCtc,
        empCode: data.empCode,
        InterviewedRemark: InterviewedRemark,
        offeredAcceptedRemark: offeredAcceptedRemark,
        joinedRemark: joinedRemark,
        updatedBy: emp_id,
      };
      if (data.JoinDate) {
        obj.joiningDate = data.JoinDate.slice(0, 10);
      }
      console.log("obj is123", obj);
      console.log("obj is123 in function");
      dispatch(updateCandidate(obj));
      // alert("Status Updated Successfully");
      // setFoundUsers([...foundUsers])
    }
    // if (
    //   data.Join === "Yes" &&
    //   (joiningDate === undefined || acceptedCtc === undefined)
    // ) {
    //   console.log("check if");
    //   alert("Please fill Joining Data & Accepted CTC");
    // } else if (
    //   data.Inter === "Yes" ||
    //   data.Inter === "No" ||
    //   data.Sel === "Yes" ||
    //   data.Sel === "No" ||
    //   data.Off === "Yes" ||
    //   data.Off === "No" ||
    //   data.Join === "No"
    // ) {
    //   console.log("check else");
    //   let obj = {
    //     ...data,
    //     Interviewed: Interviewed,
    //     selected: selected,
    //     offeredAccepted: offeredAccepted,
    //     joined: joined,
    //     joiningDate: joiningDate,
    //     acceptedCtc: acceptedCtc,
    //     InterviewedRemark: InterviewedRemark,
    //     offeredAcceptedRemark: offeredAcceptedRemark,
    //     joinedRemark: joinedRemark,
    //   };
    //   dispatch(updateCandidate(obj));
    //   alert("Status Update Successfully");
    //   console.log("can in update data", obj);
    //   setTimeout(() => {
    //     dispatch(getCandidate());
    //   }, 100);
    //   setOpen(true);
    // }
  };

  const filter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = candidateList.filter((client) => {
        return (
          (client.clientName &&
            client.clientName
              .toLowerCase()
              .startsWith(keyword.toLowerCase())) ||
          (client.candidateName &&
            client.candidateName
              .toLowerCase()
              .startsWith(keyword.toLowerCase()))
        );
      });
      setFoundUsers(results);
      setPage(0);
    } else {
      setFoundUsers(candidateList);
    }

    setClientName(keyword);
  };

  useEffect(() => {
    setFoundUsers(candidateList);
  }, [candidateList]);

  console.log("foundUsers", foundUsers);

  return (
    <div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: 500,
          margin: "16px 0px ",
          color: "#434345",
          lineHeight: "22px",
        }}
      >
        CANDIDATE STATUS
      </div>
      <div className="inputtextmobilediv">
        <TextField
          className="inputtextmobile"
          size="small"
          style={{
            display: "none",
          }}
          placeholder="Search Here"
          value={clientName}
          onChange={filter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="medium" style={{ color: "#767676" }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div style={{ width: "100%", height: "100%", fontFamily: "sans-serif" }}>
        <div style={{ overflowY: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow
                className="candidatestatusdis"
                style={{ backgroundColor: "#D9E8FF" }}
              >
                <TableCell
                  style={{
                    width: "7.5%",
                    color: "#272945",
                    textAlign: "start",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Submission Date
                </TableCell>
                <TableCell
                  style={{
                    width: "11%",
                    color: "#272945",
                    textAlign: "start",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Client Name
                </TableCell>
                <TableCell
                  style={{
                    width: "11%",
                    color: "#272945",
                    textAlign: "start",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Position Name
                </TableCell>
                <TableCell
                  style={{
                    width: "9%",
                    color: "#272945",
                    textAlign: "start",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Candidate Name
                </TableCell>
                <TableCell
                  style={{
                    width: "10%",
                    color: "#272945",
                    textAlign: "start",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  Candidate Ph.
                </TableCell>
                <TableCell
                  style={{
                    width: "45%",
                    color: "#272945",
                    textAlignLast: "end",
                    fontSize: "13px",
                    padding: "5px",
                  }}
                >
                  <TextField
                    className="inputnonmobilecanstatus"
                    size="small"
                    style={{
                      width: "210px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                    }}
                    placeholder="Search Here"
                    value={clientName}
                    onChange={filter}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon
                            fontSize="medium"
                            style={{ color: "#767676" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </TableCell>
              </TableRow>

              {/* mobile view */}

              <TableRow
                className="candidatestatusdismobile"
                style={{
                  backgroundColor: "#BFE8FF",
                  display: "flex",
                  justifyContent: "space-between",
                  display: "none",
                }}
              >
                <div
                  style={{
                    color: "#2B2B2B",
                    display: "flex",
                    justifyContent: "space-between",
                    // padding: "0 15px",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <TableCell
                      style={{
                        width: "10%",
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13px",
                        fontWeight: "700",
                      }}
                    >
                      Client Name
                    </TableCell>
                  </div>
                  <div style={{ padding: "0 15px" }}>
                    <CgScrollV />
                  </div>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <TableCell
                    style={{
                      // width: "10%",
                      color: "#272945",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    Position Name
                  </TableCell>

                  <TableCell
                    style={{
                      // width: "10%",
                      color: "#272945",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    Candidate Name
                  </TableCell>

                  <TableCell
                    style={{
                      // width: "13%",
                      color: "#272945",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    Candidate Ph.
                  </TableCell>
                </div>
              </TableRow>
            </TableHead>
          </Table>
        </div>

        {/* mobile View */}

        <div
          className="candidatestatusdismobile"
          style={{
            display: "none",
          }}
        >
          <div style={{ width: "100%" }}>
            {foundUsers &&
              foundUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, i) => {
                  return (
                    <Table
                      key={data.name}
                      style={{
                        border: "1px solid #CDDDE7 ",
                        borderRadius: "30px",
                        shadows: "none",
                        marginTop: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      {/* <div style={{ border: "1px solid #CDDDE7 ",
                borderRadius:'30px',
                shadows: "none", marginTop:'10px',paddingTop:'10px'}}  > */}

                      <TableRow
                        style={{
                          width: "100% ",
                          border: "1px solid #CDDDE7 ",
                          borderRadius: "30px",
                        }}
                      >
                        <div>
                          <TableCell
                            style={{
                              border: "0",
                              // width: "10%",
                              color: "#2E2E2E",
                              textAlign: "start",
                              fontSize: "12px",
                            }}
                          >
                            {data.clientName}
                          </TableCell>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <TableCell
                            style={{
                              border: "0",
                              // width: "20%",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "10px",
                            }}
                          >
                            {data.positionName}
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0",
                              // width: "10%",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "10px",
                            }}
                          >
                            {data.candidateName}
                          </TableCell>

                          <TableCell
                            style={{
                              border: "0",
                              // width: "10%",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "10px",
                            }}
                          >
                            {data.contactNo}
                          </TableCell>
                        </div>

                        <div>
                          <TableCell
                            style={{
                              border: "0",
                              width: "45%",
                              overflowX: "scroll",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "300px",
                                columnGap: "20px",
                              }}
                            >
                              <div>
                                <button
                                  onClick={() => handleDropCandidate(data)}
                                  style={{
                                    backgroundColor:
                                      localStorage.getItem("roleName") ==
                                      "vendor"
                                        ? "gray"
                                        : "#3B82F6",
                                    width: "50px",
                                    height: "35px",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    color: "#FFFFFF",
                                    borderRadius: "6px",
                                    border: "1px solid #3B82F6",
                                    cursor:
                                      localStorage.getItem("roleName") ==
                                      "vendor"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  disabled={
                                    localStorage.getItem("roleName") ==
                                      "Auditor" ||
                                    localStorage.getItem("roleName") == "vendor"
                                  }
                                >
                                  Drop
                                </button>
                              </div>
                              <div
                                style={{
                                  width: " max-content",
                                  color: "#2E2E2E",
                                  textAlign: "start",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "15.62px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-inline-flex",
                                    background: "#E8F9F8",
                                    columnGap: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#525252",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    Interviewed
                                  </p>
                                  {/* {console.log("check render or not", data.Inter)} */}
                                  <select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{
                                      border: "2px solid #9D9D9D",
                                      width: "70px",
                                      height: "40px",
                                      borderRadius: "6px",
                                    }}
                                    disabled={
                                      data.Interviewed ||
                                      localStorage.getItem("roleName") ==
                                        "vendor"
                                    }
                                    name="Interviewed"
                                    // value={data.Interviewed}
                                    value={
                                      data.Interviewed
                                        ? data.Interviewed
                                        : data.Inter
                                        ? data.Inter
                                        : ""
                                    }
                                    //onChange={(event) => setInerviewed(event.target.value)}
                                    onChange={(event) =>
                                      handleClickInterview(event, i)
                                    }
                                    variant="outlined"
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {data.Inter === "No" ? (
                                  <div style={{ marginTop: "15px" }}>
                                    <select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      style={{
                                        border: "2px solid #9D9D9D",
                                        width: "150px",
                                        height: "40px",
                                        borderRadius: "6px",
                                      }}
                                      variant="outlined"
                                      name="InterviewedRemark"
                                      value={data.InterviewedRemark}
                                      onChange={(event) =>
                                        setInterviewedRemark(event.target.value)
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="Position on hold">
                                        Position on hold
                                      </option>
                                      <option value="internally closed">
                                        internally closed
                                      </option>
                                      <option value="not reached">
                                        not reached
                                      </option>
                                      <option value="not intrested">
                                        not intrested
                                      </option>
                                      <option value="other reason">
                                        other reason
                                      </option>
                                    </select>
                                  </div>
                                ) : null}
                              </div>

                              <div
                                style={{
                                  width: "max-content",
                                  color: "#2E2E2E",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "15.62px",
                                  filter:
                                    data.Inter == "Yes" ||
                                    data.Interviewed == "Yes"
                                      ? "none"
                                      : "blur(5px)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-inline-flex",
                                    background: "#E8F9F8",
                                    columnGap: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#525252",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    Selected
                                  </p>
                                  <select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{
                                      border: "2px solid #9D9D9D",
                                      width: "70px",
                                      height: "40px",
                                      borderRadius: "6px",
                                    }}
                                    disabled={
                                      data.selected ||
                                      localStorage.getItem("roleName") ==
                                        "vendor"
                                    }
                                    name="selected"
                                    variant="outlined"
                                    // value={data.selected}
                                    value={
                                      data.selected
                                        ? data.selected
                                        : data.Sel
                                        ? data.sel
                                        : ""
                                    }
                                    //onChange={(event) => setSelected(event.target.value)}
                                    onChange={(event) =>
                                      handleClickSelected(event, i)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "max-content",
                                  color: "#2E2E2E",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "15.62px",

                                  filter:
                                    data.Sel == "Yes" || data.selected == "Yes"
                                      ? "none"
                                      : "blur(5px)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-inline-flex",
                                    background: "#E8F9F8",
                                    columnGap: "10px",
                                    width: "max-content",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#525252",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    Offered Accepted
                                  </p>
                                  <select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{
                                      border: "2px solid #9D9D9D",
                                      width: "70px",
                                      height: "40px",
                                      borderRadius: "6px",
                                    }}
                                    disabled={
                                      data.offeredAccepted ||
                                      localStorage.getItem("roleName") ==
                                        "vendor"
                                    }
                                    name="offeredAccepted"
                                    variant="outlined"
                                    // value={data.offeredAccepted}
                                    value={
                                      data.offeredAccepted
                                        ? data.offeredAccepted
                                        : data.Off
                                        ? data.Off
                                        : ""
                                    }
                                    //onChange={(event) => setOfferedAccepted(event.target.value)}
                                    onChange={(event) =>
                                      handleClickAccepted(event, i)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {data.Off == "No" ? (
                                  <div style={{ marginTop: "10px" }}>
                                    <select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      style={{
                                        border: "2px solid #9D9D9D",
                                        width: "175px",
                                        height: "40px",
                                        borderRadius: "6px",
                                      }}
                                      variant="outlined"
                                      name="offeredAcceptedRemark"
                                      value={data.offeredAcceptedRemark}
                                      onChange={(event) =>
                                        setOfferedAcceptedRemark(
                                          event.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="1">
                                        retained by current company
                                      </option>
                                      <option value="2">
                                        expecting more salary
                                      </option>
                                      <option value="3">
                                        not ready for relocation
                                      </option>
                                      <option value="4">
                                        not intrested in company
                                      </option>
                                      <option value="5">
                                        got better offer
                                      </option>
                                      <option value="6">
                                        notice period is high
                                      </option>
                                      <option value="7">other reason</option>
                                    </select>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  width: "max-content",
                                  color: "#2E2E2E",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "15.62px",
                                  filter:
                                    data.Off == "Yes" ||
                                    data.offeredAccepted == "Yes"
                                      ? "none"
                                      : "blur(5px)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-inline-flex",
                                    background: "#E8F9F8",
                                    columnGap: "10px",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#525252",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    Joined
                                  </p>
                                  <select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    style={{
                                      border: "2px solid #9D9D9D",
                                      width: "70px",
                                      height: "40px",
                                      borderRadius: "6px",
                                    }}
                                    name="joined"
                                    disabled={
                                      (data.Off != "Yes" &&
                                        data.offeredAccepted != "Yes") ||
                                      localStorage.getItem("roleName") ==
                                        "vendor"
                                    }
                                    variant="outlined"
                                    value={data.joined}
                                    //onChange={(event) => setJoined(event.target.value)}
                                    onChange={(event) =>
                                      handleClickJoined(event, i)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </div>
                                {data.Join == "No" ? (
                                  <div style={{ marginTop: "15px" }}>
                                    <select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      style={{
                                        border: "2px solid #9D9D9D",
                                        width: "118px",
                                        height: "40px",
                                        borderRadius: "6px",
                                      }}
                                      variant="outlined"
                                      name="joinedRemark"
                                      value={data.joinedRemark}
                                      onChange={(event) =>
                                        setJoinedRemark(event.target.value)
                                      }
                                    >
                                      <option value="">Select</option>
                                      <option value="1">
                                        retained by current company
                                      </option>
                                      <option value="2">
                                        expecting more salary
                                      </option>
                                      <option value="3">
                                        not ready for relocation
                                      </option>
                                      <option value="4">
                                        not intrested in company
                                      </option>
                                      <option value="5">
                                        got better offer
                                      </option>
                                      <option value="6">
                                        notice period is high
                                      </option>
                                      <option value="7">other reason</option>
                                    </select>
                                  </div>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  width: "max-content",
                                  color: "#2E2E2E",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "15.62px",

                                  filter:
                                    data.Join == "Yes" || data.joined == "Yes"
                                      ? "none"
                                      : "blur(5px)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-inline-flex",
                                    background: "#E8F9F8",
                                    columnGap: "10px",
                                    width: "max-content",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#525252",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    Joining Date
                                  </p>
                                  <TextField
                                    size="small"
                                    type="date"
                                    name="joiningDate"
                                    style={{
                                      border: "1px solid #9D9D9D",
                                      borderRadius: "6px",
                                      font: "small-caption",
                                      height: "40px",
                                      background: "#FFFFFF",
                                    }}
                                    disabled={
                                      (data.Join != "Yes" &&
                                        data.joined != "Yes") ||
                                      localStorage.getItem("roleName") ==
                                        "vendor"
                                    }
                                    // value={data.joiningDate}
                                    // onChange={(event) =>
                                    //   setJoiningDate(event.target.value)
                                    // }
                                    onChange={(event) =>
                                      handleJoiningDate(event, i)
                                    }
                                    placeholder="Enter Here"
                                    variant="outlined"
                                    inputProps={{
                                      max: maxDate,
                                      min: new Date(data.createdAt)
                                        .toISOString()
                                        .split("T")[0],
                                    }}
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  width: "max-content",
                                  color: "#2E2E2E",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "15.62px",

                                  filter:
                                    data.Join == "Yes" || data.joined == "Yes"
                                      ? "none"
                                      : "blur(5px)",
                                }}
                              >
                                <div
                                  style={{
                                    display: "-webkit-inline-flex",
                                    background: "#E8F9F8",
                                    columnGap: "10px",
                                    width: "max-content",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: "#525252",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    {" "}
                                    Accepted CTC (Monthly)
                                  </p>
                                  <TextField
                                    size="small"
                                    type="number"
                                    name="acceptedCtc"
                                    style={{
                                      border: "1px solid #9D9D9D",
                                      borderRadius: "6px",
                                      font: "small-caption",
                                      width: "120px",
                                      height: "40px",
                                      background: "#FFFFFF",
                                      //filter: componentVisible3 ? 'none' : 'blur(5px)'
                                    }}
                                    disabled={
                                      (data.Join != "Yes" &&
                                        data.joined != "Yes") ||
                                      localStorage.getItem("roleName") ==
                                        "vendor"
                                    }
                                    value={data.acceptedCtc}
                                    onChange={(event) =>
                                      setAccptedCtc(event.target.value)
                                    }
                                    placeholder="Enter Here"
                                    variant="outlined"
                                  />
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </div>
                        <div>
                          <TableCell
                            style={{
                              border: "0",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ paddingRight: "0px" }}>
                              <button
                                style={{
                                  backgroundColor:
                                    localStorage.getItem("roleName") == "vendor"
                                      ? "gray"
                                      : "#3B82F6",
                                  width: "50px",
                                  height: "35px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#FFFFFF",
                                  borderRadius: "6px",
                                  border:
                                    localStorage.getItem("roleName") == "vendor"
                                      ? "1px solid #gray"
                                      : "1px solid #3B82F6",
                                  cursor:
                                    localStorage.getItem("roleName") == "vendor"
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                disabled={
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                onClick={() => saveCandidateStatus(data)}
                              >
                                Save
                              </button>
                            </div>
                          </TableCell>
                        </div>
                      </TableRow>
                      {/* </div> */}
                    </Table>
                  );
                })}
          </div>
        </div>

        {/* desktop View */}

        <div
          className="candidatestatusdis"
          style={{ height: "54.5vh", overflowY: "scroll" }}
        >
          {foundUsers &&
            foundUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((data, i) => {
                return (
                  <Table key={data.name}>
                    <TableRow>
                      <TableCell
                        style={{
                          width: "8%",
                          color: "#2E2E2E",
                          textAlign: "start",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {new Date(data.createdAt).toDateString()}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "12%",
                          color: "#2E2E2E",
                          // textAlign: "center",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {data.clientName}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "12%",
                          color: "#2E2E2E",
                          // textAlign: "center",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {data.positionName}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "10%",
                          color: "#2E2E2E",
                          // textAlign: "center",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {data.candidateName}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "8%",
                          color: "#2E2E2E",
                          // textAlign: "center",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        {data.contactNo}
                      </TableCell>

                      <TableCell
                        style={{
                          width: "45%",
                          overflowX: "scroll",
                          padding: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "300px",
                            columnGap: "20px",
                          }}
                        >
                          <div>
                            <button
                              onClick={() => handleDropCandidate(data)}
                              style={{
                                backgroundColor:
                                  localStorage.getItem("roleName") == "vendor"
                                    ? "gray"
                                    : "#3B82F6",
                                width: "50px",
                                height: "35px",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#FFFFFF",
                                borderRadius: "6px",
                                border: "1px solid #3B82F6",
                                cursor:
                                  localStorage.getItem("roleName") == "vendor"
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              disabled={
                                localStorage.getItem("roleName") == "Auditor" ||
                                localStorage.getItem("roleName") == "vendor"
                              }
                            >
                              Drop
                            </button>
                          </div>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>Enter Valid Reason</DialogContent>
                            <DialogContent>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setDropReason(e.target.value);
                                }}
                              />
                            </DialogContent>

                            <DialogActions>
                              <button onClick={handleClose}>Cancel</button>
                              <button onClick={() => confirmDrop(data)}>
                                Drop
                              </button>
                            </DialogActions>
                          </Dialog>
                          <div
                            style={{
                              width: " max-content",
                              color: "#2E2E2E",
                              textAlign: "start",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-inline-flex",
                                background: "#E8F9F8",
                                columnGap: "10px",
                              }}
                            >
                              <p
                                style={{
                                  color: "#525252",
                                  paddingLeft: "10px",
                                }}
                              >
                                Interviewed
                              </p>
                              {/* {console.log("check render or not", data.Inter)} */}
                              <select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{
                                  border: "2px solid #9D9D9D",
                                  width: "70px",
                                  height: "40px",
                                  borderRadius: "6px",
                                }}
                                disabled={
                                  data.Interviewed ||
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                name="Interviewed"
                                // value={data.Interviewed}
                                value={
                                  data.Interviewed
                                    ? data.Interviewed
                                    : data.Inter
                                    ? data.Inter
                                    : ""
                                }
                                //onChange={(event) => setInerviewed(event.target.value)}
                                onChange={(event) =>
                                  handleClickInterview(event, i)
                                }
                                variant="outlined"
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            {data.Inter === "No" ? (
                              <div style={{ marginTop: "15px" }}>
                                <select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  style={{
                                    border: "2px solid #9D9D9D",
                                    width: "150px",
                                    height: "40px",
                                    borderRadius: "6px",
                                  }}
                                  variant="outlined"
                                  name="InterviewedRemark"
                                  value={data.InterviewedRemark}
                                  onChange={(event) =>
                                    setInterviewedRemark(event.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Position on hold">
                                    Position on hold
                                  </option>
                                  <option value="internally closed">
                                    internally closed
                                  </option>
                                  <option value="not reached">
                                    not reached
                                  </option>
                                  <option value="not intrested">
                                    not intrested
                                  </option>
                                  <option value="other reason">
                                    other reason
                                  </option>
                                </select>
                              </div>
                            ) : null}
                          </div>

                          <div
                            style={{
                              width: "max-content",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",
                              filter:
                                data.Inter == "Yes" || data.Interviewed == "Yes"
                                  ? "none"
                                  : "blur(5px)",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-inline-flex",
                                background: "#E8F9F8",
                                columnGap: "10px",
                              }}
                            >
                              <p
                                style={{
                                  color: "#525252",
                                  paddingLeft: "10px",
                                }}
                              >
                                Selected
                              </p>
                              <select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{
                                  border: "2px solid #9D9D9D",
                                  width: "70px",
                                  height: "40px",
                                  borderRadius: "6px",
                                }}
                                disabled={
                                  data.selected ||
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                name="selected"
                                variant="outlined"
                                // value={data.selected}
                                value={
                                  data.selected
                                    ? data.selected
                                    : data.Sel
                                    ? data.sel
                                    : ""
                                }
                                //onChange={(event) => setSelected(event.target.value)}
                                onChange={(event) =>
                                  handleClickSelected(event, i)
                                }
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "max-content",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",

                              filter:
                                data.Sel == "Yes" || data.selected == "Yes"
                                  ? "none"
                                  : "blur(5px)",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-inline-flex",
                                background: "#E8F9F8",
                                columnGap: "10px",
                                width: "max-content",
                              }}
                            >
                              <p
                                style={{
                                  color: "#525252",
                                  paddingLeft: "10px",
                                }}
                              >
                                Offered Accepted
                              </p>
                              <select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{
                                  border: "2px solid #9D9D9D",
                                  width: "70px",
                                  height: "40px",
                                  borderRadius: "6px",
                                }}
                                disabled={
                                  data.offeredAccepted ||
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                name="offeredAccepted"
                                variant="outlined"
                                // value={data.offeredAccepted}
                                value={
                                  data.offeredAccepted
                                    ? data.offeredAccepted
                                    : data.Off
                                    ? data.Off
                                    : ""
                                }
                                //onChange={(event) => setOfferedAccepted(event.target.value)}
                                onChange={(event) =>
                                  handleClickAccepted(event, i)
                                }
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            {data.Off == "No" ? (
                              <div style={{ marginTop: "10px" }}>
                                <select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  style={{
                                    border: "2px solid #9D9D9D",
                                    width: "175px",
                                    height: "40px",
                                    borderRadius: "6px",
                                  }}
                                  variant="outlined"
                                  name="offeredAcceptedRemark"
                                  value={data.offeredAcceptedRemark}
                                  onChange={(event) =>
                                    setOfferedAcceptedRemark(event.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="retained by current company">
                                    retained by current company
                                  </option>
                                  <option value="expecting more salary">
                                    expecting more salary
                                  </option>
                                  <option value="not ready for relocation">
                                    not ready for relocation
                                  </option>
                                  <option value="not intrested in company">
                                    not intrested in company
                                  </option>
                                  <option value="got better offer">
                                    got better offer
                                  </option>
                                  <option value="notice period is high">
                                    notice period is high
                                  </option>
                                  <option value="other reason">
                                    other reason
                                  </option>
                                </select>
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              width: "max-content",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",
                              filter:
                                data.Off == "Yes" ||
                                data.offeredAccepted == "Yes"
                                  ? "none"
                                  : "blur(5px)",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-inline-flex",
                                background: "#E8F9F8",
                                columnGap: "10px",
                              }}
                            >
                              <p
                                style={{
                                  color: "#525252",
                                  paddingLeft: "10px",
                                }}
                              >
                                Joined
                              </p>
                              <select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                style={{
                                  border: "2px solid #9D9D9D",
                                  width: "70px",
                                  height: "40px",
                                  borderRadius: "6px",
                                }}
                                name="joined"
                                disabled={
                                  (data.Off != "Yes" &&
                                    data.offeredAccepted != "Yes") ||
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                variant="outlined"
                                value={data.joined}
                                //onChange={(event) => setJoined(event.target.value)}
                                onChange={(event) =>
                                  handleClickJoined(event, i)
                                }
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                            </div>
                            {data.Join == "No" ? (
                              <div style={{ marginTop: "15px" }}>
                                <select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  style={{
                                    border: "2px solid #9D9D9D",
                                    width: "118px",
                                    height: "40px",
                                    borderRadius: "6px",
                                  }}
                                  variant="outlined"
                                  name="joinedRemark"
                                  value={data.joinedRemark}
                                  onChange={(event) =>
                                    setJoinedRemark(event.target.value)
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="retained by current company">
                                    retained by current company
                                  </option>
                                  <option value="expecting more salary">
                                    expecting more salary
                                  </option>
                                  <option value="not ready for relocation">
                                    not ready for relocation
                                  </option>
                                  <option value="not intrested in company">
                                    not intrested in company
                                  </option>
                                  <option value="got better offer">
                                    got better offer
                                  </option>
                                  <option value="notice period is high">
                                    notice period is high
                                  </option>
                                  <option value="other reason">
                                    other reason
                                  </option>
                                </select>
                              </div>
                            ) : null}
                          </div>
                          <div
                            style={{
                              width: "max-content",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",

                              filter:
                                data.Join == "Yes" || data.joined == "Yes"
                                  ? "none"
                                  : "blur(5px)",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-inline-flex",
                                background: "#E8F9F8",
                                columnGap: "10px",
                                width: "max-content",
                              }}
                            >
                              <p
                                style={{
                                  color: "#525252",
                                  paddingLeft: "10px",
                                }}
                              >
                                Joining Date
                              </p>
                              <TextField
                                size="small"
                                type="date"
                                name="joiningDate"
                                style={{
                                  border: "1px solid #9D9D9D",
                                  borderRadius: "6px",
                                  font: "small-caption",
                                  height: "40px",
                                  background: "#FFFFFF",
                                }}
                                disabled={
                                  (data.Join != "Yes" &&
                                    data.joined != "Yes") ||
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                // value={data.joiningDate}
                                onChange={(event) =>
                                  handleJoiningDate(event, i)
                                }
                                placeholder="Enter Here"
                                variant="outlined"
                                inputProps={{
                                  max: maxDate,
                                  min: new Date(data.createdAt)
                                    .toISOString()
                                    .split("T")[0],
                                }}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              width: "max-content",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",

                              filter:
                                data.Join == "Yes" || data.joined == "Yes"
                                  ? "none"
                                  : "blur(5px)",
                            }}
                          >
                            <div
                              style={{
                                display: "-webkit-inline-flex",
                                background: "#E8F9F8",
                                columnGap: "10px",
                                width: "max-content",
                              }}
                            >
                              <p
                                style={{
                                  color: "#525252",
                                  paddingLeft: "10px",
                                }}
                              >
                                {" "}
                                Accepted CTC (Monthly)
                              </p>
                              <TextField
                                size="small"
                                type="number"
                                name="acceptedCtc"
                                style={{
                                  border: "1px solid #9D9D9D",
                                  borderRadius: "6px",
                                  font: "small-caption",
                                  width: "120px",
                                  height: "40px",
                                  background: "#FFFFFF",
                                  //filter: componentVisible3 ? 'none' : 'blur(5px)'
                                }}
                                disabled={
                                  (data.Join != "Yes" &&
                                    data.joined != "Yes") ||
                                  localStorage.getItem("roleName") == "vendor"
                                }
                                value={data.acceptedCtc}
                                onChange={(event) =>
                                  handleAcceptedCTC(event, i)
                                }
                                placeholder="Enter Here"
                                variant="outlined"
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              width: "max-content",
                              color: "#2E2E2E",
                              textAlign: "center",
                              fontSize: "12px",
                              fontWeight: 500,
                              lineHeight: "15.62px",

                              filter:
                                data.Join == "Yes" || data.joined == "Yes"
                                  ? "none"
                                  : "blur(5px)",
                            }}
                          >
                            {data.hiring_type === "Contractual" && (
                              <div
                                style={{
                                  display: "-webkit-inline-flex",
                                  background: "#E8F9F8",
                                  columnGap: "10px",
                                  width: "max-content",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#525252",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Emp. Code
                                </p>
                                <TextField
                                  size="small"
                                  type="text"
                                  name="empCode"
                                  style={{
                                    border: "1px solid #9D9D9D",
                                    borderRadius: "6px",
                                    font: "small-caption",
                                    width: "120px",
                                    height: "40px",
                                    background: "#FFFFFF",
                                  }}
                                  disabled={
                                    (data.Join != "Yes" &&
                                      data.joined != "Yes") ||
                                    localStorage.getItem("roleName") == "vendor"
                                  }
                                  value={data.empCode}
                                  onChange={(event) => handleEmpCode(event, i)}
                                  placeholder="Enter Here"
                                  variant="outlined"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div style={{ paddingRight: "0px" }}>
                          <button
                            style={{
                              backgroundColor:
                                localStorage.getItem("roleName") == "vendor"
                                  ? "gray"
                                  : "#3B82F6",
                              width: "50px",
                              height: "35px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFFFFF",
                              borderRadius: "6px",
                              border:
                                localStorage.getItem("roleName") == "vendor"
                                  ? "1px solid #gray"
                                  : "1px solid #3B82F6",
                              cursor:
                                localStorage.getItem("roleName") == "vendor"
                                  ? "not-allowed"
                                  : "pointer",
                            }}
                            disabled={
                              localStorage.getItem("roleName") == "vendor"
                            }
                            onClick={() => saveCandidateStatus(data)}
                          >
                            Save
                          </button>
                        </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                );
              })}
        </div>
        <Table>
          <TableRow style={{ backgroundColor: "#DCE0EB" }}>
            <TableCell
              className="flexcontain"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "0px",
              }}
            >
              <div className="-ml-4">
                <TablePagination
                  component="div"
                  size="small"
                  count={foundUsers.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </div>
  );
}
export default CandidateStatus;
