import React from 'react';
import Select from 'react-select';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Step1Form = ({ 
    handleCheckboxChange,
    handleInputChange ,
    agreementDuration,
    headOffice,
    registeredAddress,
    handleContact,
    dateOfAgreement,
    city,
    state,
    contactNumber,
    contactPersonName,
    removeContactField,
    addContactField,
    contacts,
    address,
    tanNo,
    panNo,
    gatNo,
    email,
    vendorName,
    panNoError,
    company,companyList,handleChangeCompany,handledropdownSelectChange,dropdownSelected

}) => {
  return (
    <div>
      <h2 style={{textAlign:"center",marginTop:"0",paddingTop:"0"}}>Step 1 : Vendor Details</h2>
      <div style={{ width:"100%",fontSize:"13px",width:"48%" }}>
              <p style={{margin:"0"}}>Select Organisation:</p>
              <select 
              // disabled={loader == true}
                className="addclintone"
                  style={{
                  width:"100%",
                  height:"32px",

                }}
                value={company}
                onChange={handleChangeCompany}
              >
                {companyList.map((n) => (
                  <option value={n.company}>{n.company}</option>
                ))}
              </select>
            </div>


   <div style={{width:"100%",fontSize:"13px",display:"flex",flexWrap:"wrap",justifyContent:"space-between", alignItems:"center"}}> 
      <div style={{width:"48%"}} >
         <p style={{margin:"15px 0 0 0"}}>Vendor Name:</p>
           <input
             style={{width:"96%",height:"32px",paddingLeft:"10px"}}
             type="text"
             name="vendorName"
             value={vendorName}
             onChange={handleInputChange}
           />
      </div>



      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Legal Vendor Status:</p>
        <Select
          value={{ value: dropdownSelected, label: dropdownSelected }}
          onChange={handledropdownSelectChange}    
          options={[
            { value: 'Ltd Company', label: 'Ltd Company' },
            { value: 'Pvt Ltd', label: 'Pvt Ltd' },
            { value: 'LLP', label: 'LLP' },
            { value: 'Partnership', label: 'Partnership' },
            { value: 'Proprietorship', label: 'Proprietorship' },
            { value: 'Non Profitable Organization', label: 'Non Profitable Organization' },
            { value: 'Government Organization', label: 'Government Organization' },
          ]}
        />
      </div>




      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Email ID:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="email"
          name="email"
          value={email}
          onChange={handleInputChange}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Address:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="address"
          value={address}
          onChange={handleInputChange}
        />
      </div>
      {/* <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Contact Person Name:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="contactPersonName"

          value={contactPersonName}
          onChange={handleInputChange}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Contact Number:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="contactNumber"
          value={contactNumber}
          onChange={handleInputChange}
        />
      </div> */}
<div style={{width: '100%',border:"1px solid gray",margin:"15px 0 0 0", padding:"0 0 25px 0",borderRadius:"8px"}}>

{contacts && contacts.map((contact, index) => (
        <div key={index}
         style={{ display: 'flex', width: '100%',position:"relative" ,justifyContent:"space-between",alignItems:"center",}}
         >
          <div style={{ width: "46%" ,paddingLeft:"15px"}}>
            <p style={{ margin: "15px 0 0 0" }}>Contact Person Name:</p>
            <input
              style={{ width: "96%", height: "32px", paddingLeft: "10px" }}
              type="text"
              name="contactPersonName"
              value={contact.contactPersonName}
              onChange={(e) => handleContact(index, e)}
            />
          </div>
          <div style={{ width: "45%",paddingRight:"20px" }}>
            <p style={{ margin: "15px 0 0 0" }}>Contact Number:</p>
            <input
              style={{ width: "96%", height: "32px", paddingLeft: "10px" }}
              type="number"
              name="contactNumber"
              value={contact.contactNumber}
              onChange={(e) => handleContact(index, e)}
            />
          </div>
            {index === contacts.length - 1 ? (
              <div style={{position:"absolute",right:"10px",bottom:"-25px",cursor: 'pointer',color:"blue"}}  onClick={addContactField}>
              <FaPlus size={11} style={{  }} /> Add more 
              </div>
            ) : (
              <div style={{position:"absolute",right:"3px",bottom:"8px"}}>
              <FaMinus size={10} onClick={() => removeContactField(index)} style={{ cursor: 'pointer',color:"red"}} />
              </div> )}
        </div>
      ))}


</div>


      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>State:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="state"
          value={state}
          onChange={handleInputChange}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>City:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="city"
          value={city}
          onChange={handleInputChange}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Registered Office Address:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="registeredAddress"
          value={registeredAddress}
          onChange={handleInputChange}
        />
      </div>
   
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Head Office:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="headOffice"
          value={headOffice}
          onChange={handleInputChange}
        />
      </div>



      <div style={{width:"48%",position:"relative"}}>
        <p style={{margin:"15px 0 0 0"}}>Vendor PAN NO.</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={panNo.toUpperCase()}
          name="panNo"
          onChange={handleInputChange}
        />
      {panNoError && <span style={{ color: 'red',position:"absolute",fontSize:"10px" }}>{panNoError}</span>}

      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Vendor GST NO.</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={gatNo}
          name="gatNo"
          onChange={handleInputChange}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Vendor TAN NO.</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={tanNo}
          name="tanNo"
          onChange={handleInputChange}
        />
      </div>

      {/* <div>
        <p style={{margin:"15px 0 0 0"}}>Date of Agreement:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="date"
          name="dateOfAgreement"
          value={dateOfAgreement}
          onChange={handleInputChange}
        />
      </div>
      <div>
        <p style={{margin:"15px 0 0 0"}}>Agreement Duration:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="agreementDuration"
          value={agreementDuration}
          onChange={handleInputChange}
        />
      </div> */}
    </div>

    </div>
  );
};

export default Step1Form;
