import { CLIENT_LIST,ADDROLE,ADDBRANCH,ADDTYPEHIRING,ADDDIVISION,
    GET_ROLE,GET_BRANCH,GET_TYPE_HIRING,GET_DIVISION,DELETE_ROLE,
    DELETE_BRANCH,DELETE_TYPE_HIRING,DELETE_DIVISION,GET_USER_LIST,
    ADDUSER,GETEMPLOYEELIST,ADD_CLIENT,
    GET_CANDIDATE, SET_CANDIDATE,ADD_CANDIDATE,
    POST_REQUISITION, PUT_REQUISITION, GET_REQUISITION, SET_REQUISITION ,UPDATE_CANDIDATE,GET_COMPANY,
    ADD_COMPANY,DELETE_COMPANY,GET_DESIGNATION,GET_REQUISITION_BY_TAG,
    ADD_BILLING_STATUS,GET_BILLING_STATUS,SET_BILLING_STATUS,GETALLCANDIDATE,
    GET_CAND_FOR_REVENUE,
    GET_ONE_TIME_SOURCE_CANDIDATE,
    GET_PERMANENT_CAND_REVENUE,GSTOKEN, GET_BRANCHBYCLIENT,
    ADD_VENDER, GET_VENDER, SET_VENDER ,GET_CLIENTBY_COMPANY, SET_ALL_CLIENTBY_COMPANY, GET_ALL_CLIENTBY_COMPANY
 } from "./constant";

 export const addVender = (obj) => {
    return {
        type: ADD_VENDER,
        payload: obj
    }
}

export const setVender = (obj) => {
    return {
        type: SET_VENDER,
        payload: obj
    }
}

export const getVender = (obj) => {
    return {
        type: GET_VENDER,
        payload: obj
    }
}

export const getClient = (obj) => {
    return {
        type: CLIENT_LIST,
        payload: obj
    }
}

export const getRole = (obj) => {
    return {
        type: GET_ROLE,
        payload: obj
    }
}

export const getBranch = (obj) => {
    return {
        type: GET_BRANCH,
        payload: obj
    }
}

export const getTypeHiring = (obj) => {
    return {
        type: GET_TYPE_HIRING,
        payload: obj
    }
}

export const getDivision = (obj) => {
    return {
        type: GET_DIVISION,
        payload: obj
    }
}

export const getCompany = (obj) => {
    return {
        type: GET_COMPANY,
        payload: obj
    }
}

export const addRole = (obj) => {
    return {
        type: ADDROLE,
        payload: obj
    }
}

export const addBranch = (obj) => {
    return {
        type: ADDBRANCH,
        payload: obj
    }
}

export const addTypeHiring = (obj) => {
    return {
        type: ADDTYPEHIRING,
        payload: obj
    }
}

export const addDivision = (obj) => {
    return {
        type: ADDDIVISION,
        payload: obj
    }
}

export const addCompany = (obj) => {
    return {
        type: ADD_COMPANY,
        payload: obj
    }
}

export const deleteRole = (obj) => {
    return {
        type: DELETE_ROLE,
        payload: obj
    }
}

export const deleteBranch = (obj) => {
    return {
        type: DELETE_BRANCH,
        payload: obj
    }
}

export const deleteTypeOfHiring = (obj) => {
    return {
        type: DELETE_TYPE_HIRING,
        payload: obj
    }
}

export const deleteDivision = (obj) => {
    return {
        type: DELETE_DIVISION,
        payload: obj
    }
}

export const getUserList = (obj) => {
    return {
        type: GET_USER_LIST,
        payload: obj
    }
}

export const addUser = (obj) => {
    return {
        type: ADDUSER,
        payload: obj
    }
}

export const getEmployeeList = (obj) => {
    return {
        type: GETEMPLOYEELIST,
        payload: obj
    }
}

export const addClient = (obj) => {
    return {
        type: ADD_CLIENT,
        payload: obj
    }
}

export const getCandidate = (obj) => {
    return {
        type: GET_CANDIDATE,
        payload: obj
    }
}

export const setCandidate = (obj) => {
    return {
        type: SET_CANDIDATE,
        payload: obj
    }
}
export const addCandidate = (obj) => {
    console.log("sdfghjk",obj)
    return {
        type: ADD_CANDIDATE,
        payload: obj
    }
}
export const updateCandidate = (obj) => {
    return {
        type:UPDATE_CANDIDATE,
        payload:obj
    }
}
export const postRequisition = (obj) => {
    console.log("POST DTA",obj)
    return {
        type: POST_REQUISITION,
        payload: obj
    }
}
export const putRequisition = (obj) => {
    return {
        type: PUT_REQUISITION,
        payload: obj
    }
}

export const getRequisitionByTag = () => {
    return {
        type: GET_REQUISITION_BY_TAG
    }
}

export const getRequisition = () => {
    return {
        type: GET_REQUISITION
    }
}

export const setRequisition = (obj) => {
    return {
        type: SET_REQUISITION,
        payload: obj
    }
}

export const deleteCompany=(obj)=>{
    return{
        type:DELETE_COMPANY,
        payload:obj
    }
}

export const getDesignation = (obj) => {
    return {
        type: GET_DESIGNATION,
        payload: obj
    }
}
export const addBillingStatus = (obj) =>{
    return{
        type: ADD_BILLING_STATUS,
        payload: obj
    }
}
export const getBillingStatus = (obj) =>{
    return{
        type: GET_BILLING_STATUS,
        payload: obj
    }
}
export const setBillingStatus = (obj) =>{
    return{
        type: SET_BILLING_STATUS,
        payload: obj
    }
}

export const getAllCandidate=(data)=>{
    return{
        type:GETALLCANDIDATE,
        payload:data
    }
}

export const getCandForRevunue = () => {
    return {
        type: GET_CAND_FOR_REVENUE,
    }
}

export const getOneTimeSoueceCand = () => {
    return {
        type: GET_ONE_TIME_SOURCE_CANDIDATE,
    }
}

export const getPermanentCand = () => {
    return {
        type: GET_PERMANENT_CAND_REVENUE,
    }
}
export const gSTokenD = (o) => {
    return {
        type: GSTOKEN,
        l: o
    }
}

export const getBranchListByClient=(obj)=>{
    return{
        type:GET_BRANCHBYCLIENT,
        payload:obj
    }
}

export const getClientByCompany=(data)=>{
    return{
        type:GET_CLIENTBY_COMPANY,
        payload:data
    }
}

export const getAllClientByCompany=(data)=>{
    return{
        type:GET_ALL_CLIENTBY_COMPANY,
        payload:data
    }
}