import React, { useEffect, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  getCandidate,
  getClient,
  getBillingStatus,
  addBillingStatus,
  getUserList,
  getCandForRevunue,
} from "../../../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Popover,
  TablePagination,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";

import axios from "axios";

const { URL } = require("../../../../config.json");

function ContractualOnMonthly() {
  let dispatch = useDispatch();

  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);

  let candidateList = useSelector((state) => state.clientReducer.candidateList);
  let UserList = useSelector((state) => state.clientReducer.userList);
  let candidateBilling = useSelector(
    (state) => state.clientReducer.candidateBilling
  );
  let clientList = useSelector((state) => state.clientReducer.clientList);
  let candidateForRevenue = useSelector(
    (state) => state.clientReducer.candForRevenue
  );

  console.log("candidateForRevenue1", candidateForRevenue);

  useEffect(() => {
    dispatch(getCandidate());
    dispatch(getClient());
    dispatch(getBillingStatus());
    dispatch(getUserList());
    dispatch(getCandForRevunue());
  }, []);

  const [newContr, setNewContr] = useState([]);
  let [foundCandidate1, setFoundCandidate1] = useState(newContr);
  console.log("foundCandidate1", foundCandidate1);

  useEffect(() => {
    const contrCandidate = candidateBilling.filter(
      (can) => can.hiring_type === "Contractual"
    );
    let arr = [];
    clientList.forEach((obj, i) => {
      // if (obj.contractual && obj.contractual.contractualMarginAmount) {
      contrCandidate.forEach((contr) => {
        if (contr.clientId === obj._id) {
          const newObj = { ...contr };
          newObj.contractual = { ...obj.contractual };
          newObj.annualCTC = newObj.final_ctc || newObj.acceptedCtc;
          if (arr.length == 0) {
            arr.push(newObj);
          } else {
            if (arr.find((abc) => abc._id != newObj._id)) {
              arr.push(newObj);
            }
          }
        }
      });
      // }
      if (clientList.length == i + 1) {
        setTimeout(() => {
          setNewContr(arr);
        }, [100]);
      }
    });
  }, [candidateBilling]);

  let [foundCandidate, setFoundCandidate] = useState();

  useEffect(() => {
    setFoundCandidate1(newContr);
    setFoundCandidate(candidateForRevenue);
  }, [newContr]);

  function reqCreatedBy(id) {
    return UserList.find((u) => u && u._id == id);
  }

  // function handleChangeFinalCtc(e, data, i) {
  //   foundCandidate1[i].annualCTC = e.target.value;
  //   let obj = foundCandidate1[page1 * rowsPerPage1 + i];
  //   let obj3 = Object.assign(obj, {
  //     annualCTC: e.target.value && e.target.value.slice(0, 10),
  //   });
  //   foundCandidate1.splice(page1 * rowsPerPage1 + i, 1, obj3);

  //   setFoundCandidate1([...foundCandidate1]);
  // }

  function handleChangeFinalempCode(e, data, i) {
    const updatedCandidate = [...foundCandidate1];
    updatedCandidate[i].empCode = e.target.value;
    
    // Assuming empCode is a string with a format you want to slice
    const slicedValue = e.target.value && e.target.value.slice(0, 10);
    
    updatedCandidate.splice(page1 * rowsPerPage1 + i, 1, {
      ...updatedCandidate[page1 * rowsPerPage1 + i],
      empCode: slicedValue,
    });
  
    setFoundCandidate1(updatedCandidate);
  }
  function handleChangeFinalCtc(e, data, i) {
    const updatedCandidate = [...foundCandidate1];
    updatedCandidate[i].annualCTC = e.target.value;
    
    // Assuming annualCTC is a string with a format you want to slice
    const slicedValue = e.target.value && e.target.value.slice(0, 10);
    
    updatedCandidate.splice(page1 * rowsPerPage1 + i, 1, {
      ...updatedCandidate[page1 * rowsPerPage1 + i],
      annualCTC: slicedValue,
    });
  
    setFoundCandidate1(updatedCandidate);
  }

  // function handleChangeTerms(e, data, i) {
  //   foundCandidate1[i].contractual.contractualMarginAmount = e.target.value;
  //   let obj = foundCandidate1[page1 * rowsPerPage1 + i];
  //   let updatedObj = {
  //     ...obj,
  //     contractual: {
  //       ...obj.contractual,
  //       contractualMarginAmount: e.target.value && e.target.value.slice(0, 10),
  //     },
  //   };
  //   foundCandidate1.splice(page1 * rowsPerPage1 + i, 1, updatedObj);
  //   setFoundCandidate1([...foundCandidate1]);
  // }

  function handleChangeTerms(e, data, i) {
    const updatedCandidate = [...foundCandidate1];
    updatedCandidate[i].contractual.contractualMarginAmount = e.target.value;
  
    let obj = updatedCandidate[page1 * rowsPerPage1 + i];
    
    let updatedObj = {
      ...obj,
      contractual: {
        ...obj.contractual,
        contractualMarginAmount: e.target.value && e.target.value.slice(0, 10),
      },
    };
  
    updatedCandidate.splice(page1 * rowsPerPage1 + i, 1, updatedObj);
    setFoundCandidate1(updatedCandidate);
  }


  function handleChangeMonth(e, data, i) {
    foundCandidate1[i].contractual.selectedMonth = e.target.value;
    setFoundCandidate1([...foundCandidate1]);
  }

  function saveBillingStatus(data, i) {
    let obj = {
      createdBy: data.createdBy,
      updatedById: localStorage.getItem("employeeId"),
      updatedByName: localStorage.getItem("employeeName"),
      dateOfSubmission:
        data.dateOfSubmission && data.dateOfSubmission.slice(0, 10),
      candidateName: data.candidateName,
      candidateId: data._id,
      clientName: data.clientName,
      clientId: data.clientId,
      positionName: data.positionName,
      RequisitionId: data.RequisitionId,
      joiningDate: data.joiningDate && data.joiningDate.slice(0, 10),
      hiring_type: data.hiring_type,
      // final_ctc: data.annualCTC,
      empCode: data.empCode,
      final_ctc:foundCandidate1[i]?.annualCTC,
      term_type: data.contractual.contractualMarginType,
      terms: foundCandidate1[i]?.contractual?.contractualMarginAmount ,
      // terms: data.contractual.contractualMarginAmount,
      selectedmonth: data.contractual.selectedMonth,

      // invoice_no: data.invoice_no,
      // date_of_invoice: data.date_of_invoice,
      billing_type: "Contractual On Monthly Basis",
    };
    console.log(
      "212line14",data.empCode,
      data.contractual.selectedMonth,
      obj,
      data.contractual.contractualMarginAmount
    );
    if (data.contractual.contractualMarginType == "Variable in %") {
      obj.revenue = Math.round(
        // (data.annualCTC *
        //   data.contractual.contractualMarginAmount *
        //   data.contractual.selectedMonth) /
        //   100
        (foundCandidate1[i]?.annualCTC *
                                foundCandidate1[i]?.contractual?.contractualMarginAmount *
                                data.contractual.selectedMonth) /
          100
      );
    }
    if (data.contractual.contractualMarginType == "Fixed in Rs") {
      obj.revenue =
        // data.contractual.contractualMarginAmount *
        // data.contractual.selectedMonth;
        foundCandidate1[i]?.contractual?.contractualMarginAmount * data.contractual.selectedMonth;
    }
    // if (obj.invoice_no == undefined) {
    //   alert("Please Enter Invoice Number")
    // }
    // else if (obj.date_of_invoice == undefined) {
    //   alert("Please Enter Invoice Date")
    // }
    dispatch(addBillingStatus(obj));

    alert("Successfully save BillingStatus");
    setTimeout(() => {
      dispatch(getBillingStatus());
      dispatch(getCandForRevunue());
    }, 300);
    // setNewContr([...newContr]);
    console.log(" billingstatus", obj);
    //   setTimeout(() => {
    //     //dispatch(getCandidate())
    //   }, 100)
  }

  function filter1(e) {
    let keyword = e.target.value;
    if (keyword != "") {
      const result = newContr.filter((c) => {
        return (
          (c.candidateName &&
            c.candidateName.toLowerCase().startsWith(keyword.toLowerCase())) ||
          (c.clientName &&
            c.clientName.toLowerCase().startsWith(keyword.toLowerCase())) ||
          (c.positionName &&
            c.positionName.toLowerCase().startsWith(keyword.toLowerCase()))
        );
      });
      setFoundCandidate1(result);
      setPage1(0);
    } else {
      setFoundCandidate1(newContr);
    }
  }

  // function filter(e) {
  //   let keyword = e.target.value;
  //   console.log("keyword",keyword)
  //   if (keyword != "") {
  //     const result = candidateForRevenue.filter((c) => {
  //       return (
  //         (c.candidateName &&
  //           c.candidateName.toLowerCase().startsWith(keyword.toLowerCase())) ||
  //         (c.clientName &&
  //           c.clientName.toLowerCase().startsWith(keyword.toLowerCase())) ||
  //         (c.positionName &&
  //           c.positionName.toLowerCase().startsWith(keyword.toLowerCase()))
  //       );
  //     });
  //     console.log("FilteredResult:", result);
  //     setFoundCandidate(result);
  //   } else {
  //     setFoundCandidate(candidateForRevenue);
  //   }
  // }

  console.log("newContr==", newContr);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValUser, setSearchValUser] = useState("");
  const [searchValClient, setSearchValClient] = useState("");
  const [openPopOver, setOpenPopOver] = useState(false);
  const [openDatePopOver, setOpenDatePopOver] = useState(false);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [openClientPopOver, setOpenClientPopOver] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    setPage1(0);
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setPage1(0);
    setEndDate(event.target.value);
  };

  const handleClickDateFilter = (event) => {
    // setPage1(0)
    setAnchorElDateFilter(event.currentTarget);
    setOpenDatePopOver(true);
  };

  const handleCloseDatePop = () => {
    setAnchorElDateFilter(null);
    setOpenDatePopOver(false);
  };

  const handleClearDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  // Filter the UserList based on the search value
  const filteredUserList = useMemo(() => {
    return UserList.filter((data) => {
      return (
        data.userName &&
        data.userName.toLowerCase().includes(searchValUser.toLowerCase())
      );
    });
  }, [searchValUser, UserList]);

  const filteredClientList = useMemo(() => {
    return clientList.filter((data) => {
      return (
        data.clientName &&
        data.clientName.toLowerCase().includes(searchValClient.toLowerCase())
      );
    });
  }, [searchValClient, clientList]);

  const handleCheckboxChange = (event, userName) => {
    setPage1(0);
    const checked = event.target.checked;
    if (checked) {
      setSelectedCheckboxes((prevList) => [...prevList, userName]);
    } else {
      setSelectedCheckboxes((prevList) =>
        prevList.filter((item) => item !== userName)
      );
    }
  };
  const handleClientCheckboxChange = (event, clientName) => {
    setPage1(0);
    const checked = event.target.checked;
    if (checked) {
      setSelectedClients((prevList) => [...prevList, clientName]);
    } else {
      setSelectedClients((prevList) =>
        prevList.filter((item) => item !== clientName)
      );
    }
  };

  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopOver(true);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
    setOpenPopOver(false);
    setSearchValUser("");
  };
  const handleClickClientCheckbox = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenClientPopOver(true);
    // setPage1(0)
  };

  const handleCloseClientPop = () => {
    setAnchorEl(null);
    setOpenClientPopOver(false);
    setSearchValClient("");
    // setPage1(0)
  };

  const filteredData = foundCandidate1.filter((data) => {
    
    const joiningDate = data.joiningDate && data.joiningDate.slice(0, 10);

    const userNameFilter =
      selectedCheckboxes.length === 0 ||
      (selectedCheckboxes &&
        selectedCheckboxes.includes(
          reqCreatedBy(data.createdBy) && reqCreatedBy(data.createdBy).userName
        ));

    const clientFilter =
      selectedClients.length === 0 || selectedClients.includes(data.clientName);

    const dateFilter =
      !startDate ||
      !endDate ||
      (joiningDate >= startDate && joiningDate <= endDate);

    return userNameFilter && clientFilter && dateFilter;
  });

  let EstRev =
    filteredData &&
    filteredData.map((e) => {
      let Es = 0;
      if (e.annualCTC == "NaN") {
        return Es;
      } else {
        if (e.contractual.contractualMarginType == "Fixed in Rs") {
          Es =
            e.contractual.contractualMarginAmount * e.contractual.selectedMonth;
          return Es;
        } else if (e.contractual.contractualMarginType == "Variable in %") {
          Es = Math.round(
            (e.annualCTC *
              e.contractual.contractualMarginAmount *
              e.contractual.selectedMonth) /
              100
          );
          return Es;
        } else {
          return Es;
        }
      }
    });

  EstRev = EstRev.filter((value) => !isNaN(value) && isFinite(value));

  let EstimateRevenue = 0;

  if (EstRev.length !== 0) {
    EstimateRevenue = EstRev.reduce(function (pre, curr) {
      return pre + curr;
    });
  }
  // let EstimateRevenue = 0;
  // if (EstRev.length != 0) {
  //   EstimateRevenue =
  //     EstRev &&
  //     EstRev.reduce(function (pre, curr) {
  //       return +pre + +curr;
  //     });
  // }

  console.log("sumSumsumsum", EstRev);

  // invoice data
  // const [foundCandidate, setFoundCandidate] = useState(permanentCandidate);
  const [selectedClientsInvoice, setSelectedClientsInvoice] = useState([]);
  const [startDateinvoice, setStartDateInvoice] = useState(null);
  const [endDateinvoice, setEndDateInvoice] = useState(null);
  const [openDatePopOverinvoice, setOpenDatePopOverInvoice] = useState(false);
  const [anchorElDateFilterinvoice, setAnchorElDateFilterInvoice] =
    useState(null);
  const [openClientPopOverinvoice, setOpenClientPopOverInvoice] =
    useState(false);
  const [searchValClientinvoice, setSearchValClientInvoice] = useState("");
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [openRecruiterPopOver, setOpenRecruiterPopOver] = useState(false);
  const [searchValRecruiter, setSearchValRecruiter] = useState("");
  const [keyword, setKeyword] = useState("");

  const filteredRecruiterList = useMemo(() => {
    return UserList.filter((data) => {
      return (
        data.userName &&
        data.userName.toLowerCase().includes(searchValRecruiter.toLowerCase())
      );
    });
  }, [searchValRecruiter, UserList]);

  const filteredClientListinvoice = useMemo(() => {
    return clientList.filter((data) => {
      return (
        data.clientName &&
        data.clientName
          .toLowerCase()
          .includes(searchValClientinvoice.toLowerCase())
      );
    });
  }, [searchValClientinvoice, clientList]);

  const handleClickClientCheckboxinvoice = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenClientPopOverInvoice(true);
  };

  const handleCloseClientPopinvoice = () => {
    setAnchorEl(null);
    setOpenClientPopOverInvoice(false);
    setSearchValClientInvoice("");
  };

  const handleClientCheckboxChangeinvoice = (event, clientName) => {
    const updatedSelectedClients = event.target.checked
      ? [...selectedClientsInvoice, clientName]
      : selectedClientsInvoice.filter((name) => name !== clientName);
    setSelectedClientsInvoice(updatedSelectedClients);
  };

  const handleStartDateChangeinvoice = (event) => {
    setStartDateInvoice(event.target.value);
  };

  const handleEndDateChangeinvoice = (event) => {
    setEndDateInvoice(event.target.value);
  };

  const handleClickDateFilterinvoice = (event) => {
    setAnchorElDateFilterInvoice(event.currentTarget);
    setOpenDatePopOverInvoice(true);
  };

  const handleCloseDatePopinvoice = () => {
    setAnchorElDateFilterInvoice(null);
    setOpenDatePopOverInvoice(false);
  };

  const handleClearDateFilterinvoice = () => {
    setStartDateInvoice(null);
    setEndDateInvoice(null);
  };

  const handleClickRecruiterCheckbox = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenRecruiterPopOver(true);
  };

  const handleCloseRecruiterPop = () => {
    setAnchorEl(null);
    setOpenRecruiterPopOver(false);
    setSearchValRecruiter("");
  };

  const handleClearClientFilter = () => {
    setSelectedClients([]);
  };

  const handleRecruiterCheckboxChange = (event, recruiterName) => {
    const updatedSelectedRecruiters = event.target.checked
      ? [...selectedRecruiters, recruiterName]
      : selectedRecruiters.filter((name) => name !== recruiterName);
    setSelectedRecruiters(updatedSelectedRecruiters);
  };

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  };

  const filterData = () => {
    return candidateForRevenue.filter((data) => {
      const joiningDate = data.joiningDate && data.joiningDate.slice(0, 10);

      const userNameFilter =
        selectedRecruiters.length === 0 ||
        (selectedRecruiters &&
          selectedRecruiters.includes(
            reqCreatedBy(data.createdBy) &&
              reqCreatedBy(data.createdBy).userName
          ));

      const clientFilter =
        selectedClientsInvoice.length === 0 ||
        selectedClientsInvoice.includes(data.clientName);

      const dateFilter =
        !startDateinvoice ||
        !endDateinvoice ||
        (joiningDate >= startDateinvoice && joiningDate <= endDateinvoice);

      const keywordFilter =
        !keyword ||
        (data.candidateName &&
          data.candidateName.toLowerCase().includes(keyword.toLowerCase())) ||
        (data.clientName &&
          data.clientName.toLowerCase().includes(keyword.toLowerCase())) ||
        (data.positionName &&
          data.positionName.toLowerCase().includes(keyword.toLowerCase()));

      return userNameFilter && clientFilter && dateFilter && keywordFilter;
    });
  };

  const filteredDatainvoice = filterData();
  console.log("hbhbhb", filteredDatainvoice);

  let rev =
    filteredDatainvoice &&
    filteredDatainvoice.map((j) => {
      return j.revenue || 0;
    });

  console.log("revrev", rev);

  let sum = 0;
  if (rev.length !== 0) {
    // Filter out non-numeric values and convert to numbers
    const numericRev = rev.filter((value) => !isNaN(value)).map(Number);

    // Sum the numeric values
    sum = numericRev.reduce(function (previousValue, currentValue) {
      return previousValue + currentValue;
    }, 0);
  }

  console.log("sum", sum);

  const handleClearRecruiterFilter = () => {
    setSelectedCheckboxes([]);
    // setClearRecruiterFilter(false);
  };
  const handleClearClientFilterinvoice = () => {
    setSelectedClientsInvoice([]);
  };
  const handleClearRecruiterFilterinvoice = () => {
    setSelectedRecruiters([]); 
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const getRowNumber = (i) => {
    return i + 1 + page1 * rowsPerPage1;
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedStatusValue, setSelectedStatusValue] = useState("active");
  const [selectedId, setSelectedId] = useState(null);
  const [inputError, setInputError] = useState(false);

  const handleOpenDialog = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setInputValue("");
  };

  const saveBillingStatusdrop = () => {
    const postData = {
      _id: selectedId,
      dropAfterJoin_Reason: inputValue,
      status: "false",
    };

    axios
      .put(`${URL}bi_candidate/dropCandidateAfterJoin`, postData)
      .then((response) => {
        setOpenDialog(false);

        setSelectedId(null);

        dispatch(getCandidate());
        dispatch(getClient());
        dispatch(getBillingStatus());
        dispatch(getUserList());
        dispatch(getCandForRevunue());
        alert(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      style={{
        fontFamily: "sans-serif",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <div
        className="displayjustify"
        // style={{ display: "flex", justifyContent: "end" }}
        style={{ position: "absolute", right: "5px", top: "-80px" }}
      >
        <div className="inputtextmobilediv">
          <TextField
            className="inputtextmobile"
            size="small"
            style={{
              width: "210px",
              borderRadius: "6px",
              background: "#FFFFFF",
            }}
            placeholder="Search Here"
            // value={clientName}
            onChange={filter1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="medium" style={{ color: "#767676" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </div>
      </div>

      <div className="clinettablelist" style={{}}>
        <div className="clinettablelistdivtwo" style={{}}>
          <div style={{ width: "100%", fontFamily: "sans-serif" }}>
            <Table>
              <TableHead>
                <TableRow
                  className="backgroundchange"
                  style={{ backgroundColor: "#F5FBFE" }}
                >
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "6%",
                      fontSize: "13.5px",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "8%",
                      fontSize: "13.5px",
                      paddingLeft: "20px",
                    }}
                  >
                    Emp. Code
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      // color: "#272945",
                      width: "8%",
                      fontSize: "13.5px",
                      padding: "5px 5px",

                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        // width: "8%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div style={{}}>Dt. of Joining</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickDateFilter}
                          checked={startDate || endDate}
                        />
                        {startDate || endDate ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "2px",
                              position: "absolute",
                              left: "1px",
                            }}
                            onClick={handleClearDateFilter}
                          />
                        ) : null}
                        <Popover
                          open={openDatePopOver}
                          anchorEl={anchorElDateFilter}
                          onClose={handleCloseDatePop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <div>
                            <div>
                              {" "}
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                  margin: "0",
                                  paddingBottom: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                Select Date
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ display: "block" }}>
                                <p
                                  style={{
                                    margin: "0",
                                    paddingLeft: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  From Date
                                </p>
                                <input
                                  type="date"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 0 5px 5px",
                                  }}
                                />
                              </div>
                              <p style={{ margin: "12px 5px 0" }}> -</p>
                              <div>
                                <p
                                  style={{
                                    margin: "0",
                                    textAlign: "start",
                                    fontSize: "10px",
                                  }}
                                >
                                  To Date
                                </p>
                                <input
                                  type="date"
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 5px 5px 0",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell
                    className="textfont"
                    style={{
                      // color: "#272945",
                      width: "7%",
                      fontSize: "13.5px",
                      padding: "5px 5px 5px 30px",
                      // display: "flex",
                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "7%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div style={{}}>Client</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickClientCheckbox}
                          checked={selectedClients.length > 0}
                          onChange={handleClickClientCheckbox}
                        />
                        {selectedClients.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "2px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearClientFilter}
                          />
                        ) : null}
                        <Popover
                          open={openClientPopOver}
                          anchorEl={anchorEl}
                          onClose={handleCloseClientPop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "700px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchValClient(e.target.value)}
                            placeholder="Search Here"
                          />
                          {filteredClientList.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Clients found with this name
                            </p>
                          ) : (
                            filteredClientList.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedClients.includes(
                                      data.clientName
                                    )}
                                    onChange={(event) =>
                                      handleClientCheckboxChange(
                                        event,
                                        data.clientName
                                      )
                                    }
                                  />
                                  {data.clientName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "center",
                      width: "10%",
                      // paddingLeft: '24px',
                      fontSize: "13.5px",
                    }}
                  >
                    Candidate
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "9%",
                      fontSize: "13.5px",
                      paddingLeft: "0",
                    }}
                  >
                    Position Name
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      width: "5%",
                      // fontSize: "13.5px",
                      padding: "5px 5px",
                      // display: "flex",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        width: "5%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                      }}
                    >
                      <div style={{ textAlign: "center", marginLeft: "5px" }}>
                        Recruiter
                      </div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickPop}
                          checked={selectedCheckboxes.length > 0}
                          onChange={handleClickPop}
                        />

                        {selectedCheckboxes.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearRecruiterFilter}
                          />
                        ) : null}

                        <Popover
                          // id={id}
                          open={openPopOver}
                          anchorEl={anchorEl}
                          onClose={handleClosePop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "1000px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchValUser(e.target.value)}
                            placeholder="Search Here"
                          />
                          {filteredUserList.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Recruiters found with this name
                            </p>
                          ) : (
                            filteredUserList.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedCheckboxes.includes(
                                      data.userName
                                    )}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, data.userName)
                                    }
                                  />
                                  {data.userName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                      paddingLeft: "20px",
                    }}
                  >
                    Life Span(Month)
                  </TableCell>
                

                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                      paddingLeft: "0px",
                    }}
                  >
                    Final CTC(Month)
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                    }}
                  >
                    Terms
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                    }}
                  >
                    Revenue
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                    }}
                  >
                    <select
                      style={{
                        width: "75px",
                        fontSize: "15px",
                        height: "25px",
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontWeight: "500",
                      }}
                      value={selectedStatusValue}
                      onChange={(e) => setSelectedStatusValue(e.target.value)}
                    >
                      <option
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          lineHeight: "150%",
                        }}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          lineHeight: "150%",
                        }}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </select>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "6%",
                      fontSize: "13.5px",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>

          <div
            className="heightscrollsecond"
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              width: "100%",
              height: "342px",
              overflowY: "scroll",
            }}
          >
            {filteredData &&
              filteredData
                .filter((data) => {
                  return (
                    (selectedStatusValue === "active" &&
                      data.status === "true") ||
                    (selectedStatusValue === "inactive" &&
                      data.status === "false")
                  );
                })
                .slice(page1 * rowsPerPage1, (page1 + 1) * rowsPerPage1)
                .map((data, i) => {
                  return (
                    <TableRow>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "6%",
                          fontSize: "12px",
                        }}
                      >
                        {/* {i++} */}
                        {getRowNumber(i)}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "11%",
                          fontSize: "12px",
                          paddingLeft: "3%",
                        }}
                      >
                        {/* <input
                          type="text"
                          style={{
                            width: "94px",
                            borderRadius: "5px",
                            background: "#FFFFFF",
                            border: "1px solid #9A9A9A",
                            height: "30px",
                          }}
                          placeholder="Enter Here"
                          name="emp_Code"
                          // value={data.acceptedCtc ? data.acceptedCtc * 12 : final_ctc}
                          // value={data.annualCTC}
                          // onChange={(e) => handleChangeFinalCtc(e, data, i)}
                          value={foundCandidate1[i]?.empCode || ''}  
                          onChange={(e) => handleChangeFinalempCode(e, data, i)}
                        /> */}
                        {data.empCode}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "9%",
                          fontSize: "12px",
                          // paddingLeft: "2.5%",
                        }}
                      >
                        {data.joiningDate && data.joiningDate.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                          //   paddingLeft: "1.2%",
                        }}
                      >
                        {data.clientName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          // paddingLeft: "1.6%",
                          fontSize: "12px",
                        }}
                      >
                        {data.candidateName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                          paddingLeft: "1.2%",
                        }}
                      >
                        {data.positionName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                          paddingLeft: "3.2%",
                        }}
                      >
                        {/* {data.createdBy ? canBy && canBy.userName && canBy.userName : ''} */}
                        {/* {data.createdBy} */}
                        {reqCreatedBy(data.createdBy) &&
                          reqCreatedBy(data.createdBy).userName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "11%",
                          fontSize: "12px",
                          paddingLeft: "2.8%",
                        }}
                      >
                        {/* <input
                        type="number"
                        style={{
                          width: "48px",
                          borderRadius: "5px",
                          background: "#FFFFFF",
                          border: "1px solid #9A9A9A",
                          height: "30px",
                          padding:"0 0 0 5px"
                        }}
                        placeholder="Enter Here"
                        // name="final_ctc"
                        // value={data.acceptedCtc ? data.acceptedCtc * 12 : final_ctc}
                        name="selectedmonth"
                        value=
                          {  data.contractual
                            ? data.contractual.selectedMonth || ""
                            : 0} 
                        
                        // onChange={(event) =>
                        //   handleChangeMonth(event, data, i)
                        // }
                      />  */}
                        {data.contractual
                          ? data.contractual.selectedMonth || ""
                          : 0}
                      </TableCell>

                    
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "11%",
                          fontSize: "12px",
                          paddingLeft: "1.6%",
                        }}
                      >
                        <input
                          type="number"
                          style={{
                            width: "94px",
                            borderRadius: "5px",
                            background: "#FFFFFF",
                            border: "1px solid #9A9A9A",
                            height: "30px",
                          }}
                          placeholder="Enter Here"
                          name="final_ctc"
                          // value={data.acceptedCtc ? data.acceptedCtc * 12 : final_ctc}
                          // value={data.annualCTC}
                          // onChange={(e) => handleChangeFinalCtc(e, data, i)}
                          value={foundCandidate1[page1 * rowsPerPage1 + i]?.annualCTC || ''}  
                          // value={data.annualCTC || ''}

                          onChange={(e) => handleChangeFinalCtc(e, data, i)}
                        />
                      </TableCell>
                      {/* } */}
                      {/* {editTerms ? ( */}
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "5%",
                          fontSize: "12px",
                          paddingLeft: "1.4%",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {data.contractual &&
                          data.contractual.contractualMarginType ==
                            "Variable in %" ? (
                            <div style={{ display: "flex" }}>
                              <PercentIcon className="icondoler" />
                              <input
                                type="number"
                                style={{
                                  width: "56px",
                                  borderRadius: "5px",
                                  background: "#FFFFFF",
                                  border: "1px solid #9A9A9A",
                                  height: "30px",
                                }}
                                placeholder="Enter"
                                name="terms"
                                // value={
                                //   data.contractual
                                //     ? data.contractual.contractualMarginAmount
                                //     : 0
                                // }
                                // value={foundCandidate1[i]?.contractual?.contractualMarginAmount || 0}
                                value={foundCandidate1[page1 * rowsPerPage1 + i]?.contractual?.contractualMarginAmount || 0}

                                onChange={(event) =>
                                  handleChangeTerms(event, data, i)
                                }
                              />
                            </div>
                          ) : (
                            <div
                              className="fontpera"
                              style={{ display: "flex" }}
                            >
                              {data.contractual &&
                              data.contractual.contractualMarginType ==
                                "Fixed in Rs" ? (
                                <CurrencyRupeeIcon className="icondoler" />
                              ) : (
                                <div style={{ width: "20px" }}> </div>
                              )}

                              <input
                                type="number"
                                style={{
                                  width: "56px",
                                  borderRadius: "5px",
                                  background: "#FFFFFF",
                                  border: "1px solid #9A9A9A",
                                  height: "30px",
                                }}
                                placeholder="Enter"
                                name="terms"
                                // value={
                                //   data.contractual
                                //     ? data.contractual.contractualMarginAmount
                                //     : 0
                                // }
                                // value={foundCandidate1[i]?.contractual?.contractualMarginAmount || 0} 
                                value={foundCandidate1[page1 * rowsPerPage1 + i]?.contractual?.contractualMarginAmount || 0}

                                onChange={(event) =>
                                  handleChangeTerms(event, data, i)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>
                      {/* ) : */}
                      {/* } */}
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "5%",
                          fontSize: "12px",
                          paddingLeft: "1.2%",
                        }}
                      >
                       
                        {data.contractual &&
                        data.contractual.contractualMarginType ==
                          "Variable in %" ? (
                          <input
                            type="number"
                            style={{
                              width: "74px",
                              borderRadius: "5px",
                              background: "#FFFFFF",
                              border: "1px solid #9A9A9A",
                              height: "30px",
                            }}
                            placeholder="Enter Here"
                            name="revenue"
                            value={Math.round(
                              (foundCandidate1[page1 * rowsPerPage1 + i]?.annualCTC *
                                foundCandidate1[page1 * rowsPerPage1 + i]?.contractual?.contractualMarginAmount *
                                data.contractual.selectedMonth) /
                                100
                            )} 
                            readOnly
                          />     
                        ) : (
                          <input
                            type="number"
                            style={{
                              width: "74px",
                              borderRadius: "5px",
                              background: "#FFFFFF",
                              border: "1px solid #9A9A9A",
                              height: "30px",
                            }}
                            placeholder="Enter Here"
                            name="revenue"
                            // value={
                            //   data.contractual
                            //     ? data.contractual.contractualMarginAmount
                            //     : 0
                            // }
                            value={Math.round(
                              foundCandidate1[page1 * rowsPerPage1 + i]?.contractual?.contractualMarginAmount *
                                data.contractual.selectedMonth
                            )}
                            readOnly
                          />
                        )}
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#272945",
                          textAlign: "center",
                          width: "6%",
                          fontSize: "12px",
                          paddingLeft: "8px",
                          padding: "5px 5px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => handleOpenDialog(data._id)}
                            style={{
                              backgroundColor: "#4594DE",
                              width: "52px",
                              height: "32px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFFFFF",
                              borderRadius: "6px",
                              border: "1px solid #3B82F6",
                              cursor: "pointer",
                              visibility:
                                data.status === "false" ? "hidden" : "",
                            }}
                            disabled={localStorage.getItem('roleName') == 'Auditor'}
                          >
                            Drop
                          </button>
                        </div>

                        {/* <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>Enter Valid Reason</DialogContent>
                            <DialogContent>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setDropReason(e.target.value);
                                }}
                              />
                            </DialogContent>

                            <DialogActions>
                              <button
                               onClick={handleClose}
                               >
                                Cancel
                                </button>
                              <button
                               onClick={() => confirmDrop(data)}
                               >
                                Drop
                              </button>
                            </DialogActions>
                          </Dialog> */}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "6%",
                          fontSize: "12px",
                        }}
                      >
                        <button
                          className="fontpera"
                          style={{
                            width: "52px",
                            height: "32px",
                            // backgroundColor: "#4594DE",
                            borderRadius: "5px",
                            color: "#EDF0F6",
                            fontFamily: "sans-serif",
                            fontWeight: 500,
                            fontSize: "15px",
                            border: "1px ridge",
                            backgroundColor:
                              data.status === "false" ? "#CCCCCC" : "#4594DE",
                            cursor:
                              data.status === "false"
                                ? "not-allowed"
                                : "pointer",
                          }}
                          title={
                            data.status === "false"
                              ? "This User is Drop"
                              : undefined
                          }
                          onClick={() => saveBillingStatus(data, i)}
                          disabled={data.status === "false" || localStorage.getItem('roleName') == 'Auditor'}
                        >
                          Save
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </div>
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogContent style={{ paddingBottom: "0" }}>
              Enter Valid Reason
            </DialogContent>
            <DialogContent>
              <TextField
                style={{ paddingBottom: "20px" }}
                label="Enter Valid Reason"
                variant="outlined"
                fullWidth
                value={inputValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setInputValue(newValue);

                  // Validate the input
                  if (newValue.trim() === "" || !isNaN(newValue)) {
                    setInputError(true);
                  } else {
                    setInputError(false);
                  }
                }}
                error={inputError}
                helperText={
                  inputError ? "Text cannot be empty or a number" : ""
                }
              />
              <Button
                style={{ float: "right" }}
                variant="contained"
                color="primary"
                onClick={saveBillingStatusdrop}
                disabled={inputError}
              >
                Save
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div style={{ backgroundColor: "#DCE0EB", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "0px",
          }}
        >
          <div className="-ml-4">
            <TablePagination
              component="div"
              size="small"
              count={filteredData.length}
              page={page1}
              onPageChange={handleChangePage1}
              rowsPerPage={rowsPerPage1}
              rowsPerPageOptions={[10, 25, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage1}
            />{" "}
          </div>
        </div>
      </div>

      <div className="textfont" style={{ margin: "20px 0px", float: "right" }}>
        Estimated Revenue : {EstimateRevenue}
        {/* Estimated Revenue : {EstimateRevenue && (EstimateRevenue/100000).toFixed(2)  +" " +"lakh"} */}
      </div>
      <div>
        {/* <p>Total Candidate : {filteredData.length}</p> */}

        <p>Total Candidate: {filteredData.length}</p>
        {/* {selectedStatusValue === "active" ? (
          <p>
            Total Active Candidates:{" "}
            {filteredData.filter((data) => data.status === "true").length}
          </p>
        ) : (
          <p>
            Total Inactive Candidates:{" "}
            {filteredData.filter((data) => data.status === "false").length}
          </p>
        )} */}
      </div>
      <div
        className="invoicedisplay"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="invoicegenerated " style={{ margin: "20px 0px" }}>
          Invoice Generated Candidate's
        </div>
        <div className="inputtextmobilediv">
          <TextField
            className="inputtextmobile"
            size="small"
            style={{
              width: "210px",
              borderRadius: "6px",
              background: "#FFFFFF",
            }}
            placeholder="Search Here"
            value={keyword}
            onChange={handleKeywordChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="medium" style={{ color: "#767676" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </div>
      </div>
      <div className="clinettablelist" style={{}}>
        <div className="clinettablelistdivtwo" style={{}}>
          <div>
            <Table>
              <TableHead>
                <TableRow
                  className="backgroundchange"
                  style={{ backgroundColor: "#F5FBFE" }}
                >
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "6%",
                      fontSize: "13.5px",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                    }}
                  >
                    Emp. Code
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "9%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        // width: "8%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div style={{}}>Dt. of Joining</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickDateFilterinvoice}
                          checked={startDateinvoice || endDateinvoice}
                        />
                        {startDateinvoice || endDateinvoice ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "2px",
                              position: "absolute",
                              left: "1px",
                            }}
                            onClick={handleClearDateFilterinvoice}
                          />
                        ) : null}
                        <Popover
                          open={openDatePopOverinvoice}
                          anchorEl={anchorElDateFilterinvoice}
                          onClose={handleCloseDatePopinvoice}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <div>
                            <div>
                              {" "}
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                  margin: "0",
                                  paddingBottom: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                Select Date
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ display: "block" }}>
                                <p
                                  style={{
                                    margin: "0",
                                    paddingLeft: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  From Date
                                </p>
                                <input
                                  type="date"
                                  value={startDateinvoice}
                                  onChange={handleStartDateChangeinvoice}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 0 5px 5px",
                                  }}
                                />
                              </div>
                              <p style={{ margin: "12px 5px 0" }}> -</p>
                              <div>
                                <p
                                  style={{
                                    margin: "0",
                                    textAlign: "start",
                                    fontSize: "10px",
                                  }}
                                >
                                  To Date
                                </p>
                                <input
                                  type="date"
                                  value={endDateinvoice}
                                  onChange={handleEndDateChangeinvoice}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 5px 5px 0",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      // color: "#272945",
                      width: "10%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                      // display: "flex",
                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        // width: "10%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "",
                      }}
                    >
                      <div style={{ paddingLeft: "12px" }}>Client</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickClientCheckboxinvoice}
                        />

                        {selectedClientsInvoice.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearClientFilterinvoice}
                          />
                        ) : null}
                        <Popover
                          open={openClientPopOverinvoice}
                          anchorEl={anchorEl}
                          onClose={handleCloseClientPopinvoice}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "700px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              setSearchValClientInvoice(e.target.value)
                            }
                            placeholder="Search Here"
                          />
                          {filteredClientListinvoice.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Clients found with this name
                            </p>
                          ) : (
                            filteredClientListinvoice.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedClientsInvoice.includes(
                                      data.clientName
                                    )}
                                    onChange={(event) =>
                                      handleClientCheckboxChangeinvoice(
                                        event,
                                        data.clientName
                                      )
                                    }
                                  />
                                  {data.clientName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      // paddingLeft: '24px',
                      fontSize: "13.5px",
                    }}
                  >
                    Candidate
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      fontSize: "13.5px",
                    }}
                  >
                    Position Name
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        fontSize: "13.5px",
                        display: "flex",
                      }}
                    >
                      <div style={{ textAlign: "center", marginLeft: "5px" }}>
                        Recruiter
                      </div>
                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickRecruiterCheckbox}
                        />
                        {selectedRecruiters.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearRecruiterFilterinvoice}
                          />
                        ) : null}
                        <Popover
                          open={openRecruiterPopOver}
                          anchorEl={anchorEl}
                          onClose={handleCloseRecruiterPop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "1000px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              setSearchValRecruiter(e.target.value)
                            }
                            placeholder="Search Here"
                          />
                          {filteredRecruiterList.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Recruiters found with this name
                            </p>
                          ) : (
                            filteredRecruiterList.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedRecruiters.includes(
                                      data.userName
                                    )}
                                    onChange={(event) =>
                                      handleRecruiterCheckboxChange(
                                        event,
                                        data.userName
                                      )
                                    }
                                  />
                                  {data.userName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>

                  {/* <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                    }}
                  >
                    Life Span(Month)
                  </TableCell> */}
                 
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                    }}
                  >
                    Final CTC(Month)
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                    }}
                  >
                    Terms
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                    }}
                  >
                    Revenue
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div
              className="heightscrollsecond"
              style={{
                rowGap: "12px",
                width: "100%",
                height: "342px",
                overflowY: "scroll",
              }}
            >
              {filteredDatainvoice &&
                filteredDatainvoice.map((data, i) => (
                  <TableRow>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "6%",
                        fontSize: "12px",
                      }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "11%",
                        fontSize: "12px",
                        paddingLeft: "1.6%",
                      }}
                    >
                      {data.empCode}
                      {console.log("empCodein",data.empCode)}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "9%",
                        fontSize: "12px",
                        //   paddingLeft: "1.7%",
                      }}
                    >
                      {data.joiningDate && data.joiningDate.slice(0, 10)}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        //   paddingLeft: "1.2%",
                      }}
                    >
                      {data.clientName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        // paddingLeft: "1.6%",
                        fontSize: "12px",
                      }}
                    >
                      {data.candidateName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        paddingLeft: "1.2%",
                      }}
                    >
                      {data.positionName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        paddingLeft: "1.4%",
                      }}
                    >
                      {reqCreatedBy(data.createdBy) &&
                        reqCreatedBy(data.createdBy).userName}
                    </TableCell>
                    {/* <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "11%",
                        fontSize: "12px",
                        paddingLeft: "1.6%",
                      }}
                    >
                      {data.selectedmonth}
                    </TableCell> */}
                 
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "11%",
                        fontSize: "12px",
                        paddingLeft: "1.6%",
                      }}
                    >
                      {data.final_ctc}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "5%",
                        fontSize: "12px",
                        paddingLeft: "1.4%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {data.term_type == "Variable in %" ? (
                          <PercentIcon className="icondoler" fontSize="small" />
                        ) : (
                          <CurrencyRupeeIcon
                            className="icondoler"
                            fontSize="small"
                          />
                        )}
                        {data.terms}
                      </div>
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "5%",
                        fontSize: "12px",
                        paddingLeft: "1.2%",
                      }}
                    >
                      {data.revenue}
                    </TableCell>
                  </TableRow>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <p className="textfont" style={{ float: "right" }}>
          Total Revenue : {sum && (sum / 100000).toFixed(2) + " " + "lakh"}
        </p>
      </div>
      <div>
        <p>Total Invoice Data: {filteredDatainvoice.length}</p>
      </div>
    </div>
  );
}

export default ContractualOnMonthly;
