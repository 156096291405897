import React, { useState, useEffect } from "react";
import { Card, InputAdornment, TextField } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveIcon from "../RemoveIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select"
import SearchIcon from "@mui/icons-material/Search"
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EditIcon from "../EditIcon.svg"
import { useForm } from "react-hook-form"
import "./userlist.css";
import axios from "axios";
import {
  getRole,
  getBranch,
  getTypeHiring,
  getDivision,
  addRole,
  addBranch,
  addTypeHiring,
  addDivision,
  deleteRole,
  deleteBranch,
  deleteTypeOfHiring,
  deleteDivision,
  addCompany,
  deleteCompany,
  getCompany,
  addVender,
  getVender,
} from "../../../../redux/action";
const { URL } = require("../../../../config.json");
function EditMaster(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  let emp_id = localStorage.getItem("employeeId")
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompany());
    dispatch(getRole());
    dispatch(getBranch());
    dispatch(getTypeHiring());
    dispatch(getDivision());
    dispatch(getVender())
  }, []);

  let roleData = useSelector((state) => state.clientReducer.role);
  let branchData = useSelector((state) => state.clientReducer.branch);
  let typeHiringData = useSelector((state) => state.clientReducer.typeOfHiring);
  let divisionData = useSelector((state) => state.clientReducer.division);
  let companyData = useSelector((state) => state.clientReducer.company);
  let vendorData = useSelector((state) => state.clientReducer.vendor);
  const [role, setRole] = useState();
  const [branchName, setBranchName] = useState();
  const [typeHiringName, setTypeHiring] = useState();
  const [divisionName, setDivisionName] = useState();
  const [company, setCompany] = useState();
  //const [vender, setVender] = useState();

  const UserList = useSelector((state) => state.clientReducer.userList)
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [vendorName, setVendorName] = useState()
  const [branch, setBranch] = useState("")
  const [vendorInformation, setVendorInformation] = useState("")
  const [division, setDivision] = useState([])
  const [selected, setSelected] = useState([])

  const [status, setStatus] = useState('true')
  const [showVendor, setShowVendor] = useState(false)

  const [handleEditMaster, setHandleEditMaster] = useState("DefineRole");

  function handleCompany() {
    setHandleEditMaster("Company");
  }
  function handleDefineRole() {
    setHandleEditMaster("DefineRole");
  }

  function handleBranchNames() {
    setHandleEditMaster("BranchNames");
  }

  function handleTypeHiring() {
    setHandleEditMaster("TypeHiring");
  }

  function handleDivisionNames() {
    setHandleEditMaster("DivisionNames");
  }

  function handleVender() {
    setHandleEditMaster("Vendor");
  }

  let expanded = false

  const [empName, setempName] = useState("")

  const [employeelist, setEmployee] = useState(vendorData)

  const [foundemployee, setFoundEmployee] = useState(employeelist)

  useEffect(() => {
    if (Array.isArray(vendorData)) {
      setEmployee(vendorData.filter((n) => n.status == status))
      setFoundEmployee(vendorData.filter((n) => n.status == status))
    }
  }, [status, vendorData])
  // useEffect(() => {
  //   setEmployee(vendorData.filter((n) => n.status == status))
  //   setFoundEmployee(vendorData.filter((n) => n.status == status))
  // }, [status, vendorData])

  function changeDiv() {
    var checkboxes = document.getElementById("division")
    if (!expanded) {
      checkboxes.style.display = "grid"
      expanded = true
    } else {
      checkboxes.style.display = "none"
      expanded = false
    }
  }

  const [sendStatus, setSendStatus] = useState();

  useEffect(() => {
    setSendStatus(vendorInformation.status);
    setVendorName(vendorInformation.vendorName)
    setEmail(vendorInformation.email)
    setRole(vendorInformation.role)
    setBranch(vendorInformation.branch)
    setCompany(vendorInformation.company)
  }, [vendorInformation]);

  const handleToggleSendStatus = (e) => {
    setSendStatus(e.target.checked);
  };
  console.log("sendStatus", sendStatus);


  const handleClickOpen = () => {
    if (role == "") {
      window.alert("Please Select Role")
    } else if (branch == "") {
      window.alert("Please Select Branch")
    } else if (company == "") {
      window.alert("Please Select Company")
    }
    if (role != "" && branch != "" && company != "") {
      console.log('handlSave', role, branch, company)
      let data = {
        role: role,
        password: password,
        email: email,
        vendorName: vendorName,
        branchName: branch,
        company: company,
        division: divisionName,
        status: sendStatus,
        createdBy: emp_id,
      }
      setPassword("")
      setEmail("")
      setVendorName("")
      setRole("")
      setBranch("")
      setCompany("")
      setDivision("")
      setSendStatus("")
      dispatch(addVender(data))
    }

    setTimeout(() => {
      dispatch(getVender())
    }, 100)
  }

  function update() {
    axios.put(`${URL}bi_vender/bi_vender`, {
      _id: vendorInformation._id,
      role: role,
      password: password,
      email: email,
      vendorName: vendorName,
      branchName: branch,
      company: company,
      division: divisionName,
      status: sendStatus,
      createdBy: emp_id,
    })
      .then((re) => {
        window.alert(re.data.message);
        setTimeout(() => {
          dispatch(getVender());
        }, 5000)
        setVendorInformation("")
      })
      .catch((error) => {
        window.alert(error.response.data.message);
      });
  }

  const handleClick = (event, d, name) => {
    console.log('handleClick', event, d)
    // if (event.target.checked == true) {
    //   division.push({ divisionName: d.divisionName, divisionId: d._id })
    // } else if (event.target.checked == false) {
    //   let data = division.filter((div, i) => div.divisionId != d._id)
    //   setDivision(data)
    // }
    // const selectedIndex = selected.indexOf(d.id)
    // let newSelected = []

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, d.id)
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1))
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1))
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selected.slice(0, selectedIndex),
    //     selected.slice(selectedIndex + 1)
    //   )
    // }
    // setSelected(newSelected)
  }
  //const [open2, setOpen2] = useState(false)

  const editVendor = (n) => {
    setVendorInformation(n)
    // setOpen2(true)
  }
  console.log('vendorInformation', vendorInformation)

  const insertRole = () => {
    dispatch(addRole(role));
    setTimeout(() => {
      dispatch(getRole());
    }, 100);
    setRole("");
  };

  const insertTypeHiring = () => {
    dispatch(addTypeHiring(typeHiringName));
    setTimeout(() => {
      dispatch(getTypeHiring());
    }, 100);
    setTypeHiring("");
  };

  const insertDivision = () => {
    dispatch(addDivision(divisionName));
    setTimeout(() => {
      dispatch(getDivision());
    }, 100);
    setDivisionName("");
  };

  const insertBranch = () => {
    dispatch(addBranch(branchName));
    setTimeout(() => {
      dispatch(getBranch());
    }, 100);
    setBranchName("");
  };

  const insertCompany = () => {
    dispatch(addCompany(company));
    setTimeout(() => {
      dispatch(getCompany());
    }, 100);
    setCompany("");
  };

  const handleDeleteRole = (id) => {
    dispatch(deleteRole(id));
    setTimeout(() => {
      dispatch(getRole());
    }, 100);
  };

  const handleDeleteTypeHiring = (id) => {
    dispatch(deleteTypeOfHiring(id));
    setTimeout(() => {
      dispatch(getTypeHiring());
    }, 100);
  };

  const handleDeleteDivision = (id) => {
    dispatch(deleteDivision(id));
    setTimeout(() => {
      dispatch(getDivision());
    }, 100);
  };

  const handleDeleteBranch = (id) => {
    dispatch(deleteBranch(id));
    setTimeout(() => {
      dispatch(getBranch());
    }, 100);
  };

  const handleDeleteCompany = (id) => {
    dispatch(deleteCompany(id));
    setTimeout(() => {
      dispatch(getCompany());
    }, 100);
  };

  let sr = 0;
  return (
    <div
      id="EditComponent"
      style={{ display: "none", height: "100%", width: "100%",position:"relative" }}
    >
      <div
        style={{
          color: "#11416F",
          position: "absolute",
          right:"15px",
          top:"55px",
          marginLeft: "92%",
          cursor: "pointer",
          marginTop: "-75px",
        }}
        onClick={props.hideEditMaster}
      >
        <ArrowBackIcon fontSize="inherit" />
        Back
      </div>
      <div
        style={{
          padding: "0px 24px 0px 24px",
          marginBottom: "56px",
          fontFamily: "sans-serif",
        }}
      >
        <div style={{ fontFamily: "sans-serif" }}>
          <p
            style={{
              color: "#434345",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Masters
          </p>

          <hr
            style={{
              background: "#BCBCBC",
              color: "#BCBCBC",
              borderColor: "#BCBCBC",
              // height: '1px',
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            marginTop: "-8px",
          }}
        >
          <div style={{ width: "20%", borderRight: "3px solid #BCBCBC" }}>
            <div
              style={{
                width: "fit-content",
                display: "flex",
                flexDirection: "column",
                gap: "30px 0px",
                padding: "24px 0px 0px 20px",
              }}
            >
              <div
                onClick={handleDefineRole}
                // onClick={setHandleEditMaster('DefineRole')}

                style={{
                  textAlign: "start",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  padding: "8px 0px",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                <DoubleArrowIcon fontSize="inherit" />
                Define Role
              </div>
              <div
                onClick={handleBranchNames}
                style={{
                  textAlign: "start",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  padding: "8px 0px",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                <DoubleArrowIcon fontSize="inherit" />
                Branch Names
              </div>
              <div
                onClick={handleTypeHiring}
                style={{
                  textAlign: "start",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  padding: "8px 0px",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                <DoubleArrowIcon fontSize="inherit" />
                Type of Hiring
              </div>
              <div
                onClick={handleDivisionNames}
                style={{
                  textAlign: "start",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  padding: "8px 0px",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                <DoubleArrowIcon fontSize="inherit" />
                Division Names
              </div>
              <div
                onClick={handleCompany}
                style={{
                  textAlign: "start",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  padding: "8px 0px",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                <DoubleArrowIcon fontSize="inherit" />
                Company
              </div>
              <div
                onClick={handleVender}
                style={{
                  textAlign: "start",
                  cursor: "pointer",
                  fontFamily: "sans-serif",
                  padding: "8px 0px",
                  fontSize: "18px",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                <DoubleArrowIcon fontSize="inherit" />
                Vendor
              </div>
            </div>
          </div>
          <div style={{ width: "75%" }}>
            {handleEditMaster == "DefineRole" ? (
              <div
                style={{
                  minWidth: "384px",
                  maxWidth: "max-content",
                  minHeight: "200px",
                  maxHeight: "350px",
                  background: "#EEF1F4",
                  margin: "32px 36px 0px 36px",
                  paddingBottom: "16px",
                  overflowY: "scroll",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    columnGap: "10px",
                    padding: "16px 24px 0px 24px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "start",
                      height: "21px",
                      margin: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <DoubleArrowIcon fontSize="inherit" />
                    Define Role
                    <hr
                      className="mt-1"
                      style={{
                        background: "#BCBCBC",
                        color: "#BCBCBC",
                        borderColor: "#BCBCBC",
                        height: "0.5px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", marginLeft: "20px" }}>
                    <input
                      type="text"
                      size="small"
                      name=""
                      style={{
                        background: "#FFFFFF",
                        border: "1px solid #B4B5B5",
                        borderRadius: "5px",
                        marginTop: "15px",
                        width: "140px",
                        height: "30px",
                      }}
                      placeholder="Enter Here"
                      variant="outlined"
                      autoComplete="off"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                    <AddCircleIcon
                      onClick={insertRole}
                      style={{
                        color: "#4594DE",
                        fontSize: "xx-large",
                        marginLeft: "5px",
                        marginTop: "15px",
                      }}
                    />
                  </div>
                </div>
                <div style={{}}>
                  {roleData.map((n, i) => (
                    <div
                      style={{ display: "flex", padding: "0px 0px 0px 30px" }}
                    >
                      <div style={{ width: "50%", display: "flex" }}>
                        <p>{(sr = sr + 1)}. </p>
                        <p>{n.roleName}</p>
                      </div>
                      <div style={{ paddingTop: "18px" }}>
                        <img
                          src={RemoveIcon}
                          onClick={() => handleDeleteRole(n._id)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {handleEditMaster == "BranchNames" ? (
              <div>
                <div
                  style={{
                    minWidth: "384px",
                    maxWidth: "max-content",
                    minHeight: "200px",
                    maxHeight: "350px",
                    background: "#EEF1F4",
                    margin: "32px 36px 0px 36px",
                    paddingBottom: "16px",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                      padding: "16px 24px 0px 24px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        height: "21px",
                        margin: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <DoubleArrowIcon fontSize="inherit" />
                      Branch Names
                      <hr
                        className="mt-1"
                        style={{
                          background: "#BCBCBC",
                          color: "#BCBCBC",
                          borderColor: "#BCBCBC",
                          height: "0.5px",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", marginLeft: "20px" }}>
                      <input
                        type="text"
                        className="w-36 rounded"
                        size="small"
                        name=""
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #B4B5B5",
                          borderRadius: "5px",
                          marginTop: "15px",
                          width: "140px",
                          height: "30px",
                        }}
                        placeholder="Enter Here"
                        variant="outlined"
                        autoComplete="off"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                      />
                      <AddCircleIcon
                        onClick={insertBranch}
                        style={{
                          color: "#4594DE",
                          fontSize: "xx-large",
                          marginLeft: "5px",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </div>

                  <div style={{}}>
                    {branchData.map((n, i) => (
                      <div
                        style={{ display: "flex", padding: "0px 0px 0px 30px" }}
                      >
                        <div style={{ width: "50%", display: "flex" }}>
                          <p>{(sr = sr + 1)}. </p>
                          <p>{n.branchName}</p>
                        </div>
                        <div style={{ paddingTop: "18px" }}>
                          <img
                            src={RemoveIcon}
                            onClick={() => handleDeleteBranch(n._id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div  style={{ display: 'flex', columnGap:'20px',fontFamily:'sans-serif',padding:'16px 24px 0px 24px' }}>
                    <div  style={{ display: 'flex',flexFlow:'column', rowGap:'8px' }}>
                      {branchData.map((n, i) => (
                        <div className="flex gap-x-3 leading-4 text-sm font-normal" style={{ display: 'flex', gap: '10px' }}>
                          <p></p>
                          <p>{n.branchName}</p>
                          <img src={RemoveIcon} onClick={() => handleDeleteBranch(n._id)} />
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            ) : null}

            {handleEditMaster == "TypeHiring" ? (
              <div>
                <div
                  className="mx-9 mt-8 pb-4"
                  style={{
                    minWidth: "384px",
                    maxWidth: "max-content",
                    minHeight: "200px",
                    maxHeight: "350px",
                    background: "#EEF1F4",
                    margin: "32px 36px 0px 36px",
                    paddingBottom: "16px",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                      padding: "16px 24px 0px 24px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        height: "21px",
                        margin: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <DoubleArrowIcon fontSize="inherit" />
                      Type of Hiring
                      <hr
                        className="mt-1"
                        style={{
                          background: "#BCBCBC",
                          color: "#BCBCBC",
                          borderColor: "#BCBCBC",
                          height: "0.5px",
                        }}
                      />
                    </div>
                    <div style={{ display: "flex", marginLeft: "20px" }}>
                      <input
                        type="text"
                        className="w-36 rounded"
                        size="small"
                        name=""
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #B4B5B5",
                          borderRadius: "5px",
                          marginTop: "15px",
                          width: "140px",
                          height: "30px",
                        }}
                        placeholder="Enter Here"
                        variant="outlined"
                        autoComplete="off"
                        value={typeHiringName}
                        onChange={(e) => setTypeHiring(e.target.value)}
                      />
                      <AddCircleIcon
                        onClick={insertTypeHiring}
                        style={{
                          color: "#4594DE",
                          fontSize: "xx-large",
                          marginLeft: "5px",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{}}>
                    {typeHiringData.map((n, i) => (
                      <div
                        style={{ display: "flex", padding: "0px 0px 0px 30px" }}
                      >
                        <div style={{ width: "50%", display: "flex" }}>
                          <p>{(sr = sr + 1)}. </p>
                          <p>{n.typeOfHiring}</p>
                        </div>
                        <div style={{ paddingTop: "18px" }}>
                          <img
                            src={RemoveIcon}
                            onClick={() => handleDeleteTypeHiring(n._id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {handleEditMaster == "DivisionNames" ? (
              <div>
                <div
                  className="mx-9 mt-8 pb-4"
                  style={{
                    minWidth: "384px",
                    maxWidth: "max-content",
                    minHeight: "200px",
                    maxHeight: "350px",
                    background: "#EEF1F4",
                    margin: "32px 36px 0px 36px",
                    paddingBottom: "16px",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                      padding: "16px 24px 0px 24px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        height: "21px",
                        margin: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <DoubleArrowIcon fontSize="inherit" />
                      Division Names
                      <hr
                        className="mt-1"
                        style={{
                          background: "#BCBCBC",
                          color: "#BCBCBC",
                          borderColor: "#BCBCBC",
                          height: "0.5px",
                        }}
                      />
                    </div>
                    <div
                      className="flex gap-x-1"
                      style={{ display: "flex", marginLeft: "20px" }}
                    >
                      <input
                        type="text"
                        className="w-36 rounded"
                        size="small"
                        name=""
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #B4B5B5",
                          borderRadius: "5px",
                          marginTop: "15px",
                          width: "140px",
                          height: "30px",
                        }}
                        placeholder="Enter Here"
                        variant="outlined"
                        autoComplete="off"
                        value={divisionName}
                        onChange={(e) => setDivisionName(e.target.value)}
                      />
                      <AddCircleIcon
                        onClick={insertDivision}
                        style={{
                          color: "#4594DE",
                          fontSize: "xx-large",
                          marginLeft: "5px",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{}}>
                    {divisionData.map((n, i) => (
                      <div
                        style={{ display: "flex", padding: "0px 0px 0px 30px" }}
                      >
                        <div style={{ width: "70%", display: "flex" }}>
                          <p>{(sr = sr + 1)}. </p>
                          <p>{n.divisionName}</p>
                        </div>
                        <div style={{ paddingTop: "18px" }}>
                          <img
                            src={RemoveIcon}
                            onClick={() => handleDeleteDivision(n._id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {handleEditMaster == "Company" ? (
              <div>
                <div
                  className="mx-9 mt-8 pb-4"
                  style={{
                    minWidth: "384px",
                    maxWidth: "max-content",
                    minHeight: "200px",
                    maxHeight: "max-content",
                    background: "#EEF1F4",
                    margin: "32px 36px 0px 36px",
                    paddingBottom: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      columnGap: "10px",
                      padding: "16px 24px 0px 24px",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "start",
                        height: "21px",
                        margin: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <DoubleArrowIcon fontSize="inherit" />
                      Company
                      <hr
                        className="mt-1"
                        style={{
                          background: "#BCBCBC",
                          color: "#BCBCBC",
                          borderColor: "#BCBCBC",
                          height: "0.5px",
                        }}
                      />
                    </div>
                    <div
                      className="flex gap-x-1"
                      style={{ display: "flex", marginLeft: "20px" }}
                    >
                      <input
                        type="text"
                        className="w-36 rounded"
                        size="small"
                        name=""
                        style={{
                          background: "#FFFFFF",
                          border: "1px solid #B4B5B5",
                          borderRadius: "5px",
                          marginTop: "15px",
                          width: "140px",
                          height: "30px",
                        }}
                        placeholder="Enter Here"
                        variant="outlined"
                        autoComplete="off"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                      <AddCircleIcon
                        onClick={insertCompany}
                        style={{
                          color: "#4594DE",
                          fontSize: "xx-large",
                          marginLeft: "5px",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{}}>
                    {companyData.map((n, i) => (
                      <div
                        style={{ display: "flex", padding: "0px 0px 0px 30px" }}
                      >
                        <div style={{ width: "85%", display: "flex" }}>
                          <p>{(sr = sr + 1)}. </p>
                          <p>{n.company}</p>
                        </div>
                        <div style={{ paddingTop: "18px" }}>
                          <img
                            src={RemoveIcon}
                            onClick={() => handleDeleteCompany(n._id)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {handleEditMaster == "Vendor" ? (
              <div>
                <div
                  className="userlistdiv"
                  style={{
                    marginTop: "22px",
                    width: "70%",
                    marginLeft: "35px",
                    // height: "550px",
                    padding: "20px 0 30px 0",
                    border: "1px solid #CFDFED",
                    background: "#EEF1F4",
                    borderRadius: "7px",
                  }}
                >
                  <div>
                    {vendorInformation ? (<p
                      style={{
                        display: "flex",
                        color: "#155188",
                        justifyContent: "center",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "18.23px",
                      }}
                    >
                      EDIT VENDOR
                    </p>) : <p
                      style={{
                        display: "flex",
                        color: "#155188",
                        justifyContent: "center",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "18.23px",
                      }}
                    >
                      ADD VENDOR
                    </p>}
                    <div
                      className="userdetailmobile"
                      style={{
                        display: "flex",
                        gap: "16px",
                        fontFamily: "sans-serif",
                        flexFlow: "row",
                        marginLeft: "35px"
                      }}
                    >
                      <form
                        className="gapzero"
                        onSubmit={vendorInformation ? (handleSubmit(update)) : handleSubmit(handleClickOpen)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "6px",
                          paddingTop: "8px",
                        }}
                      >
                        <div style={{ display: 'flex', gap: '35px' }}>
                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: "flex" }}>
                              Vendor Name
                            </p>

                            <input
                              // {...register("Name", { required: true })}
                              // placeholder={
                              //   errors.Name?.type === "required" && "Name is required"
                              // }
                              // aria-invalid={errors.Name ? "true" : "false"}
                              type="text"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "245px",
                                height: "35px",
                                borderRadius: "5px",
                              }}
                              value={vendorName}
                              onChange={(d) => setVendorName(d.target.value)}
                            />
                          </div>
                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: 'flex' }}>
                              Password
                            </p>

                            <input
                              // {...register("Password", { required: true })}
                              // placeholder={
                              //   errors.Password?.type === "required" &&
                              //   "Password is required"
                              // }
                              // aria-invalid={errors.Password ? "true" : "false"}
                              type="password"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "245px",
                                height: "35px",
                                borderRadius: "5px",
                              }}
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '35px' }}>
                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: "flex" }}>
                              Email
                            </p>

                            <input
                              // {...register("Email", { required: true })}
                              // placeholder={
                              //   errors.Email?.type === "required" &&
                              //   "Email is required"
                              // }
                              // aria-invalid={errors.Email ? "true" : "false"}
                              type="email"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "245px",
                                height: "35px",
                                borderRadius: "5px",
                              }}
                              value={email}
                              onChange={(d) => setEmail(d.target.value)}
                            />
                          </div>

                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: "flex" }}>
                              Role
                            </p>

                            <select
                              className="w-56 h-10 rounded"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "250px",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              variant="outlined"
                              value={role}
                              onChange={(e) => setRole(e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="vendor">Vendor</option>
                            </select>
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '35px', width: '250px' }}>
                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: "flex" }}>
                              Division
                            </p>
                            <select
                              className="w-56 h-10 rounded"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "250px",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              variant="outlined"
                              value={division}
                              onChange={(d) => setDivision(d.target.value)}
                            >
                              <option value="">Select</option>
                              {divisionData.map((d) => (
                                <option value={d.divisionName}>{d.divisionName}</option>
                              ))}
                            </select>
                            {/* <div>
                              <div
                                style={{
                                  borderRadius: "5px",
                                  height: "30px",
                                  border: "1px solid black",
                                  background: "white",
                                  width: '250px'
                                }}
                                onClick={changeDiv}
                              >
                                Select Division
                              </div>
                              <div
                                id="division"
                                style={{
                                  display: "none",
                                  border: "1px solid black",
                                  borderRadius: "5px",
                                  width: "245px",
                                  height: "150px",
                                  background: "white",
                                  position: "absolute",
                                }}
                              >
                                <div style={{ overflow: "auto", display: "grid" }}>
                                  {divisionData.map((d) => (
                                    <label>
                                      {console.log('ddd', divisionData, d.divisionName)}
                                      <input
                                        type="checkbox"
                                        onClick={(n) => handleClick(n, d)}
                                      />
                                      {d.divisionName}
                                    </label>
                                  ))}
                                </div>
                                <div
                                  style={{
                                    border: "1px solid black",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    background: "skyblue",
                                  }}
                                  onClick={changeDiv}
                                >
                                  OK
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: "flex" }}>
                              Branch Name
                            </p>
                            <select
                              className="w-56 h-10 rounded"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "250px",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              variant="outlined"
                              value={branch}
                              onChange={(d) => setBranch(d.target.value)}
                            >
                              <option value="">Select</option>
                              {branchData.map((d) => (
                                <option value={d.branchName}>{d.branchName}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div style={{ display: 'flex', gap: '35px' }}>
                          <div className="divuser" style={{ marginBottom: "0" }}>
                            <p className="userd" style={{ display: "flex" }}>
                              {" "}
                              Company
                            </p>
                            <select
                              className="w-56 h-10 rounded"
                              style={{
                                border: "1px solid #DCE3EA",
                                width: "250px",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              variant="outlined"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                            >
                              <option value="">Select</option>
                              {companyData.map((z) => (
                                <option value={z.company}>{z.company}</option>
                              ))}
                            </select>
                          </div>
                          <div style={{ display: "flex" }}>
                            <FormGroup style={{ display: "flex" }}>
                              <p className="userd" style={{}}>
                                {" "}
                                Status
                              </p>
                              <FormControlLabel
                                control={
                                  <Switch
                                    defaultChecked={true}
                                    checked={
                                      sendStatus == "true" || sendStatus == true ? true : false
                                    }
                                    onChange={(e) => {
                                      handleToggleSendStatus(e);
                                    }}
                                    color="primary"
                                  />
                                }
                              />
                            </FormGroup>
                          </div>
                        </div>
                        <div style={{ aligngSelf: "flex-end", marginTop: "10px" }}>
                          {vendorInformation ? (
                            <button
                              className="savebox"
                              type="submit"
                              style={{
                                backgroundColor: "#4594DE",
                                width: "70px",
                                height: "40px",
                                borderRadius: "5px",
                                color: "#FFFFFF",
                                border: "1px solid #4594DE",
                              }}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              className="savebox"
                              type="submit"
                              style={{
                                backgroundColor: "#4594DE",
                                width: "70px",
                                height: "40px",
                                borderRadius: "5px",
                                color: "#FFFFFF",
                                border: "1px solid #4594DE",
                              }}
                            >
                              Save
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>


                <div className="userrolemain" style={{}}>
                  <div className="userrolescroll" style={{}}>
                    <div className="" style={{ marginTop: "10px", marginLeft: "35px", width: "75%" }}>
                      <Table>
                        <TableHead>
                          <TableRow
                            className="backgroundmain"
                            style={{ backgroundColor: "#F5FBFE" }}
                          >
                            <TableCell
                              className="textfont"
                              style={{
                                width: "9%",
                                color: "#525252",
                                textAlign: "start",
                              }}
                            >
                              S. No.
                            </TableCell>
                            <TableCell
                              className="textfont"
                              style={{
                                width: "18%",
                                color: "#525252",
                                textAlign: "start",
                              }}
                            >
                              Vendor Name
                            </TableCell>
                            {/* <TableCell
                          className="textfont"
                          style={{
                            width: "20%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          Reporting Manager
                        </TableCell> */}
                            <TableCell
                              className="textfont"
                              style={{
                                width: "12%",
                                color: "#525252",
                                textAlign: "start",
                              }}
                            >
                              Role
                            </TableCell>
                            <TableCell
                              className="textfont"
                              style={{
                                width: "12%",
                                color: "#525252",
                                textAlign: "start",
                              }}
                            >
                              Branch
                            </TableCell>
                            <TableCell
                              className="textfont"
                              style={{
                                width: "20%",
                                color: "#525252",
                                textAlign: "start",
                              }}
                            >

                              <select value={status}
                              //onChange={selectUserStatus}
                              >
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                            </TableCell>

                            <TableCell
                              className="textfont"
                              style={{
                                width: "7%",

                                textAlign: "start",
                                cursor: "pointer",
                              }}
                            />
                          </TableRow>
                        </TableHead>
                      </Table>

                      <div style={{ height: "390px", overflowY: "scroll" }}>
                        <Table>
                          {foundemployee.map((n, ind) => (
                            <TableRow style={{ background: "#FAFAFA" }}>
                              <TableCell
                                className="maintextfont"
                                style={{
                                  width: "9%",
                                  color: "#525252",
                                  textAlign: "start",
                                }}
                              >
                                {ind + 1}
                              </TableCell>
                              <TableCell
                                className="maintextfont"
                                style={{
                                  width: "18%",
                                  color: "#525252",
                                  textAlign: "start",
                                }}
                              >
                                {n.vendorName}
                              </TableCell>
                              {/* <TableCell
                            className="maintextfont"
                            style={{
                              width: "20%",
                              color: "#525252",
                              textAlign: "start",
                            }}
                          >
                            {/* {n.reportingManagerName} */}
                              {/* </TableCell> */}
                              <TableCell
                                className="maintextfont"
                                style={{
                                  width: "12%",
                                  color: "#525252",
                                  textAlign: "start",
                                  paddingLeft: "2%",
                                }}
                              >
                                {n.role}
                              </TableCell>
                              <TableCell
                                className="maintextfont"
                                style={{
                                  width: "12%",
                                  color: "#525252",
                                  textAlign: "start",
                                  paddingLeft: "3%",
                                }}
                              >
                                {n.branchName}
                              </TableCell>
                              <TableCell
                                className="maintextfont"
                                style={{
                                  width: "20%",
                                  color: "#525252",
                                  textAlign: "start",
                                  cursor: "pointer",
                                  padding: "3%",
                                }}
                              >
                                {n.division}
                              </TableCell>
                              <TableCell
                                className="maintextfont"
                                style={{
                                  width: "7%",
                                  textAlign: "start",
                                  cursor: "pointer",
                                }}
                                onClick={() => editVendor(n)}
                              >
                                <img className="w-5 h-6" src={EditIcon} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Dialog open={open2}>
                  <DialogContent className="paddupdate">
                  
                  </DialogContent>
                </Dialog> */}
              </div>
            ) : null}

          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMaster;
