import React from "react";
import TickIcon from "../TickIcon.svg";

function PopupSaveRequisition() {
  return (
    <div className="savebuttonclientlist" style={{ width: "330px", height: "140px", borderRadius: "10px" }}>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}
      >
        <img
          style={{ width: "40px", height: "40px", color: "#20A926" }}
          src={TickIcon}
        />
      </div>
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "18px",
          marginTop: "24px",
          color: "#2E2E2E",
        }}
      >
        You have successfully added Requisition.
      </p>
    </div>
  );
}

export default PopupSaveRequisition;
