import {
    SET_CLIENT_LIST, SETADDROLE, SETADDBRANCH, SETADDTYPEHIRING, SETADDDIVISION,
    SET_ROLE, SET_BRANCH, SET_TYPE_HIRING, SET_DIVISION, SET_USER_LIST, SETEMPLOYEELIST,
    SET_CANDIDATE, SET_REQUISITION,
    SET_COMPANY, SET_DESIGNATION, SET_BILLING_STATUS,
    SET_REQUISITION_BY_TAG,
    SETALLCANDIDATE,
    SET_CAND_FOR_REVENUE,
    SET_ONE_TIME_SOURCE_CANDIDATE,
    SET_PERMANENT_CAND_REVENUE, SET_BRANCHBYCLIENT, SET_VENDER, SET_CLIENTBY_COMPANY , SET_ALL_CLIENTBY_COMPANY
} from "./constant"

const initialState = {
    clientList: [],
    role: [],
    branch: [],
    typeOfHiring: [],
    division: [],
    addRole: [],
    userList: [],
    employeeList: [],
    candidateList: [],
    requisitionByTag: [],
    requisitionList: [],
    allCandidate: [],
    company: [],
    designation: [],
    candidateBilling: [],
    candForRevenue: [],
    branchListByClient:[],
    clientByCompany:[],
    oneTimeSourceCandidate: [],
    allClientByCompany:[],
    permanentCandForRevenue: [],
    vendor: [],
}

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_CLIENT_LIST:
            state.clientList = action.data
            return {
                ...state
            }

        case SET_VENDER:
            state.vendor = action.data
            return {
                ...state
            }

        case SET_ROLE:
            state.role = action.data
            return {
                ...state
            }

        case SET_BRANCH:
            state.branch = action.data
            return {
                ...state
            }

        case SET_TYPE_HIRING:
            state.typeOfHiring = action.data
            return {
                ...state
            }

        case SET_DIVISION:
            state.division = action.data
            return {
                ...state
            }

        case SET_COMPANY:
            state.company = action.data
            return {
                ...state
            }

        case SETADDROLE:
            state.addRole = action.data
            return {
                ...state
            }

        case SET_USER_LIST:
            state.userList = action.data
            return {
                ...state
            }

        case SETEMPLOYEELIST:
            state.employeeList = action.data
            return {
                ...state
            }
        case SET_CANDIDATE:
            state.candidateList = action.data
            return {
                ...state
            }

        case SET_REQUISITION_BY_TAG:
            state.requisitionByTag = action.data
            return {
                ...state
            }

        case SETALLCANDIDATE:
            state.allCandidate = action.data
            return {
                ...state
            }

        case SET_DESIGNATION:
            state.designation = action.data
            return {
                ...state
            }

        case SET_REQUISITION:
            state.requisitionList = action.data
            return {
                ...state
            }
        case SET_BILLING_STATUS:
            state.candidateBilling = action.data
            return {
                ...state
            }

        case SET_CAND_FOR_REVENUE:
            state.candForRevenue = action.data
            return {
                ...state
            }

        case SET_ONE_TIME_SOURCE_CANDIDATE:
            state.oneTimeSourceCandidate = action.data
            return {
                ...state
            }

        case SET_PERMANENT_CAND_REVENUE:
            state.permanentCandForRevenue = action.data
            return {
                ...state
            }

            case SET_ALL_CLIENTBY_COMPANY:
                state.allClientByCompany = action.data
                return {
                    ...state
                }

        case SET_BRANCHBYCLIENT:
                state.branchListByClient = action.data
                return {
                    ...state
                }

        case SET_CLIENTBY_COMPANY:
                    state.clientByCompany = action.data
                    return {
                        ...state
                    }

        default:
            return state
    }
}