import React from 'react';
import TickIcon from '../TickIcon.svg';

function PopupSaveCandidate() {
  return (
    <div className='Savecandidate' style={{ width: '355px', background: 'white', height: '190px', top: '39%', left: '39%', textAlign: '-webkit-center', position:'fixed' ,borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
      <div  style={{display: "flex", justifyContent: "center", marginTop:'50px'}}>
        <img         
          style={{ color: '#20A926', height:'40px', width:'40px', alignContent: 'center' }}
          src={TickIcon}
        />
      </div>
      <p
        className="flex justify-center font-sans font-normal leading-5 text-base mt-6"
        style={{ color: '#525252' }}
      >
        You have successfully added Candidate..
      </p>
    </div>
  );
}

export default PopupSaveCandidate;
