
import React from 'react'
import Select from 'react-select';


const Step3Form = ({
    handleTechnicalSkillsCheckboxChange,
    handleNonTechnicalSkillsCheckboxChange,
    handleUnskilledLaborCheckboxChange,
    handleInternsCheckboxChange,
    showTechnicalSkillsDropdown,
    showNonTechnicalSkillsDropdown,
    showUnskilledLaborDropdown,
    showInternsDropdown,
    handleInputChangePage2,
    technicalSkills,
    nonTechnicalSkills,
    unskilledLabor,
    interns,
    agreementDuration,
    handleInputChange,
    dateOfAgreement,
    billingDetails,
    panGatTanNo,
    accountManagerfromVendor,
    accountManagerfromIPSPL,
    clauseRequirement,
    vendorDetails,
    responsibilitiesofIPS,
  }) => {





  return (
    <>
     <div>
      <h2 style={{textAlign:"center",marginTop:"0",paddingTop:"0"}}> Step 3 : Vendor Agreement Details </h2>
      

      <div style={{width:"100%",fontSize:"13px",display:"flex",flexWrap:"wrap",justifyContent:"space-between", alignItems:"center"}}>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Important Responsibilities of IPS</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={responsibilitiesofIPS}
          name="responsibilitiesofIPS"
          onChange={handleInputChangePage2}
        />
      </div>
     <div  style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Vendor Details</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={vendorDetails}
          name="vendorDetails"
          onChange={handleInputChangePage2}
        />
      </div>
     <div  style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Other Important Clause / Requirement</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={clauseRequirement}
          name="clauseRequirement"
          onChange={handleInputChangePage2}
        />
      </div>
     <div  style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Account Manager from IPSPL</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={accountManagerfromIPSPL}
          name="accountManagerfromIPSPL"
          onChange={handleInputChangePage2}
        />
      </div>
     <div  style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Account Manager from Vendor as Relationship Manager</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={accountManagerfromVendor}
          name="accountManagerfromVendor"
          onChange={handleInputChangePage2}
        />
      </div>
     
     {/* < style={{width:"48%"}}div >
        <p style={{margin:"15px 0 0 0"}}>PAN NO. / GST NO. / TAN NO. (if  any) from Vendor</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={panGatTanNo}
          name="panGatTanNo"
          onChange={handleInputChangePage2}
        />
      </> */}
     <div  style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Billing Details of Client (will be informed Clientwise)</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          value={billingDetails}
          name="billingDetails"
          onChange={handleInputChangePage2}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Date of Agreement:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="date"
          name="dateOfAgreement"
          value={dateOfAgreement}
          onChange={handleInputChange}
        />
      </div>
      <div style={{width:"48%"}}>
        <p style={{margin:"15px 0 0 0"}}>Agreement Duration:</p>
        <input
          style={{width:"96%",height:"32px",paddingLeft:"10px"}}
          type="text"
          name="agreementDuration"
          value={agreementDuration}
          onChange={handleInputChange}
        />
      </div>
   
    </div>



    {/* <div style={{fontSize:"13px",marginTop:"15px"}}>
      <p style={{margin:"0"}}>Vendor Capacity</p>
      <div style={{display:"flex",alignItems:"center",marginBottom:"10px"}}> 
      <div style={{}}>
        <label>
        <input
        checked={showTechnicalSkillsDropdown}
            type="checkbox"
            name="technicalSkills"
            onChange={handleTechnicalSkillsCheckboxChange}
          />
          Technical Skills
        </label>
      </div>
      <div style={{marginLeft:"10px"}}>
        <label>
        <input
        checked={showNonTechnicalSkillsDropdown}
            type="checkbox"
            name="nonTechnicalSkills"
            onChange={handleNonTechnicalSkillsCheckboxChange}
          />
          Non-Technical Skills
        </label>
      </div>
      <div  style={{marginLeft:"10px"}}>
        <label>
        <input
        checked={showUnskilledLaborDropdown}
            type="checkbox"
            name="unskilledLabor"
            onChange={handleUnskilledLaborCheckboxChange}
          />
          Unskilled Labor
        </label>
      </div>
      <div  style={{marginLeft:"10px"}}>
        <label>
        <input
        checked={showInternsDropdown}
            type="checkbox"
            name="interns"
            onChange={handleInternsCheckboxChange}
          />
          Interns/Apprentices
        </label>
      </div>
      </div>

    <div style={{marginTop:"15px"}}>
    {showTechnicalSkillsDropdown && (
        <div>
            <p style={{margin:"0"}}>Technical Skills</p>
            <input
            style={{width:"96%",height:"26px"}}
          type="text"
          name="technicalSkills"
          value={technicalSkills}
          onChange={handleInputChangePage2}
        />
        </div>
  
        )}
    </div>
   

   <div style={{marginTop:"15px"}}>
   {showNonTechnicalSkillsDropdown && (
      <div>
      <p style={{margin:"0"}}>Non-Technical Skills</p>
      <input
      style={{width:"96%",height:"26px"}}
          type="text"
          name="nonTechnicalSkills"
          value={nonTechnicalSkills}
          onChange={handleInputChangePage2}
        />
        </div>
        )}

   </div>
   <div style={{marginTop:"15px"}}>
   {showUnskilledLaborDropdown && (
      <div>
      <p style={{margin:"0"}}>Unskilled Labor</p>
      <input
      style={{width:"96%",height:"26px"}}
          type="text"
          name="unskilledLabor"
          value={unskilledLabor}
          onChange={handleInputChangePage2}
        />
        </div>
        )}
   </div>

    <div style={{marginTop:"15px"}}>
    {showInternsDropdown && (
          <div>
          <p style={{margin:"0"}}>Interns/Apprentices</p>
          <input
          style={{width:"96%",height:"26px"}}
          type="text"
          name="interns"
          value={interns}
          onChange={handleInputChangePage2}
        /></div>
        )}
    </div>
    </div> */}



    
    </div>
    </>
  )
}

export default Step3Form