import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditIcon from "../EditIcon.svg";
import DeleteIcon from "../DeleteIcon.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PopupSaveClientList from "./PopupSaveClientList";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { addClient, getClient, getDesignation, getCompany, getUserList } from "../../../../redux/action";
import { Divider, TableBody } from "@mui/material";
import UpdateClient from "./update";
import { useForm } from "react-hook-form";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import './clientlist.css'
import axios from "axios";
import { TiDeleteOutline } from "react-icons/ti";

const { URL } = require("../../../../config.json");


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div  
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ClientList() {
  let emp_id = localStorage.getItem("employeeId");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(getDesignation());
    dispatch(getClient());
    dispatch(getCompany());
    dispatch(getUserList());
  }, []);

  let designationList = useSelector((state) => state.clientReducer.designation);

  let userList = useSelector((state) => state.clientReducer.userList);

  const options = userList.map((x) => ({
    z: JSON.stringify(x),
    value: x.userName,
    label: x.userName,
  }));
  // const optionsDevision = userList.map((x) => ({
  //   z: JSON.stringify(x),
  //   value: x.userName,
  //   label: x.userName,
  // }));

  const desi = designationList && designationList.map((y) => ({ value: y.name, label: y.name }));

  let companyList = useSelector((state) => state.clientReducer.company);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  let clientList = useSelector((state) => state.clientReducer.clientList);
  const [open, setOpen] = useState(false);
  const [designation, setDesignation] = useState();
  const [open10, setOpen10] = useState(false);
  const [clientName, setClientName] = useState('');
  const [company, setCompany] = useState('');
  const [accountManager, setAccountManager] = useState('');
  const [services, setServices] = useState('');
  const [devision, setDevision] = useState([]);
  const [accuired, setAccuired] = useState('')
  const [sagment, setSagment] = useState('')
  const [spocName, setSpocName] = useState('');
  const [spocEmail, setSpocEmail] = useState();
  const [spocNumber, setSpocNumber] = useState();
  const [contractualMarginType, setContractualMarginType] = useState();
  const [contractualMarginAmount, setContractualMarginAmount] = useState();
  const [contractualOnTimeSourcingAmountType, setContractualOnTimeSourcingAmountType] = useState();
  const [contractualOnTimeSourcingAmount, setContractualOnTimeSourcingAmount] = useState();
  const [permonentMarginType, setPermonentMarginType] = useState();
  const [clientData, setClientData] = useState();
  const [permonentMarginAmount, setPermonentMarginAmount] = useState();
  const [permonentOnTimeSourcingType, setPermonentOnTimeSourcingType] = useState();
  const [permonentOnTimeSourcingAmount, setPermonentOnTimeSourcingAmount] = useState();
  const [spocs, setSpocs] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('6');

  const [folder_name, setFolderName] = useState('Bi_ClientList_Folder/Bi_ClientList');

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  console.log('xdgvbn', imageUrls);

  const resetFileInput = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = ''; 
    }
  };

  const noticeChangingHandler = async (event) => {
    setLoading(true);

    try {
      const uploadedFiles = event.target.files;
      const newFiles = Array.from(uploadedFiles);
      const validFiles = newFiles.filter(file => file.size >= 1024 && file.size <= 5 * 1024 * 1024);

      if (validFiles.length > 0) {
        setFiles(prevFiles => [...prevFiles, ...validFiles]); 

        await Promise.all(validFiles.map(file => uploadOnS3(file)));
      } else {
        resetFileInput();

        alert('Please select one or more files between 1KB and 5MB in size.');
      }
    } catch (error) {
      console.log('Error occurred during upload:', error);
    }
  };

  const uploadOnS3 = async (file) => {
    console.log('check data in line no. 349', file);
    let formData = new FormData();

    formData.append('image', file);
    formData.append('organisation_id', localStorage.getItem('organisationID'));
    formData.append('client_id', localStorage.getItem('buid'));
    formData.append('employeeId', localStorage.getItem('EmployeeID'));
    formData.append('folder_name', folder_name);
    console.log('check data in line no. 256', formData);

    try {
      const response = await axios.post(`${URL}/uploadFile/uploadFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully!', response.data);
      setImageUrls(prevImageUrls => [...prevImageUrls, { name: file.name, url: response.data }]);
      return { name: file.name, url: response.data };
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };


  const removeFile = (index) => {
    setImageUrls(prevFiles => prevFiles.filter((_, i) => i !== index));
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  
  const handleClickOpen = () => {
    if (clientName == ''||accountManager=='' || company == '' || accuired == ''  ) {
      window.alert('please select required fields')
    }
    else {
      const agreementUrls = imageUrls.map(({ name, url }) => ({ name, url }));

      let data = {
        clientName: clientName,
        accountManagerName: JSON.parse(accountManager).userName,
        accountManagerId: JSON.parse(accountManager)._id,
        divisionNamemultiple: selectedDivisions,
        spocs: spocs,
        selectedServices:selectedServices,
        selectedSagment:selectedSagment,
        company: company,
        createdBy: emp_id,
        Agreement_Url: agreementUrls,
        acquiredBy :JSON.parse(accuired)._id ,
        contractual: {
          contractualMarginType: contractualMarginType,
          contractualMarginAmount: contractualMarginAmount,
          contractualOnTimeSourcingAmountType:
            contractualOnTimeSourcingAmountType,
          contractualOnTimeSourcingAmount: contractualOnTimeSourcingAmount,
          selectedMonth:selectedMonth,
        },
        permonent: {
          permonentMarginType: permonentMarginType,
          permonentMarginAmount: permonentMarginAmount,
          permonentOnTimeSourcingType: permonentOnTimeSourcingType,
          permonentOnTimeSourcingAmount: permonentOnTimeSourcingAmount,
        },
      };
      dispatch(addClient(data));

      setDesignation('')
      setClientName('')
      setCompany('')
      setAccountManager('')
      setFiles([])
      resetFileInput(); 
      setAccuired('')
      setSpocName('')
      setSpocEmail('')
      setSpocNumber('')
      setContractualMarginType('')
      setContractualMarginAmount('')
      setContractualOnTimeSourcingAmountType('')
      setContractualOnTimeSourcingAmount('')
      setPermonentMarginType('')
      setClientData('')
      setPermonentMarginAmount('')

      setFiles([]);
      setImageUrls([])
      resetFileInput();

      dispatch(getClient());

      setTimeout(() => {
        dispatch(getClient());
      }, 100)
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 2000)
    }
  };

  const editClient = (n) => {
    setClientData(n);
    setOpen10(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose10 = () => {
    setOpen10(false);
  };

  const addSpoc = () => {
    spocs.push({
      spocName: spocName,
      spocEmail: spocEmail,
      spocNumber: spocNumber,
      designation: designation,
    });
    setSpocName("");
    setDesignation("");
    setSpocEmail("");
    setSpocNumber("");
  };

  const deleteSpoc = (ind) => {
    let d = spocs.filter((d, i) => i != ind);
    setSpocs(d);
  };

  const empl = clientList;

  const [empName, setempName] = useState("");

  const [foundemployee, setFoundEmployee] = useState(clientList);

  useEffect(() => {
    setFoundEmployee(clientList);
  }, [clientList]);

  const empfilter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = empl.filter((user) => {
        return user.clientName == undefined
          ? ""
          : user.clientName.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFoundEmployee(results);
    } else {
      setFoundEmployee(empl);
    }

    setempName(keyword);
  };

  // const months = Array.from({ length: 12 }, (_, index) => ({
  //   value: (index + 1).toString(),
  //   label: `${index + 1}`,
  // }));


  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };
  


  const [divisions, setDivisions] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  console.log("uyfuyg",selectedDivisions)

  useEffect(() => {
    axios.get(`${URL}bi_division/all_division`)
      .then(response => {
        console.log("cvghb",response.data)
        const names = response.data.map(division => division.divisionName);

        setDivisions(names);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const optionsDevision = divisions.map(name => ({
    value: name,
    label: name,
  }));
  const handleDivisionChange = selectedOptions => {
    const selectedNames = selectedOptions.map(option => option.value);
    console.log("tryb",selectedNames)
    setSelectedDivisions(selectedNames);
  };



  const [selectedServices, setSelectedServices] = useState([]);

  console.log("hgcygv",selectedServices)

  const optionServices = [
    { value: 'permanent', label: 'Permanent' },
    { value: 'contractual', label: 'Contractual' },
    { value: 'rpo', label: 'RPO' },
    { value: 'workforceManagement', label: 'Workforce Management' },
  ];
  const handleServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const [selectedSagment, setSelectedSagment] = useState('');

  const optionSagment = [
    { value: 'iTStaffing', label: 'IT Staffing' },
    { value: 'executiveSearch', label: 'Executive Search' },
    { value: 'internationalStaffingService', label: 'International Staffing Service' },
    { value: 'recruitmentProcessOutsource', label: 'Recruitment Process Outsourcing' },
    { value: 'flexiStaffing', label: 'Flexi Staffing' },
    { value: 'staffingForInfrastructureDomain', label: 'Staffing for Infrastructure Domain' },
    { value: 'staffingInOilAndEnergy', label: 'Staffing in Oil and Energy sector' },
    { value: 'staffingInHospitalityIndustry', label: 'Staffing in Hospitality Industry' },
    { value: 'staffingAndManagementInTelecomm', label: 'Staffing and Management in Telecommunications' },
    { value: 'salesStaffing', label: 'Sales Staffing Services' },
    { value: 'payRollingandCompliance', label: 'Pay rolling and Compliance Management(Internal & External)' },
    { value: 'traningAndDevelopment', label: 'Traning and Development of resources / performance management / HR policy implementation' },
   
  ];

  const handleSagmentChanges = (selectedOption) => {
    setSelectedSagment(selectedOption ? selectedOption.label : '');
  };







  return (
    <div  style={{ width: "100%", fontFamily: "sans-serif", marginTop: "20px"  }}>
      <Dialog maxWidth={false} open={open10}>
        <DialogContent className="popup">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <CloseIcon className="closeicon"
              onClick={handleClose10}
              fontSize="small"
              style={{
                marginTop: "-4px",
                float: "right",
                position: "absolute",
                marginRight: "-8px",
              }}
            />
          </div>
          <UpdateClient close={handleClose10} client={clientData} />
        </DialogContent>
      </Dialog>
      {/* <Dialog maxWidth={false} open={open}>
        <DialogContent>
          <PopupSaveClientList />
        </DialogContent>
      </Dialog> */}
      <div className="clint_listMainDiv"
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontFamily: "sans-serif",
          margin: "12px 15px 12px 15px",
        }}
      >
        <div className="clientdivOne" style={{ fontFamily: "sans-serif", width: "49%" }}>
          <div
          className="mainpad"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "10px",
            }}
          >
            <p className="fonttext"
              style={{
                color: "#434345",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "24px",
                marginTop: "5px",
              }}
            >
              CLIENT LIST
            </p>
            <div
              style={{
                fontFamily: "sans-serif",
                display: "flex",
                columnGap: "20px",
              }}
            >
              <p
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#434343",
                  fontFamily: "sans-serif",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  width: "82px",
                  height: "28px",
                  borderRadius: "5px",
                  textAlign: "center",
                  paddingTop: "12px",
                  marginTop: "1px",
                }}
              >
                {clientList.length} Clients
              </p>
              <TextField
              className="inputtext"
                size="small"
                style={{
                  width: "226px",
                  borderRadius: "5px",
                  // border: "1px solid #E5E5E5",
                }}
                placeholder="Search Here"
                value={empName}
                onChange={empfilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        fontSize="small"
                        style={{ color: "#767676" }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </div>

           
          </div>


           {/* mobile view forv search */}
           <div className="inputtextmobilediv">
           
            <TextField
              className="inputtextmobile"
                size="small"
                style={{
                  // width: "100% !important",
                  borderRadius: "5px",
                  display:'none',
                  border: "1px solid #E5E5E5",
                }}
                placeholder="Search Here"
                value={empName}
                onChange={empfilter}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" >
                      <SearchIcon
                        fontSize="small"
                        style={{ color: "#767676" }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              </div>
          <div>
            <Table>
              <TableHead>
                <TableRow  className="backgroundmain" style={{ backgroundColor: "#F5FBFE" }}>
                  <TableCell
                 className="textfont"
                    style={{
                      width: "9%",
                      color: "#525252",
                      textAlign: "start",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                  className="textfont"
                    style={{
                      width: "18%",
                      color: "#525252",
                      textAlign: "start",
                      paddingLeft:"0"
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                  className="textfont"
                    style={{
                      width: "20%",
                      color: "#525252",
                      textAlign: "start",
                    }}
                  >
                    Account Manager
                  </TableCell>
                  <TableCell
                  className="textfont"
                    style={{
                      width: "24%",
                      color: "#525252",
                      textAlign: "start",
                    }}
                  >
                    Agreement File
                  </TableCell>
                  <TableCell
                  className="textfont"
                    style={{
                      width: "12%",
                      color: "#525252",
                      textAlign: "start",
                      cursor: "pointer",
                    }}
                  >
                    Edit
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>

            <div style={{ height: "416px", overflowY: "scroll" }}>
              <Table>
                {foundemployee&&foundemployee.map((x, i) => (
                  <TableRow style={{ background: "#FAFAFA" }}>
                    <TableCell
                    className="maintextfont"
                      style={{
                        width: "8%",
                        color: "#525252",
                        textAlign: "start",
                        paddingLeft: "4%",
                      }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                    className="maintextfont"
                      style={{
                        width: "20%",
                        color: "#525252",
                        textAlign: "start",
                        paddingLeft: "2.8%",
                      }}
                    >
                      {x.clientName}
                    </TableCell>
                    <TableCell
                    className="maintextfont"
                      style={{
                        width: "20%",
                        color: "#525252",
                        textAlign: "start",
                        paddingLeft: "3.3%",
                      }}
                    >
                      {x.accountManagerName}
                    </TableCell>
                    <TableCell
                    className="maintextfont"
                      style={{
                        width: "30%",
                        color: "#525252",
                        textAlign: "start",
                        paddingLeft: "3.3%",
                        fontSize:"12px",
                        wordBreak: 'break-all'
                      }}
                    >
      {x.Agreement_Url && x.Agreement_Url.map((agreement, j) => (
        <div style={{margin:"3px 0"}} key={j}>
          <div>{j + 1}. <a  href={agreement.url} target="_blank" rel="noopener noreferrer">{agreement.name}</a></div>
        </div>
      ))}
                    </TableCell>

                    <TableCell
                    className="maintextfont"
                      onClick={() => editClient(x)}
                      style={{
                        width: "12%",
                        textAlign: "start",
                        cursor: "pointer",
                        paddingLeft: "5%",
                      }}
                    >
                      <img
                        style={{ width: "16px", height: "16px" }}
                        src={EditIcon}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </div>
          </div>
        </div>
        <div className="clientdivTwo" style={{ width: "49%" }}>
          <div className="clientdivchild"
            style={{
              // height: "950px",
              padding:"10px 0",
              border: "1px solid #CFDFED",
              background: "#EEF1F4",
              borderRadius: "10px",
            }}
          >
            <form
              onSubmit={handleSubmit(handleClickOpen)}
              style={{ margin: "16px 16px 16px 16px" }}
            >
              <p
                style={{
                  color: "#155188",
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                  lineHeight: "18px",
                  fontWeight: 500,
                  marginTop: "18px",
                }}
              >
                ADD CLIENT
              </p>
              <hr />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "sans-serif",
                  rowGap: "8px",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#525252",
                }}
              >
                <div className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "20px",
                    marginTop: "16px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Client Name *</p>
                  <input
                    type="text"
                    {...register("ClientName", { required: true })}
                    placeholder={
                      errors.ClientName?.type === "required" &&
                      "ClientName is required"
                    }
                    aria-invalid={errors.ClientName ? "true" : "false"}
                    className="addclintone_firstname"
                    style={{
                      marginLeft: "20px",
                      width: "196px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                    }}
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                  <p className="textalinepera">Company *</p>
                  <select
                  className="addclintone"
                    style={{
                      width: "200px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      height: "43px",
                      border: "1px solid #DCE3EA",
                      marginLeft:"33px"
                    }}
                    value={company}
                    onChange={(k) => setCompany(k.target.value)}
                  >
                    <option>Select</option>
                    {companyList.map((n) => (
                      <option value={n.company}>{n.company}</option>
                    ))}
                  </select>
                </div>


                <div  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "20px",
                    marginTop: "16px",
                  }} >
                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Acquired By *</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                      marginLeft:"30px"
                    }}
                  >
                    <Select
                      options={options}
                      onChange={(g) => setAccuired(g.z)}
                    />
                  </div>
                </div>



                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Account Manager *</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                    }}
                  >
                    <Select
                      options={options}
                      onChange={(g) => setAccountManager(g.z)}
                    />
                  </div>
                </div>
                </div>








                <div  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "20px",
                    marginTop: "16px",
                  }} >
                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Segment</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      // background: "#FFFFFF",
                      // border: "1px solid #DCE3EA",
                      marginLeft:"55px"
                    }}
                  >
                    {console.log("hgcgc",selectedSagment)}
                   <Select
        options={optionSagment}
        onChange={handleSagmentChanges}
        value={optionSagment.find(option => option.value === selectedSagment)}
      />
                  </div>
                </div>

                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Services</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                      marginLeft:"55px"
                    }}
                  >
                     <Select
                       options={optionServices}
                       isMulti
                       onChange={handleServiceChange}
                       value={optionServices.filter(option => selectedServices.includes(option.value))}
                     />

                  </div>
                </div>
                </div>


















                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    alignItems:"center",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}> Division</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      margin:"16px 18px 0 55px"
                    }}
                  >
                  <Select
        options={optionsDevision}
        isMulti
        value={optionsDevision.filter(option => selectedDivisions.includes(option.value))}
        onChange={handleDivisionChange}
      />
                  </div>
                </div>




                <div style={{marginTop:"15px"}}> 
                 <label>Agreement Copy :</label>
                       <label id={`my`} style={{   marginLeft: "12px",border: "1px solid blue", borderRadius: "5px", backgroundColor: "white", color: "blue", padding: "5px 10px", cursor: "pointer", display: "inline-block" }}>
            <input 
            htmlFor={`my`} 
            type="file"
             hidden           
                    onChange={(event) => noticeChangingHandler(event)}
 multiple />
            Attach Here
          </label>
                        {files.length > 0 && (
        <ul>
          {files.map((file, index) => (
            <li  key={index}>{file.name} <span style={{marginLeft:"3px",color:"red",cursor:"pointer"}} onClick={() => removeFile(index)}><TiDeleteOutline /></span> 
            </li>
            
          ))}
        </ul>
      )}
                </div>




               

                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "18px",
                    marginTop: "16px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>SPOC </p>
                  <input
                    
                    type="text"
                    size="small"
                    className="addclintone_sop"
                    style={{
                      width: "196px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                      height: "40px",
                      marginLeft: "55px",
                      paddingLeft:"5px"
                    }}
                    placeholder="Name"
                    value={spocName}
                    onChange={(e) => setSpocName(e.target.value)}
                  />
                  <input
                  className="addclintone_sop emailmargin"
                    type="email"
                    size="small"
                    style={{
                      width: "196px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                      height: "40px",
                      paddingLeft:"5px"
                    }}
                    placeholder="Email ID"
                    value={spocEmail}
                    onChange={(e) => setSpocEmail(e.target.value)}
                  />
                </div>
                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "18px",
                  }}
                >
                  <input
                  className="addclintone_sop"
                    type="number"
                    size="small"
                    style={{
                      width: "196px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                      height: "40px",
                      marginLeft: "106px",
                      paddingLeft:"5px"
                    }}
                    placeholder="Mobile No."
                    value={spocNumber}
                    onChange={(e) => setSpocNumber(e.target.value)}
                  />
                  <div style={{ width: "203px" }} className="addclintone_sop">
                    <Select
                    className="addclintone emailmargin"
                      options={desi}
                      onChange={(g) => setDesignation(g.value)}
                    />
                  </div>
                  <button
                  className="addclintone_sop"
                    disabled={spocName == ""}
                    onClick={addSpoc}
                    style={{
                      background: "#4594DE",
                      borderRadius: "100px",
                      width: "84px",
                      height: "32px",
                      color: "#FFFFFF",
                      fontSize: "10px",
                      marginTop: "4px",
                      cursor: spocName ? "pointer" : "not-allowed",
                      border: "1px ridge",
                    }}
                  >
                    Add SPOC
                  </button>
                </div>




              



                <div className="clinettablelist" style={{ }}>
                    <div  className="clinettablelistdivtwo" style={{ }}>

                <Table  style={{ width: "675px", marginTop: "12px" }}>
                  <TableHead >
                    <TableRow  style={{ backgroundColor: "#CEE3F6" }}>
                      <TableCell
                      className="distag"
                     
                        style={{
                          width: "24%",
                          color: "#525252",
                          textAlign: "start",
                          fontWeight: 550,
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                      className="distag"
                        style={{
                          width: "24%",
                          color: "#525252",
                          textAlign: "start",
                          fontWeight: 550,
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                      className="distag"
                        style={{
                          width: "20%",
                          color: "#525252",
                          textAlign: "start",
                          fontWeight: 550,
                        }}
                      >
                        Mobile No.
                      </TableCell>
                      <TableCell
                      className="distag"
                        style={{
                          width: "27%",
                          color: "#525252",
                          textAlign: "start",
                          fontWeight: 550,
                        }}
                      >
                        Designation
                      </TableCell>
                      <TableCell
                      className="distag"
                        style={{
                          width: "5%",
                          color: "#525252",
                          textAlign: "start",
                          fontWeight: 550,
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                </Table>

                <div className="clinettablelist"
                  style={{
                    height: "165px",
                    overflowY: "scroll",
                    marginTop: "-8px",
                    width: "675px",
                    // marginLeft: "56px",
                    background: "#ffffff",
                  }}
                >
                  <Table>
                    {spocs.map((n, i) => (
                      <TableRow>
                        <TableCell
                        className="textfont"
                          style={{
                            width: "24%",
                            color: "#525252",
                            textAlign: "start",
                            paddingLeft: "2.5%",
                            fontSize: "12px",
                          }}
                        >
                          {n.spocName}
                        </TableCell>
                        <TableCell
                        className="textfont"
                          style={{
                            width: "24%",
                            color: "#525252",
                            textAlign: "start",
                            paddingLeft: "4.3%",
                            fontSize: "12px",
                          }}
                        >
                          {n.spocEmail}
                        </TableCell>
                        <TableCell
                        className="textfont"
                          style={{
                            width: "20%",
                            color: "#525252",
                            textAlign: "start",
                            paddingLeft: "5.5%",
                            fontSize: "12px",
                          }}
                        >
                          {n.spocNumber}
                        </TableCell>
                        <TableCell
                        className="textfont"
                          style={{
                            width: "27%",
                            color: "#525252",
                            textAlign: "start",
                            paddingLeft: "6.6%",
                            fontSize: "12px",
                          }}
                        >
                          {n.designation}
                        </TableCell>
                        <TableCell
                        className="textfont"
                          style={{
                            width: "5%",
                            color: "#525252",
                            textAlign: "start",
                            fontSize: "12px",
                          }}
                        >
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                              cursor: "pointer",
                            }}
                            src={DeleteIcon}
                            onClick={() => deleteSpoc(i)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </div>

                </div>
                </div>

                <div className="typeofhiring" style={{ marginTop: "10px", fontSize: "14px" }}>
                  Type of Hiring :-
                </div>
                <div>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab className="fontsizemain" label="Contractual" {...a11yProps(0)} />
                      <Tab className="fontsizemain" label="Permanent" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <div
                    
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <div className="clientteam"
                        style={{
                          width: "514px",
                          // height: "236px",
                          padding:"20px 0",
                          backgroundColor: "#DCE4EE",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "#525252",
                          }}
                        >
                          Client Terms and Conditions
                        </p>

                        <Divider style={{ marginTop: "-6px" }} />

                        <div
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            marginTop: "16px",
                            rowGap: "14px",
                            color: "#525252",
                            paddingLeft: "4%",
                          }}
                        >
                          <div className="typeofhiring">1. Contracting Margin on Monthly Basis</div>
                          <div
                            style={{
                              display: "flex",
                              columnGap: "28px",
                              marginTop: "14px",
                            }}
                          >
                            <select
                              style={{
                                width: "196px",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #DCE3EA",
                                marginLeft: "5%",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              value={contractualMarginType}
                              onChange={(e) =>
                                setContractualMarginType(e.target.value)
                              }
                              variant="outlined"
                            >
                              <option value="">Select Here</option>
                              <option value="Variable in %">
                                Variable in %
                              </option>
                              <option value="Fixed in Rs">Fixed in Rs</option>
                            </select>
                            <TextField
                              size="small"
                              //required={true}
                              name=""
                              style={{
                                width: "196px",
                                borderRadius: "5px",
                                border: "1px solid #DCE3EA",
                                backgroundColor: "#ffffff",
                              }}
                              placeholder="Enter Amount"
                              value={contractualMarginAmount}
                              onChange={(e) =>
                                setContractualMarginAmount(e.target.value)
                              }
                              variant="outlined"
                              autoComplete="off"
                            />
                          </div>
                          <div className="typeofhiring" style={{ marginTop: "14px" }}>
                            2. One Time Sourcing Fee
                          </div>
                          <div
                            style={{
                              display: "flex",
                              columnGap: "28px",
                              marginTop: "14px",
                            }}
                          >
                            <select
                              style={{
                                width: "196px",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #DCE3EA",
                                marginLeft: "5%",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              value={contractualOnTimeSourcingAmountType}
                              onChange={(d) =>
                                setContractualOnTimeSourcingAmountType(
                                  d.target.value
                                )
                              }
                              variant="outlined"
                            >
                              <option value="">Select Here</option>
                              <option value="Variable in %">
                                Variable in %
                              </option>
                              <option value="Fixed in Rs">Fixed in Rs</option>
                            </select>
                            <TextField
                              size="small"
                              //required={true}
                              name=""
                              style={{
                                width: "196px",
                                borderRadius: "5px",
                                border: "1px solid #DCE3EA",
                                backgroundColor: "#ffffff",
                              }}
                              placeholder="Enter Amount"
                              value={contractualOnTimeSourcingAmount}
                              onChange={(e) =>
                                setContractualOnTimeSourcingAmount(
                                  e.target.value
                                )
                              }
                              variant="outlined"
                              autoComplete="off"
                            />
                          </div>

                          <div
                className="addclintone"
                  style={{
                    display: "flex",
                    flexDirection:"column",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                    marginTop: "16px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>3. Employee Avg. Lifecycle ( Month ) </p>
                  <div
                  className="addclintone mar"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      border: "1px solid #DCE3EA",
                      marginLeft:"25px",
                      // marginTop: "14px",
                    }}
                  >
            {/* <Select
        value={months.find((month) => month.value === selectedMonth)}
        onChange={handleMonthChange}
        options={months}
        isSearchable={true}
      /> */}
        <select
        className="addclintone"
                value={selectedMonth}
                onChange={handleMonthChange}
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "8px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                 
                }}
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
                    
                  </div>
                </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "sans-serif",
                      }}
                    >
                      <div
                      className="clientteam"
                        style={{
                          width: "514px",
                          height: "236px",
                          backgroundColor: "#DCE4EE",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "18px",
                            color: "#525252",
                          }}
                        >
                          Client Terms and Conditions
                        </p>

                        <Divider style={{ marginTop: "-6px" }} />

                        <div
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            marginTop: "16px",
                            rowGap: "14px",
                            color: "#525252",
                            paddingLeft: "4%",
                          }}
                        >
                          <div className="typeofhiring">1. Contracting Margin on Monthly Basis</div>
                          <div
                            style={{
                              display: "flex",
                              columnGap: "28px",
                              marginTop: "14px",
                            }}
                          >
                            <select
                              style={{
                                width: "196px",
                                height: "40px",
                                borderRadius: "5px",
                                border: "1px solid #DCE3EA",
                                marginLeft: "5%",
                              }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name=""
                              placeholder="Enter Here"
                              value={permonentMarginType}
                              onChange={(e) =>
                                setPermonentMarginType(e.target.value)
                              }
                              variant="outlined"
                            >
                              <option value="">Select Here</option>
                              <option value="Variable in %">
                                Variable in %
                              </option>
                              <option value="Fixed in Rs">Fixed in Rs</option>
                            </select>
                            <TextField
                              size="small"
                              //required={true}
                              name=""
                              style={{
                                width: "196px",
                                borderRadius: "5px",
                                border: "1px solid #DCE3EA",
                                backgroundColor: "#ffffff",
                              }}
                              placeholder="Enter Amount"
                              value={permonentMarginAmount}
                              onChange={(e) =>
                                setPermonentMarginAmount(e.target.value)
                              }
                              variant="outlined"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                className="savebuttonclientlist"
                  type="submit"
                  style={{
                    width: "70px",
                    height: "40px",
                    borderRadius: "5px",
                    color: "#EDF0F6",
                    backgroundColor: "#4594DE",
                    cursor: "pointer",
                    border: "1px solid #4594DE",
                  }}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientList;
