import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "../EditIcon.svg";
import PopupClient from "./PopupClient";
import CloseIcon from "@mui/icons-material/Close";
import PopupAddRequisition from "./PopupAddRequisition";
import { useDispatch, useSelector } from "react-redux";
import {
  getClient,
  getDesignation,
  getRequisition,
  putRequisition,
  getDivision,
  getUserList,
} from "../../../../redux/action";
import PopupEditRequisition from "./PopupEditRequisition";
import UpdateStatus from "./UpdateStatus";
import axios from "axios";
import { Modal } from "@mui/material";
import "./requisition.css";
import { MenuItem, Select } from "@mui/base";

const { URL } = require("../../../../config.json");

function Requisition() {
  let dispatch = useDispatch();

  let requisitionList = useSelector(
    (state) => state.clientReducer.requisitionList
  );
  let clientList = useSelector((state) => state.clientReducer.clientList);
  let UserList = useSelector((state) => state.clientReducer.userList);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [showClientData, setShowClientData] = useState({});
  const handleClickOpen = (x) => {
    let tempClientData = clientList.filter((data) => data._id == x.clientId);

    setShowClientData(...tempClientData);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const [operationStatusType, setOperationStatusType] = useState("add");
  const [requisitionEditData, setRequisitionEditData] = useState({});
  const [count, setCount] = useState(100);

  const handleClickOpenEdit = (data) => {
    setRequisitionEditData(data);
    setOpenEdit(true);
  };
  const handleClickOpen1 = (val, data) => {
    setOperationStatusType(val);
    if (val != "add") {
      setRequisitionEditData(data);
    }
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  useEffect(() => {
    dispatch(getRequisition());
    dispatch(getClient());
    dispatch(getDivision());
    dispatch(getUserList());
    dispatch(getDesignation());
  }, []);
  useEffect(() => {
    setReqList(requisitionList.filter((b, i) => i < count));
  }, [requisitionList]);

  const handleChangePriority = (e, data) => {
    let { value } = e.target;
    let tempData = { ...data, priority: value };

    dispatch(putRequisition(tempData));
    // alert("Status Updated");
  };
  const [searchVal, setSearchVal] = useState("");
  const [status, setStatus] = useState("true");
  const [reqList, setReqList] = useState([]);

  const [requisitionId, setRequisition] = useState();
  const handleSearch = (e) => {
    let { value } = e.target;
    setSearchVal(value);

    if (value != "") {
      let x = requisitionList.filter((data) =>
        // return row.name.toLowerCase().startsWith(keyword.toLowerCase());
        {
          return (
            (data.g.positionName &&
              data.g.positionName
                .toLowerCase()
                .startsWith(value.toLowerCase())) ||
            (data.g.clientName &&
              data.g.clientName.toLowerCase().startsWith(value.toLowerCase()))
          );
        }
      );
      setReqList(x);
    } else {
      setReqList(requisitionList.filter((b, i) => i < count));
    }
  };

  const updateRequisitionStatus = async (requisitionId, remark, status) => {

    if (remark == "") {
      window.alert("Please Enter Remark");
    } else {
      axios
        .put(
          `${URL}bi_requisition/updateStatus/${status}/${requisitionId}/${remark}`
        )
        .then((res) => {
         
          setOpen12(false);
          dispatch(getRequisition());

        })
        .catch((err) => {});
    }
  };

  const CloseStatu = () => {
    setOpen12(false);
  };

  const handleOpen = (status, id) => {
    setOpen12(true);
    setRequisition(id);
  };

  function load() {
    setCount(count + 100);
    setReqList(requisitionList.filter((b, i) => i < count + 100));
  }

  const maxItemsToShow = 2;
  const hoverDuration = 10000;

  const [showAllMap, setShowAllMap] = useState(
    reqList.reduce((acc, cur) => {
      acc[cur.g._id] = false;
      return acc;
    }, {})
  );

  const handleMouseEnter = (gId) => {
    setShowAllMap((prev) => ({ ...prev, [gId]: true }));
  };

  const handleMouseLeave = (gId) => {
    setShowAllMap((prev) => ({ ...prev, [gId]: false }));
  };

  function reqCreatedBy(id) {
    let user = UserList.find((u) => u._id == id);
    return user == undefined ? "" : user;
  }

  // const [holdRows, setHoldRows] = useState([]);

  const handleStatusChange = (event, data) => {
   
    const newStatus = event.target.value;
    console.log("sdcsdc", data);
    let rem = "-";
    if (newStatus == "Closed") {
      handleOpen("Closed", data._id);

    }else if (newStatus === "Hold") {
      
      alert("Requisition is on hold.");
      const holddata = { ...data, status: newStatus };
      console.log("holddata", holddata);
      updateRequisitionStatus(holddata._id, rem, holddata.status);
    } 
    else {
      alert("Requisition is Active.");
      const holddata = { ...data, status: newStatus };
      console.log("holddata", holddata);
      updateRequisitionStatus(holddata._id, rem, holddata.status);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        fontFamily: "sans-serif",
      }}
    >
      <Dialog maxWidth={false} open={open}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              onClick={handleClose}
              fontSize="small"
              style={{
                marginTop: "-4px",
                float: "right",
                position: "absolute",
                marginRight: "-8px",
                cursor: "pointer",
              }}
            />
          </div>
          <PopupClient showClientData={showClientData} />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={false} open={openEdit}>
        <DialogContent className="popaddreq">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              className="closeico"
              onClick={handleCloseEdit}
              fontSize="small"
              style={{
                marginTop: "-4px",
                float: "right",
                position: "absolute",
                marginRight: "-8px",
                cursor: "pointer",
              }}
            />
          </div>
          <PopupEditRequisition
            handleCloseEdit={handleCloseEdit}
            operationStatusType={operationStatusType}
            requisitionData={requisitionEditData}
          />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={false} open={open1}>
        <DialogContent className="popaddreq">
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              className="closeico"
              onClick={handleClose1}
              fontSize="small"
              style={{
                marginTop: "-4px",
                float: "right",
                position: "absolute",
                marginRight: "-8px",
              }}
            />
          </div>
          <PopupAddRequisition
            operationStatusType={operationStatusType}
            requisitionData={requisitionEditData}
            handleClose1={handleClose1}
          />
        </DialogContent>
      </Dialog>
      <div
        style={{
          fontFamily: "sans-serif",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0px 0px 0px",
        }}
      >
        <div
          style={{
            color: "#434345",
            fontFamily: "sans-serif",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
          }}
        >
          REQUISITION
        </div>
        <div style={{ display: "flex", columnGap: "12px" }}>
          <p
            style={{
              backgroundColor: "#F1F1F1",
              color: "#434343",
              fontFamily: "sans-serif",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              width: "112px",
              height: "28px",
              borderRadius: "5px",
              textAlign: "center",
              paddingTop: "12px",
              marginTop: "1px",
            }}
          >
            {requisitionList.length} Requisitions
          </p>
          <TextField
            className="reudisplaynone"
            size="small"
            style={{
              width: "256px",
              borderRadius: "5px",
            }}
            placeholder="Search Here"
            onChange={handleSearch}
            value={searchVal}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" style={{ color: "#767676" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
          <button
            className="reudisplaynone"
            style={{
              backgroundColor: "#4594DE",
              fontFamily: "sans-serif",
              width: "128px",
              height: "40px",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "16px",
              borderRadius: "5px",
              color: "#FFFFFF",
              border: "1px ridge",
              cursor: "pointer",
            }}
            onClick={() => handleClickOpen1("add", {})}
          >
            ADD REQUISITION
          </button>
        </div>
      </div>

      {/* mobile View */}

      <div className="displblock" style={{ display: "none" }}>
        <TextField
          size="small"
          style={{
            width: "100%",
            borderRadius: "5px",
          }}
          placeholder="Search Here"
          onChange={handleSearch}
          value={searchVal}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" style={{ color: "#767676" }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "10px" }}
        >
          <button
            style={{
              backgroundColor: "#4594DE",
              fontFamily: "sans-serif",
              width: "115px",
              height: "40px",
              fontSize: "10px",
              fontWeight: "600",
              lineHeight: "16px",
              borderRadius: "5px",
              color: "#FFFFFF",
              border: "1px ridge",
              cursor: "pointer",
            }}
            onClick={() => handleClickOpen1("add", {})}
          >
            ADD REQUISITION
          </button>
        </div>
      </div>
      <hr style={{ marginBottom: "18px" }} />
      <div
        className="scrollmain"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="scrollreqursion" style={{ width: "1550px" }}>
          {reqList.length > 0 ? (
            <>
              <Table>
                <TableHead>
                  <TableRow
                    className="backgroundmain"
                    style={{ backgroundColor: "#F5FBFE", fontSize: "10px" }}
                  >
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "5%",
                        color: "#272945",
                        textAlign: "center",
                      }}
                    >
                      S. No.
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "11%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Client Name
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "10%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Position Name
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "11%",
                        color: "#272945",
                        textAlign: "center",
                      }}
                    >
                      Date of Allocation
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "10%",
                        color: "#272945",
                        textAlign: "center",
                      }}
                    >
                      Location
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "10%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Total Positions
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "10%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Pending Position
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "10%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Add Recruiter
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "6%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      TAT
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "7%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Priority
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "5%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "12px",
                        fontWeight: "550",
                        width: "5%",
                        color: "#272945",
                        textAlign: "start",
                      }}
                    >
                      Edit
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>

              <div
                style={{ width: "100%", height: "395px", overflowY: "scroll" }}
              >
                <Table>
                  {reqList.map((data, index) => (
                    <div
                      key={data.g._id}
                      // className={holdRows.includes(data.g._id) ? 'disabled-row' : ''}
                    >
                      <TableRow style={{ position: "relative" }}>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "5%",
                            color: "#2E2E2E",
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "11%",
                            color: "#1879D4",
                            textAlign: "start",
                            textDecoration: "underline",
                            cursor: "pointer",
                            paddingLeft: "1.4%",
                            verticalAlign: "top",
                            wordBreak: "break-word",
                          }}
                          onClick={() => handleClickOpen(data.g)}
                        >
                          {data.g.clientName}
                        </TableCell>

                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "10%",
                            color: "#2E2E2E",
                            textAlign: "start",
                            verticalAlign: "top",
                            // paddingLeft: "1.75%",
                            wordBreak: "break-word",
                          }}
                        >
                          {data.g.positionName}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "11%",
                            color: "#2E2E2E",
                            textAlign: "center  ",
                            verticalAlign: "top",
                            // paddingLeft: "2.1%",
                          }}
                        >
                          {data.g.createdAt
                            ? data.g.createdAt.slice(0, 10)
                            : ""}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "10%",
                            color: "#2E2E2E",
                            textAlign: "center",
                            paddingLeft: "2.6%",
                            wordBreak: "break-all",
                            verticalAlign: "top",
                            wordBreak: "break-word",
                          }}
                        >
                          {data.g.locationName}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "10%",
                            color: "#2E2E2E",
                            textAlign: "center",
                            verticalAlign: "top",
                            // paddingLeft: "2.9%",
                          }}
                        >
                          {data.g.noOfPosition ? data.g.noOfPosition : 0}
                          {/* {data?.locationArray.reduce(
                      (sum, data) => sum + parseInt(data.noOfPosition),
                      0
                    )} */}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "10%",
                            color: "#2E2E2E",
                            textAlign: "center",
                            verticalAlign: "top",
                            // paddingLeft: "4.5%",
                          }}
                        >
                          {data.pendingposition}
                        </TableCell>

                        <TableCell
                          className="maintextfont"
                          style={{
                            wordBreak: "break-word",
                            cursor: "pointer",
                            fontSize: "10px",
                            width: "10%",
                            color: "#2E2E2E",
                            textAlign: "center",
                            overflow: "hidden",
                            transition: `max-height ${hoverDuration}ms `,
                            maxHeight: showAllMap[data.g._id]
                              ? `${data.g.tagRecruiter.length * 16}px`
                              : `${maxItemsToShow * 16}px`,
                          }}
                          onMouseEnter={() => handleMouseEnter(data.g._id)}
                          onMouseLeave={() => handleMouseLeave(data.g._id)}
                        >
                          {data.g.tagRecruiter &&
                            data.g.tagRecruiter
                              .slice(
                                0,
                                showAllMap[data.g._id]
                                  ? data.g.tagRecruiter.length
                                  : maxItemsToShow
                              )
                              .map((m) => (
                                <p
                                  key={m._id}
                                  style={{
                                    wordBreak: "break-word",
                                    borderRadius: "5px",
                                    width: "max-content",
                                    padding: "0px 6px 0px 6px",
                                    backgroundColor: "#CEFFCD",
                                    marginBottom: "10px",
                                    marginTop: "0",
                                    display: "block",
                                  }}
                                >
                                  {m.userName}
                                </p>
                              ))}

                          {!showAllMap[data.g._id] &&
                            data.g.tagRecruiter &&
                            data.g.tagRecruiter.length > maxItemsToShow && (
                              <p
                                style={{
                                  borderRadius: "5px",
                                  width: "max-content",
                                  padding: "0px 6px 0px 6px",
                                  backgroundColor: "#CEFFCD",
                                  marginBottom: "10px",
                                  marginTop: "0",
                                  display: "block",
                                }}
                              >
                                ...
                              </p>
                            )}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            fontSize: "10px",
                            width: "6%",
                            color: "#2E2E2E",
                            textAlign: "center",
                            fontSize: "12px",
                            verticalAlign: "top",
                            // paddingLeft: "1.9%",
                          }}
                        >
                          {data.g.tat}
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            width: "8%",
                            color: "#2E2E2E",
                            textAlign: "start",
                            // paddingLeft: "1.5%",
                            verticalAlign: "top",
                          }}
                        >
                          <select
                            style={{
                              cursor: "pointer",

                              border: "1px solid #9A9A9A",

                              width: "96px",
                              height: "32px",
                              borderRadius: "5px",
                              backgroundColor:
                                data.g.priority == "Burning"
                                  ? "#FF4242"
                                  : data.g.priority == "Hot"
                                  ? "#FF9270"
                                  : "#FFCE70",
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name=""
                            placeholder="Enter Here"
                            onChange={(e) => handleChangePriority(e, data.g)}
                            value={data.g.priority}
                            disabled={localStorage.getItem('roleName') == 'Auditor'}
                            variant="outlined"
                          >
                            <option
                              style={{ backgroundColor: "#FF4242" }}
                              value="Burning"
                            >
                              Burning
                            </option>
                            <option
                              style={{ backgroundColor: "#FF9270" }}
                              value="Hot"
                            >
                              Hot
                            </option>
                            <option
                              style={{ backgroundColor: "#FFCE70" }}
                              value="Moderate"
                            >
                              Moderate{" "}
                            </option>
                          </select>
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            width: "4%",
                            color: "#3B7FEE",
                            textAlign: "end",
                            cursor: "pointer",

                            verticalAlign: "top",
                            paddingLeft: "0",
                            paddingRight: "0",
                          }}
                        >
                          <select
                            value={data.g.status}
                            onChange={(event) => {
                              handleStatusChange(event, data.g);
                              // if (event.target.value === "Closed") {
                              //   handleOpen("Closed", data.g._id);
                              // }
                            }}
                            style={{
                              cursor: "pointer",
                              border: "1px solid #9A9A9A",
                              width: "60px",
                              height: "32px",
                              borderRadius: "5px",
                              backgroundColor:
                                data.g.status === "Closed"
                                  ? "#FF4242"
                                  : data.g.status === "Hold"
                                  ? "#FFCE70"
                                  : data.g.status === "Active"
                                  ? "#36B37E"
                                  : "#36B37E",
                            }}
                            disabled={localStorage.getItem('roleName') == 'Auditor'}
                          >
                            <option
                              style={{ backgroundColor: "#36B37E" }}
                              value="Active"
                            >
                              Active
                            </option>
                            <option
                              style={{ backgroundColor: "#FFCE70" }}
                              value="Hold"
                            >
                              Hold
                            </option>
                            <option
                              style={{ backgroundColor: "#FF4242" }}
                              value="Closed"
                            >
                              Closed
                            </option>
                          </select>
                        </TableCell>
                        <TableCell
                          className="maintextfont"
                          style={{
                            width: "5%",
                            color: "#3B7FEE",
                            textAlign: "center",
                            cursor: "pointer",
                            verticalAlign: "top",
                          }}
                        >
                          <img
                            style={{
                              width: "18px",
                              height: "18px",
                              marginLeft: "9px",
                            }}
                            onClick={() => handleClickOpenEdit(data.g)}
                            src={EditIcon}
                          />
                        </TableCell>

                        <div
                          style={{
                            position: "absolute",
                            left: "20px",
                            bottom: "-10px",
                          }}
                        >
                          <p style={{ fontSize: "9px" }}>
                            Created by :
                            <span
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                paddingLeft: "5px",
                              }}
                            >
                              {data.g.createdBy == null
                                ? ""
                                : reqCreatedBy(data.g.createdBy).userName}
                            </span>
                          </p>
                        </div>
                      </TableRow>
                      <button
                        style={{
                          display:
                            reqList.length > index + 1
                              ? "none"
                              : reqList.length < count
                              ? "none"
                              : "",
                        }}
                        onClick={load}
                      >
                        Show More
                      </button>
                    </div>
                  ))}
                </Table>
                <Modal open={open12}>
                  <UpdateStatus
                    off={CloseStatu}
                    updateRequisitionStatus={updateRequisitionStatus}
                    requisitionId={requisitionId}
                  />
                </Modal>
              </div>
            </>
          ) : (
            <div>
              <p>No data found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Requisition;
