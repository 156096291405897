import React, { useState, useEffect } from "react";
import IpsLogo from "./IpsLogo.svg";
import {
  Routes,
  Route,
  Switch,
  Redirect,
  Link,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserList from "../IpsBussiness/UserList/UserList";
import ClientList from "../IpsBussiness/ClientList/ClientList";
import Requisition from "../IpsBussiness/Requisition/Requisition";
import Candidate from "../IpsBussiness/Candidate/Candidate";
import CandidateStatus from "../IpsBussiness/CandidateStatus/CandidateStatus";
import BillingStatus from "../IpsBussiness/BillingStatus/BillingStatus";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../firebaseConfig";
import { gSTokenD } from "../../../redux/action";
import { useDispatch } from "react-redux";
import { FaArrowAltCircleRight } from "react-icons/fa";

import "../../../App.css";
import CloseIcon from '@mui/icons-material/Close';
import Avter from "./Reports/Reports";
import ClientCandidateStatus from "./Client_Canditate_Status/ClientCandidateStatus";
import Budgeting from "./Budgeting/Budgeting";
import VendorDetail from "./Vendor_Detail/VendorDetail";




function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          className="maincontainerhead"
          sx={{ p: 3 }}
          style={{ paddingTop: "0px" }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(
    localStorage.getItem("roleName") == "Manager"
      ? 2
      : localStorage.getItem("roleName") == "Recruiter"
        ? 3
        : localStorage.getItem("roleName") == "Business Development"
          ? 2
          : localStorage.getItem("roleName") == "DivisionalHead"
            ? 2
            : localStorage.getItem("roleName") == "Auditor"
              ? 1
              : localStorage.getItem("roleName") == "vendor"
                ? 3
                : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };
  const redirectToLink = () => {
    window.open('https://managers-5gag.onrender.com', '_blank');
  };

  // const [showReports, setShowReports] = useState(false);
  // const toggleReports = () => {
  //   if (!showReports) {
  //     // Disable scrolling when the popup is opened
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     // Re-enable scrolling when the popup is closed
  //     document.body.style.overflow = 'auto';
  //   }
  //   setShowReports(!showReports);
  // };
  // const closeReports = () => {
  //   // Re-enable scrolling when the popup is closed
  //   document.body.style.overflow = 'auto';
  //   setShowReports(false);
  // };

  let dispatch = useDispatch();

  async function requestNotPermission() {
    try {
      const permission = await Notification.requestPermission();
      console.log('Not permission:', permission);
      if (permission === 'granted') {
        //Generate Token
        const token = await getToken(messaging, {
          vapidKey: 'BF-kY3_rjRV5TfncvuJVeczxffEvIL8pAIUm3gkNTo2xcs8_-DwxQ1zb8n-9TWbauqKFZYl3ZswUxWfdBtHIC28',
        });
        const employeeId = localStorage.getItem('employeeId')
        // http://localhost:9000/v1/deviceToken/deviceToken
        let data = {
          userId: employeeId,
          token: token
        }
        dispatch(gSTokenD(data))
        console.log('token:', token);
      } else if (permission === 'denied') {
        alert('you denied for the notification')
      }
    } catch (error) {
      console.log('Error requesting notification permission:', error);
    }
  }
  useEffect(() => {
    requestNotPermission()
  }, [])

  
  const handleArrowClick = () => {
    if (value < 9) { 
      setValue(value + 1);
    }
  };

  useEffect(() => {
    const scrollDiv = document.querySelector('.scrolldivtwo');
    const arrow = document.querySelector('.scroll-arrow');

    const handleScroll = () => {
      const maxScrollLeft = scrollDiv.scrollWidth - scrollDiv.clientWidth;
      if (scrollDiv.scrollLeft >= maxScrollLeft - 10) { 
        arrow.style.display = 'none';
      } else if (scrollDiv.scrollLeft <= 10) { 
        arrow.style.display = 'block';
      }
    };

    scrollDiv.addEventListener('scroll', handleScroll);

    return () => {
      scrollDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (value === 9) { 
      document.querySelector('.scroll-arrow').style.display = 'none';
    } else {
      document.querySelector('.scroll-arrow').style.display = 'block';
    }
  }, [value]);

  return (
    <div style={{position:"relative"}}>
    <Box sx={{ width: "100%", }}>
      <div className=" w-full h-32 " style={{ background: "#1A5F9E" }}>
        <div>
          <div style={{ display: "inline-flex" }}>
            <img
              className="navLogo"
              style={{
                width: "100px",
                height: "90px",
                margin: "5px 30px 5px 58px",
              }}
              src={IpsLogo}
            />
            <p
              className="peranav"
              style={{ color: "#FFFFFF", marginTop: "auto" }}
            >
              Welcome to IPS Business Intelligence
            </p>
          </div>
          <button

            className="logoutNav"
            style={{
              cursor: "pointer",
              width: "60px",
              marginTop: "55px",
              float: "right",
              height: "30px",
              color: "#FFFFFF",
              borderRadius: "4px",
              background: "#1A5F9E",
              border: "1px solid #FFFFFF",
              marginRight: "33px",
            }}
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>

      <div
        className="mainscrollnav"
        style={{ width: "100%", overflow: "visible",  }}
      >
        <div
          className="scrolldivtwo"
          style={{
            overflowY: "visible",
            overflowX: "visible",
            width: "100%",
            padding: "0 0 5px 0",
          }}

        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="fonttab"
                  style={{
                    display:
                      localStorage.getItem("roleName") == "Recruiter" ||
                        localStorage.getItem("roleName") == "Manager" ||
                        localStorage.getItem("roleName") == "Business Development" ||
                        localStorage.getItem("roleName") == "DivisionalHead" ||
                        localStorage.getItem("roleName") == "Auditor" ||
                        localStorage.getItem("roleName") == "vendor" ||
                        localStorage.getItem("roleName") == "Legal Compliance"

                        ? "none"
                        : "",
                  }}
                  label="User List"
                  {...a11yProps(0)}
                />
                <Tab
                  className="fonttab"
                  style={{
                    display:
                      localStorage.getItem("roleName") == "Recruiter" ||
                        localStorage.getItem("roleName") == "Manager" ||
                        localStorage.getItem("roleName") == "Business Development" ||
                        localStorage.getItem("roleName") == "DivisionalHead" ||
                        localStorage.getItem("roleName") == "vendor" 
                        ? "none"
                        : "",
                  }}
                  label="Client List"
                  {...a11yProps(1)}
                />
                <Tab
                  className="fonttab"
                  style={{
                    display:
                      localStorage.getItem("roleName") == "Recruiter" ||
                        localStorage.getItem("roleName") == "vendor"
                        ? "none"
                        : "",
                  }}
                  label="Requisition"
                  {...a11yProps(2)}
                />
                <Tab
                  className="fonttab"
                  label="Candidate Submission"
                  {...a11yProps(3)}
                />
                <Tab
                  className="fonttab"
                  label="Candidate Status"
                  {...a11yProps(4)}
                />
                <Tab
                  style={{
                    display:
                      localStorage.getItem("roleName") == "vendor"
                        ? "none"
                        : "",
                  }}
                  className="fonttab"
                  label="Billing Status"
                  {...a11yProps(5)}
                />
                <Tab
                  style={{
                    display:
                      localStorage.getItem("roleName") == "vendor"
                        ? "none"
                        : "",
                  }}
                  className="fonttab"
                  label="Client Candidate Status"
                  {...a11yProps(6)}
                />
                 <Tab
                  style={{
                    display:
                      localStorage.getItem("roleName") != "Admin"
                        ? "none"
                        : "",
                  }}
                  className="fonttab"
                  label="Budgeting"
                  {...a11yProps(7)}
                />
                  <Tab
                  style={{
                    display:
                      localStorage.getItem("roleName") != "Admin"
                        ? "none"
                        : "",
                  }}
                  className="fonttab"
                  label="Vendor Details"
                  {...a11yProps(8)}
                />
                <Tab
                  className="fonttab"
                  label="Reports"
                  {...a11yProps(9)}
                />
              </Tabs>

            </Box>


          </div>
        </div>
      
     
   
      </div>
      {/* {showReports && (
        <div className="floating-popup" style={{overflow:"hidden",marginTop:"40px" }} >
         
         <div style={{width:"100%", textAlign:"end" }}>
         <CloseIcon   onClick={closeReports} style={{cursor:"pointer"}} />

         </div>
         
         
          <iframe  src="https://managers-5gag.onrender.com" width="100%" height="646px" scrolling="yes" ></iframe>
          

        </div>
      )} */}
      <TabPanel
        style={{
          display:
            localStorage.getItem("roleName") == "Recruiter" ||
              localStorage.getItem("roleName") == "Manager" ||
              localStorage.getItem("roleName") == "Legal Compliance"
              ? "none"
              : "",
        }}
        value={value}
        index={0}
      >
        <UserList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ClientList />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Requisition />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Candidate />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CandidateStatus />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <BillingStatus />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <ClientCandidateStatus />
      </TabPanel>
       <TabPanel value={value} index={7}>
        <Budgeting />
      </TabPanel> 
       <TabPanel value={value} index={8}>
        <VendorDetail />
      </TabPanel> 
      <TabPanel value={value} index={9}>
        <Avter />
      </TabPanel>
    </Box>
    <div className="scroll-arrow" onClick={handleArrowClick}>
      <FaArrowAltCircleRight size={20} style={{color:"blue"}} />
    </div>
    </div>
  );
}
