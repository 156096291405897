import React, { useEffect, useState } from 'react';
import Stepper from 'react-stepper-horizontal';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from "react-redux";
import {
    getDesignation,
    getCompany,
    getUserList, getAllClientByCompany, getBranch
  } from "../../../../redux/action";

import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import Step3Form from './Step3Form';
import Step4Form from './Step4Form';
import axios from 'axios';
const { URL } = require("../../../../config.json");

const VendorDetail = () => {

    let dispatch = useDispatch();

    useEffect(() => {
      dispatch(getAllClientByCompany(companyList && companyList[0].company))
    //   getClientByCompany({ company: companyList && companyList[0].company, selectedBranches: selectedBranches, financialYear: selectedYear });
     
      return () => {
        clientByCompany = []
      }
    }, []);

    let [clientByCompany, setClientListByCompany] = useState([])
  const [step, setStep] = useState(0);
  const [vendorName, setVendorName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [contactPersonName, setContactPersonName] = useState('');
  const [state, SetState] = useState('');
  const [registeredAddress, SetRegisteredAddress] = useState('');
  const [headOffice, SetHeadOffice] = useState('');
  const [agreementDuration, SetAgreementDuration] = useState('');
  const [panNo ,setPanNo] = useState('')
  const [panNoError, setPanNoError] = useState('');
  const [gatNo ,setGatNo] = useState('')
  const [tanNo ,setTanNo] = useState('')
  const [contactNumber, setContactNumber] = useState('');
  const [city, setCity] = useState('');
  const [dateOfAgreement, setDateOfAgreement] = useState('');
  const [company, setCompany] = useState();

console.log("company",company)
  let companyList = useSelector((state) => state.clientReducer.company);
  console.log("companyList",companyList)



  useEffect(() => {
    setCompany(companyList[0].company)
  }, [companyList])
  // const [checkboxValues, setCheckboxValues] = useState({
  //   proprietor: false,
  //   individual1: false,
  //   individual2: false,
  //   individual3: false
  // });
  console.log("checkboxValues",vendorName,email,address,contactPersonName,contactNumber,city,dateOfAgreement)

  const steps = [
    { title: 'Vendor Details' },
    { title: 'Vendor Infrastructure Details' },
    { title: 'Vendor Agreement Details' },
    { title: 'Terms' }
  ];

  const handleNext = () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };



// step 1

const [dropdownSelected,setDropdownSelected] = useState('')
const [contacts, setContacts] = useState([{ contactPersonName: '', contactNumber: '' }]);

   console.log("dropdownSelected",dropdownSelected)
   console.log("agreementDuration",agreementDuration,dateOfAgreement,contacts)


const handleChangeCompany = (e) => {
    setCompany(e.target.value)
    dispatch(getAllClientByCompany(e.target.value))
  }

  const handledropdownSelectChange = (selectedOptions) => {
    setDropdownSelected(selectedOptions.value);
  };


  // const handleCheckboxChange = (event) => {
  //   const { name, checked } = event.target;
  //   setCheckboxValues({
  //     ...checkboxValues,
  //     [name]: checked
  //   });
  // };

  const addContactField = () => {
    setContacts([...contacts, { contactPersonName: '', contactNumber: '' }]);
  };

  const removeContactField = (index) => {
    const newContacts = contacts.filter((_, i) => i !== index);
    setContacts(newContacts);
  };


const handleContact = (index,event)=>{
  const { name, value } = event.target;
 const newContacts = [...contacts];
    newContacts[index][name] = value;
    setContacts(newContacts);
}


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "panNo") {
      const upperCaseValue = value.toUpperCase();
      const isValidPan = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(upperCaseValue);
      if (!isValidPan) {
        setPanNoError('Invalid PAN number format');
      } else {
        setPanNoError('');
      }
      setPanNo(upperCaseValue);
    }

   

    switch (name) {
      case 'vendorName':
        setVendorName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'address':
        setAddress(value);
        break;
      case 'contactPersonName':
        setContactPersonName(value);
        break;
      case 'contactNumber':
        setContactNumber(value);
        break;
        case 'state':
            SetState(value);
            break;
      case 'city':
        setCity(value);
        break;
      case 'registeredAddress':
        SetRegisteredAddress(value);
        break;
      case 'headOffice':
        SetHeadOffice(value);
        break;
        case 'panNo':
          setPanNo(value);
          break;
      case 'gatNo':
          setGatNo(value);
          break;
      case 'tanNo':
          setTanNo(value);
          break;
      case 'dateOfAgreement':
        setDateOfAgreement(value);
        break;
      case 'agreementDuration':
        SetAgreementDuration(value);
        break;
      default:
        break;
    }
  };


//   step 2

const [showTechnicalSkillsDropdown, setShowTechnicalSkillsDropdown] = useState(false);
const [showNonTechnicalSkillsDropdown, setShowNonTechnicalSkillsDropdown] = useState(false);
const [showUnskilledLaborDropdown, setShowUnskilledLaborDropdown] = useState(false);
const [showInternsDropdown, setShowInternsDropdown] = useState(false);
// const [technicalSkillsSelected, setTechnicalSkillsSelected] = useState([]);

const handleTechnicalSkillsCheckboxChange = (event) => {
  const isChecked = event.target.checked;
  setShowTechnicalSkillsDropdown(isChecked);
  // handleCheckboxChange(event);
};

const handleNonTechnicalSkillsCheckboxChange = (event) => {
  const isChecked = event.target.checked;
  setShowNonTechnicalSkillsDropdown(isChecked);
  // handleCheckboxChange(event);
};

const handleUnskilledLaborCheckboxChange = (event) => {
  const isChecked = event.target.checked;
  setShowUnskilledLaborDropdown(isChecked);
  // handleCheckboxChange(event);
};

const handleInternsCheckboxChange = (event) => {
  const isChecked = event.target.checked;
  setShowInternsDropdown(isChecked);
  // handleCheckboxChange(event);
};






const [responsibilitiesofIPS ,setResponsibilitiesofIPS] = useState('')
const [vendorDetails ,setVendorDetails] = useState('')
const [clauseRequirement ,setClauseRequirement] = useState('')
const [accountManagerfromIPSPL ,setAccountManagerfromIPSPL] = useState('')
const [accountManagerfromVendor ,setAccountManagerfromVendor] = useState('')

const [billingDetails ,setBillingDetails] = useState('')


const [technicalSkills, setTechnicalSkills] = useState([]);
const [nonTechnicalSkills, setNonTechnicalSkills] = useState([]);
const [unskilledLabor, setUnskilledLabor] = useState([]);
const [interns, setInterns] = useState([]);


const handleSelectChange = (setter) => (selectedOptions) => {
  setter(selectedOptions ? selectedOptions.map(option => option.value) : []);
};

const technicalSkillsOptions = [
  { value: 'JavaScript', label: 'JavaScript' },
  { value: 'HTML', label: 'HTML' },
  { value: 'CSS', label: 'CSS' },
];

const nonTechnicalSkillsOptions = [
  { value: 'Communication', label: 'Communication' },
  { value: 'Problem Solving', label: 'Problem Solving' },
  { value: 'Teamwork', label: 'Teamwork' },
];

const unskilledLaborOptions = [
  { value: 'Cleaning', label: 'Cleaning' },
  { value: 'Janitorial Services', label: 'Janitorial Services' },
];

const internsOptions = [
  { value: 'Internship Programs', label: 'Internship Programs' },
  { value: 'Apprenticeship Programs', label: 'Apprenticeship Programs' },
];


  const handleInputChangePage2 = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'responsibilitiesofIPS':
        setResponsibilitiesofIPS(value);
        break;
      case 'vendorDetails':
        setVendorDetails(value);
        break;
      case 'clauseRequirement':
        setClauseRequirement(value);
        break;
      case 'accountManagerfromIPSPL':
        setAccountManagerfromIPSPL(value);
        break;
      case 'accountManagerfromVendor':
        setAccountManagerfromVendor(value);
        break;
      case 'billingDetails':
        setBillingDetails(value);
        break;
      // case 'technicalSkills':
      //   setTechnicalSkills(value);
      //   break;
      // case 'nonTechnicalSkills':
      //   setNonTechnicalSkills(value);
      //   break;
      // case 'unskilledLabor':
      //   setUnskilledLabor(value);
      //   break;
      // case 'interns':
      //   setInterns(value);
      //   break;
      default:
        break;
    }
  };



//   step 3

const [venderEmployeeSize ,setVenderEmployeeSize] = useState('')
const [noOfHardwarePcLaptops ,setNoOfHardwarePcLaptops] = useState('')
const [vendorHaveLaptop ,setVendorHaveLaptopSelected] = useState('')
console.log("vendorHaveLaptop",vendorHaveLaptop)

const handleInputChangePage3 = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'venderEmployeeSize':
        setVenderEmployeeSize(value);
        break;
      case 'noOfHardwarePcLaptops':
        setNoOfHardwarePcLaptops(value);
        break;
      default:
        break;
    }
  };

  const handleVendorHaveLaptopSelectChange = (selectedOptions) => {
    setVendorHaveLaptopSelected(selectedOptions.value);
  };



//   step 4


const [showSkilledDropdown,setShowSkilledDropdown] =useState('')
const [showUnSkilledDropdown,setShowUnSkilledDropdown] =useState('')


  const handleSkilledCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setShowSkilledDropdown(isChecked);
    // handleCheckboxChange(event);
  };
  
  const handleUnSkilledCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setShowUnSkilledDropdown(isChecked);
    // handleCheckboxChange(event);
  };


// skill
  const [skilledDropdown, setSkilledDropdown] = useState([]);

  useEffect(() => {
    const fetchDropdownData = async (type) => {
      try {
        const response = await axios.get(`${URL}/VendorDataBi/getBiVenderDropdown/skilled`);
        setSkilledDropdown(response.data);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    }; 
      fetchDropdownData('skilled');
  }, []);
 

  const [dynamicFieldsskill, setDynamicFieldsskill] = useState([
    { skillName: '', details: [{ Subcategories: '', Amount: '', YearsOfExperience: '' }] }
  ]);
// console.log("dynamicFieldsskill",dynamicFieldsskill)

const yearsOptions = Array.from({ length: 31 }, (_, i) => ({ value: i.toString(), label: i.toString() }));

  const handleYearOfExperienceChange = (skillIndex, objectIndex, selectedOption) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields[skillIndex].details[objectIndex].YearsOfExperience = selectedOption.value;
    setDynamicFieldsskill(updatedFields);
  };
  const handleSubcategorySelectChange = (skillIndex, objectIndex, selectedOption) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields[skillIndex].details[objectIndex].Subcategories = selectedOption.value;
    setDynamicFieldsskill(updatedFields);
  };

  const handleSkilledSelectChange = (skillIndex, selectedOption) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields[skillIndex].skillName = selectedOption.value;
    updatedFields[skillIndex].details = [{ Subcategories: '', Amount: '', YearsOfExperience: '' }];
    setDynamicFieldsskill(updatedFields);
  };

  const handleAmountChange = (skillIndex, objectIndex, event) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields[skillIndex].details[objectIndex].Amount = event.target.value;
    setDynamicFieldsskill(updatedFields);
  };


  const removeSubcategoryDetail = (skillIndex, objectIndex) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields[skillIndex].details.splice(objectIndex, 1);
    setDynamicFieldsskill(updatedFields);
  };

  const addSkill = () => {
    setDynamicFieldsskill([...dynamicFieldsskill, { skillName: '', details: [{ Subcategories: '', Amount: '', YearsOfExperience: '' }] }]);
  };

  const removeSkill = (skillIndex) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields.splice(skillIndex, 1);
    setDynamicFieldsskill(updatedFields);
  };
  
  const addSubcategoryDetail = (skillIndex) => {
    const updatedFields = [...dynamicFieldsskill];
    updatedFields[skillIndex].details.push({ Subcategories: '', Amount: '', YearsOfExperience: '' });
    setDynamicFieldsskill(updatedFields);
  };






 




  // unskilled


  const [unskilledDropdown, setUnskilledDropdown] = useState([]);

  useEffect(() => {
    const fetchDropdownData = async (type) => {
      try {
        const response = await axios.get(`${URL}/VendorDataBi/getBiVenderDropdown/unskilled`);
        setUnskilledDropdown(response.data);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };   
      fetchDropdownData('unskilled');
  }, []);



  const [dynamicFieldsunskill, setDynamicFieldsunskill] = useState([
    { UnskillName: '', details: [{ Subcategories: '', Amount: '', YearsOfExperience: '' }] }
  ]);
// console.log("dynamicFieldsskill",dynamicFieldsunskill)


const yearsOptionsunskill = Array.from({ length: 31 }, (_, i) => ({ value: i.toString(), label: i.toString() }));

  const handleYearOfExperienceChangeunskill = (skillIndex, objectIndex, selectedOption) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields[skillIndex].details[objectIndex].YearsOfExperience = selectedOption.value;
    setDynamicFieldsunskill(updatedFields);
  };
  const handleSubcategorySelectChangeunskill = (skillIndex, objectIndex, selectedOption) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields[skillIndex].details[objectIndex].Subcategories = selectedOption.value;
    setDynamicFieldsunskill(updatedFields);
  };

  const handleSkilledSelectChangeunskill = (skillIndex, selectedOption) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields[skillIndex].UnskillName = selectedOption.value;
    updatedFields[skillIndex].details = [{ Subcategories: '', Amount: '', YearsOfExperience: '' }];
    setDynamicFieldsunskill(updatedFields);
  };

  const handleAmountChangeunskill = (skillIndex, objectIndex, event) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields[skillIndex].details[objectIndex].Amount = event.target.value;
    setDynamicFieldsunskill(updatedFields);
  };


  const removeSubcategoryDetailunskill = (skillIndex, objectIndex) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields[skillIndex].details.splice(objectIndex, 1);
    setDynamicFieldsunskill(updatedFields);
  };

  const addunskill = () => {
    setDynamicFieldsunskill([...dynamicFieldsunskill, { UnskillName: '', details: [{ Subcategories: '', Amount: '', YearsOfExperience: '' }] }]);
  };

  const removeunskill = (skillIndex) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields.splice(skillIndex, 1);
    setDynamicFieldsunskill(updatedFields);
  };
  
  const addSubcategoryDetailunskill = (skillIndex) => {
    const updatedFields = [...dynamicFieldsunskill];
    updatedFields[skillIndex].details.push({ Subcategories: '', Amount: '', YearsOfExperience: '' });
    setDynamicFieldsunskill(updatedFields);
  };



  let empid = localStorage.getItem('employeeId')
 
  const handleSubmit = async () => {
    const formData = {

      // step 1 details

      company,
      empid,
      tanNo,
      gatNo,
      panNo,
      headOffice,
      registeredAddress,
      city,
      state,
      address,
      email,
      vendorName,
      dropdownSelected,
      contacts,

      // step 2 details

      interns,
      unskilledLabor,
      nonTechnicalSkills,
      technicalSkills,
      venderEmployeeSize,
      noOfHardwarePcLaptops,
      vendorHaveLaptop,
      showTechnicalSkillsDropdown,
      showNonTechnicalSkillsDropdown,
      showUnskilledLaborDropdown,
      showInternsDropdown,

      // step 3 details

      agreementDuration,
      dateOfAgreement,
      billingDetails,
      accountManagerfromVendor,
      accountManagerfromIPSPL,
      clauseRequirement,
      vendorDetails,
      responsibilitiesofIPS,

      // step 4 details

      showUnSkilledDropdown,
      showSkilledDropdown,  
      dynamicFieldsskill: showSkilledDropdown ? dynamicFieldsskill : [],
      dynamicFieldsunskill: showUnSkilledDropdown ? dynamicFieldsunskill : [],
    };
    console.log("formData",formData)
    try {
      const response = await axios.post(`${URL}/VendorDataBi/saveBiVendorData`, formData);
      console.log('Data saved successfully', response.data);
      alert("data save")
    } catch (error) {

      console.error('Error saving data', error);

    }
  };



  return (
    <div style={{width:"100%"}}>
        <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <div style={{width:"900px"}}>
      <Stepper
        steps={steps}
        activeStep={step}
        activeColor="#007bff"
        completeColor="#28a745"
        completeBarColor="#28a745"
        completeTitleColor="#ffffff"
        defaultBarColor="#e9ecef"
        defaultTitleColor="#6c757d"
      /></div>
      </div>
      <div style={{ width:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column" }}>
        <div style={{width:"750px",border:"1px solid black",padding:"20px", marginTop: '20px',borderRadius:"15px",boxShadow:"6px 6px 10px #9E9E9E"}}>
            

      <div style={{ }}>
        {step === 0 && (
             <Step1Form
             handleChangeCompany={handleChangeCompany}
             companyList={companyList}
             company={company}
             panNo={panNo}
             gatNo={gatNo}
             tanNo={tanNo}
             handleContact={handleContact}
             contacts={contacts}
             addContactField={addContactField}
             removeContactField={removeContactField}
             handledropdownSelectChange={handledropdownSelectChange}
             dropdownSelected={dropdownSelected}
            //  handleCheckboxChange={handleCheckboxChange}
             handleInputChange={handleInputChange}
            //  checkboxValues={checkboxValues}
             agreementDuration={agreementDuration}
             headOffice={headOffice}
             registeredAddress={registeredAddress}
             dateOfAgreement={dateOfAgreement}
             city={city}
            //  contactPersonName={contactPersonName}
             address={address}
             state={state}
             email={email}
             vendorName={vendorName}
            //  contactNumber={contactNumber}
             panNoError={panNoError}
             />
          )}
      
         {step === 1 && (
         <Step2Form 
       venderEmployeeSize={venderEmployeeSize}
       noOfHardwarePcLaptops={noOfHardwarePcLaptops}
       vendorHaveLaptop={vendorHaveLaptop}
       handleVendorHaveLaptopSelectChange={handleVendorHaveLaptopSelectChange}
       handleInputChangePage3={handleInputChangePage3}
       internsOptions={internsOptions}
       unskilledLaborOptions={unskilledLaborOptions}
       nonTechnicalSkillsOptions={nonTechnicalSkillsOptions}
       technicalSkillsOptions={technicalSkillsOptions}
       handleSelectChange={handleSelectChange}
       setInterns={setInterns}
       setUnskilledLabor={setUnskilledLabor}
       setNonTechnicalSkills={setNonTechnicalSkills}
       setTechnicalSkills={setTechnicalSkills}
     
       technicalSkills={technicalSkills}
       nonTechnicalSkills={nonTechnicalSkills}
       unskilledLabor={unskilledLabor}
       interns={interns}
       handleTechnicalSkillsCheckboxChange={handleTechnicalSkillsCheckboxChange}
       handleNonTechnicalSkillsCheckboxChange={handleNonTechnicalSkillsCheckboxChange}
       handleUnskilledLaborCheckboxChange={handleUnskilledLaborCheckboxChange}
       handleInternsCheckboxChange={handleInternsCheckboxChange}
       showTechnicalSkillsDropdown={showTechnicalSkillsDropdown}
       showNonTechnicalSkillsDropdown={showNonTechnicalSkillsDropdown}
       showUnskilledLaborDropdown={showUnskilledLaborDropdown}
       showInternsDropdown={showInternsDropdown}

       handleInputChangePage2
       
         />)}
       {step === 2 && (
       <Step3Form
       billingDetails={billingDetails}
       agreementDuration={agreementDuration}
       handleInputChange={handleInputChange}
       dateOfAgreement={dateOfAgreement}
      //  panGatTanNo={panGatTanNo}
       accountManagerfromVendor={accountManagerfromVendor}
       accountManagerfromIPSPL={accountManagerfromIPSPL}
       clauseRequirement={clauseRequirement}
       vendorDetails={vendorDetails}
       responsibilitiesofIPS={responsibilitiesofIPS}
       handleTechnicalSkillsCheckboxChange={handleTechnicalSkillsCheckboxChange}
       handleInputChangePage2={handleInputChangePage2}
       handleNonTechnicalSkillsCheckboxChange={handleNonTechnicalSkillsCheckboxChange}
       handleUnskilledLaborCheckboxChange={handleUnskilledLaborCheckboxChange}
       handleInternsCheckboxChange={handleInternsCheckboxChange}
       showTechnicalSkillsDropdown={showTechnicalSkillsDropdown}
       showNonTechnicalSkillsDropdown={showNonTechnicalSkillsDropdown}
       showUnskilledLaborDropdown={showUnskilledLaborDropdown}
       showInternsDropdown={showInternsDropdown}
       
       />)}
          {step === 3 && (
             <Step4Form 
             handleSkilledCheckboxChange={handleSkilledCheckboxChange}
             handleUnSkilledCheckboxChange={handleUnSkilledCheckboxChange}
             handleSkilledSelectChange={handleSkilledSelectChange}
            //  subcategories={subcategories}
            //  subcategoriesunskilled={subcategoriesunskilled}
            //  handleUnSkilledSelectChange={handleUnSkilledSelectChange}
             showSkilledDropdown={showSkilledDropdown}
             showUnSkilledDropdown={showUnSkilledDropdown}
            //  skilledSelected={skilledSelected}
            //  unSkilledSelected={unSkilledSelected}
             skilledDropdown={skilledDropdown}
             unskilledDropdown={unskilledDropdown}
             dynamicFieldsskill={dynamicFieldsskill}
             yearsOptions={yearsOptions}
             handleYearOfExperienceChange={handleYearOfExperienceChange}
             handleSubcategorySelectChange={handleSubcategorySelectChange}
             handleAmountChange={handleAmountChange}
             removeSubcategoryDetail={removeSubcategoryDetail}
             addSkill={addSkill}
             removeSkill={removeSkill}
             addSubcategoryDetail={addSubcategoryDetail}
             yearsOptionsunskill={yearsOptionsunskill}
             dynamicFieldsunskill={dynamicFieldsunskill}
             handleYearOfExperienceChangeunskill={handleYearOfExperienceChangeunskill}
             handleSubcategorySelectChangeunskill={handleSubcategorySelectChangeunskill}
             handleSkilledSelectChangeunskill={handleSkilledSelectChangeunskill}
             handleAmountChangeunskill={handleAmountChangeunskill}
             removeSubcategoryDetailunskill={removeSubcategoryDetailunskill}
             addunskill={addunskill}
             removeunskill={removeunskill}
             addSubcategoryDetailunskill={addSubcategoryDetailunskill}
             />
        )}
      </div>

      <div style={{ marginTop: '40px' ,width:"100%",display:"flex",justifyContent:"center",alignItems:"center" }}>
        <div style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"250px",}}>
        {step > 0 && <Button style={{fontSize:"12px",height:"30px",width:"130px"}}  variant="contained" onClick={handlePrevious}>Previous</Button>}
        {step < steps.length - 1 && <Button style={{fontSize:"12px",height:"30px",width:"100px"}} variant="contained" onClick={handleNext}>Next</Button>}
        {step === steps.length - 1 && <Button style={{fontSize:"12px",height:"30px",width:"100px"}} variant="contained" color="success" onClick={handleSubmit}>Submit</Button>}
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};


export default VendorDetail;
