import React from 'react'

import { isExpired, decodeToken } from "react-jwt"
import Login from "./components/Login"
import UserClient from './components/pages/IpsBussiness/UserAndClientIndex'
import { useNavigate } from 'react-router-dom'

export default function Loadingpage() {
const nvaigate=useNavigate()
    const token = localStorage.getItem('jwttoken')

    return (
        <div>
            {
                token ? <UserClient /> : <Login />
            }
        </div>
    )
}
