import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import { Button, TextField } from "@mui/material";
import "./Client_Canditate.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const { URL } = require("../../../../config.json");

const ClientCandidateStatus = () => {
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = useState("true");
  const [candidateData, setCandidateData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateFieldValue, setDateFieldValue] = useState("");
  const [textFieldValue, setTextFieldValue] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}clientlist/clientlist`);
        console.log("jhgcvygv", clientList, response.data);
        setClientList(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const clientCanditate = async () => {
      try {
        const response = await axios.get(
          `${URL}bi_ClientCandidateStatus/bi_ClientCandidateStatus/${selectedClient}/${active}`
        );
        setCandidateData(response.data);
      } catch (error) {
        setError(error.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };
    clientCanditate();
  }, [selectedClient, active]);

  const handleDropdownChange = (event) => {
    setSelectedClient(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleActiveChange = (event) => {
    const selectedValue = event.target.value;

    setActive(selectedValue === "" ? null : selectedValue === "true");
  };



  const handleToggleStatus = (_id, currentStatus) => {
    setCandidateData((prevData) =>
      prevData.map((candidate) =>
        candidate._id === _id
          ? { ...candidate, status: !currentStatus }
          : candidate
      )
    );
  };
  const handleCancel = (_id) => {
    setDateFieldValue(""); 
    setTextFieldValue(""); 
    
    setCandidateData((prevData) =>
      prevData.map((candidate) =>
        candidate._id === _id ? { ...candidate, status: false } : candidate
      )
    );
  };
  const handleSave = async (_id) => {
    try {
      if (!dateFieldValue || !textFieldValue) {
        alert("Date and Resion are mandatory.");
        return;
      }

      await axios.put(
        `${URL}bi_ClientCandidateStatus/bi_updateClientCandidateStatus`,
        {
          _id,
          status: false,
          leavingDate: dateFieldValue,
          leavingReason: textFieldValue,
        }
      );
      setDateFieldValue("");
      setTextFieldValue("");
      setCandidateData((prevData) =>
        prevData.map((candidate) =>
          candidate._id === _id ? { ...candidate, status: false } : candidate
        )
      );
      alert("Data Save Successfully");
      const response = await axios.get(
        `${URL}bi_ClientCandidateStatus/bi_ClientCandidateStatus/${selectedClient}/${active}`
      );
      setCandidateData(response.data);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const filteredCandidates = candidateData.filter(
    (candidate) =>
      candidate.candidateName
        .toLowerCase()
        .includes(searchTerm && searchTerm.toLowerCase()) ||
        candidate && candidate.positionName && candidate.positionName.toLowerCase().includes(searchTerm && searchTerm.toLowerCase()) ||
        candidate && candidate.empCode && candidate.empCode.toLowerCase().includes(searchTerm && searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (clientList.length > 0) {
      setSelectedClient(clientList[0]._id);
    }
  }, [clientList]); 




  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredCandidates);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "Client_Candidate.xlsx");
  };




  return (
    <>
      <div>
        <div
          className="Client_Canditate"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            className="Client_Canditate_child"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <div className="paddremov" style={{ paddingRight: "30px" }}>
             
              <label style={{ marginRight: "20px" }} htmlFor="clientDropdown">
                Select Client :
              </label>
              <select
                style={{ width: "245px", borderRadius: "3px", height: " 35px" }}
                id="clientDropdown"
                value={selectedClient}
                onChange={handleDropdownChange}
              >
                {clientList.map((client) => (
                  <option key={client._id} value={client._id}>
                    {client.clientName}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label style={{ marginRight: "20px" }} htmlFor="activeDropdown">
                Select Status :
              </label>
              <select
                style={{ width: "100px", borderRadius: "3px", height: " 35px" }}
                id="activeDropdown"
                value={active === null ? "" : active.toString()}
                onChange={handleActiveChange}
              >
                <option value="">Select status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>

            <div style={{ marginLeft: "20px", fontSize: "14px" }}>
              <p className="position">
                Total Candidates :<span style={{marginLeft:"3px"}}>{filteredCandidates.length}</span>
              </p>
            </div>
          </div>
          <div>
          <Button
        variant="contained"
        color="primary"
        onClick={handleDownloadExcel}
        style={{ height:"30px",fontSize:"13px",marginTop:"13px",marginLeft:"200px" }}
      >
        Download Exce
      </Button>
      </div>

          <div
            className="Client_Canditate_search"
            style={{
              display: "flex",
              justifyContent: "end",
              textAlign: "end",
              alignItems: "center",
            }}
          >
            <input
              className="serch_input"
              style={{ width: "245px", borderRadius: "5px", height: "30px",paddingLeft:"10px" }}
              type="text"
              id="searchInput"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search Here"
            />
          </div>
        </div>

        <div
          className="scrollmain"
        
        >
          <div className="scrollreqursion" style={{}}>
            <div   style={{
            backgroundColor: "#F5FBFE",
            position: "sticky",
            top: "0",
            zIndex: "1",
          }}>
              <Table>
                <TableHead>
                  <TableRow className="backgroundchange">
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Client Name
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Candidate Name
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Employee Code
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Position Name
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Contact No.
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Date of Submission
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Accepted Ctc
                    </TableCell>
                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Joining Date
                    </TableCell>
                    {active == false && (
                      <TableCell
                        className="textfont"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          fontSize: "13.5px",
                        }}
                      >
                        Leaving Date
                      </TableCell>
                    )}
                    {active == false && (
                      <TableCell
                        className="textfont"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          fontSize: "13.5px",
                        }}
                      >
                        Leaving Reason
                      </TableCell>
                    )}

                    <TableCell
                      className="textfont"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
                width: "100%",
              }}
            >
              {console.log("filteredCandidates",filteredCandidates)}
              {filteredCandidates.map((candidate) => (
                <TableRow key={candidate._id}>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "11%" : "12%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.clientName}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "12%" : "14%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.candidateName}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "11%" : "14%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.empCode}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "8%" : "11%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.positionName}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "10%" : "10%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.contactNo}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "15%" : "16%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.dateOfSubmission &&
                      candidate.dateOfSubmission.slice(0, 10)}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "9%" : "12%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.acceptedCtc}
                  </TableCell>
                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: active == false ? "10%" : "12%",
                      fontSize: "12px",
                    }}
                  >
                    {candidate.joiningDate}
                  </TableCell>
                  {active == false && (
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: active == false ? "15%" : "15%",
                        fontSize: "12px",
                      }}
                    >
                      {candidate.leavingDate}
                    </TableCell>
                  )}
                  {active == false && (
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: active == false ? "15%" : "15%",
                        fontSize: "12px",
                      }}
                    >
                      {candidate.leavingReason}
                    </TableCell>
                  )}

                  <TableCell
                    className="fontpera"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      fontSize: "12px",
                      width: "12%",
                      position: "relative",
                    }}
                  >
                    <Switch
                      checked={
                        candidate.status == "true" || candidate.status == true
                          ? true
                          : false
                      }
                      onChange={() =>
                        handleToggleStatus(candidate._id, candidate.status)
                      }
                      color="primary"
                      name="statusToggle"
                      disabled={
                        candidate.status == "false" ||
                        localStorage.getItem("roleName") == "Auditor"
                      }
                    />

                    {candidate.status === false && (
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          background: "rgba(255, 255, 255, 0.9)",
                          zIndex: "1000",
                          border:"1px solid gray"
                          
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection:"column",
                            alignItems: "center",
                            justifyContent:"center",
                            // width: "500px",
                            padding:"50px 100px"

                          
                          }}
                        >
                          <div className="popstw" style={{ marginRight: "5px" ,marginBottom:"10px",paddingRight:"40px"}}>
                            <label style={{marginRight:"10px"}}>Select Date :</label>
                            <input
                              style={{ width: "150px", height: "30px" }}
                              type="date"
                              value={dateFieldValue}
                              onChange={(e) =>
                                setDateFieldValue(e.target.value)
                              }
                            />
                          </div>
                          <div className="pops" style={{ marginRight: "5px" ,display:"flex",justifyContent:"center",alignContent:"start"}}>
                          <label style={{marginRight:"10px"}}>Enter Reason :</label>

                            <textarea
                              placeholder="Reason"
                              type="text"
                              style={{ width: "200px", height: "60px" }}
                              value={textFieldValue}
                              onChange={(e) =>
                                setTextFieldValue(e.target.value)
                              }
                            />
                          </div>
                          
                        </div>
                        <div style={{display:"flex",justifyContent:"space-around",marginBottom:"25px"}}>
                        <Button
                               style={{ height: "30px", width: "50px" }}
                               variant="contained"
                               color="secondary"
                               onClick={() => { 
                                 handleCancel();
                                 handleToggleStatus(candidate._id, false);
                               }}
                              >
                               Cancel
                            </Button>
                            <Button
                              style={{ height: "30px", width: "50px" }}
                              variant="contained"
                              color="primary"
                              onClick={() => handleSave(candidate._id)}
                            >
                              Save
                            </Button>

                           
                          </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientCandidateStatus;
