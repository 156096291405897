import React,{StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './redux/store';
import {BrowserRouter} from 'react-router-dom'

import { RouterProvider } from "react-router-dom"
import { router } from "./App";
const root = ReactDOM.createRoot(document.getElementById('root'));
console.warn(store)
root.render(
  <Provider store={store} >
  <StrictMode>
      <RouterProvider router={router} />
  </StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
