import React, { useState, useEffect } from "react"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import UnfoldMoreSharpIcon from "@mui/icons-material/UnfoldMoreSharp"
import Table from "@mui/material/Table"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import EditMaster from "../UserList/EditMaster"
import EditIcon from "../EditIcon.svg"
import PopupAddUser from "./PopupAddUser"
import { useSelector, useDispatch } from "react-redux"
import Update from "./updateUser"
import {
  getUserList,
  addUser,
  getEmployeeList,
} from "../../../../redux/action"
import Select from "react-select"

import SearchIcon from "@mui/icons-material/Search"

import { useForm } from "react-hook-form"
import "./userlist.css"

function UserList() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  let emp_id = localStorage.getItem("employeeId")
  let dispatch = useDispatch()
  const showEditMaster = () => {
    document.getElementById("EditComponent").style.display = "block"
    document.getElementById("UserListComponent").style.display = "none"
  }

  const hideEditMaster = () => {
    document.getElementById("EditComponent").style.display = "none"
    document.getElementById("UserListComponent").style.display = "block"
  }

  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)

  useEffect(() => {
    dispatch(getUserList())
    dispatch(getEmployeeList())
  }, [])
  const UserList = useSelector((state) => state.clientReducer.userList)
  let roleData = useSelector((state) => state.clientReducer.role)
  let branchData = useSelector((state) => state.clientReducer.branch)
  let companyData = useSelector((state) => state.clientReducer.company)
  let divisionData = useSelector((state) => state.clientReducer.division)
  const [password, setPassword] = useState()
  const [email, setEmail] = useState()
  const [userName, setUserName] = useState()
  const [dateOfJoining, setDateOfJoining] = useState()
  const [leavingDate, setLeavingDate] = useState()
  const [cTC, setCTC] = useState()
  const [reportingManager, setReportingManager] = useState("")
  const [role, setRole] = useState("")
  const [branch, setBranch] = useState("")
  const [company, setCompany] = useState("")
  const [userInformation, setUserInformation] = useState("")
  const [division, setDivision] = useState([])
  const [selected, setSelected] = useState([])

  const [status, setStatus] = useState('true')

  const handleClickOpen = () => {
    if (reportingManager == "") {
      window.alert("Please Select Reporting Manager")
    } else if (role == "") {
      window.alert("Please Select Role")
    } else if (branch == "") {
      window.alert("Please Select Branch")
    } else if (company == "") {
      window.alert("Please Select Company")
    }
    if (reportingManager != "" && role != "" && branch != "" && company != "") {
      let data = {
        roleId: JSON.parse(role && role)._id,
        roleName: JSON.parse(role && role).roleName,
        reportingManagerName: JSON.parse(reportingManager && reportingManager)
          .userName,
        password: password,
        reportingManagerId: JSON.parse(reportingManager && reportingManager)
          ._id,
        email: email,
        dateOfJoining: dateOfJoining,
        leavingDate: leavingDate,
        cTC: cTC,
        userName: userName,
        branchName: branch,
        company: company,
        division: division,
        createdBy: emp_id,
      }

      setPassword("")
      setEmail("")
      setUserName("")
      setDateOfJoining("")
      setLeavingDate("")
      setCTC("")
      setReportingManager("")
      setRole("")
      setBranch("")
      setCompany("")
      dispatch(addUser(data))
    }

    setTimeout(() => {
      dispatch(getUserList())
    }, 100)
  }

  console.log("UserList", UserList)

  const handleClick = (event, d, name) => {
    if (event.target.checked == true) {
      division.push({ divisionName: d.divisionName, divisionId: d._id })
    } else if (event.target.checked == false) {
      let data = division.filter((div, i) => div.divisionId != d._id)
      setDivision(data)
    }

    const selectedIndex = selected.indexOf(d.id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, d.id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }
  console.log(division)
  const editUser = (n) => {
    setUserInformation(n)
    setOpen2(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClose2 = () => {
    setOpen2(false)
  }

  const emp = UserList

  const [empName, setempName] = useState("")

  const [employeelist,setEmployee]=useState(UserList)

  const [foundemployee, setFoundEmployee] = useState(employeelist)

  let expanded = false

  useEffect(() => {
    setEmployee(UserList.filter((n) => n.status == status))
    setFoundEmployee(UserList.filter((n) => n.status == status))
  }, [status,UserList])

  function changeDiv() {
    var checkboxes = document.getElementById("division")
    if (!expanded) {
      checkboxes.style.display = "grid"
      expanded = true
    } else {
      checkboxes.style.display = "none"
      expanded = false
    }
  }

  const empfilter = (e) => {
    const keyword = e.target.value

    if (keyword !== "") {
      const results = employeelist.filter((user) => {
        return user.userName == ""
          ? 0
          : user.userName.toLowerCase().startsWith(keyword.toLowerCase())
      })
      setFoundEmployee(results)
    } else {
      setFoundEmployee(employeelist)
    }

    setempName(keyword)
  }

  const rptManager = UserList.map((f) => ({
    dat: JSON.stringify(f),
    value: f.userName,
    label: f.userName,
  }))

  let admincount = UserList.filter((z) => z.roleName == "Admin" && z.status == status).length
  let managercount = UserList.filter((z) => z.roleName == "Manager" && z.status == status).length
  let recruitercount = UserList.filter((z) => z.roleName == "Recruiter" && z.status == status).length

  const selectUserStatus = (d) => {
    setStatus(d.target.value)
  }

  return (
    <div style={{ width: "100%", height: "100%", fontFamily: "sans-serif" }}>
      <Dialog open={open2}>
        <DialogContent className="paddupdate">
          <Update user={userInformation} updateUserClose={handleClose2} />
        </DialogContent>
      </Dialog>
      {/* <Dialog maxWidth={false} open={open}>
        <DialogContent>
          <PopupAddUser />
        </DialogContent>
      </Dialog> */}
      <div id="UserListComponent" style={{position:"relative"}}>
        <div
          className="editmastermobile"
          style={{
            color: "#11416F",
            position: "absolute",
            right:"10px",
            top:"30px",

            cursor: "pointer",
            marginLeft: "89.5%",
            marginTop: "-46px",
          }}
        >
          <div
            className="editmaste"
            onClick={showEditMaster}
            style={{
              textDecoration: "underline",
              color: "#157EE1",
              fontFamily: "sans-serif",
              fontSize: "15px",
              lineHeight: "28px",
              fontWeight: "500",
              padding: "0px 0px",
            }}
          >
            Edit Masters
          </div>
        </div>
        {/* <a
          className="internalreport"
          style={{
            textDecorationLine: "none",
            border: "1px solid black",
            background: "#d2f1ff",
            borderRadius: "4px",
            float: "right",
            color: "#000000eb",
          }}
          target="blank"
          href="https://app.powerbi.com/reportEmbed?reportId=2f3396ad-8f31-444c-b74a-57b9a7461e5a&autoAuth=true&ctid=4ee04ec2-6076-420e-913e-0fd0134143d8"
        >
          Internal Report
        </a> */}
        <div
          className="clint_listMainDiv"
          style={{
            width: "100%",
            display: "flex",
            fontFamily: "sans-serif",
            marginBottom: "40px",
          }}
        >
          <div
            className="clientdivOne"
            style={{ width: "60%", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <p
                className="fonttext"
                style={{
                  color: "#434345",
                  fontSize: "16px",
                  lineHeight: "28px",
                  fontWeight: 500,
                  marginTop: "10px",
                }}
              >
                DEFINE USER ROLE
              </p>
              <TextField
                className="inputtext"
                size="small"
                type="text"
                style={{
                  width: "220px",
                  borderRadius: "7px",
                  // border: "1px solid #E5E5E5",
                  marginBottom: "10px",
                  background: "#FFFFFF",
                }}
                placeholder=""
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        fontSize="medium"
                        style={{ color: "#767676" }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                value={empName}
                onChange={empfilter}
              />
            </div>
            <div className="inputtextmobilediv">
              <TextField
                className="inputtextmobile"
                size="small"
                type="text"
                style={{
                  // width: "220px",
                  display: "none",
                  // borderRadius: "7px",
                  // border: "1px solid #E5E5E5",
                  marginBottom: "10px",
                  background: "#FFFFFF",
                }}
                placeholder="Search Hear"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        fontSize="medium"
                        style={{ color: "#767676" }}
                      />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                value={empName}
                onChange={empfilter}
              />
            </div>

            <div className="userrolemain" style={{}}>
              <div className="userrolescroll" style={{}}>
                <div className="magtop" style={{ marginTop: "10px" }}>
                  <Table>
                    <TableHead>
                      <TableRow
                        className="backgroundmain"
                        style={{ backgroundColor: "#F5FBFE" }}
                      >
                        <TableCell
                          className="textfont"
                          style={{
                            width: "9%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          S. No.
                        </TableCell>
                        <TableCell
                          className="textfont"
                          style={{
                            width: "18%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          User Name
                        </TableCell>
                        <TableCell
                          className="textfont"
                          style={{
                            width: "20%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          Reporting Manager
                        </TableCell>
                        <TableCell
                          className="textfont"
                          style={{
                            width: "12%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          Role
                        </TableCell>
                        <TableCell
                          className="textfont"
                          style={{
                            width: "12%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          Branch
                        </TableCell>
                        <TableCell
                          className="textfont"
                          style={{
                            width: "22%",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >

                         <select value={status} onChange={selectUserStatus}>
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                        </TableCell>
                       
                        <TableCell
                          className="textfont"
                          style={{
                            width: "7%",

                            textAlign: "start",
                            cursor: "pointer",
                          }}
                        />
                      </TableRow>
                    </TableHead>
                  </Table>

                  <div style={{ height: "390px", overflowY: "scroll" }}>
                    <Table>
                      {foundemployee&&foundemployee.map((n, ind) => (
                        <TableRow style={{ background: "#FAFAFA" }}>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "9%",
                              color: "#525252",
                              textAlign: "start",
                            }}
                          >
                            {ind + 1}
                          </TableCell>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "18%",
                              color: "#525252",
                              textAlign: "start",
                            }}
                          >
                            {n.userName}
                          </TableCell>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "20%",
                              color: "#525252",
                              textAlign: "start",
                            }}
                          >
                            {n.reportingManagerName}
                          </TableCell>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "12%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "2%",
                            }}
                          >
                            {n.roleName}
                          </TableCell>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "12%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3%",
                            }}
                          >
                            {n.branchName}
                            {/* </p> */}
                          </TableCell>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "22%",
                              color: "#525252",
                              textAlign: "start",
                              cursor: "pointer",
                              padding: "3%",
                            }}
                          >
                            {/* {n.division} */}
                          </TableCell>
                          <TableCell
                            className="maintextfont"
                            style={{
                              width: "7%",
                              textAlign: "start",
                              cursor: "pointer",
                            }}
                            onClick={() => editUser(n)}
                          >
                            <img className="w-5 h-6" src={EditIcon} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="clientdivTwo"
            style={{ width: "38%", marginLeft: "auto", height: "100%" }}
          >
            <div
              className="clientdivchild"
              style={{
                width: "82%",
                background: "#F1F1F1",
                alignItems: "center",
                borderRadius: "7px",
                display: "flex",
                gap: "35px",
                height: "40px",
                marginTop: "10px",
                marginLeft: "40px",
                paddingLeft: "30px",
              }}
            >
              <p>{UserList.filter((n) => n.status == status).length} Users</p>
              <p>{admincount} Admins</p>
              <p>{managercount} Managers</p>
              <p>{recruitercount} Recruiters</p>
            </div>
            <div
              className="userlistdiv"
              style={{
                marginTop: "22px",
                width: "98%",
                // height: "550px",
                padding: "20px 0 30px 0",
                border: "1px solid #CFDFED",
                background: "#EEF1F4",
                borderRadius: "7px",
              }}
            >
              <div>
                <p
                  style={{
                    display: "flex",
                    color: "#155188",
                    justifyContent: "center",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "18.23px",
                  }}
                >
                  ADD USER
                </p>

                <div
                  className="userdetailmobile"
                  style={{
                    display: "flex",
                    gap: "16px",
                    fontFamily: "sans-serif",
                    flexFlow: "row",
                  }}
                >
                  <div
                    className="userdetail"
                    style={{
                      display: "flex",
                      flexFlow: "column",
                      width: "33%",
                      textAlign: "end",
                    }}
                  >
                    <p>User Name</p>
                    <p>Password</p>
                    <p>Email</p>

                    <p>Reporting Manager</p>
                    <p style={{}}>Role</p>
                    <p style={{ lineHeight: "10px" }}>Division</p>
                    <p style={{}}>Branch Name</p>
                    <p style={{}}>Company</p>
                    <p style={{}}>Date of Joining</p>
                    <p style={{ lineHeight: "15px" }}>Leaving Date</p>
                    <p style={{}}>CTC (Annual)</p>
                  </div>
                  <form
                    className="gapzero"
                    onSubmit={handleSubmit(handleClickOpen)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                      paddingTop: "8px",
                    }}
                  >
                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        User Name
                      </p>

                      <input
                        {...register("Name", { required: true })}
                        placeholder={
                          errors.Name?.type === "required" && "Name is required"
                        }
                        aria-invalid={errors.Name ? "true" : "false"}
                        type="text"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "245px",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                        value={userName}
                        onChange={(d) => setUserName(d.target.value)}
                      />
                    </div>
                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Password
                      </p>

                      <input
                        {...register("Password", { required: true })}
                        placeholder={
                          errors.Password?.type === "required" &&
                          "Password is required"
                        }
                        aria-invalid={errors.Password ? "true" : "false"}
                        type="password"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "245px",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Email
                      </p>

                      <input
                        {...register("Email", { required: true })}
                        placeholder={
                          errors.Email?.type === "required" &&
                          "Email is required"
                        }
                        aria-invalid={errors.Email ? "true" : "false"}
                        type="email"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "245px",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                        value={email}
                        onChange={(d) => setEmail(d.target.value)}
                      />
                    </div>

                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Reporting Manager
                      </p>
                      <Select
                        options={rptManager}
                        onChange={(e) => setReportingManager(e.dat)}
                      />
                    </div>

                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Role
                      </p>

                      <select
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        className="w-56 h-10 rounded"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "250px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        variant="outlined"
                      >
                        <option value="">Select</option>
                        {roleData.map((d) => (
                          <option value={JSON.stringify(d)}>
                            {d.roleName}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Division
                      </p>

                      <div>
                        <div
                          style={{
                            borderRadius: "5px",
                            height: "30px",
                            border: "1px solid black",
                            background: "white",
                          }}
                          onClick={changeDiv}
                        >
                          Select Division
                        </div>
                        <div
                          id="division"
                          style={{
                            display: "none",
                            border: "1px solid black",
                            borderRadius: "5px",
                            width: "245px",
                            height: "150px",
                            background: "white",
                            position: "absolute",
                          }}
                        >
                          <div style={{ overflow: "auto", display: "grid" }}>
                            {divisionData.map((d) => (
                              <label>
                                <input
                                  type="checkbox"
                                  onClick={(n) => handleClick(n, d)}
                                />
                                {d.divisionName}
                              </label>
                            ))}
                          </div>
                          <div
                            style={{
                              border: "1px solid black",
                              textAlign: "center",
                              cursor: "pointer",
                              background: "skyblue",
                            }}
                            onClick={changeDiv}
                          >
                            OK
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Branch Name
                      </p>
                      <select
                        className="w-56 h-10 rounded"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "250px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        variant="outlined"
                        value={branch}
                        onChange={(d) => setBranch(d.target.value)}
                      >
                        <option value="">Select</option>
                        {branchData.map((d) => (
                          <option value={d.branchName}>{d.branchName}</option>
                        ))}
                      </select>
                    </div>

                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        {" "}
                        Company
                      </p>
                      <select
                        className="w-56 h-10 rounded"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "250px",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        variant="outlined"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      >
                        <option value="">Select</option>
                        {companyData.map((z) => (
                          <option value={z.company}>{z.company}</option>
                        ))}
                      </select>
                    </div>
                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Date Of Joining
                      </p>

                      <input
                        // {...register("Email", { required: true })}
                        // placeholder={
                        //   errors.Email?.type === "required" &&
                        //   "Email is required"
                        // }
                        // aria-invalid={errors.Email ? "true" : "false"}
                        type="date"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "245px",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                        value={dateOfJoining}
                        onChange={(d) => setDateOfJoining(d.target.value)}
                      />
                    </div>
                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        Leaving Date
                      </p>

                      <input
                        // {...register("Email", { required: true })}
                        // placeholder={
                        //   errors.Email?.type === "required" &&
                        //   "Email is required"
                        // }
                        // aria-invalid={errors.Email ? "true" : "false"}
                        type="date"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "245px",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                        value={leavingDate}
                        onChange={(d) => setLeavingDate(d.target.value)}
                      />
                    </div>
                    <div className="divuser" style={{ marginBottom: "0" }}>
                      <p className="userd" style={{ display: "none" }}>
                        CTC (Annual)
                      </p>

                      <input
                        // {...register("Email", { required: true })}
                        // placeholder={
                        //   errors.Email?.type === "required" &&
                        //   "Email is required"
                        // }
                        // aria-invalid={errors.Email ? "true" : "false"}
                        type="number"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "245px",
                          height: "35px",
                          borderRadius: "5px",
                        }}
                        value={cTC}
                        onChange={(d) => setCTC(d.target.value)}
                      />
                    </div>
                    <button
                      className="savebox"
                      type="submit"
                      style={{
                        backgroundColor: "#4594DE",
                        width: "70px",
                        height: "40px",
                        borderRadius: "5px",
                        color: "#FFFFFF",
                        border: "1px solid #4594DE",
                      }}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
      <div>
        <EditMaster hideEditMaster={hideEditMaster} />
      </div>
    </div>
  )
}

export default UserList
