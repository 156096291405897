import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import CloseIcon from "@mui/icons-material/Close";
import PopupAddCandidate from "./PopupAddCandidate";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopupClient from "../Requisition/PopupClient";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { CgScrollV } from "react-icons/cg";
import {
  getCandidate,
  getClient,
  getRequisitionByTag,
  getAllCandidate,
  getUserList,
} from "../../../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import Position from "./Position";
import "./candidate.css";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Checkbox, Popover } from "@mui/material";

// import Mobileview from "./Mobileview";

function Candidate() {
  let dispatch = useDispatch();

  let candidateBy = useSelector((state) => state.clientReducer.allCandidate);
  let requisitionList = useSelector(
    (state) => state.clientReducer.requisitionByTag
  );
  let UserList = useSelector((state) => state.clientReducer.userList);
  console.log("UserList",UserList)
  let clientList = useSelector((state) => state.clientReducer.clientList);

  console.log("candidateBy", candidateBy);

  const [expanded, setExpanded] = React.useState(false);

  const reqlis = requisitionList;
  

  const [empName, setempName] = useState("");

  const [foundemployee, setFoundEmployee] = useState([]);
  console.log("123foundemployee", foundemployee);

  const empfilter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = reqlis.filter((user) => {
        return (
          (user.positionName &&
            user.positionName
              .toLowerCase()
              .startsWith(keyword.toLowerCase())) ||
          (user.clientName &&
            user.clientName.toLowerCase().startsWith(keyword.toLowerCase()))
        );
      });
      setFoundEmployee(results);
    } else {
      setFoundEmployee(requisitionList.filter((b, i) => i < count));
    }
    setempName(keyword);
  };
  const [count, setCount] = useState(100);

  // useEffect(() => {
  //   setFoundEmployee(requisitionList.filter((b, i) => i < count));
  // }, [requisitionList]);

  useEffect(() => {
    // Update the foundEmployee state by filtering the requisitionList
    setFoundEmployee(requisitionList.filter((b, i) => i < count));
  }, [requisitionList, count]);

  // Function to load more items
  const loadMoreItems = () => {
    setCount(count + 10); // Increment count by 10 to load more items
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [openPosition, setOpenPosition] = useState(false);
  const [reqData, setReqData] = useState({});

  useEffect(() => {
    dispatch(getRequisitionByTag());
    // dispatch(getAllCandidate());
    dispatch(getClient());
    dispatch(getUserList());
  }, []);

  const handleClickOpen = (obj) => {
    localStorage.setItem("RequisitionId", obj._id);
    setReqData(obj);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //   

  const [showClientData, setShowClientData] = useState({});
  const handleClickOpen1 = (f) => {
    let tempClientData = clientList.filter((data) => data._id == f.clientId);
    setShowClientData(...tempClientData);
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const resultArray = [];

  foundemployee.forEach((obj) => {
    obj.locationArray.forEach((locationObj) => {
      const newObj = { ...locationObj };
      newObj.accomodation = obj.accomodation;
      newObj.addRecruiter = obj.addRecruiter;
      newObj.bu_id = obj.bu_id;
      newObj.clientId = obj.clientId;
      newObj.clientName = obj.clientName;
      newObj.createdAt = obj.createdAt;
      newObj.createdBy = obj.createdBy;
      newObj.ctcOffered = obj.ctcOffered;
      newObj.organisation_id = obj.organisation_id;
      newObj.positionName = obj.positionName;
      newObj.dateOfAllocation = obj.dateOfAllocation;
      newObj.totalPositions = obj.totalPositions;
      newObj.tat = obj.tat;
      newObj.priority = obj.priority;
      newObj.spoc = obj.spoc;
      newObj.RequisitionId = obj._id;
      newObj.educationCriteria = obj.educationCriteria;
      newObj.localWorker = obj.localWorker;
      newObj.instructions = obj.instructions;
      newObj.totalWorkLocation = obj.totalWorkLocation;
      resultArray.push(newObj);
    });
  });

  let candidateArray = [];
  const [arrayCandidate, setArrayCandidate] = useState();
  const handleAccordion = async (obj, value) => {
    dispatch(getAllCandidate(obj));
    // const found = await allCandidate.filter((can) => can.RequisitionId === obj)
    // if (found && value === "true") {
    //   candidateArray.push(found);
    //   setArrayCandidate(candidateArray);
    // } else {
    // }
  };

  const [selectedOption, setSelectedOption] = useState("#FF4242");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handlePosition = (obj) => {
    setOpenPosition(true);
    setReqData(obj);
  };

  const handleClosePosition = () => {
    setOpenPosition(false);
  };

  function reqCreatedBy(id) {
    return UserList.find((u) => u._id == id);
  }

  function load() {
    setCount(count + 100);
    setFoundEmployee(requisitionList.filter((b, i) => i < count + 100));
  }

  const [filteredRecruiters, setFilteredRecruiters] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValUser, setSearchValUser] = useState("");
  const [openPopOver, setOpenPopOver] = useState(false);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  console.log("filteredRecruiters", filteredRecruiters);

  const handleClickPop = (tagRecruiter) => {
    setAnchorEl(anchorEl);
    setFilteredRecruiters(tagRecruiter);
    setOpenPopOver(true);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
    setOpenPopOver(false);
    setSearchValUser("");
  };

  const handleRecruiterSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();

    if (searchValue === "") {
      setFilteredRecruiters(filteredRecruiters);
    } else {
      const filteredRecruiters1 = filteredRecruiters.filter((data) =>
        data.userName.toLowerCase().includes(searchValue)
      );
      setFilteredRecruiters(filteredRecruiters1);
    }
  };

  const [candidateBy1, setCandidateBy1] = useState(candidateBy);

  const [filteredData, setFilteredData] = useState([]);

  const [filteredCandidates, setFilteredCandidates] = useState([]);

  const handleRecruiterCheckboxChange = (event, recruiterId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedRecruiters([...selectedRecruiters, recruiterId]);
    } else {
      const updatedSelectedRecruiters = selectedRecruiters.filter(
        (id) => id !== recruiterId
      );
      setSelectedRecruiters(updatedSelectedRecruiters);
    }
  };


  useEffect(() => {
    // Apply the filter whenever selectedRecruiters or candidateBy1 change
    const updatedCandidates = candidateBy.map((candidate) => ({
      ...candidate,
      isVisible:
        selectedRecruiters.length === 0 ||
        selectedRecruiters.includes(candidate.createdBy),
    }));

    setFilteredCandidates(updatedCandidates);
  }, [selectedRecruiters, candidateBy]);

  
  console.log("filteredCandidates", filteredCandidates);
  console.log("selectedRecruiters", selectedRecruiters);

  function reqCreatedBy(id) {
    let user = UserList.find((u) => u._id == id);
    return user == undefined ? "" : user;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        fontFamily: "sans-serif",
        padding: "0",
      }}
    >
      <Dialog maxWidth={false} open={open}>
        <DialogContent>
          <PopupAddCandidate
            requisitionList={foundemployee}
            reqData={reqData}
            closePopup={handleClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={false} open={open1}>
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              cursor: "pointer",
            }}
          >
            <CloseIcon
              onClick={handleClose1}
              fontSize="small"
              style={{
                marginTop: "-4px",
                float: "right",
                position: "absolute",
                marginRight: "-8px",
                cursor:"pointer"
              }}
            />
          </div>
          <PopupClient showClientData={showClientData} />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={false} open={openPosition}>
        <DialogContent>
          <Position reqData={reqData} closepopup={handleClosePosition} />
        </DialogContent>
      </Dialog>

      <div
        style={{
          fontFamily: "sans-serif",
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "5px",
        }}
      >
        <p style={{ fontSize: "16px", lineHeight: "22px", color: "#434345" }}>
          CANDIDATES
        </p>
        <div style={{ display: "flex", columnGap: "12px" }}>
          <p
            style={{
              backgroundColor: "#F1F1F1",
              color: "#434343",
              fontFamily: "sans-serif",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
              width: "112px",
              height: "28px",
              borderRadius: "5px",
              textAlign: "center",
              paddingTop: "12px",
              marginTop: "2px",
            }}
          >
            {requisitionList.length} Requisitions
          </p>
          <TextField
            className="inputnonmobile"
            size="small"
            style={{
              width: "226px",
              borderRadius: "5px",
              marginTop: "2px",
              marginRight: "28px",
              // border: "1px solid #E5E5E5",
            }}
            placeholder="Search Here"
            value={empName}
            onChange={empfilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" style={{ color: "#767676" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </div>
      </div>
      <div
        className="inputtextmobilediv"
        style={{ display: "flex", columnGap: "12px" }}
      >
        <TextField
          className="inputtextmobile"
          size="small"
          style={{
            display: "none",

            // border: "1px solid #E5E5E5",
          }}
          placeholder="Search Here"
          value={empName}
          onChange={empfilter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" style={{ color: "#767676" }} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="candidatmaindiv" style={{ width: "1450px" }}>
          <div className="candidatmaindiv" style={{ width: "75%" }}>
            <Table>
              <TableHead>
                <TableRow
                  className="candidatedis"
                  style={{ backgroundColor: "#F5FBFE" }}
                >
                  <TableCell
                    style={{
                      // wordBreak: "break-word",
                      color: "#272945",
                      textAlign: "start",
                      width: "8%",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-word",
                      color: "#272945",
                      textAlign: "start",
                      width: "17%",
                    }}
                  >
                    Client Name
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-all",
                      color: "#272945",
                      textAlign: "center",
                      width: "20%",
                    }}
                  >
                    Position Name / No.
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-word",
                      color: "#272945",
                      textAlign: "start",
                      width: "18%",
                      paddingLeft: "3.5%",
                    }}
                  >
                    Date of Allocation
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-word",
                      color: "#272945",
                      textAlign: "start",
                      width: "20%",
                    }}
                  >
                    Location
                  </TableCell>
                  <TableCell
                    style={{
                      // wordBreak: "break-word",
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                    }}
                  >
                    TAT
                  </TableCell>
                  <TableCell
                    style={{
                      wordBreak: "break-word",
                      color: "#272945",
                      textAlign: "end",
                      width: "10%",
                    }}
                  >
                    Priority
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#272945",
                      textAlign: "start",
                    }}
                  />
                </TableRow>

                {/* Mobile View */}

                <TableRow
                  className="candidatedismobile"
                  style={{
                    backgroundColor: "#BFE8FF",
                    display: "flex",
                    justifyContent: "space-between",
                    display: "none",
                  }}
                >
                  <div
                    style={{
                      color: "#2B2B2B",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 15px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "18.23px",
                        }}
                      >
                        Client
                      </p>
                    </div>
                    <div>
                      <CgScrollV />
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TableCell
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        // width: "20%",
                        fontSize: "12px",
                      }}
                    >
                      Position Name / No.
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        // width: "18%",
                        paddingLeft: "3.5%",
                        fontSize: "12px",
                      }}
                    >
                      Date of Allocation
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        // width: "20%",
                        fontSize: "12px",
                      }}
                    >
                      Location
                    </TableCell>
                  </div>
                </TableRow>
              </TableHead>
            </Table>
          </div>

          {/* <Mobileview /> */}

          <div
            className="candidatedismobile"
            style={{
              display: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
                width: "100%",
                minHeight: "438px",
                overflowY: "scroll",
                marginTop: "10px",
              }}
            >
              {foundemployee &&
                foundemployee.map((req, i) => (
                  <div>
                    {/* {console.log("123tagRecruiter", req.tagRecruiter)} */}
                    <Accordion
                      key={i}
                      expanded={expanded === req._id}
                      onChange={() =>
                        setExpanded(expanded === req._id ? null : req._id)
                      }
                      style={{
                        border: "1px solid #CDDDE7 ",
                        borderRadius: "20px",
                        shadows: "none",
                        padding: "0 7px",
                      }}
                    >
                      <div
                        onClick={() => handleAccordion(req._id, "true")}
                        style={{
                          fontFamily: "sans-serif",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#525252",
                          // flexDirection: "column",
                          // display:'block'
                        }}
                      >
                        <TableRow
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {/* <TableCell
                      className=""
                      style={{ width: "5%", textAlign: "center" }}
                    >
                      {i + 1}
                    </TableCell> */}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <TableCell
                              style={{
                                border: "none",
                                color: "#1879D4",
                                textDecoration: "underline",
                                cursor: "pointer",
                                maxWidth: "100px",
                                textAlign: "start",
                                paddingLeft: "1.4%",
                              }}
                              onClick={() => handleClickOpen1(req)}
                            >
                            
                              {req.clientName}
                            </TableCell>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <TableCell
                                className=""
                                style={{
                                  border: "none",
                                  // width: "10%",
                                  textAlign: "start",
                                  paddingLeft: "2.6%",
                                }}
                              >
                                <p
                                  style={{
                                    padding: "0 5px",
                                    height: "32px",
                                    borderRadius: "5px",
                                    border: "1px solid #9A9A9A",
                                    backgroundColor:
                                      req.priority == "Burning"
                                        ? "#FF4242"
                                        : req.priority == "Hot"
                                        ? "#FF9270"
                                        : "#FFCE70",
                                    color: "black",
                                    textAlign: "center",
                                    lineHeight: "32px",
                                  }}
                                  // value={selectedOption}
                                  // onChange={handleSelectChange}
                                  variant="outlined"
                                >
                                  {req.priority}
                                </p>
                              </TableCell>
                              <TableCell
                                style={{
                                  border: "none",
                                }}
                              >
                                <button
                                  style={{
                                    border: "none",
                                    backgroundColor: req.status === "Hold" ? "#CCCCCC" : "#4594DE",                                    //   marginLeft: "32px",
                                    fontFamily: "sans-serif",
                                    //   width: "122px",
                                    padding: "0 7px",
                                    minHeight: "32px",
                                    color: "#FFFFFF",
                                    fontWeight: 600,
                                    fontSize: "12px",
                                    //   marginTop: "4px",
                                    lineHeight: "16px",
                                    borderRadius: "6px",
                                    border: "1px ridge",
                                    cursor: req.status === "Hold" ? "not-allowed" : "pointer", 
                                  }}
                                  onClick={() => handleClickOpen(req)}
                                  title={
                                    req.status === "Hold" ? "Status is on Hold" : undefined 
                                  }
                                  disabled={req.status === "Hold"} 
                                >
                                  ADD CANDIDATE
                                </button>
                              </TableCell>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",

                              justifyContent: "space-between",
                              alignItems: "center",
                              fontSize: "15px",
                            }}
                          >
                            <TableCell
                              className=""
                              style={{
                                border: "none",
                                fontSize: "10px",
                                width: "25%",
                                textAlign: "start",
                                paddingLeft: "1.5%",
                                textAlign: "center",
                              }}
                            >
                              {/* {req.tat} */}
                            </TableCell>

                            <TableCell
                              className=""
                              style={{
                                textAlign: "center",
                                border: "none",
                                textAlign: "start",
                              }}
                            >
                              {req.createdAt ? req.createdAt.slice(0, 10) : ""}
                            </TableCell>

                            <TableCell
                              className=""
                              style={{
                                textAlign: "center",
                                border: "none",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                wordBreak: "break-all",
                                width: "100px",
                                textAlign: "start",
                                paddingLeft: "2%",
                              }}
                            >
                              {req.locationName}
                            </TableCell>
                          </div>

                          <div
                            style={{
                              border: "none",
                              display: "flex",
                              justifyContent: "end",
                              width: "100%",
                              alignItems: "center",
                              height: "50px",
                            }}
                          >
                            <TableCell style={{ border: "none" }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                
                                <span
                                  style={{
                                    fontSize: "10px",
                                    fontWeight: 500,
                                    lineHeight: "16px",
                                  }}
                                >
                                  SHOW MORE
                                </span>
                              </AccordionSummary>
                            </TableCell>
                          </div>
                        </TableRow>
                      </div>
                      <div style={{ width: "100%", overflow: "scroll" }}>
                        <div
                          style={{
                            overflowY: "scroll",
                            overflowX: "scroll",
                            width: "800px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "10px",
                              width: "100%",
                              color: "#525252",
                              fontFamily: "sans-serif",
                              fontWeight: 600,
                              lineHeight: "12px",
                              paddingTop: "12px",
                              paddingBottom: "4px",
                            }}
                          >
                            <div style={{}}>
                              <div style={{ display: "flex" }}>
                              <div
                            style={{
                              // width: "10%",
                              textAlign: "start",
                            }}
                          >
                            Sr. no.
                          </div>
                                <div
                                  style={{
                                    width: "80px",
                                    textAlign: "center",
                                  }}
                                >
                                  Dt. of submission
                                </div>

                                <div
                                  style={{
                                    width: "17%",
                                    textAlign: "center",
                                  }}
                                >
                                  Candidate Name
                                </div>

                                <div
                                  style={{
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  Contact Number
                                </div>

                                <div
                                  style={{
                                    width: "18%",
                                    textAlign: "center",
                                    // paddingLeft: "3%",
                                  }}
                                >
                                  Email Id
                                </div>

                                <div
                                  style={{
                                    width: "10%",
                                    textAlign: "center",
                                    // paddingLeft: "1%",
                                  }}
                                >
                                  Aadhar No. (optional)
                                </div>

                                <div
                                  style={{
                                    width: "15%",
                                    textAlign: "center",
                                    // paddingLeft: "3%",
                                  }}
                                >
                                  Status
                                </div>

                                <div
                                  style={{
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  Created By
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            style={{
                              height: "235px",
                              overflowY: "scroll",
                              overflowX: "scroll",
                              width: "100%",
                              color: "#545454",
                              fontFamily: "sans-serif",
                              fontWeight: 500,
                              fontSize: "10px",
                              lineHeight: "16px",
                            }}
                          >
                            <div>
                              {candidateBy.map((row,i) => {
                                const createdByRecruiterId = row.createdBy;
                                const isRecruiterChecked =
                                  selectedRecruiters.includes(
                                    createdByRecruiterId
                                  );

                                return isRecruiterChecked ? null : (
                                  <div>
                                    <div
                                      key={row.name}
                                      style={{
                                        backgroundColor: "#F0F9F5",
                                        // width: "100%",
                                        display: "flex",
                                        paddingTop: "12px",
                                        paddingBottom: "4px",
                                      }}
                                    >
                                       <div
                                      style={{
                                        width: "3%",
                                        textAlign: "start",
                                        paddingLeft:"2px"
                                      }}
                                    >
                                       {i + 1}.
                                    </div>
                                      <div
                                        style={{
                                          width: "10%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {row.dateOfSubmission
                                          ? row.dateOfSubmission.slice(0, 10)
                                          : ""}
                                      </div>
                                      <div
                                        style={{
                                          width: "17%",
                                          textAlign: "center",
                                          // paddingLeft: "0.5%",
                                        }}
                                      >
                                        {row.candidateName}
                                      </div>
                                      <div
                                        style={{
                                          width: "15%",
                                          textAlign: "center",
                                          // paddingLeft: "1.5%",
                                        }}
                                      >
                                        {row.contactNo}
                                      </div>
                                      <div
                                        style={{
                                          width: "18%",
                                          textAlign: "center",
                                          // paddingLeft: "3.5%",
                                        }}
                                      >
                                        {row.emailId}
                                      </div>
                                      <div
                                        style={{
                                          width: "10%",
                                          textAlign: "center",
                                          // paddingLeft: "2%",
                                        }}
                                      >
                                        {row.adhaarNo}
                                      </div>
                                      <div
                                        style={{
                                          width: "15%",
                                          textAlign: "center",
                                          // paddingLeft: "4%",
                                        }}
                                      >
                                        {row.candidateStatus}
                                      </div>
                                      <td
                                        style={{
                                          width: "15%",
                                          textAlign: "center",
                                        }}
                                      >
                                        {row.createdBy == null
                                          ? "N"
                                          : reqCreatedBy(row.createdBy)
                                              .userName}
                                      </td>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion>
                    <button
                      style={{
                        display: foundemployee.length > i + 1 ? "none" : "",
                      }}
                      onClick={loadMoreItems}
                      // onClick={load}
                    >
                      Show More
                    </button>
                  </div>
                ))}
            </div>
          </div>

          {/* desktop View */}

          <div
            className="candidatedis"
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              width: "100%",
              height: "438px",
              overflowY: "scroll",
            }}
          >
            {foundemployee &&
              foundemployee.map((req, i) => (
                <div key={i}>
                  <Accordion
                    expanded={expanded === req._id}
                    onChange={() =>
                      setExpanded(expanded === req._id ? null : req._id)
                    }
                  >
                    <div
                      onClick={() => handleAccordion(req._id, "true")}
                      style={{
                        fontFamily: "sans-serif",
                        fontWeight: 400,
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "#525252",
                      }}
                    >

                      <TableRow style={{position:"relative"}}>
                      <div style={{position:"absolute",left:"0", bottom:"0"}}>
                      <p style={{ fontSize: "9px" }}>
                            Created by :
                            <span
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                                paddingLeft: "2px",
                              }}
                            >
                      {req.createdBy == null
                                ? ""
                                : reqCreatedBy(req.createdBy).userName}</span></p>
                      </div>
                        <TableCell
                          className=""
                          style={{ width: "5%", textAlign: "start" }}
                        >
                          {i + 1}
                        </TableCell>
                        <TableCell
                          style={{
                            wordBreak: "break-word",
                            color: "#1879D4",
                            textDecoration: "underline",
                            cursor: "pointer",
                            width: "17%",
                            textAlign: "start",
                            paddingLeft: "1.4%",
                          }}
                          onClick={() => handleClickOpen1(req)}
                        >
                          {req.clientName}
                        </TableCell>
                        <TableCell
                          onClick={() => handlePosition(req)}
                          style={{
                            wordBreak: "break-word",
                            color: "#1879D4",
                            cursor: "pointer",
                            textDecoration: "underline",
                            width: "15%",
                            textAlign: "start",
                          }}
                        >
                          {req.positionName} / {req.noOfPosition}
                        </TableCell>
                        <TableCell
                          className=""
                          style={{
                            wordBreak: "break-word",
                            width: "10%",
                            textAlign: "start",
                          }}
                        >
                          {req.createdAt ? req.createdAt.slice(0, 10) : ""}
                        </TableCell>
                        <TableCell
                          className=""
                          style={{
                            wordBreak: "break-word",
                            width: "15%",
                            textAlign: "start",
                            paddingLeft: "2%",
                          }}
                        >
                          {req.locationName}
                        </TableCell>
                        <TableCell
                          className=""
                          style={{
                            wordBreak: "break-word",

                            width: "5%",
                            textAlign: "start",
                            paddingLeft: "1.5%",
                          }}
                        >
                          {req.tat}
                        </TableCell>
                        <TableCell
                          className=""
                          style={{
                            wordBreak: "break-word",
                            width: "10%",
                            textAlign: "start",
                            paddingLeft: "2.6%",
                          }}
                        >
                          <p
                            style={{
                              wordBreak: "break-word",
                              width: "96px",
                              height: "32px",
                              borderRadius: "5px",
                              border: "1px solid #9A9A9A",
                              backgroundColor:
                                req.priority == "Burning"
                                  ? "#FF4242"
                                  : req.priority == "Hot"
                                  ? "#FF9270"
                                  : "#FFCE70",
                              color: "black",
                              textAlign: "center",
                              lineHeight: "32px",
                            }}
                            // value={selectedOption}
                            // onChange={handleSelectChange}
                            variant="outlined"
                          >
                            {req.priority}
                          </p>
                        </TableCell>
                        <TableCell>
                          <button
                            style={{
                              backgroundColor: req.status === "Hold" ? "#CCCCCC" : "#4594DE",
                              marginLeft: "32px",
                              fontFamily: "sans-serif",
                              width: "122px",
                              height: "32px",
                              color: "#FFFFFF",
                              fontWeight: 600,
                              fontSize: "12px",
                              marginTop: "4px",
                              lineHeight: "16px",
                              borderRadius: "6px",
                              border: "1px ridge",
                              cursor: req.status === "Hold" ? "not-allowed" : "pointer", 
                            }}
                            onClick={() => handleClickOpen(req)}
                            disabled={req.status === "Hold"} 
                            title={
                              req.status === "Hold" ? "Status is on Hold" : undefined 
                            }
                          >
                            ADD CANDIDATE
                          </button>
                        </TableCell>

                        <TableCell style={{ paddingLeft: "12px" }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            onClick={() => setSelectedRecruiters([])}
                          >
                            
                            <span
                              style={{
                                fontSize: "10px",
                                fontWeight: 500,
                                lineHeight: "16px",
                              }}
                            >
                              SHOW MORE
                            </span>
                          </AccordionSummary>
                        </TableCell>
                      </TableRow>
                    </div>

                    <div
                      style={{
                        fontSize: "12px",
                        color: "#525252",
                        fontFamily: "sans-serif",
                        fontWeight: 600,
                        lineHeight: "12px",
                        paddingTop: "12px",
                        paddingBottom: "4px",
                      }}
                    >
                      {/* <span style={{ marginLeft: "13px" }}>Dt. of submission</span>
                <span style={{ marginLeft: "70px" }}>Candidate Name</span>
                <span style={{ marginLeft: "126px" }}>Contact Number</span>
                <span style={{ marginLeft: "104px" }}>Email Id</span>
                <span style={{ marginLeft: "270px" }}>
                  Aadhar No. (optional)
                </span>

                <span style={{ marginLeft: "150px" }}>Status</span>
                <span style={{ marginLeft: "100px" }}>Created By</span> */}

                      <div>
                        <div style={{ width: "100%", display: "flex" }}>
                        <div
                            style={{
                              width: "5%",
                              textAlign: "start",
                            }}
                          >
                            S. No.
                          </div>
                          <div
                            style={{
                              width: "10%",
                              textAlign: "start",
                            }}
                          >
                            Dt. of submission
                          </div>

                          <div
                            style={{
                              width: "17%",
                              textAlign: "start",
                            }}
                          >
                            Candidate Name
                          </div>

                          <div
                            style={{
                              width: "15%",
                              textAlign: "start",
                            }}
                          >
                            Contact Number
                          </div>

                          <div
                            style={{
                              width: "18%",
                              textAlign: "start",
                              paddingLeft: "3%",
                            }}
                          >
                            Email Id
                          </div>

                          <div
                            style={{
                              width: "10%",
                              textAlign: "start",
                              paddingLeft: "1%",
                            }}
                          >
                            Aadhar No. (optional)
                          </div>

                          <div
                            style={{
                              width: "15%",
                              textAlign: "start",
                              paddingLeft: "3%",
                            }}
                          >
                            Status
                          </div>

                          <div
                            style={{
                              width: "15%",
                              textAlign: "start",
                            }}
                          >
                            <div
                              style={{
                                // color: "#272945",
                                textAlign: "center",
                                // width: "7%",
                                // fontSize: "13.5px",
                                // padding: "5px 5px",
                                display: "flex",
                                justifyContent: "",
                              }}
                            >
                              <div style={{}}> Created By</div>

                              <div
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                  fontFamily: "sans-serif",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                }}
                              >
                                <FilterListIcon
                                  style={{
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() =>
                                    handleClickPop(req.tagRecruiter)
                                  }
                                  // checked={selectedCheckboxes.length > 0}
                                  // onChange={handleClickPop}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        height: "235px",
                        overflowY: "scroll",
                        width: "100%",
                        color: "#545454",
                        fontFamily: "sans-serif",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "16px",
                      }}
                    >
                      <div>
                        {filteredCandidates.length > 0 ? (
                          filteredCandidates.map(
                            (row, i) =>
                              row.isVisible && (
                                <div>
                                  <div
                                    key={row.name}
                                    style={{
                                      backgroundColor: "#F0F9F5",
                                      width: "100%",
                                      display: "flex",
                                      paddingTop: "12px",
                                      paddingBottom: "4px",
                                    }}
                                  >
                                     <div
                                      style={{
                                        width: "5%",
                                        textAlign: "start",
                                        paddingLeft:"2px"
                                      }}
                                    >
                                       {i + 1}.
                                    </div>
                                    <div
                                      style={{
                                        width: "10%",
                                        textAlign: "start",
                                      }}
                                    >
                                      {row.dateOfSubmission
                                        ? row.dateOfSubmission.slice(0, 10)
                                        : ""}
                                    </div>
                                    <div
                                      style={{
                                        width: "17%",
                                        textAlign: "start",
                                        paddingLeft: "0.5%",
                                      }}
                                    >
                                      {row.candidateName}
                                    </div>
                                    <div
                                      style={{
                                        width: "15%",
                                        textAlign: "start",
                                        paddingLeft: "1.5%",
                                      }}
                                    >
                                      {row.contactNo}
                                    </div>
                                    <div
                                      style={{
                                        width: "18%",
                                        textAlign: "start",
                                        paddingLeft: "3.5%",
                                      }}
                                    >
                                      {row.emailId}
                                    </div>
                                    <div
                                      style={{
                                        width: "10%",
                                        textAlign: "start",
                                        paddingLeft: "2%",
                                      }}
                                    >
                                      {row.adhaarNo}
                                    </div>
                                    <div
                                      style={{
                                        width: "15%",
                                        textAlign: "start",
                                        paddingLeft: "4%",
                                      }}
                                    >
                                      {row.candidateStatus}
                                    </div>
                                    <td
                                      style={{
                                        width: "15%",
                                        textAlign: "start",
                                      }}
                                    >
                                      {row.createdBy == null
                                        ? "N"
                                        : reqCreatedBy(row.createdBy).userName}
                                    </td>
                                  </div>
                                </div>
                              )
                          )
                        ) : (
                          <p>No candidates </p>
                        )}
                      </div>
                    </div>
                  </Accordion>
                  <button
                    style={{
                      display:
                        foundemployee.length > i + 1
                          ? "none"
                          : foundemployee.length < count
                          ? "none"
                          : "",
                    }}
                    // onClick={load}
                    onClick={loadMoreItems}
                  >
                    Show More
                  </button>
                </div>
              ))}
          </div>
          <Popover
            open={openPopOver}
            anchorEl={anchorEl}
            onClose={handleClosePop}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            style={{ height: "400px", maxWidth: "700px", boxShadow: "none " }}
          >
            <TextField
              size="small"
              style={{
                width: "256px",
                borderRadius: "5px",
                marginTop: "12px",
                marginLeft: "5px",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" style={{ color: "#767676" }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleRecruiterSearch(e)}
              placeholder="Search Here"
            />
            {filteredRecruiters.length === 0 ? (
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                No Recruiters found with this name
              </p>
            ) : (
              filteredRecruiters.map((data, index) => (
                <ul
                  key={data._id}
                  style={{
                    marginTop: "1px",
                    marginBottom: "1px",
                  }}
                >
                  <li>
                    <Checkbox
                      checked={selectedRecruiters.includes(data.recruiterId)}
                      onChange={(event) =>
                        handleRecruiterCheckboxChange(event, data.recruiterId)
                      }
                    />

                    {data.userName}
                    {/* {data.createdBy == null ? 'N' : reqCreatedBy(data.createdBy).userName} */}
                  </li>
                </ul>
              ))
            )}
          </Popover>
        </div>
      </div>
    </div>
  );
}

export default Candidate;
