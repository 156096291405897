import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { FaPlus ,FaMinus} from 'react-icons/fa';
import { MdOutlineCancel } from "react-icons/md";
import VendorDetail from './VendorDetail';



const Step4Form = ({
    handleSkilledCheckboxChange,
    handleUnSkilledCheckboxChange,
    handleSkilledSelectChange,
    showSkilledDropdown,
    showUnSkilledDropdown,
    addSubcategoryDetailunskill,
    removeunskill,
    addunskill,
    removeSubcategoryDetailunskill,
    handleAmountChangeunskill,
    handleSubcategorySelectChangeunskill,
    handleYearOfExperienceChangeunskill,
    handleSkilledSelectChangeunskill,
    yearsOptionsunskill,
    addSubcategoryDetail,
    removeSkill,
    addSkill,
    removeSubcategoryDetail,
    handleAmountChange,
    handleSubcategorySelectChange,
    handleYearOfExperienceChange,
    yearsOptions,
    dynamicFieldsskill,
    dynamicFieldsunskill,
    unskilledDropdown,
    skilledDropdown,  
     }) => {


  return (
    <>
     <div>
      <h2 style={{textAlign:"center",marginTop:"0",paddingTop:"0"}}> Step 4 : Terms </h2>


    <div style={{fontSize:"13px",marginTop:"15px",width:"100%",}}>    
      <p style={{margin:"0"}}>Skilled/Unskillled</p>
      <div style={{display:"flex",alignItems:"center",marginBottom:"10px"}}> 
      <div style={{}}>
        <label>
        <input
        checked={showSkilledDropdown}
            type="checkbox"
            name="Skilled"
            onChange={handleSkilledCheckboxChange}
          />
          Skilled
        </label>
      </div>
      <div style={{marginLeft:"10px"}}>
        <label>
        <input
        checked={showUnSkilledDropdown}
            type="checkbox"
            name="unSkilled"
            onChange={handleUnSkilledCheckboxChange}
          />
          Unskilled
        </label>
      </div>
      </div>



{console.log("showSkilledDropdown",showSkilledDropdown)}
{/* skilled */}

 <div>
     <div style={{marginTop:"15px"}}>
        {showSkilledDropdown && dynamicFieldsskill.map((skill, skillIndex) => (
        <div key={skillIndex} style={{border:"1px solid gray",marginTop:"20px",padding:"20px 10px 20px 10px",borderRadius:"10px"}}>
          <div style={{display:"flex",position:"relative"}}>  
          {showSkilledDropdown && (
        <div style={{width:"96%"}}>
            <p style={{margin:"0"}}>Skilled</p>
            <Select
              value={skill.skillName ? { value: skill.skillName, label: skill.skillName } : null}
              onChange={(selectedOption) => handleSkilledSelectChange(skillIndex, selectedOption)}
              options={skilledDropdown.map(item => ({
                value: item.name,
                label: item.name,
                subcategories: item.subcategories || []
              }))}
            />
        </div>
         )} 

{ skillIndex > 0 && (  <button onClick={() => removeSkill(skillIndex)} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#dc3545',position:"absolute",right:"0",top:"-10px" }}> <MdOutlineCancel size={15} /></button> )}
             </div>
             {skill.details.map((detail, objectIndex) => (
            <div key={objectIndex}  style={{ marginTop: '15px',width:"100%",justifyContent:"start", display:"flex",alignItems:"center"}}>
              <div style={{justifyContent:"space-around", display:"flex",alignItems:"center", width:"96%"}}>
              <div style={{width:"40%" }}>
                <div>
                   <p style={{ margin: '0',}}>Subcategories</p>
                   <Select
                  value={detail.Subcategories ? { value: detail.Subcategories, label: detail.Subcategories } : null}
                  onChange={(selectedOption) => handleSubcategorySelectChange(skillIndex, objectIndex, selectedOption)}
                  options={skilledDropdown
                    .find(option => option.name === skill.skillName)
                    ?.subcategories.map(sub => ({
                      value: sub.name,
                      label: `${sub.name} (${sub.range})`
                    })) || []}
                />
                  </div>
              </div>
              <div style={{ width: '40%' ,borderRadius:'5px',marginLeft:'15px'}}>
              <p style={{ margin: '0',}}>Amount</p>
              <input
                  type="number"
                  value={detail.Amount}
                  onChange={(event) => handleAmountChange(skillIndex, objectIndex, event)}
                  style={{ height: "32px",paddingLeft:"10px" }}
                  placeholder="Enter Amount"
                />
              </div>
              <div style={{ width: '30%',marginLeft:"15px" }}>
              <p style={{ margin: '0',}}>Years of Experience</p>
              <Select
                  value={detail.YearsOfExperience ? { value: detail.YearsOfExperience, label: detail.YearsOfExperience } : null}
                  onChange={(selectedOption) => handleYearOfExperienceChange(skillIndex, objectIndex, selectedOption)}
                  options={yearsOptions}
                />
              </div>
              </div>
                 { objectIndex > 0 && <button onClick={() => removeSubcategoryDetail(skillIndex, objectIndex)} 
              style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#dc3545',marginTop:"18px" }}>
                 <FaMinus size={10} />
                 </button>}

            </div>
          ))}
        
           <button
            onClick={() => addSubcategoryDetail(skillIndex)}
            style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#007bff',marginTop:"15px" }} 
           >
           <FaPlus size={12} /> Add more Subcategories
          </button>
        
        </div>
      ))}

     </div>
     <div style={{width:"100%",textAlign:"end"}}>
         { showSkilledDropdown && <button  onClick={addSkill} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#007bff' ,marginTop:"20px",fontWeight:"bold"}}>  <span ><FaPlus size={12} /></span> Add more Skill</button>} 
     </div>
    </div>


{/* unskilled */}


<div style={{marginTop:"50px"}}>
     <div style={{marginTop:"15px"}}>
        {showUnSkilledDropdown && dynamicFieldsunskill.map((skill, skillIndex) => (
        <div key={skillIndex} style={{border:"1px solid gray",marginTop:"20px",padding:"20px 10px 20px 10px",borderRadius:"10px"}}>
          <div style={{display:"flex",position:"relative"}}>  
          {showUnSkilledDropdown && (
        <div style={{width:"96%"}}>
            <p style={{margin:"0"}}>UnSkilled</p>
            <Select
              value={skill.UnskillName ? { value: skill.UnskillName, label: skill.UnskillName } : null}
              onChange={(selectedOption) => handleSkilledSelectChangeunskill(skillIndex, selectedOption)}
              options={unskilledDropdown.map(item => ({
                value: item.name,
                label: item.name,
                subcategories: item.subcategories || []
              }))}
            />
        </div>
         )} 

{ skillIndex > 0 && (  <button onClick={() => removeunskill(skillIndex)} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#dc3545',position:"absolute",right:"0",top:"-10px" }}> <MdOutlineCancel size={15} /></button> )}
             </div>
             {skill.details.map((detail, objectIndex) => (
            <div key={objectIndex}  style={{ marginTop: '15px',width:"100%",justifyContent:"start", display:"flex",alignItems:"center"}}>
             <div style={{justifyContent:"space-around", display:"flex",alignItems:"center", width:"96%"}}>
              <div style={{width:"40%" }}>
                <div>
                   <p style={{ margin: '0',}}>Subcategories</p>
                   <Select
                  value={detail.Subcategories ? { value: detail.Subcategories, label: detail.Subcategories } : null}
                  onChange={(selectedOption) => handleSubcategorySelectChangeunskill(skillIndex, objectIndex, selectedOption)}
                  options={unskilledDropdown
                    .find(option => option.name === skill.UnskillName)
                    ?.subcategories.map(sub => ({
                      value: sub.name,
                      label: `${sub.name} (${sub.range})`
                    })) || []}
                />
                  </div>
              </div>
              <div style={{ width: '40%' ,borderRadius:'5px',marginLeft:'15px'}}>
              <p style={{ margin: '0',}}>Amount</p>
              <input
                  type="number"
                  value={detail.Amount}
                  onChange={(event) => handleAmountChangeunskill(skillIndex, objectIndex, event)}
                  style={{ height: "32px",paddingLeft:"10px" }}
                  placeholder="Enter Amount"
                />
              </div>
              <div style={{ width: '30%',marginLeft:"15px" }}>
              <p style={{ margin: '0',}}>Years of Experience</p>
              <Select
                  value={detail.YearsOfExperience ? { value: detail.YearsOfExperience, label: detail.YearsOfExperience } : null}
                  onChange={(selectedOption) => handleYearOfExperienceChangeunskill(skillIndex, objectIndex, selectedOption)}
                  options={yearsOptionsunskill}
                />
              </div>
              </div>
                { objectIndex > 0  &&  <button onClick={() => removeSubcategoryDetailunskill(skillIndex, objectIndex)} 
              style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#dc3545',marginTop:"18px" }}>
                 <FaMinus size={10} />
                 </button>}

            </div>
          ))}
        
           <button
            onClick={() => addSubcategoryDetailunskill(skillIndex)}
            style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#007bff',marginTop:"15px" }} 
           >
           <FaPlus size={12} /> Add more Subcategories
          </button>
        
        </div>
      ))}

     </div>
     <div style={{width:"100%",textAlign:"end"}}>
              { showUnSkilledDropdown && <button  onClick={addunskill} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#007bff' ,marginTop:"20px",fontWeight:"bold"}}>  <span ><FaPlus size={12} /></span> Add more UnSkill</button>} 
              </div>
    </div>  
    <div style={{display:"none"}}>
      <VendorDetail 
        dynamicFieldsskill={dynamicFieldsskill}
        dynamicFieldsunskill={dynamicFieldsunskill}
       />
    </div>
    </div>
    </div>
    </>
  )
}

export default Step4Form