import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DeleteIcon from "../DeleteIcon.svg";
import PopupEditSaveRequisition from "./PopupEditSaveRequisition";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import {
  getClient,
  getUserList,
  postRequisition,
} from "../../../../redux/action";
import { ExpandMore } from "@mui/icons-material";
import { Modal, Popover } from "@mui/material";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import AddSpoc from "./AddSpoc";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import './requisition.css'
import axios from "axios";
const { URL } = require("../../../../config.json");

function PopupAddRequisition({
  requisitionEditData,
  operationStatusType,
  handleClose1,
}) {
  console.log("requisitionEditData", requisitionEditData, operationStatusType);
  let dispatch = useDispatch();

  let divisionList = useSelector((state) => state.clientReducer.division);
  let clientList = useSelector((state) => state.clientReducer.clientList);
  const [open, setOpen] = useState(false);
  const [open10, setOpen10] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen10(true);
  };

  const handleClose10 = () => {
    setOpen10(false);
  };

  let emp_id = localStorage.getItem("employeeId");
  let org_id = localStorage.getItem("organisation_id");
  const handleClose = () => {
    setOpen(false);
  };
  let defaultRequisition = {
    organisation_id: org_id,
    createdBy: emp_id,
    bu_id: "",
    // allocationDate: formatDate(new Date()),
    clientName: "",
    clientId: "",
    positionName: "",
    location: "",
    totalPositions: "",
    addRecruiter: "",
    tat: "",
    priority: "",
    spoc: "",
    ctcOffered: "",
    accomodation: "Yes",
    educationCriteria: "",
    localWorker: "Local",
    hiring_type: "",
    instructions: "",
    totalWorkLocation: "",
    locationArray: [],
  };

  const [locationName, setLocationName] = useState("");
  const [noOfPosition, setNoOfPosition] = useState("");
  const [tagRecruiter, setTagRecruiter] = useState([]);
  const [tagVendor, setTagVendor] = useState([])
  const [requiredLocationArray, setRequiredLocationArray] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [clientId, setClientId] = useState();
  const [searchVender, setSearchVender] = useState("")
  const handleAddLocationArray = () => {
    if (
      locationName === "" ||
      noOfPosition === "" ||
      tagRecruiter.length === 0 
    ) {
      alert("Please fill required fields ");
      setRequiredLocationArray(true);
    } else {
      let temp = {
        locationName: locationName,
        noOfPosition: noOfPosition,
        tagRecruiter: tagRecruiter,
        tagVendor: tagVendor
      };

      setRequisition({
        ...requisition, location: locationName,
        locationArray: [temp, ...requisition.locationArray],

      });
      setTagRecruiter([]);
      setTagVendor([]);
      setLocationName("");
      setNoOfPosition("");
    }
  };
  const [division, setDivision] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePop = () => {
    setAnchorEl(null);
  };

  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  const [anchorEle, setAnchorEle] = useState(null);

  const handleClickVendor = (event) => {
    setAnchorEle(event.currentTarget);
  };
  const handleCloseVendor = () => {
    setAnchorEle(null);
  };

  const openPopOver1 = Boolean(anchorEle);
  const id1 = openPopOver1 ? "simple-popover" : undefined;

  const handleSetTagVendor = (e, venderName) => {
    const isChecked = e.target.checked;
    let d = { vendorName: venderName.vendorName, vendorId: venderName._id };

    if (isChecked) {
     setTagVendor((prev) => [...prev, d]);
    } else {
      setTagVendor((prev) =>
        prev.filter((item) => item.vendorName !== venderName.vendorName)
      );
   }
  };

  const handleSetTagRecruiter = (e, userName) => {
    const isChecked = e.target.checked;
    let d = { userName: userName.userName, recruiterId: userName._id };

    if (isChecked) {
      setTagRecruiter((prev) => [...prev, d]);
    } else {
      setTagRecruiter((prev) =>
        prev.filter((item) => item.userName !== userName.userName)
      );
    }
  };

  const [requisition, setRequisition] = useState(defaultRequisition);
  const [spocOptions, setSpocOptions] = useState([]);

  const handleChangeDivision = (e) => {
    setDivision(e.target.value);
    setRequisition({
      ...requisition,
      divisionName: e.target.value == '' ? '' : JSON.parse(e.target.value).divisionName,
      divisionId: e.target.value == '' ? '' : JSON.parse(e.target.value)._id,
    });
  };

  const handleChangeRequisitionData = (e) => {
    const { name, value } = e.target;

    setRequisition({ ...requisition, [name]: value });
  };

  const [c_id, setCId] = useState();

  const handleChangeRequisitionClientName = (e) => {
    let tClientId = e._id;
    setClientId(e._id);
    let spoc = clientList.filter((data) => {
      if (data.clientName == e.clientName) return data;
    });
    setSpocOptions(spoc != undefined ? spoc[0].spocs : []);
    setRequisition({
      ...requisition,
      clientName: e.clientName,
      clientId: tClientId,
    });
  };

  const handleSubmit = () => {
    if (
      requisition.positionName === "" ||
      requisition.clientName === "" ||
      division == '' ||
      requisition.ctcOffered == '' ||
      requisition.tat == '' ||
      requisition.locationArray.length === 0 ||
      requisition.priority === "" ||
      requisition.hiring_type == ""
    ) {
      alert("Please fill all the required fields");
    } else {
      dispatch(postRequisition(requisition));
      setRequisition(defaultRequisition);

      handleClose1();
    }
  };

  useEffect(() => {
    dispatch(getClient());
    dispatch(getUserList());
  }, []);

  const UserList = useSelector((state) => state.clientReducer.userList);
  const vendorData = useSelector((state) => state.clientReducer.vendor);

  const options = clientList.map((x) => ({
    z: x,
    value: x.clientName,
    label: x.clientName,
  }));

  const filteredList = UserList.filter((product) => {
    let searchFullName = product.userName;
    return searchFullName.toLowerCase().includes(searchVal.toLowerCase());
  });

//   const filteredVender = vendorData.filter((product) => {
//     let searchFullName1 = product.vendorName;
//     if (searchFullName1) {
//         return searchFullName1.toLowerCase().includes(searchVender.toLowerCase());
//     } else {
//         return false;
//     }
// });



const [filteredVender, setFilteredVender] = useState([]);
const [vendors, setVendors] = useState([]);

console.log("filteredVender",filteredVender)
useEffect(() => {
  axios.get(`${URL}/bi_vender/bi_vender`)
    .then(response => {
      console.log("hgfcghc",response.data)
      setVendors(response.data);
      setFilteredVender(response.data);
    })
    .catch(error => {
      console.error('Error fetching vendors:', error);
    });
}, []);


const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  setFilteredVender(searchValue);
  const filtered = vendors.filter(vendor =>
    vendor.vendorName.toLowerCase().includes(searchValue)
  );
  setFilteredVender(filtered);
};



  return (
    <div
      className="mainpopup"
      style={{
        fontFamily: "sans-serif",
        height: "895px",
        width: "868px",
        borderRadius: "10px",
        backgroundColor: "#ECF6FF",
        border: "1px solid #CFDFED",
        margin: "-20px -24px -20px -24px",
      }}
    >
      <Dialog maxWidth={false} open={open}>
        <DialogContent>
          <PopupEditSaveRequisition />
        </DialogContent>
      </Dialog>
      <div
        className="addpadding"
        style={{
          backgroundColor: "#CEE3F6",
          color: "#155188",
          // paddingLeft: '350px',
          height: "44px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          lineHeight: "18px",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        ADD REQUISITION

      </div>
      <div

        style={{ fontFamily: "sans-serif", margin: "0px 56px 0px 56px" }}>
        <div
          className=""
          style={{
            color: "#525252",
            fontFamily: "sans-serif",
            fontSize: "12px",
            fontWeight: 500,
            lineHeight: "16px",
            display: "flex",
            marginTop: "24px",
            rowGap: "8px",
            flexDirection: "column",
          }}
        >
          <div
            className="popupcontain"
            style={{ marginLeft: "68px", display: "flex", columnGap: "16px" }}
          >
            <div
              className="popupcontain" style={{ display: "flex", columnGap: "6px" }}>
              <p style={{ marginTop: "10px" }}>Position Name *</p>
              <TextField
                className="popupcontaininput"
                size="small"
                //required={true}
                name="positionName"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.positionName}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              className="popupcontain"
              style={{
                marginLeft: "22px",
                display: "flex",
                columnGap: "17px",
                marginLeft: "50px",
              }}
            >
              <p style={{ marginTop: "10px" }}>Select Division *</p>
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                value={division}
                onChange={handleChangeDivision}
              >
                <option value="">Select</option>
                {divisionList.map((n) => (
                  <option value={JSON.stringify(n)}>{n.divisionName}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="popupcontain" style={{ display: "flex", columnGap: "54px" }}>
            <div
              className="popupcontain"
              style={{ marginLeft: "78px", display: "flex", columnGap: "6px" }}
            >
              <p style={{ marginTop: "10px", width: "75px" }}>Client Name *</p>
              <div
                className="popupcontain"
                style={{
                  width: "204px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                }}
              >
                <Select

                  options={options}
                  onChange={(g) => handleChangeRequisitionClientName(g.z)}
                />
              </div>

            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "68px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>SPOC</p>
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="spoc"
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.spoc}
                variant="outlined"
              >
                {/* {console.log("SPOC ", spocOptions)} */}
                {spocOptions.map((data) => (
                  <option value={data.spocName}>{data.spocName}</option>
                ))}
                {/* <option value="">Select Here</option> */}
              </select>
              <AddCircleOutlineOutlinedIcon
                onClick={handleOpen}
                fontSize="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "78px", display: "flex", columnGap: "55px" }}
          >
            <div
              className="popupcontain" style={{ display: "flex", columnGap: "6px" }}>
              <p style={{ marginTop: "10px" }}>Monthly CTC *</p>
              <TextField
                className="popupcontaininput"
                type="number"
                size="small"
                name="ctcOffered"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.ctcOffered}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "22px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Accomodation</p>
              {/* <TextField
                size="small"
                //required={true}
                name="accomodation"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.accomodation}
                variant="outlined"
                autoComplete="off"
              /> */}
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accomodation"
                onChange={handleChangeRequisitionData}
                value={requisition.accomodation}
                variant="outlined"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "73px", display: "flex", columnGap: "48px" }}
          >
            <div
              className="popupcontain" style={{ display: "flex", columnGap: "6px" }}>
              <p style={{ marginTop: "10px" }}>TAT (IN Days) *</p>
              <TextField
                className="popupcontaininput"
                size="small"
                //required={true}
                name="tat"
                type="number"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.tat}
                variant="outlined"
                autoComplete="off"
              />
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "60px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Priority *</p>
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="priority"
                onChange={handleChangeRequisitionData}
                value={requisition.priority}
                variant="outlined"
              >
                <option value="">Select</option>
                <option style={{ backgroundColor: "#FF4242" }} value="Burning">
                  Burning
                </option>
                <option style={{ backgroundColor: "#FF9270" }} value="Hot">
                  Hot
                </option>
                <option style={{ backgroundColor: "#FFCE70" }} value="Moderate">
                  Moderate{" "}
                </option>
              </select>
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "80px", display: "flex", columnGap: "55px" }}
          >
            <div
              className="popupcontain"
              style={{ marginLeft: "9px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Work Type</p>
              {/* <TextField
              size="small"
              //required={true}
              name="localWorker"
              style={{
                width: "204px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              onChange={handleChangeRequisitionData}
              value={requisition.localWorker}
              variant="outlined"
              autoComplete="off"
            /> */}
              <select
                className="popupcontaininput"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  height: "40px",
                  border: "1px solid #9A9A9A",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="localWorker"
                onChange={handleChangeRequisitionData}
                value={requisition.localWorker}
                variant="outlined"
              >
                <option value="Local">Local</option>
                <option value="Migrant">Migrant</option>
                <option value="LocalAndMigrant">Local and Migrant</option>
              </select>
            </div>
            <div
              className="popupcontain"
              style={{ marginLeft: "5px", display: "flex", columnGap: "16px" }}
            >
              <p style={{ marginTop: "10px" }}>Education Criteria</p>
              <TextField
                className="popupcontaininput"
                size="small"
                //required={true}
                name="educationCriteria"
                style={{
                  width: "204px",
                  borderRadius: "5px",
                  border: "1px solid #A3A3A3",
                  backgroundColor: "#ffffff",
                }}
                placeholder="Enter Here"
                onChange={handleChangeRequisitionData}
                value={requisition.educationCriteria}
                variant="outlined"
                autoComplete="off"
              />
            </div>
          </div>
          <div
            className="popupcontain"
            style={{ marginLeft: "85px", display: "flex", columnGap: "9px" }}
          >
            <p style={{ marginTop: "10px" }}>Hiring Type *</p>
            {/* <TextField
              size="small"
              //required={true}
              name="positionName"
              style={{
                width: "204px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              onChange={handleChangeRequisitionData}
              value={requisition.positionName}
              variant="outlined"
              autoComplete="off"
            /> */}
            <select
              className="popupcontaininput"
              style={{
                width: "204px",
                borderRadius: "5px",
                height: "40px",
                border: "1px solid #9A9A9A",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="hiring_type"
              onChange={handleChangeRequisitionData}
              value={requisition.hiring_type}
              variant="outlined"
            >
              <option value="">Select</option>
              <option value="Permanent">Permanent</option>
              <option value="Contractual">Contractual</option>
              <option value="International">International</option>
            </select>
          </div>
          <div
            className="popupcontain"
            style={{
              marginLeft: "8px",
              marginRight: "20px",
              display: "flex",
              columnGap: "16px",
            }}
          >
            <p style={{ marginTop: "10px" }}>Any Important Instructions</p>
            <TextField
              className="popupcontaininput"
              size="small"
              //required={true}
              name="instructions"
              style={{
                width: "571px",
                borderRadius: "5px",
                border: "1px solid #A3A3A3",
                backgroundColor: "#ffffff",
              }}
              placeholder="Enter Here"
              onChange={handleChangeRequisitionData}
              value={requisition.instructions}
              variant="outlined"
              autoComplete="off"
            />
          </div>
        </div>



        <div
          // className="popupcontain"
          style={{
            display: "flex",
            fontFamily: "sans-serif",
            justifyContent: "center",
            marginTop: "36px",
          }}
        >

          <div

            className="heightpopup"
            style={{
              width: "811px",
              height: "440px",
              backgroundColor: "#F4FAFF",
            }}
          >

            <div
              className="popupcontain"
              style={{
                color: "#525252",
                fontFamily: "sans-serif",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                margin: "0px 20px 0px 20px",
              }}
            >
              <div
                className="gapcol"
                style={{ display: "flex", columnGap: "32px" }}>
                <div

                  style={{ display: "flex", columnGap: "8px" }}>
                  <p style={{ marginTop: "15px" }}>Total Work Locations</p>
                  <p
                    style={{
                      backgroundColor: "#D3E5F4",
                      color: "#454545",
                      height: "24px",
                      width: "28px",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {requisition.locationArray.length}
                  </p>
                </div>
                <div style={{ display: "flex", columnGap: "8px" }}>
                  <p style={{ marginTop: "15px" }}>Total Positions</p>
                  <p
                    style={{
                      backgroundColor: "#D3E5F4",
                      color: "#454545",
                      height: "24px",
                      width: "28px",
                      borderRadius: "5px",
                      fontFamily: "sans-serif",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {requisition.locationArray.reduce(
                      (sum, data) => sum + parseInt(data.noOfPosition),
                      0
                    )}
                  </p>
                </div>
              </div>
              <div
                className="popupcontain"
                style={{
                  display: "flex",
                  columnGap: "16px",
                  marginTop: "20px",
                }}
              >
                <div >
                  <p>Location Name</p>
                  <TextField
                    className="popupcontaininput"
                    size="small"
                    //required={true}
                    name="locationName"
                    style={{
                      width: "204px",
                      borderRadius: "5px",
                      border: "1px solid #A3A3A3",
                      backgroundColor: "#ffffff",
                    }}
                    placeholder="Enter Here"
                    onChange={(e) => setLocationName(e.target.value)}
                    value={locationName}
                    variant="outlined"
                    autoComplete="off"
                  />
                </div>
                <div>
                  <p>No. of Position</p>
                  <TextField
                    className="popupcontaininput"
                    size="small"
                    //required={true}
                    name=""
                    type="number"
                    style={{
                      width: "122px",
                      borderRadius: "5px",
                      border: "1px solid #A3A3A3",
                      backgroundColor: "#ffffff",
                    }}
                    placeholder="Enter Here"
                    onChange={(e) => setNoOfPosition(e.target.value)}
                    value={noOfPosition}
                    variant="outlined"
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "136px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "44px",
                    justifyContent: "center",
                    border: "1px ridge",
                  }}
                >
                  <ExpandMore
                    style={{ marginTop: "4px", marginLeft: "-8px" }}
                    onClick={handleClickPop}
                  />
                  <p style={{ marginTop: "8px" }} onClick={handleClickPop}>
                    Tag Recruiters
                  </p>
                  <Popover
                    id={id}
                    open={openPopOver}
                    anchorEl={anchorEl}
                    onClose={handleClosePop}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    style={{ height: "70%", marginTop: "-5%" }}
                  >
                    <TextField
                      size="small"
                      style={{
                        width: "256px",
                        borderRadius: "5px",
                        marginTop: "12px",
                        marginLeft: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              fontSize="small"
                              style={{ color: "#767676" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchVal(e.target.value)}
                      placeholder="Search Here"
                    />
                    {filteredList.map((data, index) => (
                      <ul key={data._id}>
                        <li>
                          <Checkbox
                            // checked={tagRecruiter.includes(data.userName)}
                            checked={
                              tagRecruiter.find(
                                (item) => item.userName === data.userName
                              ) !== undefined
                            }
                            onChange={(e) => handleSetTagRecruiter(e, data)}
                          />
                          {data.userName}
                        </li>
                      </ul>
                    ))}
                  </Popover>
                </div>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "136px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "44px",
                    justifyContent: "center",
                    border: "1px ridge",
                  }}
                >
                  <ExpandMore
                    style={{ marginTop: "4px", marginLeft: "-8px" }}
                    onClick={handleClickVendor}
                  />
                  <p style={{ marginTop: "8px" }} onClick={handleClickVendor}>
                    Tag Vendor
                  </p>
                  <Popover
                    id={id1}
                    open={openPopOver1}
                    anchorEl={anchorEle}
                    onClose={handleCloseVendor}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    style={{ height: "70%", marginTop: "-5%" }}
                  >
                    <TextField
                      size="small"
                      style={{
                        width: "256px",
                        borderRadius: "5px",
                        marginTop: "12px",
                        marginLeft: "5px",
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              fontSize="small"
                              style={{ color: "#767676" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearch}
                      placeholder="Search Here"
                    />
                    {filteredVender.map((data, index) => (
                      <ul key={data._id}>
                        <li>
                          <Checkbox
                            //checked={tagVendor.includes(data.vendorName)}
                            checked={
                              tagVendor.find((item) => 
                                item.vendorName === data.vendorName
                              ) !== undefined
                            }
                            onChange={(e) => handleSetTagVendor(e, data)}
                          />
                          {data.vendorName}
                        </li>
                      </ul>
                    ))}
                  </Popover>
                </div>
                <button
                  className="displaynone"
                  style={{
                    backgroundColor: "#4594DE",
                    color: "#FFFFFF",
                    borderRadius: "100px",
                    cursor: "pointer",
                    width: "118px",
                    height: "32px",
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginTop: "44px",
                    border: "1px ridge",
                  }}
                  onClick={handleAddLocationArray}
                >
                  Add Location
                </button>
              </div>
              
            <div style={{ gap:"14px"}}>
            {tagRecruiter.length > 0 && (
                <div
                  style={{
                    marginTop: "12px",
                    fontWeight: 550,
                    fontSize: "14px",
                  }}
                >
                  Tagged Recruiters:
                  {tagRecruiter.map((data) => (
                    <p style={{ display: "inline", fontWeight: "500" }}>
                      {" "}
                      {data.userName}{" "}
                    </p>
                  ))}
                </div>
              )}
              {tagVendor.length > 0 && (
                <div
                  style={{
                    marginTop: "12px",
                    fontWeight: 550,
                    fontSize: "14px",
                  }}
                >
                  Tagged Vendors:
                  {tagVendor.map((data) => (
                    <p style={{ display: "inline", fontWeight: "500" }}>
                      {" "}
                      {data.vendorName}{" "}
                    </p>
                  ))}
                </div>
              )}
            </div>


              {/* mobile View */}
              <button
                className="displaymobile"
                style={{
                  display: 'none',
                  backgroundColor: "#4594DE",
                  color: "#FFFFFF",
                  borderRadius: "100px",
                  cursor: "pointer",
                  width: "100%",
                  height: "32px",
                  fontFamily: "sans-serif",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "16px",
                  marginTop: "15px",
                  border: "1px ridge",
                }}
                onClick={handleAddLocationArray}
              >
                Add Location
              </button>



              <div className="popscrollmain" style={{}}>
                <div className="" style={{}}>
                  <Table style={{ width: "635px", marginTop: "12px" }}>
                    <TableHead>
                      <TableRow style={{ backgroundColor: "#CEE3F6" }}>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "30%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          Location Name
                        </TableCell>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "25%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          No. of Position
                        </TableCell>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "30%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          Recruiters
                        </TableCell>
                        <TableCell
                          className="fontslide"
                          style={{
                            width: "25%",
                            color: "#525252",
                            textAlign: "start",
                            fontWeight: 550,
                          }}
                        >
                          Vendor
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>




                  <div
                    style={{
                      height: "165px",
                      overflowY: "scroll",
                      marginTop: "-8px",
                      width: "637px",
                      // marginLeft: "56px",
                      background: "#ffffff",
                    }}
                  >
                    <Table>
                      {requisition?.locationArray?.map((data, index) => (
                        <TableRow>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "30%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3%",
                            }}
                          >
                            {data.locationName}
                          </TableCell>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "25%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "9%",
                            }}
                          >
                            {data.noOfPosition}
                          </TableCell>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "30%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3.9%",
                            }}
                          >
                            {data.tagRecruiter.map((data) => (
                              <p style={{ display: "inline", fontWeight: "500" }}>
                                {" "}
                                {data.userName}{" "}
                              </p>
                            ))}
                          </TableCell>
                          <TableCell
                            className="fontperascrol"
                            style={{
                              width: "25%",
                              color: "#525252",
                              textAlign: "start",
                              paddingLeft: "3.9%",
                            }}
                          >
                            {data.tagVendor.map((data) => (
                              <p style={{ display: "inline", fontWeight: "500" }}>
                                {" "}
                                {data.vendorName}{" "}
                              </p>
                            ))}
                          </TableCell>
                          {/* <TableCell
                        style={{
                          width: "5%",
                          color: "#525252",
                          textAlign: "start",
                        }}
                      >
                        <img
                          style={{
                            width: "18px",
                            height: "18px",
                            cursor: "pointer",
                          }}
                          src={DeleteIcon}
                        // onClick={() => deleteSpoc(i)}
                        />
                      </TableCell> */}
                        </TableRow>
                      ))}
                    </Table>
                  </div>

                </div></div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "14px",
                }}
              >
                <button
                  className="savebutton"
                  style={{
                    backgroundColor: "#3B82F6",
                    color: "#EDF0F6",
                    fontFamily: "sans-serif",
                    width: "56px",
                    height: "32px",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "16px",
                    borderRadius: "5px",
                    padding: "0px 8px 0px 8px",
                    border: "1px ridge",
                    cursor: "pointer",
                  }}
                  onClick={handleSubmit}
                >
                  SAVE
                </button>
              </div>
            </div>




          </div>
        </div>
        <Modal open={open10}>
          <AddSpoc clientId={clientId} closePopup={handleClose10} />
        </Modal>
      </div>
    </div>
  );
}

export default PopupAddRequisition;
