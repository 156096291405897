import React, { useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { getClient } from "../../../../redux/action";
import './requisition.css'
const { URL } = require("../../../../config.json");


export default function AddSpoc({ clientId, closePopup }) {
  const dispacth = useDispatch();
  const [designation, setDesignation] = useState('');
  const [spocName, setSpocName] = useState('');
  const [spocEmail, setSpocEmail] = useState('');
  const [spocNumber, setSpocNumber] = useState('');

  let designationList = useSelector((state) => state.clientReducer.designation);

  const desi = designationList.map((y) => ({ value: y.name, label: y.name }));

  const addSpoc = async () => {
    if (spocName == '' || spocNumber == '' || spocEmail == '') {
      window.alert('Please Enter Required Field')
    }
    else {
      let sp = await axios.post(`${URL}clientList/inserSpocByClientId`, {
        clientId: clientId,
        spocs: {
          designation: designation,
          spocName: spocName,
          spocEmail: spocEmail,
          spocNumber: spocNumber,
        }
      })
      dispacth(getClient())
      closePopup()
    }
  }

  return (
    <div
    className="addspocmaindiv"
      style={{
        height: "355px",
        width: "425px",
        borderRadius: "10px",
        backgroundColor: "#ECF6FF",
        border: "1px solid #CFDFED",
        position: "absolute",
        left: "40%",
        top: "30%",
      }}
    >
      <div style={{ marginLeft: "16px", marginRight: "16px" }}>
        <CloseIcon
          fontSize="small"
          onClick={closePopup}
          style={{ float: "right", cursor: "pointer" }}
        />
        <p
          style={{
            color: "#155188",
            display: "flex",
            justifyContent: "center",
            fontFamily: "sans-serif",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
          }}
        >
          ADD SPOC
        </p>
        <hr />

        <div className="centerAddspoc"
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
            marginLeft: "12px",
            fontSize: "14px",
          }}
        >
          <div style={{ display: "flex", columnGap: "54px" }}>
            <p style={{ marginTop: "18px" }}>Name *</p>
            <input
              type="text"
              size="small"
              style={{
                marginTop: "10px",
                width: "226px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
                height: "35px",
              }}
              placeholder="Enter Here"
              value={spocName}
              onChange={(e) => setSpocName(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", columnGap: "42px" }}>
            <p>Email ID *</p>
            <input
              type="email"
              size="small"
              style={{
                marginTop: "4px",
                width: "226px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
                height: "35px",
              }}
              placeholder="Enter Here"
              value={spocEmail}
              onChange={(e) => setSpocEmail(e.target.value)}
            />
          </div>
          <div style={{ display: "flex", columnGap: "26px" }}>
            <p style={{ marginTop: "10px" }}>Mobile No *</p>
            <input
              type="number"
              size="small"
              style={{
                width: "226px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
                height: "35px",
              }}
              placeholder="Enter Here"
              value={spocNumber}
              onChange={(e) => setSpocNumber(e.target.value)}
            />
          </div>
          <div 
            style={{
              display: "flex",
              columnGap: "26px",
            }}
          >
            <p  className="centerAddspoc" style={{ marginTop: "10px", fontSize: "14px" }}>Designation</p>
            <div
              style={{
                width: "227px",
                height: "35px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
              }}
            >
              <Select
                options={desi}
                onChange={(g) => setDesignation(g.value)}
                placeholder="Select"
              />
            </div>
             </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              disabled={spocName == ""}
              onClick={addSpoc}
              style={{
                background: "#4594DE",
                borderRadius: "100px",
                width: "84px",
                height: "32px",
                color: "#FFFFFF",
                fontSize: "10px",
                marginTop: "10px",
                cursor: spocName ? "pointer" : "not-allowed",
                border: "1px ridge",
              }}
            >
              Add SPOC
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
