import React, { useEffect, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  getCandidate,
  getClient,
  getBillingStatus,
  addBillingStatus,
  getUserList,
  getOneTimeSoueceCand,
} from "../../../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Popover,
} from "@mui/material";

import axios from "axios";

const { URL } = require("../../../../config.json");

function ContractualOneTimeSourcing() {
  let dispatch = useDispatch();

  let candidateList = useSelector((state) => state.clientReducer.candidateList);
  let UserList = useSelector((state) => state.clientReducer.userList);
  let candidateBilling = useSelector(
    (state) => state.clientReducer.candidateBilling
  );
  let clientList = useSelector((state) => state.clientReducer.clientList);
  let oneTimeCandidate = useSelector(
    (state) => state.clientReducer.oneTimeSourceCandidate
  );

  useEffect(() => {
    dispatch(getCandidate());
    dispatch(getClient());
    dispatch(getBillingStatus());
    dispatch(getUserList());
    dispatch(getOneTimeSoueceCand());
  }, []);

  const [newContr, setNewContr] = useState([]);
  let [foundCandidate1, setFoundCandidate1] = useState(newContr);

  useEffect(() => {
    const contrCandidate = candidateBilling.filter(
      (can) => can.hiring_type === "Contractual"
    );
    let arr = [];
    clientList.forEach((obj, i) => {
      if (obj.contractual && obj.contractual.contractualOnTimeSourcingAmount) {
        contrCandidate.forEach((contr) => {
          if (contr.clientId === obj._id) {
            const newObj = { ...contr };
            // console.log("newObj", newObj);
            newObj.contractual = { ...obj.contractual };
            newObj.annualCTC =
              newObj.final_ctc || (newObj.acceptedCtc * 12).toString();
            if (arr.length == 0) {
              arr.push(newObj);
            } else {
              if (arr.find((abc) => abc._id != newObj._id)) {
                arr.push(newObj);
              }
              // newContr.find(abc => {
              //     if (abc._id != newObj._id) {
              //         newContr.push(newObj);
              //     }
              // })
            }
          }
        });
      }
      if (clientList.length == i + 1) {
        setTimeout(() => {
          setNewContr(arr);
          // setRender(!render)
          // console.log("times",arr)
        }, [1000]);
      }
    });
  }, [candidateBilling]);

  let [foundCandidate, setFoundCandidate] = useState();

  useEffect(() => {
    setFoundCandidate1(newContr);
    setFoundCandidate(oneTimeCandidate);
  }, [newContr]);

  function reqCreatedBy(id) {
    return UserList.find((u) => u && u._id == id);
  }

  function handleChangeFinalCtc(e, data, i) {
    foundCandidate1[i].annualCTC = e.target.value;
    setFoundCandidate1([...foundCandidate1]);
  }

  function handleChangeTerms(e, data, i) {
    foundCandidate1[i].contractual.contractualOnTimeSourcingAmount =
      e.target.value;
    setFoundCandidate1([...foundCandidate1]);
  }

  function handleChangeInvoiceNo(e, data, i) {
    foundCandidate1[i].invoice_no = e.target.value;
    setFoundCandidate1([...foundCandidate1]);
  }

  function handleChangeInvoiceDate(e, i) {
    foundCandidate1[i].date_of_invoice = e.target.value;
    setFoundCandidate1([...foundCandidate1]);
  }

  function saveBillingStatus(data, i) {
    console.log("data", data);
    let obj = {
      createdBy: data.createdBy,
      updatedById: localStorage.getItem("employeeId"),
      updatedByName: localStorage.getItem("employeeName"),
      dateOfSubmission:
        data.dateOfSubmission && data.dateOfSubmission.slice(0, 10),
      candidateName: data.candidateName,
      candidateId: data._id,
      clientName: data.clientName,
      clientId: data.clientId,
      positionName: data.positionName,
      RequisitionId: data.RequisitionId,
      joiningDate: data.joiningDate && data.joiningDate.slice(0, 10),
      hiring_type: data.hiring_type,
      final_ctc: data.annualCTC,
      term_type: data.contractual.contractualMarginType,
      terms: data.contractual.contractualMarginAmount,
      invoice_no: data.invoice_no,
      date_of_invoice: data.date_of_invoice,
      billing_type: "Contractual On OneTime Sourcing",
    };
    if (data.contractual.contractualMarginType == "Variable in %") {
      obj.revenue = Math.round(
        (data.annualCTC * data.contractual.contractualMarginAmount) / 100
      );
    }
    if (data.contractual.contractualMarginType == "Fixed in Rs") {
      obj.revenue = data.contractual.contractualMarginAmount;
    }
    if (obj.invoice_no == undefined) {
      alert("Please Enter Invoice Number");
    } else if (obj.date_of_invoice == undefined) {
      alert("Please Enter Invoice Date");
    } else {
      dispatch(addBillingStatus(obj));
      alert("Successfully save BillingStatus");
      setTimeout(() => {
        dispatch(getBillingStatus());
        dispatch(getOneTimeSoueceCand());
      }, 300);
      // setNewContr([...newContr]);
    }
  }

  function filter1(e) {
    let keyword = e.target.value;
    if (keyword != "") {
      const result = newContr.filter((c) => {
        return (
          (c.candidateName &&
            c.candidateName.toLowerCase().startsWith(keyword.toLowerCase())) ||
          (c.clientName &&
            c.clientName.toLowerCase().startsWith(keyword.toLowerCase())) ||
          (c.positionName &&
            c.positionName.toLowerCase().startsWith(keyword.toLowerCase()))
        );
      });
      setFoundCandidate1(result);
    } else {
      setFoundCandidate1(newContr);
    }
  }

  function filter(e) {
    let keyword = e.target.value;
    if (keyword != "") {
      const result = oneTimeCandidate.filter((c) => {
        return (
          (c.candidateName &&
            c.candidateName.toLowerCase().startsWith(keyword.toLowerCase())) ||
          (c.clientName &&
            c.clientName.toLowerCase().startsWith(keyword.toLowerCase())) ||
          (c.positionName &&
            c.positionName.toLowerCase().startsWith(keyword.toLowerCase()))
        );
      });
      setFoundCandidate(result);
    } else {
      setFoundCandidate(oneTimeCandidate);
    }
  }

  console.log("newContr==", newContr);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValUser, setSearchValUser] = useState("");
  const [searchValClient, setSearchValClient] = useState("");
  const [openPopOver, setOpenPopOver] = useState(false);
  const [openDatePopOver, setOpenDatePopOver] = useState(false);
  const [anchorElDateFilter, setAnchorElDateFilter] = useState(null);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [openClientPopOver, setOpenClientPopOver] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleClickDateFilter = (event) => {
    setAnchorElDateFilter(event.currentTarget);
    setOpenDatePopOver(true);
  };
  const handleCloseDatePop = () => {
    setAnchorElDateFilter(null);
    setOpenDatePopOver(false);
  };

  const handleClearDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  // Filter the UserList based on the search value
  const filteredUserList = useMemo(() => {
    return UserList.filter((data) => {
      return (
        data.userName &&
        data.userName.toLowerCase().includes(searchValUser.toLowerCase())
      );
    });
  }, [searchValUser, UserList]);

  const filteredClientList = useMemo(() => {
    return clientList.filter((data) => {
      return (
        data.clientName &&
        data.clientName.toLowerCase().includes(searchValClient.toLowerCase())
      );
    });
  }, [searchValClient, clientList]);

  const handleCheckboxChange = (event, userName) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedCheckboxes((prevList) => [...prevList, userName]);
    } else {
      setSelectedCheckboxes((prevList) =>
        prevList.filter((item) => item !== userName)
      );
    }
  };
  const handleClientCheckboxChange = (event, clientName) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedClients((prevList) => [...prevList, clientName]);
    } else {
      setSelectedClients((prevList) =>
        prevList.filter((item) => item !== clientName)
      );
    }
  };

  const handleClickPop = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopOver(true);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
    setOpenPopOver(false);
    setSearchValUser("");
  };
  const handleClickClientCheckbox = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenClientPopOver(true);
  };

  const handleCloseClientPop = () => {
    setAnchorEl(null);
    setOpenClientPopOver(false);
    setSearchValClient("");
  };

  const filteredData = foundCandidate1.filter((data) => {
    const joiningDate = data.joiningDate && data.joiningDate.slice(0, 10);

    const userNameFilter =
      selectedCheckboxes.length === 0 ||
      (selectedCheckboxes &&
        selectedCheckboxes.includes(
          reqCreatedBy(data.createdBy) && reqCreatedBy(data.createdBy).userName
        ));

    const clientFilter =
      selectedClients.length === 0 || selectedClients.includes(data.clientName);

    const dateFilter =
      !startDate ||
      !endDate ||
      (joiningDate >= startDate && joiningDate <= endDate);

    return userNameFilter && clientFilter && dateFilter;
  });

  let EstRev =
    filteredData &&
    filteredData.map((e) => {
      let Es = 0;
      if (e.contractual.contractualOnTimeSourcingAmountType == "Fixed in Rs") {
        Es = e.contractual.contractualOnTimeSourcingAmount;
        return Es;
      } else if (
        e.contractual.contractualOnTimeSourcingAmountType == "Variable in %"
      ) {
        Es = Math.round(
          (e.annualCTC * e.contractual.contractualOnTimeSourcingAmount) / 100
        );
        return Es;
      } else {
        return Es;
      }
    });

  let EstimateRevenue = 0;
  if (EstRev.length != 0) {
    EstimateRevenue =
      EstRev &&
      EstRev.reduce(function (previousValue, currentValue) {
        return +previousValue + +currentValue;
      });
  }
  console.log("EstRev", EstRev);

  // invoice data
  // const [foundCandidate, setFoundCandidate] = useState(permanentCandidate);
  const [selectedClientsInvoice, setSelectedClientsInvoice] = useState([]);
  const [startDateinvoice, setStartDateInvoice] = useState(null);
  const [endDateinvoice, setEndDateInvoice] = useState(null);
  const [openDatePopOverinvoice, setOpenDatePopOverInvoice] = useState(false);
  const [anchorElDateFilterinvoice, setAnchorElDateFilterInvoice] =
    useState(null);
  const [openClientPopOverinvoice, setOpenClientPopOverInvoice] =
    useState(false);
  const [searchValClientinvoice, setSearchValClientInvoice] = useState("");
  const [selectedRecruiters, setSelectedRecruiters] = useState([]);
  const [openRecruiterPopOver, setOpenRecruiterPopOver] = useState(false);
  const [searchValRecruiter, setSearchValRecruiter] = useState("");

  const filteredRecruiterList = useMemo(() => {
    return UserList.filter((data) => {
      return (
        data.userName &&
        data.userName.toLowerCase().includes(searchValRecruiter.toLowerCase())
      );
    });
  }, [searchValRecruiter, UserList]);

  const filteredClientListinvoice = useMemo(() => {
    return clientList.filter((data) => {
      return (
        data.clientName &&
        data.clientName
          .toLowerCase()
          .includes(searchValClientinvoice.toLowerCase())
      );
    });
  }, [searchValClientinvoice, clientList]);

  const handleClickClientCheckboxinvoice = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenClientPopOverInvoice(true);
  };

  const handleCloseClientPopinvoice = () => {
    setAnchorEl(null);
    setOpenClientPopOverInvoice(false);
    setSearchValClientInvoice("");
  };

  const handleClearClientFilter = () => {
    setSelectedClients([]);
  };

  const handleClientCheckboxChangeinvoice = (event, clientName) => {
    const updatedSelectedClients = event.target.checked
      ? [...selectedClientsInvoice, clientName]
      : selectedClientsInvoice.filter((name) => name !== clientName);
    setSelectedClientsInvoice(updatedSelectedClients);
  };

  const handleStartDateChangeinvoice = (event) => {
    setStartDateInvoice(event.target.value);
  };
  function reqCreatedBy(id) {
    let user = UserList.find((u) => u._id == id);
    return user == undefined ? "" : user;
  }

  const handleEndDateChangeinvoice = (event) => {
    setEndDateInvoice(event.target.value);
  };

  const handleClickDateFilterinvoice = (event) => {
    setAnchorElDateFilterInvoice(event.currentTarget);
    setOpenDatePopOverInvoice(true);
  };

  const handleCloseDatePopinvoice = () => {
    setAnchorElDateFilterInvoice(null);
    setOpenDatePopOverInvoice(false);
  };

  const handleClearDateFilterinvoice = () => {
    setStartDateInvoice(null);
    setEndDateInvoice(null);
  };

  const handleClickRecruiterCheckbox = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenRecruiterPopOver(true);
  };

  const handleCloseRecruiterPop = () => {
    setAnchorEl(null);
    setOpenRecruiterPopOver(false);
    setSearchValRecruiter("");
  };

  const handleRecruiterCheckboxChange = (event, recruiterName) => {
    const updatedSelectedRecruiters = event.target.checked
      ? [...selectedRecruiters, recruiterName]
      : selectedRecruiters.filter((name) => name !== recruiterName);
    setSelectedRecruiters(updatedSelectedRecruiters);
  };

  const filteredDatainvoice =
    foundCandidate &&
    foundCandidate.filter((data) => {
      const joiningDate = data.joiningDate && data.joiningDate.slice(0, 10);

      const userNameFilter =
        selectedRecruiters.length === 0 ||
        (selectedRecruiters &&
          selectedRecruiters.includes(
            reqCreatedBy(data.createdBy) &&
            reqCreatedBy(data.createdBy).userName
          ));

      const clientFilter =
        selectedClientsInvoice.length === 0 ||
        selectedClientsInvoice.includes(data.clientName);

      const dateFilter =
        !startDateinvoice ||
        !endDateinvoice ||
        (joiningDate >= startDateinvoice && joiningDate <= endDateinvoice);

      return userNameFilter && clientFilter && dateFilter;
    });

  let rev =
    filteredDatainvoice &&
    filteredDatainvoice.map((j) => {
      return j.revenue;
    });

  let sum = 0;
  if (rev && rev.length != 0) {
    sum =
      rev &&
      rev.reduce(function (previousValue, currentValue) {
        return +previousValue + +currentValue;
      });
  }

  const handleClearRecruiterFilter = () => {
    setSelectedCheckboxes([]);
    // setClearRecruiterFilter(false);
  };
  const handleClearClientFilterinvoice = () => {
    setSelectedClientsInvoice([]);
  };
  const handleClearRecruiterFilterinvoice = () => {
    setSelectedRecruiters([]); // Clear selected recruiters
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedStatusValue, setSelectedStatusValue] = useState("active");
  const [selectedId, setSelectedId] = useState(null);
  const [inputError, setInputError] = useState(false);

  const handleOpenDialog = (id) => {
    setSelectedId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setInputValue("");
  };

  const saveBillingStatusdrop = () => {
    const postData = {
      _id: selectedId,
      dropAfterJoin_Reason: inputValue,
      status: "false",
    };

    axios
      .put(`${URL}bi_candidate/dropCandidateAfterJoin`, postData)
      .then((response) => {
        setOpenDialog(false);

        setSelectedId(null);
        //        dispatch(getCandidate());
        //        dispatch(getCandidate());

        //  dispatch(getBillingStatus());
        //  dispatch(getUserList());
        //  dispatch(getPermanentCand());
        dispatch(getCandidate());
        dispatch(getClient());
        dispatch(getBillingStatus());
        dispatch(getUserList());
        dispatch(getOneTimeSoueceCand());
        alert(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div style={{ fontFamily: "sans-serif", width: "100%", height: "100%" }}>
      <div
        className="displayjustify"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <div className="inputtextmobilediv">
          <TextField
            className="inputtextmobile"
            size="small"
            style={{
              width: "210px",
              borderRadius: "6px",
              background: "#FFFFFF",
            }}
            placeholder="Search Here"
            // value={clientName}
            onChange={filter1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="medium" style={{ color: "#767676" }} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </div>
      </div>
      <div className="clinettablelist" style={{}}>
        <div className="clinettablelistdivtwo" style={{}}>
          <div style={{ width: "100%", fontFamily: "sans-serif" }}>
            <Table>
              <TableHead>
                <TableRow
                  className="backgroundchange"
                  style={{ backgroundColor: "#F5FBFE" }}
                >
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5.5%",
                      fontSize: "13.5px",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      // color: "#272945",
                      width: "9%",
                      fontSize: "13.5px",
                      padding: "5px 5px",

                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        // width: "8%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div style={{}}>Dt. of Joining</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickDateFilter}
                          checked={startDate || endDate}
                        />
                        {startDate || endDate ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "2px",
                              position: "absolute",
                              left: "1px",
                            }}
                            onClick={handleClearDateFilter}
                          />
                        ) : null}
                        <Popover
                          open={openDatePopOver}
                          anchorEl={anchorElDateFilter}
                          onClose={handleCloseDatePop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <div>
                            <div>
                              {" "}
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                  margin: "0",
                                  paddingBottom: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                Select Date
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ display: "block" }}>
                                <p
                                  style={{
                                    margin: "0",
                                    paddingLeft: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  From Date
                                </p>
                                <input
                                  type="date"
                                  value={startDate}
                                  onChange={handleStartDateChange}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 0 5px 5px",
                                  }}
                                />
                              </div>
                              <p style={{ margin: "12px 5px 0" }}> -</p>
                              <div>
                                <p
                                  style={{
                                    margin: "0",
                                    textAlign: "start",
                                    fontSize: "10px",
                                  }}
                                >
                                  To Date
                                </p>
                                <input
                                  type="date"
                                  value={endDate}
                                  onChange={handleEndDateChange}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 5px 5px 0",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      width: "6%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                      // display: "flex",
                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "7%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "start",
                        marginLeft: "10px",
                      }}
                    >
                      <div style={{}}>Client</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickClientCheckbox}
                          checked={selectedClients.length > 0}
                          onChange={handleClickClientCheckbox}
                        />
                        {selectedClients.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "2px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearClientFilter}
                          />
                        ) : null}
                        <Popover
                          open={openClientPopOver}
                          anchorEl={anchorEl}
                          onClose={handleCloseClientPop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "700px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchValClient(e.target.value)}
                            placeholder="Search Here"
                          />
                          {filteredClientList.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Clients found with this name
                            </p>
                          ) : (
                            filteredClientList.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedClients.includes(
                                      data.clientName
                                    )}
                                    onChange={(event) =>
                                      handleClientCheckboxChange(
                                        event,
                                        data.clientName
                                      )
                                    }
                                  />
                                  {data.clientName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "center",
                      width: "8%",
                      paddingLeft: "10px",
                      fontSize: "13.5px",
                    }}
                  >
                    Candidate
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      fontSize: "13.5px",
                      paddingLeft: "15px",
                    }}
                  >
                    Position Name
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      width: "5%",
                      padding: "5px 5px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        width: "5%",
                        fontSize: "13.5px",
                        display: "flex",
                      }}
                    >
                      <div style={{ textAlign: "center", marginLeft: "5px" }}>
                        Recruiter
                      </div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickPop}
                          checked={selectedCheckboxes.length > 0}
                          onChange={handleClickPop}
                        />
                        {selectedCheckboxes.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearRecruiterFilter}
                          />
                        ) : null}

                        <Popover
                          // id={id}
                          open={openPopOver}
                          anchorEl={anchorEl}
                          onClose={handleClosePop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "1000px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchValUser(e.target.value)}
                            placeholder="Search Here"
                          />
                          {filteredUserList.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Recruiters found with this name
                            </p>
                          ) : (
                            filteredUserList.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedCheckboxes.includes(
                                      data.userName
                                    )}
                                    onChange={(event) =>
                                      handleCheckboxChange(event, data.userName)
                                    }
                                  />
                                  {data.userName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                      textAlign: "end",
                      paddingRight: "5px",
                    }}
                  >
                    Final CTC(Annual)
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "end",
                      width: "7%",
                      fontSize: "13.5px",
                    }}
                  >
                    Terms
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "8%",
                      fontSize: "13.5px",
                      paddingLeft: "25px",
                    }}
                  >
                    Revenue
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      // textAlign: "end",
                      width: "10%",
                      fontSize: "13.5px",
                      paddingLeft: "5px",
                    }}
                  >
                    Invoice Number
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "9%",
                      fontSize: "13.5px",
                    }}
                  >
                    Dt. of Invoice
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                    }}
                  >
                    <select
                      style={{
                        width: "75px",
                        fontSize: "15px",
                        height: "25px",
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontWeight: "500",
                      }}
                      value={selectedStatusValue}
                      onChange={(e) => setSelectedStatusValue(e.target.value)}
                    >
                      <option
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          lineHeight: "150%",
                        }}
                        value="active"
                      >
                        Active
                      </option>
                      <option
                        style={{
                          fontSize: "14px",
                          fontWeight: "600",
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          lineHeight: "150%",
                        }}
                        value="inactive"
                      >
                        Inactive
                      </option>
                    </select>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      fontSize: "13.5px",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </div>

          <div
            className="heightscrollsecond"
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "12px",
              width: "100%",
              height: "342px",
              overflowY: "scroll",
            }}
          >
            {filteredData &&
              filteredData
                .filter((data) => {
                  return (
                    (selectedStatusValue === "active" &&
                      data.status === "true") ||
                    (selectedStatusValue === "inactive" &&
                      data.status === "false")
                  );
                })
                .map((data, i) => {
                  return (
                    <TableRow>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "6%",
                          fontSize: "12px",
                        }}
                      >
                        {i + 1}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "9%",
                          fontSize: "12px",
                          //   paddingLeft: "1.7%",
                        }}
                      >
                        {data.joiningDate && data.joiningDate.slice(0, 10)}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "12%",
                          fontSize: "12px",
                          // paddingLeft: "1.2%",
                        }}
                      >
                        {data.clientName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          // paddingLeft: "1.6%",
                          fontSize: "12px",
                        }}
                      >
                        {data.candidateName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                          paddingLeft: "1.2%",
                        }}
                      >
                        {data.positionName}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                          paddingLeft: "1.4%",
                        }}
                      >
                        {reqCreatedBy(data.createdBy) &&
                          reqCreatedBy(data.createdBy).userName}
                      </TableCell>

                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "11%",
                          fontSize: "12px",
                          paddingLeft: "1.6%",
                        }}
                      >
                        <input
                          type="number"
                          style={{
                            width: "94px",
                            borderRadius: "5px",
                            background: "#FFFFFF",
                            border: "1px solid #9A9A9A",
                            height: "30px",
                          }}
                          placeholder="Enter Here"
                          name="final_ctc"
                          value={data.annualCTC}
                          onChange={(event) =>
                            handleChangeFinalCtc(event, data, i)
                          }
                        />
                      </TableCell>
                      {/* } */}
                      {/* {editTerms ? ( */}
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "6%",
                          fontSize: "12px",
                          paddingLeft: "1.4%",
                        }}
                      >
                        <div className="fontpera" style={{ display: "flex" }}>
                          {data.contractual &&
                            data.contractual
                              .contractualOnTimeSourcingAmountType ==
                            "Variable in %" ? (
                            <div style={{ display: "flex" }}>
                              <PercentIcon className="icondoler" />
                              <input
                                type="number"
                                style={{
                                  width: "56px",
                                  borderRadius: "5px",
                                  background: "#FFFFFF",
                                  border: "1px solid #9A9A9A",
                                  height: "30px",
                                }}
                                placeholder="Enter"
                                name="terms"
                                value={
                                  data.contractual
                                    ? data.contractual
                                      .contractualOnTimeSourcingAmount
                                    : 0
                                }
                                onChange={(event) =>
                                  handleChangeTerms(event, data, i)
                                }
                              />
                            </div>
                          ) : (
                            <div
                              className="fontpera"
                              style={{ display: "flex" }}
                            >
                              <CurrencyRupeeIcon className="icondoler" />
                              <input
                                type="number"
                                style={{
                                  width: "56px",
                                  borderRadius: "5px",
                                  background: "#FFFFFF",
                                  border: "1px solid #9A9A9A",
                                  height: "30px",
                                }}
                                placeholder="Enter"
                                name="terms"
                                value={
                                  data.contractual
                                    ? data.contractual
                                      .contractualOnTimeSourcingAmount
                                    : 0
                                }
                                onChange={(event) =>
                                  handleChangeTerms(event, data, i)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </TableCell>
                      {/* ) : */}
                      {/* } */}
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "5%",
                          fontSize: "12px",
                          paddingLeft: "1.2%",
                        }}
                      >
                        {data.contractual &&
                          data.contractual.contractualOnTimeSourcingAmountType ==
                          "Variable in %" ? (
                          <input
                            type="number"
                            style={{
                              width: "74px",
                              borderRadius: "5px",
                              background: "#FFFFFF",
                              border: "1px solid #9A9A9A",
                              height: "30px",
                            }}
                            placeholder="Enter Here"
                            name="revenue"
                            value={
                              Math.round(
                                data.annualCTC *
                                data.contractual
                                  .contractualOnTimeSourcingAmount
                              ) / 100
                            }
                          />
                        ) : (
                          <input
                            type="number"
                            style={{
                              width: "74px",
                              borderRadius: "5px",
                              background: "#FFFFFF",
                              border: "1px solid #9A9A9A",
                              height: "30px",
                            }}
                            placeholder="Enter Here"
                            name="revenue"
                            value={
                              data.contractual
                                ? data.contractual
                                  .contractualOnTimeSourcingAmount
                                : 0
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                          //   paddingLeft: "1px",
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            width: "90px",
                            borderRadius: "5px",
                            background: "#FFFFFF",
                            border: "1px solid #9A9A9A",
                            height: "30px",
                          }}
                          placeholder="Enter Here"
                          name="invoice_no"
                          value={data.invoice_no}
                          onChange={(event) =>
                            handleChangeInvoiceNo(event, data, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "9%",
                          fontSize: "12px",
                          paddingLeft: "8px",
                        }}
                      >
                        <input
                          type="date"
                          style={{
                            width: "100px",
                            borderRadius: "5px",
                            background: "#FFFFFF",
                            border: "1px solid #9A9A9A",
                            height: "30px",
                          }}
                          placeholder="Enter Here"
                          name="date_of_invoice"
                          value={data.date_of_invoice}
                          onChange={(event) =>
                            handleChangeInvoiceDate(event, i)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "6%",
                          fontSize: "12px",
                          paddingLeft: "8px",
                          padding: "5px 5px",
                        }}
                      >
                        <div>
                          <button
                            onClick={() => handleOpenDialog(data._id)}
                            style={{
                              backgroundColor: "#4594DE",
                              width: "52px",
                              height: "32px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#FFFFFF",
                              borderRadius: "6px",
                              border: "1px solid #3B82F6",
                              cursor: "pointer",
                              visibility:
                                data.status === "false" ? "hidden" : "",
                            }}
                            disabled={localStorage.getItem('roleName') == 'Auditor'}
                          >
                            Drop
                          </button>
                        </div>

                        {/* <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>Enter Valid Reason</DialogContent>
                            <DialogContent>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setDropReason(e.target.value);
                                }}
                              />
                            </DialogContent>

                            <DialogActions>
                              <button
                               onClick={handleClose}
                               >
                                Cancel
                                </button>
                              <button
                               onClick={() => confirmDrop(data)}
                               >
                                Drop
                              </button>
                            </DialogActions>
                          </Dialog> */}
                      </TableCell>
                      <TableCell
                        className="fontpera"
                        style={{
                          color: "#272945",
                          textAlign: "start",
                          width: "10%",
                          fontSize: "12px",
                        }}
                      >
                        <button
                          className="fontpera"
                          style={{
                            width: "52px",
                            height: "32px",
                            // backgroundColor: "#4594DE",
                            borderRadius: "5px",
                            color: "#EDF0F6",
                            fontFamily: "sans-serif",
                            fontWeight: 500,
                            fontSize: "15px",
                            border: "1px ridge",
                            backgroundColor:
                              data.status === "false" ? "#CCCCCC" : "#4594DE",
                            cursor:
                              data.status === "false"
                                ? "not-allowed"
                                : "pointer",
                          }}
                          title={
                            data.status === "false"
                              ? "This User is Drop"
                              : undefined
                          }
                          onClick={() => saveBillingStatus(data, i)}
                          disabled={data.status === "false" || localStorage.getItem('roleName') == 'Auditor'}
                        >
                          Save
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </div>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogContent style={{ paddingBottom: "0" }}>
              Enter Valid Reason
            </DialogContent>
            <DialogContent>
              <TextField
                style={{ paddingBottom: "20px" }}
                label="Enter Valid Reason"
                variant="outlined"
                fullWidth
                value={inputValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setInputValue(newValue);

                  // Validate the input
                  if (newValue.trim() === "" || !isNaN(newValue)) {
                    setInputError(true);
                  } else {
                    setInputError(false);
                  }
                }}
                error={inputError}
                helperText={
                  inputError ? "Text cannot be empty or a number" : ""
                }
              />
              <Button
                style={{ float: "right" }}
                variant="contained"
                color="primary"
                onClick={saveBillingStatusdrop}
                disabled={inputError}
              >
                Save
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      <div className="textfont" style={{ margin: "20px 0px", float: "right" }}>
        Estimated Revenue :{" "}
        {EstimateRevenue &&
          (EstimateRevenue / 100000).toFixed(2) + " " + "lakh"}
      </div>
      <div>
        {/* <p>Total Candidate: {filteredData.length}</p> */}
        {selectedStatusValue === "active" ? (
          <p>
            Total Active Candidates:{" "}
            {filteredData.filter((data) => data.status === "true").length}
          </p>
        ) : (
          <p>
            Total Inactive Candidates:{" "}
            {filteredData.filter((data) => data.status === "false").length}
          </p>
        )}
      </div>
      <div
        className="invoicedisplay"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div className="invoicegenerated " style={{ margin: "20px 0px" }}>
          Invoice Generated Candidate's
        </div>
        <div
          className="justifycontenttt"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <div className="inputtextmobilediv">
            <TextField
              className="inputtextmobile"
              size="small"
              style={{
                width: "210px",
                borderRadius: "6px",
                background: "#FFFFFF",
              }}
              placeholder="Search Here"
              // value={clientName}
              onChange={filter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      fontSize="medium"
                      style={{ color: "#767676" }}
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </div>
        </div>
      </div>
      <div className="clinettablelist" style={{}}>
        <div className="clinettablelistdivtwo" style={{}}>
          <div>
            <Table>
              <TableHead>
                <TableRow
                  className="backgroundchange"
                  style={{ backgroundColor: "#F5FBFE" }}
                >
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "center",
                      width: "6%",
                      fontSize: "13.5px",
                    }}
                  >
                    S. No.
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "9%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        // width: "8%",
                        fontSize: "13.5px",
                        // padding: "5px 5px",
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div style={{}}>Dt. of Joining</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickDateFilterinvoice}
                          checked={startDateinvoice || endDateinvoice}
                        />
                        {startDateinvoice || endDateinvoice ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "2px",
                              position: "absolute",
                              left: "1px",
                            }}
                            onClick={handleClearDateFilterinvoice}
                          />
                        ) : null}
                        <Popover
                          open={openDatePopOverinvoice}
                          anchorEl={anchorElDateFilterinvoice}
                          onClose={handleCloseDatePopinvoice}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <div>
                            <div>
                              {" "}
                              <p
                                style={{
                                  textAlign: "center",
                                  fontWeight: "500",
                                  margin: "0",
                                  paddingBottom: "5px",
                                  fontSize: "13px",
                                }}
                              >
                                Select Date
                              </p>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ display: "block" }}>
                                <p
                                  style={{
                                    margin: "0",
                                    paddingLeft: "5px",
                                    fontSize: "10px",
                                  }}
                                >
                                  From Date
                                </p>
                                <input
                                  type="date"
                                  value={startDateinvoice}
                                  onChange={handleStartDateChangeinvoice}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 0 5px 5px",
                                  }}
                                />
                              </div>
                              <p style={{ margin: "12px 5px 0" }}> -</p>
                              <div>
                                <p
                                  style={{
                                    margin: "0",
                                    textAlign: "start",
                                    fontSize: "10px",
                                  }}
                                >
                                  To Date
                                </p>
                                <input
                                  type="date"
                                  value={endDateinvoice}
                                  onChange={handleEndDateChangeinvoice}
                                  max={new Date().toISOString().split("T")[0]}
                                  style={{
                                    width: "120px",
                                    margin: "5px 5px 5px 0",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      width: "10%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        fontSize: "13.5px",
                        display: "flex",
                        justifyContent: "",
                      }}
                    >
                      <div style={{ paddingLeft: "12px" }}>Client</div>

                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickClientCheckboxinvoice}
                        />
                        {selectedClientsInvoice.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "2px",
                            }}
                            onClick={handleClearClientFilterinvoice}
                          />
                        ) : null}
                        <Popover
                          open={openClientPopOverinvoice}
                          anchorEl={anchorEl}
                          onClose={handleCloseClientPopinvoice}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "700px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              setSearchValClientInvoice(e.target.value)
                            }
                            placeholder="Search Here"
                          />
                          {filteredClientListinvoice.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Clients found with this name
                            </p>
                          ) : (
                            filteredClientListinvoice.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedClientsInvoice.includes(
                                      data.clientName
                                    )}
                                    onChange={(event) =>
                                      handleClientCheckboxChangeinvoice(
                                        event,
                                        data.clientName
                                      )
                                    }
                                  />
                                  {data.clientName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      // paddingLeft: '24px',
                      fontSize: "13.5px",
                    }}
                  >
                    Candidate
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      fontSize: "13.5px",
                    }}
                  >
                    Position Name
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "10%",
                      fontSize: "13.5px",
                      padding: "5px 5px",
                    }}
                  >
                    <div
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        fontSize: "13.5px",
                        display: "flex",
                      }}
                    >
                      <div style={{ textAlign: "center", marginLeft: "5px" }}>
                        Recruiter
                      </div>
                      <div
                        style={{
                          color: "black",
                          cursor: "pointer",
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          position: "relative",
                        }}
                      >
                        <FilterListIcon
                          style={{
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "5px",
                          }}
                          onClick={handleClickRecruiterCheckbox}
                        />
                        {selectedRecruiters.length > 0 ? (
                          <FilterListOffIcon
                            style={{
                              fontSize: "14px",
                              marginTop: "5px",
                              marginLeft: "5px",
                              cursor: "pointer",
                              position: "absolute",
                              left: "1px",
                            }}
                            onClick={handleClearRecruiterFilterinvoice}
                          />
                        ) : null}
                        <Popover
                          open={openRecruiterPopOver}
                          anchorEl={anchorEl}
                          onClose={handleCloseRecruiterPop}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          style={{ height: "400px", maxWidth: "1000px" }}
                        >
                          <TextField
                            size="small"
                            style={{
                              width: "256px",
                              borderRadius: "5px",
                              marginTop: "12px",
                              marginLeft: "5px",
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    fontSize="small"
                                    style={{ color: "#767676" }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              setSearchValRecruiter(e.target.value)
                            }
                            placeholder="Search Here"
                          />
                          {filteredRecruiterList.length === 0 ? (
                            <p
                              style={{ fontSize: "12px", textAlign: "center" }}
                            >
                              No Recruiters found with this name
                            </p>
                          ) : (
                            filteredRecruiterList.map((data, index) => (
                              <ul
                                key={data._id}
                                style={{
                                  marginTop: "1px",
                                  marginBottom: "1px",
                                }}
                              >
                                <li>
                                  <Checkbox
                                    checked={selectedRecruiters.includes(
                                      data.userName
                                    )}
                                    onChange={(event) =>
                                      handleRecruiterCheckboxChange(
                                        event,
                                        data.userName
                                      )
                                    }
                                  />
                                  {data.userName}
                                </li>
                              </ul>
                            ))
                          )}
                        </Popover>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "11%",
                      fontSize: "13.5px",
                    }}
                  >
                    Final CTC(Annual)
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                    }}
                  >
                    Terms
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "5%",
                      fontSize: "13.5px",
                    }}
                  >
                    Revenue
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      // textAlign: "end",
                      width: "10%",
                      fontSize: "13.5px",
                      paddingLeft: "1.6%",
                    }}
                  >
                    Invoice Number
                  </TableCell>
                  <TableCell
                    className="textfont"
                    style={{
                      color: "#272945",
                      textAlign: "start",
                      width: "9%",
                      fontSize: "13.5px",
                    }}
                  >
                    Dt. of Invoice
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div
              className="heightscrollsecond"
              style={{
                rowGap: "12px",
                width: "100%",
                height: "342px",
                overflowY: "scroll",
              }}
            >
              {filteredDatainvoice &&
                filteredDatainvoice.map((data, i) => (
                  <TableRow>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        width: "6%",
                        fontSize: "12px",
                      }}
                    >
                      {i + 1}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "center",
                        width: "9%",
                        fontSize: "12px",
                        //   paddingLeft: "1.7%",
                      }}
                    >
                      {data.joiningDate && data.joiningDate.slice(0, 10)}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        //   paddingLeft: "1.2%",
                      }}
                    >
                      {data.clientName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        // paddingLeft: "1.6%",
                        fontSize: "12px",
                      }}
                    >
                      {data.candidateName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        paddingLeft: "1.7%",
                      }}
                    >
                      {data.positionName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        paddingLeft: "1.4%",
                      }}
                    >
                      {reqCreatedBy(data.createdBy) &&
                        reqCreatedBy(data.createdBy).userName}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "11%",
                        fontSize: "12px",
                        paddingLeft: "2.9%",
                      }}
                    >
                      {data.final_ctc}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "5%",
                        fontSize: "12px",
                        paddingLeft: "0.8%",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {data.term_type == "Variable in %" ? (
                          <PercentIcon className="icondoler" fontSize="small" />
                        ) : (
                          <CurrencyRupeeIcon
                            className="icondoler"
                            fontSize="small"
                          />
                        )}
                        {data.terms}
                      </div>
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "5%",
                        fontSize: "12px",
                        paddingLeft: "2.2%",
                      }}
                    >
                      {data.revenue}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "10%",
                        fontSize: "12px",
                        paddingLeft: "45px",
                      }}
                    >
                      {data.invoice_no}
                    </TableCell>
                    <TableCell
                      className="fontpera"
                      style={{
                        color: "#272945",
                        textAlign: "start",
                        width: "9%",
                        fontSize: "12px",
                        paddingLeft: "30px",
                      }}
                    >
                      {data.date_of_invoice}
                    </TableCell>
                  </TableRow>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <p className="textfont" style={{ float: "right" }}>
          {/* Total Revenue : { sum && (sum/100000).toFixed(2)  +" " +"lakh"} */}
          Total Revenue : {sum}
        </p>
      </div>
      <div>
        <p>
          Total Invoice Data:{" "}
          {filteredDatainvoice && filteredDatainvoice.length}
        </p>
      </div>
    </div>
  );
}

export default ContractualOneTimeSourcing;
