

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements
  
} from "react-router-dom";
import Login from "./components/Login"
import UserClient from './components/pages/IpsBussiness/UserAndClientIndex'
// ideally this would be an API call to server to get logged in user data

import UserList from './components/pages/IpsBussiness/UserList/UserList'
import ClientList from './components/pages/IpsBussiness/ClientList/ClientList'
import Requisition from './components/pages/IpsBussiness/Requisition/Requisition'
import Candidate from './components/pages/IpsBussiness/Candidate/Candidate'
import CandidateStatus from './components/pages/IpsBussiness/CandidateStatus/CandidateStatus'
import BillingStatus from "./components/pages/IpsBussiness/BillingStatus/BillingStatus";

import Loading from "./loadingpage";
import Avter from "./components/pages/IpsBussiness/Reports/Reports";
import ClientCandidateStatus from "./components/pages/IpsBussiness/Client_Canditate_Status/ClientCandidateStatus";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Loading/>} >
      <Route path="/" element={<UserClient />} >
        <Route path="UserList" element={<UserList />} />
        <Route path="ClientList" element={<ClientList />} />
        <Route path="Requisition" element={<Requisition />} />
        <Route path="Candidate" element={<Candidate />} />
        <Route path="CandidateStatus" element={<CandidateStatus />} />
        <Route path="CandidateBilling" element={<BillingStatus />} />
        <Route path="Clientcanditatestatus" element={<ClientCandidateStatus />} />
        <Route path="Reports" element={<Avter />} />
      </Route>
      </Route>
      <Route path="/login" element={<Login />} >
    
      </Route>
    </Route>
  )
);
