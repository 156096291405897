export const CLIENT_LIST = 'CLIENT_LIST'
export const SET_CLIENT_LIST = 'SET_CLIENT_LIST'
export const ADDROLE = 'ADDROLE'
export const SETADDROLE = 'SETADDROLE'

export const ADDBRANCH = 'ADDBRANCH'
export const SETADDBRANCH = 'SETADDBRANCH'

export const ADDTYPEHIRING = 'ADDTYPEHIRING'
export const SETADDTYPEHIRING = 'SETADDTYPEHIRING'

export const ADDDIVISION = 'ADDDIVISION'
export const SETADDDIVISION = 'SETADDDIVISION'
export const GET_ROLE = 'GET_ROLE'
export const SET_ROLE = 'SET_ROLE'

export const GET_BRANCH = 'GET_BRANCH'
export const SET_BRANCH = 'SET_BRANCH'

export const GET_TYPE_HIRING = 'GET_TYPE_HIRING'
export const SET_TYPE_HIRING = 'SET_TYPE_HIRING'
export const GET_DIVISION = 'GET_DIVISION'
export const SET_DIVISION = 'SET_DIVISION'
export const DELETE_ROLE = 'DELETE_ROLE'
export const DELETE_BRANCH = 'DELETE_BRANCH'
export const DELETE_TYPE_HIRING = 'DELETE_TYPE_HIRING'
export const DELETE_DIVISION = 'DELETE_DIVISION'
export const DELETE_COMPANY = 'DELETE_COMPANY'
export const GET_USER_LIST = 'GET_USER_LIST'
export const SET_USER_LIST = 'SET_USER_LIST'
export const ADDUSER = 'ADDUSER'
export const SETADDUSER = 'SETADDUSER'
export const GETEMPLOYEELIST = 'GETEMPLOYEELIST'
export const SETEMPLOYEELIST = 'SETEMPLOYEELIST'
export const GET_COMPANY = 'GET_COMPANY'
export const SET_COMPANY = 'SET_COMPANY'

export const ADD_CLIENT = 'ADD_CLIENT'

export const GET_DESIGNATION = 'GET_DESIGNATION'
export const SET_DESIGNATION = 'SET_DESIGNATION'
// candidate
export const GETALLCANDIDATE = 'GETALLCANDIDATE'
export const SETALLCANDIDATE = 'SETALLCANDIDATE'
export const GET_CANDIDATE = 'GET_CANDIDATE'
export const SET_CANDIDATE = 'SET_CANDIDATE'
export const ADD_CANDIDATE = 'ADD_CANDIDATE'
export const UPDATE_CANDIDATE = 'UPDATE_CANDIDATE'

//Requisition

export const GET_REQUISITION_BY_TAG = "GET_REQUISITION_BY_TAG"
export const SET_REQUISITION_BY_TAG = "SET_REQUISITION_BY_TAG"
export const POST_REQUISITION = "POST_REQUISITION"
export const GET_REQUISITION = "GET_REQUISITION"
export const PUT_REQUISITION = "PUT_REQUISITION"
export const SET_REQUISITION = "SET_REQUISITION"

export const ADD_COMPANY = 'ADD_COMPANY'
export const ADD_BILLING_STATUS = 'ADD_BILLING_STATUS'
export const GET_BILLING_STATUS = 'GET_BILLING_STATUS'
export const SET_BILLING_STATUS = 'SET_BILLING_STATUS'
export const GSTOKEN = "GSTOKEN"

export const GET_CAND_FOR_REVENUE = 'GET_CAND_FOR_REVENUE'
export const SET_CAND_FOR_REVENUE = 'SET_CAND_FOR_REVENUE'

export const GET_ONE_TIME_SOURCE_CANDIDATE = 'GET_ONE_TIME_SOURCE_CANDIDATE'
export const SET_ONE_TIME_SOURCE_CANDIDATE = 'SET_ONE_TIME_SOURCE_CANDIDATE'

export const GET_PERMANENT_CAND_REVENUE = 'GET_PERMANENT_CAND_REVENUE'
export const SET_PERMANENT_CAND_REVENUE = 'SET_PERMANENT_CAND_REVENUE'

export const GET_BRANCHBYCLIENT = 'GET_BRANCHBYCLIENT'
export const SET_BRANCHBYCLIENT = 'SET_BRANCHBYCLIENT'

export const ADD_VENDER = 'ADD_VENDER'
export const GET_VENDER = 'GET_VENDER'
export const SET_VENDER = 'SET_VENDER'

export const GET_CLIENTBY_COMPANY='GET_CLIENTBY_COMPANY'
export const SET_CLIENTBY_COMPANY='SET_CLIENTBY_COMPANY'

export const GET_ALL_CLIENTBY_COMPANY='GET_ALL_CLIENTBY_COMPANY'
export const SET_ALL_CLIENTBY_COMPANY='SET_ALL_CLIENTBY_COMPANY'