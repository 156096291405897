import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";

import CloseIcon from "@mui/icons-material/Close";

import PopupSaveCandidate from './PopupSaveCandidate'
import { useDispatch, useSelector } from 'react-redux'
import { getCandidate, addCandidate, getAllCandidate } from '../../../../redux/action';
import * as XLSX from 'xlsx';
import BulkCandidate from './BulkAddCandidate'
import axios from "axios";
import { Button, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import readXlsxFile from 'read-excel-file';

const { URL } = require("../../../../config.json");


function PopupAddCandidate({ reqData, closePopup }) {
  console.log("_id reqData", reqData);
  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(getCandidate())
    //dispatch(getRequisition())
  }, [])

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [candidateName, setCandidateName] = useState('')
  const [contactNo, setContactNo] = useState('')
  const [emailId, setEmailId] = useState()
  const [sourceofresume, setSourceofresume] = useState('');
  const [adhaarNo, setAdhaarNo] = useState()
  const [dateOfSubmission, setDateOfSubmission] = useState(new Date());
  const [transfer, setTrasf] = useState()
  const [render, setRender] = useState(false)
  let [bulkData, setBulkData] = useState([])
  const [sourceOfResumes, setSourceOfResumes] = useState([]);
  const [selectedSourceOfResume, setSelectedSourceOfResume] = useState('');
  const [selectedSourceOfResumeId, setSelectedSourceOfResumeId] = useState('');
  // const [sourceOfResumes, setSourceOfResumes] = useState([]);
  // const [selectedSourceOfResume, setSelectedSourceOfResume] = useState('');
  // const [selectedSourceOfResumeId, setSelectedSourceOfResumeId] = useState('');
  const [openVendorDialog, setOpenVendorDialog] = useState(false);
  const [openReferenceDialog, setOpenReferenceDialog] = useState(false);


  function formatDateFordateOfJoining(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }


  useEffect(() => {
    axios.get(`${URL}bi_sourceOfResume/bi_sourceOfResume`)
      .then((response) => {
        setSourceOfResumes(response.data);
      })
      .catch((error) => {
        console.error('Error fetching sourceOfResume data:', error);
      });
  }, []);

  // const handleSourceOfResumeChange = (event) => {
  //   const selectedOption = sourceOfResumes.find((item) => item.sourceOfResume === event.target.value);

  //   if (selectedOption) {
  //     setSelectedSourceOfResume(event.target.value);
  //     setSelectedSourceOfResumeId(selectedOption._id);
  //   } else {
  //     setSelectedSourceOfResume('');
  //     setSelectedSourceOfResumeId('');
  //   }
  // };

  const handleSourceOfResumeChange = (event) => {
    const selectedOption = sourceOfResumes.find((item) => item.sourceOfResume === event.target.value);

    if (selectedOption) {
      setSelectedSourceOfResume(event.target.value);
      setSelectedSourceOfResumeId(selectedOption._id);

      // Open the dialog boxes based on the selected option
      if (event.target.value === 'Vendor') {
        setOpenVendorDialog(true);
        setOpenReferenceDialog(false);
      } else if (event.target.value === 'Reference') {
        setOpenVendorDialog(false);
        setOpenReferenceDialog(true);
      } else {
        setOpenVendorDialog(false);
        setOpenReferenceDialog(false);
      }
    } else {
      setSelectedSourceOfResume('');
      setSelectedSourceOfResumeId('');
      setOpenVendorDialog(false);
      setOpenReferenceDialog(false);
    }
  };
  const [textFieldValue, setTextFieldValue] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [referenceTextFieldValue, setReferenceTextFieldValue] = useState('');
  const [refPersonName, setRefPersonName] = useState('');

  const handleTextFieldChange = (event) => {
    setTextFieldValue(event.target.value);
  };
  const handleReferenceTextFieldChange = (event) => {
    setReferenceTextFieldValue(event.target.value);
  };

  const handleSaveRefPersonName = () => {
    setRefPersonName(referenceTextFieldValue);
    setOpenReferenceDialog(false);
  };

  const handleSaveVendorName = () => {
    setVendorName(textFieldValue);
    setOpenVendorDialog(false);
  };
  const handleClickOpen = () => {
    console.log("req in add can", reqData);
    if (candidateName === "" || contactNo === "", selectedSourceOfResume === "", selectedSourceOfResumeId === "") {
      alert("Please fill all the required fields");
    } else {
      console.log("sdfghjk")
      let data = {
        candidateName: candidateName,
        contactNo: contactNo,
        emailId: emailId,
        vendorName: vendorName,
        refPersonName: refPersonName,
        adhaarNo: adhaarNo,
        sourceOfResumeId: selectedSourceOfResumeId,
        sourceOfResume: selectedSourceOfResume,
        location: reqData.locationName,
        dateOfSubmission: dateOfSubmission,
        positionName: reqData.positionName,
        hiring_type: reqData.hiring_type,
        RequisitionId: reqData._id,
        clientId: reqData.clientId,
        clientName: reqData.clientName,
        createdBy: localStorage.getItem('employeeId'),
        transfer: transfer
      }
      console.log("fghjk", data)
      dispatch(addCandidate(data))
      closePopup()
      setTimeout(() => {
        dispatch(getAllCandidate(reqData._id));
      }, 100);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const [validemail, setValidEmail] = useState(false);
  const emailValid = () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId)) {
      console.log("email check");
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }
  let userType = localStorage.getItem('roleName')

  function handleBulk(event) {
    let file = event.target.files[0]
    readXlsxFile(file).then((rows) => {
      let header = rows[0];
      let body = rows.slice(1).map((row, index) => {
        return row;
      });
      body.forEach((row, i) => {
        let candidateName = '';
        let contactNumber = '';
        let adharNumbar = '';
        let emailId = '';
        let sourceOfResume = "";
        let vendor = "";
        let reference = "";
        let acceptedCtc = "";
        let joined = "";
        let joiningDate = "";
        let empCode = "";

        header.forEach((header, j) => {
          if (header == 'Candidate Name') {
            candidateName = row[j]
          } else if (header == 'Contact Number') {
            contactNumber = row[j]
          } else if (header == 'Adhar Number') {
            adharNumbar = row[j]
          } else if (header === "Email Id") {
            emailId = row[j];
          } else if (header === "Source of Resume") {
            sourceOfResume = row[j];
          } else if (header === "Vender Person") {
            vendor = row[j];
          } else if (header === "Reference Person") {
            reference = row[j];
          } else if (header === "Accepted Ctc") {
            acceptedCtc = row[j];
          } else if (header === "Joined Status") {
            joined = row[j];
          } else if (header === "Joining Date") {
            // console.log("hjbshbh", formatDateFordateOfJoining(new Date(row[j])))
            if (row[j].length == 5) {
              var excelDateSerialNumber = row[j];
              var timestamp = (excelDateSerialNumber - 25569) * 86400 * 1000;
              var date = new Date(timestamp);
              joiningDate = new Date(date)
            } else {
              joiningDate = formatDateFordateOfJoining(new Date(row[j]));
              // joiningDate = row[j];
            }
          }else if (header === "Emp. Code") {
            empCode = row[j];
          }
        })
        let obj = {
          candidateName: candidateName,
          contactNo: contactNumber,
          emailId: emailId,
          adhaarNo: adharNumbar,
          sourceOfResume: sourceOfResume,
          vendor: vendor,
          reference: reference,
          acceptedCtc: acceptedCtc,
          joined: joined,
          joiningDate: joiningDate,
          empCode: empCode,
          sel: false,
          transfer: false
        }
        bulkData.push(obj)
        setRender(!render);
      })
      setOpen1(true)
    })
  }

  // function handleBulk(e) {
  //   let file = e.target.files[0]
  //   let reader = new FileReader();
  //   let rABS = !!reader.readAsBinaryString;
  //   reader.onload = ({ target: { result } }) => {
  //     let wb = XLSX.read(result, { type: rABS ? 'binary' : 'array' });
  //     let wsname = wb.SheetNames[0];
  //     let ws = wb.Sheets[wsname];
  //     let data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
  //     let header = data[0];
  //     console.log('header', header);
  //     let body = data.slice(1).map((row, index) => {
  //       return row;
  //     });
  //     console.log('body', body);
  //     body.forEach((row, i) => {
  //       let candidateName = '';
  //       let contactNumber = '';
  //       let adharNumbar = '';
  //       let emailId = '';
  //       let sourceOfResume = ""; 
  //       let vendor = "";         
  //       let reference = "";  
  //       let acceptedCtc = "";
  //       let joined = "";
  //       let joiningDate = ""; 

  //       header.forEach((header, j) => {
  //         if (header == 'Candidate Name') {
  //           candidateName = row[j]
  //         } else if (header == 'Contact Number') {
  //           contactNumber = row[j]
  //         } else if (header == 'Adhar Number') {
  //           adharNumbar = row[j]
  //         }else if (header === "Email Id") {
  //           emailId = row[j];
  //         } else if (header === "Source of Resume") {
  //           sourceOfResume = row[j];
  //         } else if (header === "Vender Person") {
  //           vendor = row[j];
  //         } else if (header === "Reference Person") {
  //           reference = row[j];

  //       } else if (header === "Accepted Ctc ") {
  //         acceptedCtc = row[j];
  //       } else if (header === "Joined Status") {
  //         joined = row[j];
  //       } else if (header === "Joining Date") {
  //         joiningDate = row[j];
  //       }
  //       })
  //       let obj = {
  //         candidateName: candidateName,
  //         contactNo: contactNumber,
  //         emailId: emailId,
  //         adhaarNo: adharNumbar,
  //         sourceOfResume: sourceOfResume,
  //         vendor: vendor,
  //         reference: reference,
  //         acceptedCtc: acceptedCtc,
  //        joined: joined,
  //        joiningDate: joiningDate,
  //         sel: false,
  //         transfer: false
  //       }
  //       bulkData.push(obj)
  //       setRender(!render);
  //     })
  //     setOpen1(true)
  //   };
  //   if (rABS) reader.readAsBinaryString(file);
  //   else reader.readAsArrayBuffer(file);
  // }

  useEffect(() => {
    console.log("datadata", bulkData)
  }, [render])

  function readFile(file) {
    console.log("file", file)
  }

  return (
    <div className="widthfull"

      style={{ height: '460px', width: '448px', borderRadius: '10px', fontFamily: 'sans-serif' }}
    >
      <CloseIcon style={{ float: 'right', cursor: "pointer" }} onClick={closePopup} />
      <Dialog maxWidth={false} open={open}>
        <DialogContent>
          <PopupSaveCandidate />
        </DialogContent>
      </Dialog>
      <Dialog maxWidth={false} open={open1} onClose={handleClose1}>
        <DialogContent>
          <BulkCandidate
            bulkData={bulkData}
            reqData={reqData}
            handleClose1={handleClose1}
            open={open}
            closePopup={closePopup}
          />
        </DialogContent>
      </Dialog>
      <div
        style={{
          backgroundColor: "#CEE3F6",
          fontFamily: "sans-serif",
          justifyContent: "center",
          display: "flex",
          height: "44px",
          margin: "-20px -24px",
        }}
      >
        <p style={{ color: '#155188' }}>
          ADD CANDIDATE
        </p>
        <div style={{ display: userType == 'Recruiter' ? 'none' : '', height: "30px", width: "150px", margin: "10px" }} >
          <input
            type='file'
            style={{ height: "30px", width: "80px", position: "absolute", opacity: "0" }}
            accept={["xslx", "xsl"]}
            onChange={(e) => handleBulk(e)}
            disabled={localStorage.getItem('roleName') == 'Auditor'}
          />
          <button
            style={{ height: "30px", width: "80px" }} >
            Bulk Add
          </button>
        </div>
      </div>
      <div style={{ marginTop: "50px" }}>
        <div
          className="inputnonmobile"
          style={{
            color: "#525252",
            fontFamily: "sans-serif",
            display: "flex",
            fontSize: "14px",
            lineHeight: "18.23px",
            gap: "50px",
          }}
        >
          <div style={{ display: "flex", gap: "10px", flexFlow: "column" }}>
            <p className="mt-2.5">Candidate Name *</p>
            <p className="mt-2.5">Contact Number *</p>
            <p className="mt-2.5">Aadhar No.              (optional)</p>
            <p>Email Id</p>
            <p>Source of Resume *</p>
            <p style={{ display: userType == 'Recruiter' ? 'none' : '' }}>Transfer</p>

          </div>
          <div
            className="flex ml-10 gap-x-3"
            style={{ display: "flex", gap: "15px", flexFlow: "column" }}
          >
            <TextField
              size="small"
              name=""
              type="text"
              style={{
                backgroundColor: "#ffffff",
                width: "250px",
                height: "40px",
                borderRadius: "5px",
              }}
              placeholder="Enter Here"
              onChange={(event) => setCandidateName(event.target.value)}
              value={candidateName}
              variant="outlined"
              autoComplete="off"
            />

            <TextField
              size="small"
              name=""
              style={{
                backgroundColor: "#ffffff",
                width: "250px",
                height: "40px",
                borderRadius: "5px",
              }}
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 10);
              }}
              placeholder="Enter Here"
              onChange={(event) => setContactNo(event.target.value)}
              value={contactNo}
              variant="outlined"
              autoComplete="off"
            />

            <TextField
              size="small"
              name=""
              style={{
                backgroundColor: "#ffffff",
                width: "250px",
                height: "40px",
                borderRadius: "5px",
              }}
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 12);
              }}
              placeholder="Enter Here"
              onChange={(event) => setAdhaarNo(event.target.value)}
              value={adhaarNo}
              variant="outlined"
              autoComplete="off"
            />
            <TextField
              size="small"
              name=""
              style={{
                backgroundColor: "#ffffff",
                width: "250px",
                height: "40px",
                borderRadius: "5px",
              }}
              placeholder="Enter Here"
              onChange={(event) => setEmailId(event.target.value)}
              onBlur={emailValid}
              error={validemail}
              helperText={validemail ? "Please enter valid Email" : " "}
              value={emailId}
              variant="outlined"
              autoComplete="off"
            />
            <select
              value={selectedSourceOfResume}
              onChange={handleSourceOfResumeChange}
              style={{
                backgroundColor: "#ffffff",
                width: "250px",
                height: "40px",
                borderRadius: "5px",
              }}>
              <option value="">Select</option>
              {sourceOfResumes.map((sourceOfResume) => (
                <option key={sourceOfResume._id} value={sourceOfResume.sourceOfResume}>
                  {sourceOfResume.sourceOfResume}
                </option>
              ))}
            </select>

            <Dialog
              open={openVendorDialog}
              onClose={() => setOpenVendorDialog(false)}
              aria-labelledby="vendor-dialog-title"
              style={{ zIndex: 9999 }}
            >
              <DialogTitle id="vendor-dialog-title">Vendor</DialogTitle>
              <DialogContent style={{ paddingTop: "20px" }}>
                <DialogContentText>
                  <TextField
                    label="Text Field"
                    variant="outlined"
                    value={textFieldValue}
                    onChange={handleTextFieldChange}
                    size="small"
                    autoComplete="off"
                    type="text"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "250px",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    placeholder="Enter Here"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSaveVendorName} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openReferenceDialog}
              onClose={() => setOpenReferenceDialog(false)}
              aria-labelledby="reference-dialog-title"
              style={{ zIndex: 9999 }}
            >
              <DialogTitle id="reference-dialog-title">Reference </DialogTitle>
              <DialogContent style={{ paddingTop: "20px" }}>
                <DialogContentText>
                  <TextField
                    label="Reference Person Name"
                    variant="outlined"
                    value={referenceTextFieldValue}
                    onChange={handleReferenceTextFieldChange}
                    size="small"
                    autoComplete="off"
                    type="text"
                    style={{
                      backgroundColor: "#ffffff",
                      width: "250px",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    placeholder="Enter Here"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSaveRefPersonName} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>



            <input style={{ display: userType == 'Recruiter' ? 'none' : '', marginTop: '10px', height: '20px' }} type="checkbox" checked={transfer} onChange={(x) => setTrasf(x.target.checked)} />
          </div>
        </div>

        {/* mobile View */}

        <div
          className="popupcandidatediv"
          style={{
            color: "#525252",
            fontFamily: "sans-serif",
            display: "none",
            fontSize: "14px",
            lineHeight: "18.23px",
            gap: "50px",
          }}
        >
          <div
            className="inputnonmobile"
            style={{ display: "flex", gap: "10px", flexFlow: "column" }}
          ></div>
          <div
            className="flex ml-10 gap-x-3"
            style={{ display: "flex", gap: "15px", flexFlow: "column" }}
          >
            <div>
              <p className="mt-2.5">Candidate Name *</p>
              <TextField
                size="small"
                name=""
                type="text"
                style={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                  height: "40px",
                  borderRadius: "5px",
                }}
                placeholder="Enter Here"
                onChange={(event) => setCandidateName(event.target.value)}
                value={candidateName}
                variant="outlined"
                autoComplete="off"
              />
            </div>

            <div>
              <p className="mt-2.5">Contact Number *</p>
              <TextField
                size="small"
                name=""
                style={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                  height: "40px",
                  borderRadius: "5px",
                }}
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                placeholder="Enter Here"
                onChange={(event) => setContactNo(event.target.value)}
                value={contactNo}
                variant="outlined"
                autoComplete="off"
              />
            </div>

            <div>
              <p className="mt-2.5">Aadhar No. (optional)</p>
              <TextField
                size="small"
                name=""
                style={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                  height: "40px",
                  borderRadius: "5px",
                }}
                type="number"
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 12);
                }}
                placeholder="Enter Here"
                onChange={(event) => setAdhaarNo(event.target.value)}
                value={adhaarNo}
                variant="outlined"
                autoComplete="off"
              />
            </div>

            <div>
              <p>Email Id</p>
              <TextField
                size="small"
                name=""
                style={{
                  backgroundColor: "#ffffff",
                  width: "100%",
                  height: "40px",
                  borderRadius: "5px",
                }}
                placeholder="Enter Here"
                onChange={(event) => setEmailId(event.target.value)}
                onBlur={emailValid}
                error={validemail}
                helperText={validemail ? "Please enter valid Email" : " "}
                value={emailId}
                variant="outlined"
                autoComplete="off"
              />
            </div>

            <div>
              <p>Source Of Resume *</p>
              <select
                value={selectedSourceOfResume}
                onChange={handleSourceOfResumeChange}
                style={{
                  backgroundColor: "#ffffff",
                  width: "250px",
                  height: "40px",
                  borderRadius: "5px",
                }}>
                <option value="">Select</option>
                {sourceOfResumes.map((sourceOfResume) => (
                  <option key={sourceOfResume._id} value={sourceOfResume.sourceOfResume}>
                    {sourceOfResume.sourceOfResume}
                  </option>
                ))}
              </select>

              <Dialog
                open={openVendorDialog}
                onClose={() => setOpenVendorDialog(false)}
                aria-labelledby="vendor-dialog-title"
                style={{ zIndex: 9999 }}
              >
                <DialogTitle id="vendor-dialog-title">Vendor</DialogTitle>
                <DialogContent style={{ paddingTop: "20px" }}>
                  <DialogContentText>
                    <TextField
                      label="Vendor Name"
                      variant="outlined"
                      value={textFieldValue}
                      onChange={handleTextFieldChange}
                      size="small"
                      autoComplete="off"
                      type="text"
                      style={{
                        backgroundColor: "#ffffff",
                        width: "250px",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      placeholder="Enter Here"
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleSaveVendorName} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={openReferenceDialog}
                onClose={() => setOpenReferenceDialog(false)}
                aria-labelledby="reference-dialog-title"
                style={{ zIndex: 9999 }}
              >
                <DialogTitle id="reference-dialog-title">Reference </DialogTitle>
                <DialogContent style={{ paddingTop: "20px" }}>
                  <DialogContentText>
                    <TextField
                      label="Reference Person Name"
                      variant="outlined"
                      value={referenceTextFieldValue}
                      onChange={handleReferenceTextFieldChange}
                      size="small"
                      autoComplete="off"
                      type="text"
                      style={{
                        backgroundColor: "#ffffff",
                        width: "250px",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      placeholder="Enter Here"
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleSaveRefPersonName} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <div style={{ display: 'flex ', alignItems: "center" }}>
              <p>Transfer :</p>
              <input style={{ display: userType == 'Recruiter' ? 'none' : '', height: '20px', marginLeft: "25px" }} type="checkbox" checked={transfer} onChange={(x) => setTrasf(x.target.checked)} />

            </div>


          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            paddingBottom: "15px",
          }}
        >
          <button
            className="popsavebutton"
            style={{
              backgroundColor: "#3B82F6",
              width: "70px",
              height: "40px",
              color: "#FFFFFF",
              border: "1px solid #3B82F6",
              borderRadius: "6px",
              fontFamily: "sans-serif",
            }}
            onClick={handleClickOpen}
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupAddCandidate;
