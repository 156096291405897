import React, { useState, useEffect } from "react";
import DeleteIcon from "../DeleteIcon.svg";
import EditIcon from "../EditIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Box from "@mui/material/Box";
import { addClient, getClient } from "../../../../redux/action";
import { Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import { TiDeleteOutline } from "react-icons/ti";

import "./clientlist.css";

const { URL } = require("../../../../config.json");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography style={{ padding: "0" }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UpdateClient({ client, close }) {
  let emp_id = localStorage.getItem("employeeId");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let dispatch = useDispatch();
  const [value, setValue] = React.useState(0);

  let designationList = useSelector((state) => state.clientReducer.designation);

  let userList = useSelector((state) => state.clientReducer.userList);

  let companyList = useSelector((state) => state.clientReducer.company);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("ytdtyf", useState)
  const [clientName, setClientName] = useState(client.clientName);
  const [accountManager, setAccountManager] = useState(
    JSON.stringify(userList.find((m) => m._id == client.accountManagerId))
  );
  const [accuired, setAccuired] = useState(
    JSON.stringify(userList.find((m) => m._id == client.acquiredBy))
  );

  const [designation, setDesignation] = useState();
  console.log("tftf", client.selectedMonth);
  const [selectedMonth, setSelectedMonth] = useState(client.contractual && client.contractual.selectedMonth);
  const [spocName, setSpocName] = useState();
  const [company, setCompany] = useState(client.company);
  const [spocEmail, setSpocEmail] = useState();
  const [spocNumber, setSpocNumber] = useState();
  const [contractualMarginType, setContractualMarginType] = useState(
    client.contractual && client.contractual.contractualMarginType
  );
  const [contractualMarginAmount, setContractualMarginAmount] = useState(
    client.contractual && client.contractual.contractualMarginAmount
  );
  const [
    contractualOnTimeSourcingAmountType,
    setContractualOnTimeSourcingAmountType,
  ] = useState(
    client.contractual && client.contractual.contractualOnTimeSourcingAmountType
  );
  const [contractualOnTimeSourcingAmount, setContractualOnTimeSourcingAmount] =
    useState(
      client.contractual && client.contractual.contractualOnTimeSourcingAmount
    );
  const [permonentMarginType, setPermonentMarginType] = useState(
    client.permonent && client.permonent.permonentMarginType
  );
  const [permonentMarginAmount, setPermonentMarginAmount] = useState(
    client.permonent && client.permonent.permonentMarginAmount
  );
  const [
    permonentOnTimeSourcingAmountType,
    setPermonentOnTimeSourcingAmountType,
  ] = useState(
    client.permonent && client.permonent.permonentOnTimeSourcingAmountType
  );
  const [permonentOnTimeSourcingAmount, setPermonentOnTimeSourcingAmount] =
    useState(
      client.permonent && client.permonent.permonentOnTimeSourcingAmount
    );
  const [spocs, setSpocs] = useState(client.spocs);
  const [updateButton, setUpdateBu] = useState(false);
  const [spocindex, setSpocsInd] = useState();
  const accountManagerLi = userList.map((x) => ({
    z: JSON.stringify(x),
    value: x.name,
    label: x.userName,
  }));
  const acquiredByyy = userList.map((x) => ({
    z: JSON.stringify(x),
    value: x.name,
    label: x.userName,
  }));

  const desi = designationList.map((n) => ({ value: n.name, label: n.name }));

  const addSpoc = () => {
    spocs.push({
      spocName: spocName,
      spocEmail: spocEmail,
      spocNumber: spocNumber,
      designation: designation,
    });
    setSpocName("");
    setSpocEmail("");
    setSpocNumber("");
    setSpocNumber("");
  };

  const updateSpocs = () => {
    spocs.splice(spocindex, 1, {
      spocName: spocName,
      spocEmail: spocEmail,
      spocNumber: spocNumber,
      designation: designation,
    });
    setSpocName("");
    setSpocEmail("");
    setSpocNumber("");
    setSpocNumber("");
    setUpdateBu(false);
  };

  const deleteSpoc = (ind) => {
    let d = spocs.filter((d, i) => i != ind);
    setSpocs(d);
  };

  const [divisions, setDivisions] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState(client.divisionNamemultiple);
  console.log("uyfuyg", selectedDivisions)

  useEffect(() => {
    axios.get(`${URL}bi_division/all_division`)
      .then(response => {
        console.log("cvghb", response.data)
        const names = response.data.map(division => division.divisionName);

        setDivisions(names);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const optionsDevision = divisions.map(name => ({
    value: name,
    label: name,
  }));
  const handleDivisionChange = selectedOptions => {
    const selectedNames = selectedOptions.map(option => option.value);
    console.log("tryb", selectedNames)
    setSelectedDivisions(selectedNames);
  };



  const [folder_name, setFolderName] = useState('Bi_ClientList_Folder/Bi_ClientList');

  const initialFiles = Array.isArray(client.Agreement_Url) ? client.Agreement_Url : [];
  const [files, setFiles] = useState(initialFiles);

  console.log("client.Agreement_Url",client.Agreement_Url)
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState(client.Agreement_Url,);
  console.log('xdgvbn', imageUrls);

  const resetFileInput = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = ''; 
    }
  };

  const noticeChangingHandler = async (event) => {
    setLoading(true);

    try {
      const uploadedFiles = event.target.files;
      const newFiles = Array.from(uploadedFiles);
      const validFiles = newFiles.filter(file => file.size >= 1024 && file.size <= 5 * 1024 * 1024);

      if (validFiles.length > 0) {
        const validFileObjects = validFiles.map(file => ({
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified,
          file: file // Keep the original file object for upload
        }));
        setFiles(prevFiles => [...prevFiles, ...validFiles]); 

        await Promise.all(validFiles.map(file => uploadOnS3(file)));
      } else {
        resetFileInput();

        alert('Please select one or more files between 1KB and 5MB in size.');
      }
    } catch (error) {
      console.log('Error occurred during upload:', error);
    }
  };

  const uploadOnS3 = async (file) => {
    console.log('check data in line no. 349', file);
    let formData = new FormData();

    formData.append('image', file);
    formData.append('organisation_id', localStorage.getItem('organisationID'));
    formData.append('client_id', localStorage.getItem('buid'));
    formData.append('employeeId', localStorage.getItem('EmployeeID'));
    formData.append('folder_name', folder_name);
    console.log('check data in line no. 256', formData);

    try {
      const response = await axios.post(`${URL}/uploadFile/uploadFile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully!', response.data);
      setImageUrls(prevImageUrls => [...prevImageUrls, { name: file.name, url: response.data }]);
      return { name: file.name, url: response.data };
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };


  const removeFile = (index) => {
    setImageUrls(prevFiles => prevFiles.filter((_, i) => i !== index));
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };
console.log("clientuuyg",client)
  const [selectedSagment, setSelectedSagment] = useState(client.selectedSagment);

  const optionSagment = [
    { value: 'iTStaffing', label: 'IT Staffing' },
    { value: 'executiveSearch', label: 'Executive Search' },
    { value: 'internationalStaffingService', label: 'International Staffing Service' },
    { value: 'recruitmentProcessOutsource', label: 'Recruitment Process Outsourcing' },
    { value: 'flexiStaffing', label: 'Flexi Staffing' },
    { value: 'staffingForInfrastructureDomain', label: 'Staffing for Infrastructure Domain' },
    { value: 'staffingInOilAndEnergy', label: 'Staffing in Oil and Energy sector' },
    { value: 'staffingInHospitalityIndustry', label: 'Staffing in Hospitality Industry' },
    { value: 'staffingAndManagementInTelecomm', label: 'Staffing and Management in Telecommunications' },
    { value: 'salesStaffing', label: 'Sales Staffing Services' },
    { value: 'payRollingandCompliance', label: 'Pay rolling and Compliance Management(Internal & External)' },
    { value: 'traningAndDevelopment', label: 'Traning and Development of resources / performance management / HR policy implementation' },
   
  ];

  const handleSagmentChanges = (selectedOption) => {
    setSelectedSagment(selectedOption ? selectedOption.label : '');
    console.log(selectedOption);

  };

  const [selectedServices, setSelectedServices] = useState(client && client.selectedServices);

  console.log("hgcygv",selectedServices)

  const optionServices = [
    { value: 'permanent', label: 'Permanent' },
    { value: 'contractual', label: 'Contractual' },
    { value: 'rpo', label: 'RPO' },
    { value: 'workforceManagement', label: 'Workforce Management' },
  ];
  const handleServiceChange = (selectedOptions) => {
    setSelectedServices(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };









  const update = () => {
    if (clientName == '' || accountManager == '' || company == '' || accuired == '') {
      window.alert('please select required fields')
    }
    else {
      const agreementUrls = imageUrls.map(({ name, url }) => ({ name, url }));
      let data = {
        clientId: client._id,
        clientName: clientName,
        accountManagerName: JSON.parse(accountManager).userName,
        accountManagerId: JSON.parse(accountManager)._id,
        acquiredBy: JSON.parse(accuired)._id,
        divisionNamemultiple: selectedDivisions,
        Agreement_Url: agreementUrls,
        company,
        selectedSagment:selectedSagment,
        selectedServices:selectedServices,

        company,
        spocs: spocs,
        updatedBy: emp_id,
        contractual: {
          contractualMarginType: contractualMarginType,
          contractualMarginAmount: contractualMarginAmount,
          contractualOnTimeSourcingAmountType:
            contractualOnTimeSourcingAmountType,
          contractualOnTimeSourcingAmount: contractualOnTimeSourcingAmount,
          selectedMonth: selectedMonth,
        },
        permonent: {
          permonentMarginType: permonentMarginType,
          permonentMarginAmount: permonentMarginAmount,
          permonentOnTimeSourcingAmountType: permonentOnTimeSourcingAmountType,
          permonentOnTimeSourcingAmount: permonentOnTimeSourcingAmount,
        },
      };
      axios
        .put(`${URL}clientList/clientList`, data)
        .then((result) => {
          window.alert(result.data.message);
          dispatch(getClient());
          close();
        })
        .catch((error) => { });
    }
  };

  const edit = (d, i) => {
    setUpdateBu(true);
    setSpocName(d.spocName);
    setSpocEmail(d.spocEmail);
    setSpocNumber(d.spocNumber);
    setDesignation(d.designation);
    setSpocsInd(i);
  };

  // const months = Array.from({ length: 12 }, (_, index) => ({
  //   value: (index + 1).toString(),
  //   label: `${index + 1}`,
  // }));

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };


  return (
    <div
      className="maincontainer"
      style={{
        width: "800px",
        // height: "950px",
        padding: "8px 0",
        border: "1px solid #CFDFED",
        background: "#EEF1F4",
        borderRadius: "10px",
        margin: "-24px",
      }}
    >
      <form onSubmit={handleSubmit(update)} style={{ margin: "30px" }}>
        <p
          style={{
            color: "#155188",
            display: "flex",
            justifyContent: "center",
            fontFamily: "sans-serif",
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: 500,
            marginTop: "-12px",
          }}
        >
          EDIT CLIENT
        </p>
        <hr />
        <div
          className="gaprow"
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "sans-serif",
            rowGap: "8px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            color: "#525252",
          }}
        >
          <div
            className="updatmaindiv"
            style={{
              display: "flex",
              fontFamily: "sans-serif",
              columnGap: "64px",
              marginTop: "16px",
            }}
          >
            <p style={{ marginTop: "10px" }}>Client Name</p>
            <input
              className="inputfiledupdate"
              type="text"
              {...register("ClientName", { required: true })}
              placeholder={
                errors.ClientName?.type === "required" &&
                "ClientName is required"
              }
              style={{
                width: "200px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
              }}
              value={clientName}
              onChange={(e) => setClientName(e.target.value)}
            />
            <div
              className="updatmaindiv"
              style={{
                display: "flex",
                fontFamily: "sans-serif",
                columnGap: "35px",
              }}
            >
              <p style={{ marginTop: "10px" }}> Company</p>
              <select
                className="inputfiledupdate"
                style={{
                  width: "200px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                  height: "43px",
                }}
                value={company}
                onChange={(k) => setCompany(k.target.value)}
              >
                <option>Select</option>
                {companyList.map((n) => (
                  <option value={n.company}>{n.company}</option>
                ))}
              </select>
            </div>
          </div>


          <div
            style={{
              display: "flex",
              fontFamily: "sans-serif",
              columnGap: "20px",
              marginTop: "16px",
            }}
          >
            <div
              className="addclintone"
              style={{
                display: "flex",
                fontFamily: "sans-serif",
                columnGap: "10px",
              }}
            >
              <p className="textalinepera" style={{ marginTop: "10px" }}>
                Acquire By
              </p>
              <div
                className="addclintone"
                style={{
                  width: "201px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                  marginLeft: "65px",
                }}
              >
                <Select
                  options={acquiredByyy}
                  defaultValue={[
                    acquiredByyy.find(
                      (n) => JSON.parse(n.z)._id == client.acquiredBy
                    ),
                  ]}
                  onChange={(g) => setAccuired(g.z)}
                />
              </div>
            </div>

            <div
              className="updatmaindiv"
              style={{
                display: "flex",
                fontFamily: "sans-serif",
                columnGap: "35px",
              }}
            >
              <p style={{ marginTop: "10px" }}>Account Manager</p>
              <div
                className="inputfiledupdate"
                style={{
                  width: "203px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                }}
              >
                <Select
                  options={accountManagerLi}
                  defaultValue={[
                    accountManagerLi.find(
                      (n) => JSON.parse(n.z)._id == client.accountManagerId
                    ),
                  ]}
                  onChange={(g) => setAccountManager(g.z)}
                />
              </div>
            </div>
          </div>







          <div  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "20px",
                    marginTop: "16px",
                  }} >
                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "10px",
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Segment</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      marginLeft: "75px",     
                                   }}
                  >
                   {/* <Select
        options={optionSagment}
        onChange={handleSagmentChanges}
        value={optionSagment.find(option => option.value === client.selectedSagment)}
      /> */}
              <select
                className="inputfiledupdate"
                style={{
                  width: "200px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  border: "1px solid #DCE3EA",
                  height: "39px",
                }}
                value={selectedSagment}
                onChange={(k) => setSelectedSagment(k.target.value)}

              >
                <option>Select</option>
                {optionSagment.map((n) => (
                  <option value={n.label}>{n.label}</option>
                ))}
              </select>
                  </div>
                </div>

                <div
                className="addclintone"
                  style={{
                    display: "flex",
                    fontFamily: "sans-serif",
                    columnGap: "35px",
                    marginLeft:"47px"
                  }}
                >
                  <p className="textalinepera" style={{ marginTop: "10px" }}>Services</p>
                  <div
                  className="addclintone"
                    style={{
                      width: "201px",
                      borderRadius: "6px",
                      // background: "#FFFFFF",
                      // border: "1px solid #DCE3EA",
                      // marginLeft:"55px"
                    }}
                  >
                     <Select
                       options={optionServices}
                       isMulti
                       onChange={handleServiceChange}
                       value={optionServices.filter(option => selectedServices.includes(option.value))}
                     />

                  </div>
                </div>
                </div>

















          <div
            className="addclintone"
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "sans-serif",
              columnGap: "10px",
            }}
          >
            <p className="textalinepera" style={{ marginTop: "10px" }}> Division</p>
            <div
              className="addclintone"
              style={{
                width: "100%",
                borderRadius: "6px",
                margin: "16px 52px 0 80px"

              }}
            >
              <Select
                options={optionsDevision}
                isMulti
                value={optionsDevision.filter(option => selectedDivisions.includes(option.value))}
                onChange={handleDivisionChange}
              />
            </div>
          </div>



          <div style={{marginTop:"15px"}}> 
            <label>Agreement Copy :</label>
            <label id={`my`} style={{   marginLeft: "35px",border: "1px solid blue", borderRadius: "5px", backgroundColor: "white", color: "blue", padding: "5px 10px", cursor: "pointer", display: "inline-block" }}>
            <input 
            htmlFor={`my`} 
            type="file"
            hidden           
            onChange={(event) => noticeChangingHandler(event)}
            multiple />
            Attach Here
          </label>
                        {files.length > 0 && (
        <ul>
          {files.map((file, index) => (
            <li  key={index}>{file.name} <span style={{marginLeft:"3px",color:"red",}} onClick={() => removeFile(index)}><TiDeleteOutline /></span> 
            </li>
            
          ))}
        </ul>
      )}
      
                </div>






          <div
            className="updatmaindiv"
            style={{
              marginTop: "16px",
              display: "flex",
              fontFamily: "sans-serif",
              columnGap: "18px",
            }}
          >
            <p style={{ marginTop: "10px" }}>SPOC</p>
            <input
              className="inputfiledupdate"
              type="text"
              size="small"
              style={{
                width: "199px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
                height: "40px",
                marginLeft: "77px",
                paddingLeft:"5px"
              }}
              placeholder="Name"
              value={spocName}
              onChange={(e) => setSpocName(e.target.value)}
            />
            <input
              className="inputfiledupdate"
              type="email"
              size="small"
              style={{
                width: "196px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
                height: "40px",
                paddingLeft:"5px"
              }}
              placeholder="Email ID"
              value={spocEmail}
              onChange={(e) => setSpocEmail(e.target.value)}
            />
          </div>
          <div
            className="updatmaindiv"
            style={{
              display: "flex",
              fontFamily: "sans-serif",
              columnGap: "18px",
            }}
          >
            <input
              className="inputfiledupdate"
              type="number"
              size="small"
              style={{
                width: "199px",
                borderRadius: "6px",
                background: "#FFFFFF",
                border: "1px solid #DCE3EA",
                height: "40px",
                marginLeft: "129px",
                paddingLeft:"5px"
              }}
              placeholder="Mobile No."
              value={spocNumber}
              onChange={(e) => setSpocNumber(e.target.value)}
            />
            <div className="inputfiledupdate" style={{ width: "203px" }}>
              <Select
                className="inputfiledupdate"
                options={desi}
                onChange={(g) => setDesignation(g.value)}
              />
            </div>
            {updateButton ? (
              <button
                className="savebutton"
                style={{
                  background: "#4594DE",
                  borderRadius: "100px",
                  width: "84px",
                  height: "32px",
                  color: "#FFFFFF",
                  fontSize: "10px",
                  marginTop: "4px",
                  cursor: spocName ? "pointer" : "not-allowed",
                  border: "1px ridge",
                }}
                disabled={spocName == ""}
                onClick={() => updateSpocs()}
              >
                Update
              </button>
            ) : (
              <button
                className="savebutton"
                disabled={spocName == ""}
                onClick={addSpoc}
                style={{
                  background: "#4594DE",
                  borderRadius: "100px",
                  width: "84px",
                  height: "32px",
                  color: "#FFFFFF",
                  fontSize: "10px",
                  marginTop: "4px",
                  cursor: spocName ? "pointer" : "not-allowed",
                  border: "1px ridge",
                }}
              >
                Add SPOC
              </button>
            )}
          </div>

          <div className="mainscroll" style={{}}>
            <div>
              <Table
                className="scrolldivtwo"
                style={{ width: "745px", marginTop: "12px" }}
              >
                <TableHead>
                  <TableRow style={{ backgroundColor: "#CEE3F6" }}>
                    <TableCell
                      className="textcenter"
                      style={{
                        width: "23%",
                        color: "#525252",
                        textAlign: "start",
                        fontWeight: 550,
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      className="textcenter"
                      style={{
                        width: "23%",
                        color: "#525252",
                        textAlign: "start",
                        fontWeight: 550,
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      className="textcenter"
                      style={{
                        width: "19%",
                        color: "#525252",
                        textAlign: "start",
                        fontWeight: 550,
                      }}
                    >
                      Mobile No.
                    </TableCell>
                    <TableCell
                      className="textcenter"
                      style={{
                        width: "27%",
                        color: "#525252",
                        textAlign: "start",
                        fontWeight: 550,
                      }}
                    >
                      Designation
                    </TableCell>
                    <TableCell
                      className="textcenter"
                      style={{
                        width: "8%",
                        color: "#525252",
                        textAlign: "start",
                        fontWeight: 550,
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div
                className="scrolldivtwo"
                style={{
                  height: "165px",
                  overflowY: "scroll",
                  marginTop: "-8px",
                  width: "745px",
                  // marginLeft: "56px",
                  background: "#ffffff",
                }}
              >
                <Table>
                  {spocs.map((n, i) => (
                    <TableRow>
                      <TableCell
                        className="textcenter"
                        style={{
                          width: "23%",
                          color: "#525252",
                          textAlign: "start",
                          paddingLeft: "2.5%",
                          fontSize: "12px",
                        }}
                      >
                        {n.spocName}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "23%",
                          color: "#525252",
                          textAlign: "start",
                          paddingLeft: "4.1%",
                          fontSize: "12px",
                        }}
                      >
                        {n.spocEmail}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "19%",
                          color: "#525252",
                          textAlign: "start",
                          paddingLeft: "6.1%",
                          fontSize: "12px",
                        }}
                      >
                        {n.spocNumber}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "27%",
                          color: "#525252",
                          textAlign: "start",
                          paddingLeft: "7.1%",
                          fontSize: "12px",
                        }}
                      >
                        {n.designation}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "8%",
                          color: "#525252",
                          textAlign: "start",
                          fontSize: "12px",
                        }}
                      >
                        <div style={{ display: "flex", columnGap: "19px" }}>
                          <img
                            style={{
                              width: "18px",
                              height: "18px",
                              cursor: "pointer",
                              marginTop: "2px",
                            }}
                            src={EditIcon}
                            onClick={() => edit(n, i)}
                          />
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                              cursor: "pointer",
                            }}
                            src={DeleteIcon}
                            onClick={() => deleteSpoc(i)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "10px", fontSize: "14px" }}>
            Type of Hiring :-
          </div>
          <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="fontsiz"
                  label="Contractual"
                  {...a11yProps(0)}
                />
                <Tab className="fontsiz" label="Permanent" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel style={{ padding: "0" }} value={value} index={0}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <div
                  className="clientteam"
                  style={{
                    width: "514px",
                    // height: "236px",
                    padding: "20px 0",
                    backgroundColor: "#DCE4EE",
                  }}
                >
                  <p
                    className="fontsiz"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#525252",
                    }}
                  >
                    Client Terms and Conditions
                  </p>

                  <Divider style={{ marginTop: "-6px" }} />

                  <div
                    className="fontrchild"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      marginTop: "16px",
                      rowGap: "14px",
                      color: "#525252",
                      paddingLeft: "4%",
                    }}
                  >
                    <div>1. Contracting Margin on Monthly Basis</div>
                    <div
                      className="tabcolum"
                      style={{
                        display: "flex",
                        columnGap: "28px",
                        marginTop: "14px",
                      }}
                    >
                      <select
                        className="tabwidth"
                        style={{
                          width: "196px",
                          height: "40px",
                          borderRadius: "5px",
                          border: "1px solid #DCE3EA",
                          marginLeft: "5%",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        value={contractualMarginType}
                        onChange={(e) =>
                          setContractualMarginType(e.target.value)
                        }
                        variant="outlined"
                      >
                        <option value="">Select Here</option>
                        <option value="Variable in %">Variable in %</option>
                        <option value="Fixed in Rs">Fixed in Rs</option>
                      </select>
                      <input
                        className="tabwidth"
                        type="text"
                        size="small"
                        //required={true}
                        name=""
                        style={{
                          width: "196px",
                          borderRadius: "5px",
                          border: "1px solid #DCE3EA",
                          backgroundColor: "#ffffff",
                        }}
                        placeholder="Enter Amount"
                        value={contractualMarginAmount}
                        onChange={(e) =>
                          setContractualMarginAmount(e.target.value)
                        }
                        variant="outlined"
                        autoComplete="off"
                      />
                    </div>
                    <div style={{ marginTop: "14px" }}>
                      2. One Time Sourcing Fee
                    </div>
                    <div
                      className="tabcolum"
                      style={{
                        display: "flex",
                        columnGap: "28px",
                        marginTop: "14px",
                      }}
                    >
                      <select
                        className="tabwidth"
                        style={{
                          width: "196px",
                          height: "40px",
                          borderRadius: "5px",
                          border: "1px solid #DCE3EA",
                          marginLeft: "5%",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        value={contractualOnTimeSourcingAmountType}
                        onChange={(d) =>
                          setContractualOnTimeSourcingAmountType(d.target.value)
                        }
                        variant="outlined"
                      >
                        <option value="">Select Here</option>
                        <option value="Variable in %">Variable in %</option>
                        <option value="Fixed in Rs">Fixed in Rs</option>
                      </select>
                      <input
                        className="tabwidth"
                        type="text"
                        size="small"
                        //required={true}
                        name=""
                        style={{
                          width: "196px",
                          borderRadius: "5px",
                          border: "1px solid #DCE3EA",
                          backgroundColor: "#ffffff",
                        }}
                        placeholder="Enter Amount"
                        value={contractualOnTimeSourcingAmount}
                        onChange={(e) =>
                          setContractualOnTimeSourcingAmount(e.target.value)
                        }
                        variant="outlined"
                        autoComplete="off"
                      />
                    </div>
                    <div
                      className="addclintone"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        fontFamily: "sans-serif",
                        columnGap: "10px",
                        marginTop: "16px",
                      }}
                    >
                      <p className="textalinepera" style={{ marginTop: "10px" }}>
                        3. Employee Avg. Lifecycle ( Month )
                      </p>
                      <div
                        className="addclintone mar"
                        style={{
                          // width: "201px",
                          width: "196px",
                          borderRadius: "6px",
                          background: "#FFFFFF",
                          border: "1px solid #DCE3EA",
                          marginLeft: "23px",
                        }}
                      >
                        {/* <Select
       
        value={selectedMonth}
        onChange={handleMonthChange}
        options={months}
        isSearchable={true}
      /> */}
                        <select
                          value={selectedMonth}
                          onChange={handleMonthChange}
                          style={{
                            width: "196px",
                            height: "40px",
                            // height: "-webkit-fill-available",
                            paddingLeft: "8px",
                            borderRadius: "6px",
                            background: "#FFFFFF",
                            border: "1px solid #DCE3EA",
                          }}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontFamily: "sans-serif",
                }}
              >
                <div
                  className="clientteam"
                  style={{
                    width: "514px",
                    height: "236px",
                    backgroundColor: "#DCE4EE",
                  }}
                >
                  <p
                    className="fontsiz"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#525252",
                    }}
                  >
                    Client Terms and Conditions
                  </p>

                  <Divider style={{ marginTop: "-6px" }} />

                  <div
                    className="fontrchild"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      marginTop: "16px",
                      rowGap: "14px",
                      color: "#525252",
                      paddingLeft: "4%",
                    }}
                  >
                    <div>1. Contracting Margin on Monthly Basis</div>
                    <div
                      className="tabcolum"
                      style={{
                        display: "flex",
                        columnGap: "28px",
                        marginTop: "14px",
                      }}
                    >
                      <select
                        className="tabwidth"
                        style={{
                          width: "196px",
                          height: "40px",
                          borderRadius: "5px",
                          border: "1px solid #DCE3EA",
                          marginLeft: "5%",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        value={permonentMarginType}
                        onChange={(e) => setPermonentMarginType(e.target.value)}
                        variant="outlined"
                      >
                        <option value="">Select Here</option>
                        <option value="Variable in %">Variable in %</option>
                        <option value="Fixed in Rs">Fixed in Rs</option>
                      </select>
                      <input
                        className="tabwidth"
                        type="text"
                        size="small"
                        //required={true}
                        name=""
                        style={{
                          width: "196px",
                          borderRadius: "5px",
                          border: "1px solid #DCE3EA",
                          backgroundColor: "#ffffff",
                        }}
                        placeholder="Enter Amount"
                        value={permonentMarginAmount}
                        onChange={(e) =>
                          setPermonentMarginAmount(e.target.value)
                        }
                        variant="outlined"
                        autoComplete="off"
                      />
                    </div>
                    {/* <div style={{ marginTop: "14px" }}>
                        2. One Time Sourcing Fee
                      </div>
                      <div
                        style={{
                          display: "flex",
                          columnGap: "28px",
                          marginTop: "14px",
                        }}
                      >
                        <select
                          style={{
                            width: "196px",
                            height: "40px",
                            borderRadius: "5px",
                            border: "1px solid #9A9A9A",
                            marginLeft: "5%",
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name=""
                          placeholder="Enter Here"
                          value={permonentOnTimeSourcingAmountType}
                          onChange={(d) => setPermonentOnTimeSourcingAmountType(d.target.value)}
                          variant="outlined"
                        >
                          <option value="">Select Here</option>
                          <option value="Variable in %">Variable in %</option>
                          <option value="Fixed in Rs">Fixed in Rs</option>
                        </select>
                        <input type="text"
                          size="small"
                          //required={true}
                          name=""
                          style={{
                            width: "196px",
                            borderRadius: "5px",
                            border: "1px solid #A3A3A3",
                            backgroundColor: "#ffffff",
                          }}
                          placeholder="Enter Amount"
                          value={permonentOnTimeSourcingAmount}
                          onChange={(e) => setPermonentOnTimeSourcingAmount(e.target.value)}
                          variant="outlined"
                          autoComplete="off"
                        />
                      </div> */}
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="savebuttonclientlist"
            type="submit"
            style={{
              width: "70px",
              height: "40px",
              borderRadius: "5px",
              color: "#EDF0F6",
              backgroundColor: "#4594DE",
              border: "1px solid #4594DE",
            }}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}