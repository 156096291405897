import React from 'react'
import Check from '../Check.svg';

function PopupAddSave() {
  return (
    <div className='savebuttonclientlist' style={{ width: '355px', background: 'white', height: '190px', top: '39%', left: '39%', textAlign: '-webkit-center', position:'fixed' ,borderRadius: '10px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>
    <img className='mt-12' style={{ height: '50px' }} src={Check} />
    
    <div className='mt-7 text-base' style={{ color: '#525252' }}>
    You have successfully added User..
    </div>
    
  </div>
  )
}

export default PopupAddSave