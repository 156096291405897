import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import axios from 'axios';
import PopupSaveCandidate from './PopupSaveCandidate';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


const { URL } = require("../../../../config.json");

function BulkAddCandidate({ bulkData, handleClose1, reqData, closePopup}) {
    let [candMap, setCandMap] = useState(bulkData);
    let [render, setRender] = useState(false);
    let [AllCandidate, setAllCandidate] = useState('');
    let [transferAll, setTransferAll] = useState('');
    const [dateOfSubmission, setDateOfSubmission] = useState(new Date());
    let apiCount = 0;
    let [savePopup, setSavePopup] = useState(false)
    let [errorPopup, setErrorPopup] = useState(false)

    function selectOne(e, row, i) {
        if (e.target.checked == true) {
            candMap[i].sel = true
            setRender(!render)
        } else {
            candMap[i].sel = false
            setRender(!render)
        }
    }

    function allSelected(e) {
        if (e.target.checked == true) {
            setAllCandidate(true)
            candMap.map((d) => {
                d.sel = true
            })
            setRender(!render)
        } else {
            setAllCandidate(false)
            candMap.map((d) => {
                d.sel = false
            })
            setRender(!render)
        }
    }

    function transferOne(e, row, i) {
        if (e.target.checked == true) {
            candMap[i].transfer = true
            setRender(!render)
        } else {
            candMap[i].transfer = false
            setRender(!render)
        }
    }

    function allTransfered(e) {
        if (e.target.checked == true) {
            setTransferAll(true)
            candMap.map((d) => {
                d.transfer = true
            })
            setRender(!render)
        } else {
            setTransferAll(false)
            candMap.map((d) => {
                d.transfer = false
            })
            setRender(!render)
        }
    }

    let count = candMap.filter((c) => (c.sel == true))
    console.log("count",count)
    let trans = candMap.filter((c) => (c.transfer == true))

    useEffect(() => {
        setCandMap(candMap)
        if (candMap.length == count.length) {
            setAllCandidate(true)
        } else {
            setAllCandidate(false)
        }
        if (candMap.length == trans.length) {
            setTransferAll(true)
        } else {
            setTransferAll(false)
        }
    }, [render])

    function closeTable() {
        handleClose1();
    }

    const [errorCandidate, setErrorCandidate] = useState([]);

    function saveCandidate() {
        count && count.map((s) => {
            let obj = {
                candidateName: s.candidateName,
                contactNo: s.contactNo,
                emailId: s.emailId,
                adhaarNo: s.adhaarNo,
                sourceOfResume:s.sourceOfResume,
                vendor:s.vendor,
                reference:s.reference,
                acceptedCtc: s.acceptedCtc,
                // candidateStatus: s.candidateStatus,
                joined: s.joined,
                joiningDate: s.joiningDate,
                empCode: s.empCode,
                location: reqData.locationName,
                dateOfSubmission: dateOfSubmission,
                positionName: reqData.positionName,
                hiring_type: reqData.hiring_type,
                RequisitionId: reqData._id,
                clientId: reqData.clientId,
                clientName: reqData.clientName,
                createdBy: localStorage.getItem('employeeId'),
                transfer: s.transfer
            }
            console.log("in Save", obj)
            axios.post(`${URL}bi_candidate/bi_candidate`,
                obj
            )
                .then(function (response) {
                    console.log(response)
                    apiCount = apiCount + 1
                })
                .catch(function (error) {
                    console.log("error", error)
                    apiCount = apiCount + 1
                    errorCandidate.push(obj)
                })
            savePopupOpen();

            setTimeout(() => {
                setSavePopup(false)
                setErrorCandidate(errorCandidate)
                closeTable()
                closePopup()
                if (errorCandidate.length != 0) {
                    setErrorPopup(true)
                }
                // closeTable()
            }, 1000)
        })

    }

    function savePopupOpen() {
        setSavePopup(true)
    }

    function closeErrorPopup() {
        setErrorPopup(false)
    }

    function closeAllPopup(){
        closeErrorPopup();
        closeTable();
        closePopup();
    }

    return (
        <div style={{ maxHeight: "500px", maxWidth: "1120px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "10px 0px" }}>
                <p style={{ fontSize: "20px", fontWeight: "600", margin: "0px" }}>Add Bulk Candidates</p>

                <HighlightOffIcon
                    onClick={closeTable} />
            </div>
            <div style={{ borderTop: "2px solid black" }}>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 1650 }} size="small" aria-label="a dense table">
                        <TableHead style={{      }}>
                            <TableRow>
                                <TableCell style={{ padding: "0px", display: "flex" }}>
                                    <Checkbox
                                        style={{ paddingRight: "0px" }}
                                        onChange={allSelected}
                                        checked={AllCandidate}
                                    />
                                    <p
                                        style={{
                                            
                                            fontSize: "9px",
                                            fontWeight: "600",
                                            padding: "0px"
                                        }}>
                                        Select All
                                    </p>
                                </TableCell>
                                <TableCell >Candidate Name</TableCell>
                                <TableCell >Contact Number</TableCell>
                                <TableCell >Email ID</TableCell>
                                <TableCell>Aadhar Number</TableCell>
                                <TableCell>Source of Resume</TableCell>
                                <TableCell>Vender Person</TableCell>
                                <TableCell>Reference Person</TableCell>
                                <TableCell>Accepted Ctc </TableCell>
                                {/* <TableCell>Candidate Status</TableCell> */}
                                <TableCell>Joined Status</TableCell>
                                <TableCell>Joining Date</TableCell>
                                <TableCell>Emp. Code</TableCell>

                                <TableCell
                                    style={{ padding: "0px" }}
                                    align='right'>Transfered <span> </span>
                                    
                                    <Checkbox onChange={allTransfered} checked={transferAll} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {candMap && candMap.map((row, i) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell style={{ padding: "0px" }}>
                                        <Checkbox
                                            onChange={(e) => { selectOne(e, row, i) }}
                                            checked={row.sel}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.candidateName}
                                    </TableCell>
                                    <TableCell >{row.contactNo}</TableCell>
                                    <TableCell >{row.emailId}</TableCell>
                                    <TableCell >{row.adhaarNo}</TableCell>


                                    <TableCell >{row.sourceOfResume}</TableCell>
                                    <TableCell >{row.vendor}</TableCell>
                                    <TableCell >{row.reference}</TableCell>

                                    <TableCell >{row.acceptedCtc}</TableCell>
                                    {/* <TableCell >{row.candidateStatus}</TableCell> */}
                                    <TableCell >{row.joined}</TableCell>
                                    <TableCell >{row.joiningDate}</TableCell>
                                    <TableCell >{row.empCode}</TableCell>

                                    <TableCell style={{ padding: "0px" }} align='right'>
                                        <Checkbox
                                            onChange={(e) => { transferOne(e, row, i) }}
                                            checked={row.transfer}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                <button

                    style={{ backgroundColor: '#3B82F6', width: '70px', height: '40px', color: '#FFFFFF', border: '1px solid #3B82F6', borderRadius: '6px', fontFamily: 'sans-serif' }}
                    onClick={saveCandidate}
                >
                    SAVE
                </button>
            </div>
            <Dialog maxWidth={false} open={savePopup}>
                <DialogContent>
                    <PopupSaveCandidate />
                </DialogContent>
            </Dialog>
            <Dialog maxWidth={false} open={errorPopup} onClose={closeErrorPopup}>
                <DialogContent style={{maxHeight: "500px", width: "300px"}}>
                    <div style={{display:"flex", justifyContent:"space-between", borderBottom:"2px solid black"}}>
                    <p style={{height:"20px"}}>Duplicate Candidate Found</p>
                    <HighlightOffIcon style={{marginTop:"15px"}} onClick={closeAllPopup}/>
                    </div>
                    {errorCandidate && errorCandidate.map((d, i) => (
                        <p>{i + 1} {d.candidateName}</p>
                        ))}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default BulkAddCandidate;