import React from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import PermanentTab from "./PermanentTab";
import ContractualTab from "./ContractualTab";
import "./billingstatus.css"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="paddingmobile" style={{paddingTop:"0px"}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function BillingStatus() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div 
      style={{
        width: "100%",
        height: "100%",
        fontFamily: "sans-serif",
        
      }}
    >
      <div
      >
        {/* <p
          style={{
            fontSize: "16px",
            lineHeight: "22px",
            color: "#434345",
            marginLeft: "11px",
          }}
        >
          BILLING STATUS
        </p> */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab className="paddingmobileo" label="Permanent" {...a11yProps(0)} />
          <Tab className="paddingmobileo" label="Contractual" {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <PermanentTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContractualTab />
        </TabPanel>
      </div>
    </div>
  );
}

export default BillingStatus;
