import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getDesignation,
  getCompany,
  getUserList, getAllClientByCompany, getBranch
} from "../../../../redux/action";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, FormGroup, Modal } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { format } from '../../../middleware/FormatDDMMYY'
import { TablePagination } from "@mui/material";
import Popover from '@mui/material/Popover'
import Select from "react-select";
import { RotatingLines } from 'react-loader-spinner'
const { URL } = require("../../../../config.json");

const Budgeting = () => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDesignation());
   // setLoader(true)
    dispatch(getAllClientByCompany(companyList && companyList[0].company))
   // getClientByCompany({ company: companyList && companyList[0].company, selectedBranches: selectedBranches, financialYear: selectedYear });
    dispatch(getBranch())
    dispatch(getCompany());
    dispatch(getUserList());
    return () => {
      clientByCompany = []
    }
  }, []);

  let [clientByCompany, setClientListByCompany] = useState([])
  let companyList = useSelector((state) => state.clientReducer.company);
  let branchData = useSelector((state) => state.clientReducer.branch);
  let allClientByCompany = useSelector((state) => state.clientReducer.allClientByCompany);

  async function getClientByCompany(e) {
    try {
      let c = await axios.post(`${URL}clientlist/getClientByCompany`, e)
      setClientListByCompany(c.data)
      setLoader(false)
    } catch (error) {

    }
  }

  let clientlistfordropdown = allClientByCompany && allClientByCompany.map((m) => ({
    dat: JSON.stringify(m),
    name: m.clientName,
    label: m.clientName
  }))

  useEffect(() => {
    setCompany(companyList[0].company)
  }, [companyList])
  const [foundClient, setFound] = useState([])

  let firstrender = useRef(true)

  useEffect(() => {
    // if (firstrender.current == true) {
    //   firstrender.current = false
    //   setLoader(true)
    // }
    // else {
    //   if (clientByCompany.clientlist && clientByCompany.clientlist.length > 0) {
    //     setLoader(false)
    //   }
    //}
    setFound(clientByCompany)
  }, [clientByCompany])

  const [branchListByClient, setBranchListbyClient] = useState([])
  const [company, setCompany] = useState();
  const [divisions, setDivisions] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [branchListForMap, setBranchListForMap] = useState([])
  const [loader, setLoader] = useState(false)

  const [selectedClient, setClient] = useState()

  useEffect(() => {
    axios
      .get(`${URL}bi_division/all_division`)
      .then((response) => {
        const names = response.data.map((division) => division.divisionName);

        setDivisions(names);
      })
      .catch((error) => {
      });
  }, []);

  const optionsDevision = divisions.map((name) => ({
    value: name,
    label: name,
  }));

  const handleDivisionChange = (selectedOptions) => {
    const selectedNames = selectedOptions.map((option) => option.value);
    setSelectedDivisions(selectedNames);
  };

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isArrowRotated, setIsArrowRotated] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
    setIsArrowRotated(!isArrowRotated);
  };

  const [searchinput, setSearchinput] = useState("");

  const handleSearchChange = (event) => {


    if (event.target.value != '') {
      const result = clientByCompany.clientlist && clientByCompany.clientlist.filter((m) => {
        return m.client.clientName == undefined ? '' : m.client.clientName.toLowerCase().startsWith(event.target.value.toLowerCase())
      })

      setFound({ clientlist: result, totalBudgetCount: clientByCompany.totalBudgetCount })
      setPage(0)
    }
    else {
      setFound(clientByCompany)
    }
    setSearchinput(event.target.value);
  };

  const [selectedBranches, setSelectedBranches] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showBranchData, setShowBranchData] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState()
  const dropdownRef = useRef(null);

  const handleBranchChange = (event) => {
    const branchName = event.target.value;
    if (selectedBranches.includes(branchName)) {
      setSelectedBranches(
        selectedBranches.filter((branch) => branch !== branchName)
      );
    } else {
      setSelectedBranches([...selectedBranches, branchName]);
    }
  };
  const filteredBranches = branchData.filter((branch) =>
    branch.branchName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    let arr = []
    branchData.map((a) => {
      arr.push(a.branchName)
    })
    setBranchListForMap(selectedBranches.length > 0 ? selectedBranches : arr)
  }, [selectedBranches])

  const handleSearchInputClick = () => {
    setShowBranchData(true);
  };
  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowBranchData(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [selectedYear, setSelectedYear] = useState("");

  const [update, setUpdate] = useState([])

  const generateFinancialYears = () => {
    const currentYear = new Date().getFullYear() + 5;
    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push(`${i}-${i + 1}`);
    }
    return years;
  };

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const [expanded, setExpanded] = React.useState(false);
  const [expandedId, setExpandedId] = React.useState()

  const handleChangeAccordian = (k, e, panel) => (event, isExpanded) => {
    setExpandedId(e.client._id)
    setBranchListbyClient([])
    setTimeout(() => {
      setBranchListbyClient(k)
    }, 1)
    setClient(e.client._id)
    setExpanded(isExpanded ? panel : false)
    setRevenue(!e.revenue ? 0 : e.revenue)
    setBillingValue(!e.billingValuePerResource ? 0 : e.billingValuePerResource)
  }

  const handleChangeMonthValue = (month, b, x) => {
    branchListByClient.find((z) => z.branch == b.branch)[month] = x.target.value == '' ? '' : parseInt(x.target.value)
    setUpdate([])
  }

  const [revenue, setRevenue] = useState();
  const [billingValuePerResource, setBillingValue] = useState()

  const handleChangeRevenue = (e) => {
    setRevenue(e.target.value);
  };

  const handleBilling = (e) => {
    setBillingValue(e.target.value);
  }

  const saveCandidateBudget = (e) => {

    if (selectedYear == '') {
      alert('select financial year')
    }


    else {
      axios.post(`${URL}Bi_budget/candidateBudget`, {
        clientId: selectedClient,
        financialYear: selectedYear,
        branchListByClient: branchListByClient,
        createdBy: localStorage.getItem('employeeId'),
        billingValuePerResource, billingValuePerResource,
        revenue: revenue

      })
        .then((res) => {
          alert('successfully updated')
        })
        .catch((err) => {
        })

      setLoader(true)
      getClientByCompany({ company: company, selectedBranches: selectedBranches, financialYear: selectedYear });
    }
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage = 0) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
    setFound([...foundClient])
  }

  const getData = () => {
    setLoader(true)
    getClientByCompany({ company: company, selectedBranches: selectedBranches, financialYear: selectedYear })
  }

  const handleChangeCompany = (e) => {
    setCompany(e.target.value)
    dispatch(getAllClientByCompany(e.target.value))
  }

  const selectBran = (k) => {
    if (!branchListByClient.find((z) => z.branch == k.target.value)) {
      branchListByClient.push({ branch: k.target.value, createdDate: format(new Date()), closingBalance: 0 })
    }
    setAnchorEl(null)
    setUpdate([])
  }




  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl5, setAnchorEl5] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined

  const open5 = Boolean(anchorEl5)
  const id5 = open5 ? 'client' : undefined

  const handleClick1 = (d) => {
    setAnchorEl5(d.currentTarget)
  }

  const handleClose1 = () => {
    setAnchorEl5(null)
  }

  const selectClient = (n) => {

    if (!clientByCompany.clientlist.find((z) => z.client._id == JSON.parse(n)._id)) {
      clientByCompany.clientlist.unshift({ client: JSON.parse(n), branches: [], totalClosingBalance: 0 })
    }
    setAnchorEl5(null)
    setUpdate([])
  }

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="textalinepera">Company :</p>
              <select disabled={loader == true}
                className="addclintone"
                style={{
                  width: "200px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  height: "43px",
                  border: "1px solid #DCE3EA",
                  marginLeft: "5px",
                }}
                value={company}
                onChange={handleChangeCompany}
              >
                {companyList.map((n) => (
                  <option value={n.company}>{n.company}</option>
                ))}
              </select>
            </div>

            <div style={{ marginLeft: "15px" }}>
              <label htmlFor="financialYear">Financial Year :</label>
              <select
                style={{
                  marginLeft: "5px",
                  width: "100px",
                  borderRadius: "6px",
                  background: "#FFFFFF",
                  height: "43px",
                  border: "1px solid #DCE3EA",
                }}
                id="financialYear"
                value={selectedYear}
                onChange={handleChange}
              >
                <option value="">Select Year</option>
                {generateFinancialYears().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "15px",
              }}
            >
              <p style={{}}>Branch Name :</p>
              {/* <select
                        className="w-56 h-10 rounded"
                        style={{
                          border: "1px solid #DCE3EA",
                          width: "250px",
                          height: "40px",
                          borderRadius: "5px",
                          marginLeft:"10px"
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name=""
                        placeholder="Enter Here"
                        variant="outlined"
                        value={branch}
                        onChange={(d) => setBranch(d.target.value)}
                      >
                        <option value="">Select</option>
                        {branchData.map((d) => (
                          <option value={d.branchName}>{d.branchName}</option>
                        ))}
                      </select> */}
              <div
                ref={dropdownRef}
                style={{ position: "relative", width: "250px", top: "-20px" }}
              >
                <div
                  style={{
                    height: "200px",
                    width: "250px",
                    overflow: "auto",
                    position: "absolute",
                    zIndex: "500",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                      position: "sticky",
                      top: "0",
                      background: "#ffffff",
                      zIndex: "900",
                    }}
                    onClick={handleSearchInputClick}
                  >
                    <InputBase
                      placeholder="Search…"
                      // value={searchQuery}
                      value={selectedBranches.join(", ") || searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      style={{
                        marginLeft: "8px",
                        border: "1px solid #DCE3EA",
                        width: "250px",
                        height: "40px",
                        borderRadius: "5px",
                        marginLeft: "10px",
                        padding: "0 0 0 10px",
                      }}
                    />
                  </div>
                  {showBranchData && (
                    <FormControl
                      component="fieldset"
                      style={{ padding: "0 0 0 20px" }}
                    >
                      <FormGroup
                        style={{ width: "210px", background: "#ffffff" }}
                      >
                        {filteredBranches.map((branch) => (
                          <FormControlLabel
                            key={branch._id}
                            control={
                              <Checkbox
                                checked={selectedBranches.includes(
                                  branch.branchName
                                )}
                                onChange={handleBranchChange}
                                value={branch.branchName}
                              />
                            }
                            label={branch.branchName}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                  )}
                </div>
              </div>
            </div>
            <button style={{ height: '34px', borderRadius: '4px', marginLeft: '20px', width: '100px', background: '#71ffde' }} onClick={getData}>
              Get
            </button>

            <button style={{ height: '34px', borderRadius: '4px', marginLeft: '20px', width: '150px', background: '#71ffde' }} onClick={handleClick1}>
              Add Client
            </button>
            <Popover
              id={id5}
              open={open5}
              anchorEl={anchorEl5}
              onClose={handleClose1}
              anchorOrigin={{
                vertical: 'right',
                horizontal: 'right',
              }}
            >
              <div
                className="addclintone"
                style={{
                  width: "150px",
                  height: '300px',
                  borderRadius: "6px"
                }}
              >
                <Select
                  options={clientlistfordropdown}
                  onChange={(e) => selectClient(e.dat)}
                />
              </div>
            </Popover>
            {/* <div
              className="addclintone"
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "sans-serif",
                columnGap: "10px",
                marginLeft: "15px",
              }}
            >
              <p className="textalinepera">Division :</p>
              <div
                className="addclintone"
                style={{
                  width: "250px",
                  borderRadius: "6px",
                }}
              >
                <Select
                  styles={styles}
                  closeMenuOnSelect={false}
                  options={optionsDevision}
                  isMulti
                  value={optionsDevision.filter((option) =>
                    selectedDivisions.includes(option.value)
                  )}
                  onChange={handleDivisionChange}
                />
              </div>
            </div> */}
          </div>

          <input
            type="text"
            placeholder="Search..."
            value={searchinput}
            onChange={handleSearchChange}
            style={{
              marginRight: "8px",
              height: "40px",
              borderRadius: "6px",
              border: "1px solid rgb(220, 227, 234)",
              padding: "0 0 0 10px",
            }}
          />
        </div>

        <div>
          {/* <Accordion expanded={expanded === 'unallocated'} onChange={handleChangeAccordian('unallocated')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={(e) => { getClosingBalance(e, "unallocated") }}
            >
              Unallocated
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: "450px" }}>Client Name</TableCell>
                      <TableCell style={{ width: "150px" }}>Closing Balance</TableCell>
                      <TableCell style={{ width: "150px" }}></TableCell>
                      <TableCell style={{ width: "150px" }}></TableCell>
                      <TableCell style={{ width: "150px" }}></TableCell>
                      <TableCell style={{ width: "150px" }}></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {branchListByClient.length > 0 ? branchListByClient.map((d) => (
                      <TableRow>
                        <TableCell>{d.branchName}</TableCell>
                        {/* <TableCell>{d.closingBalance}</TableCell> *
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )) : <p>No data found</p>}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion> */}

          {loader == true ? <RotatingLines
            ariaLabel="loading-indicator"
            height={70}
            width={70}
            strokeWidth={5}
            strokeColor="#22418e"
            visible={loader}
          /> :
            <div>
              <TableHead style={{ position: "sticky", top: "0", zIndex: "200", backgroundColor: "#F5FBFE", }}>
                <TableRow>
                  <TableCell style={{ width: "300px" }}>Branch Name</TableCell>
                  <TableCell style={{ width: "500px", textAlign: "end" }}>TotalClosingBalance</TableCell>
                  {/* <TableCell style={{ width: "80px", textAlign: "end" }}>Avg. Revenue</TableCell> */}

                  <TableCell style={{ width: "250px" }}>Avg. Revenue</TableCell>
                  {/* <TableCell style={{ width: "150px" }}>Previous Closing Balance</TableCell> */}
                  {/* <TableCell style={{ width: "150px" }}>Previous Updated Date</TableCell> */}

                  <TableCell style={{ width: "150px" }}>Apr</TableCell>
                  <TableCell style={{ width: "150px" }}>May</TableCell>
                  <TableCell style={{ width: "150px" }}>Jun</TableCell>
                  <TableCell style={{ width: "150px" }}>Jul</TableCell>
                  <TableCell style={{ width: "150px" }}>Aug</TableCell>
                  <TableCell style={{ width: "150px" }}>Sep</TableCell>
                  <TableCell style={{ width: "150px" }}>Oct</TableCell>
                  <TableCell style={{ width: "150px" }}>Nov</TableCell>
                  <TableCell style={{ width: "150px" }}>Dec</TableCell>
                  <TableCell style={{ width: "150px" }}>Jan</TableCell>
                  <TableCell style={{ width: "150px" }}>Feb</TableCell>
                  <TableCell style={{ width: "150px" }}>Mar</TableCell>
                </TableRow>
              </TableHead>
              {foundClient.clientlist && foundClient.clientlist
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((s, index) => {
                  return (
                    <Accordion style={{ position: "relative" }} expanded={expanded === index} onChange={handleChangeAccordian(s.branches, s, index)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      ><div style={{}}>    <p style={{ width: "200px" }}>{s.client.clientName}</p>
                        </div>
                        <div style={{ width: "30px", display: "flex", alignSelf: "center", flexDirection: "column", paddingLeft: "20px" }}>                         {s.totalClosingBalance}</div>
                        {expandedId != s.client._id ?
                          <div style={{ display: 'flex', alignItems: "center", marginLeft: '250px' }}>
                            <div style={{ width: "30px", }}>                         {s.branches.reduce((a, b) => a + ((b.apr == undefined || b.apr == '') ? 0 : b.apr), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.may == undefined || b.may == '') ? 0 : b.may), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.jun == undefined || b.jun == '') ? 0 : b.jun), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.jul == undefined || b.jul == '') ? 0 : b.jul), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.aug == undefined || b.aug == '') ? 0 : b.aug), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.sep == undefined || b.sep == '') ? 0 : b.sep), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.oct == undefined || b.oct == '') ? 0 : b.oct), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.nov == undefined || b.nov == '') ? 0 : b.nov), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.dec == undefined || b.dec == '') ? 0 : b.dec), 0)}</div>

                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.jan == undefined || b.jan == '') ? 0 : b.jan), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.feb == undefined || b.feb == '') ? 0 : b.feb), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {s.branches.reduce((a, b) => a + ((b.mar == undefined || b.mar == '') ? 0 : b.mar), 0)}</div>
                          </div>
                          :
                          <div style={{ display: 'flex', alignItems: "center", marginLeft: '250px' }}>
                            <div style={{ width: "30px", }}>                         {branchListByClient.reduce((a, b) => a + ((b.apr == undefined || b.apr == '') ? 0 : b.apr), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.may == undefined || b.may == '') ? 0 : b.may), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.jun == undefined || b.jun == '') ? 0 : b.jun), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.jul == undefined || b.jul == '') ? 0 : b.jul), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.aug == undefined || b.aug == '') ? 0 : b.aug), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.sep == undefined || b.sep == '') ? 0 : b.sep), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.oct == undefined || b.oct == '') ? 0 : b.oct), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.nov == undefined || b.nov == '') ? 0 : b.nov), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.dec == undefined || b.dec == '') ? 0 : b.dec), 0)}</div>

                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.jan == undefined || b.jan == '') ? 0 : b.jan), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.feb == undefined || b.feb == '') ? 0 : b.feb), 0)}</div>
                            <div style={{ width: "30px", marginLeft: "50px" }}>                         {branchListByClient.reduce((a, b) => a + ((b.mar == undefined || b.mar == '') ? 0 : b.mar), 0)}</div>
                          </div>
                        }


                      </AccordionSummary>

                      <AccordionDetails style={{ position: "absolute", top: "38px", left: "270px", justifyContent: 'space-between' }}>
                        <p style={{ display: 'inline-grid', marginRight: '20px',fontSize:'13px' }}>Revenue 
                          <input style={{ width: '70px' }}
                            value={revenue}
                            onChange={handleChangeRevenue}
                            placeholder="Enter Revenue"
                            type="number" />
                        </p>
                        <p style={{ display: 'inline-grid', fontSize:'13px' }}>
                          Billing Value <br/>
                          Per Resource
                          <input style={{ width: '60px' }}
                            value={billingValuePerResource}
                            onChange={handleBilling}
                            placeholder="Billing Value"
                            type="number" />
                        </p>

                      </AccordionDetails>

                      <AccordionDetails>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              {branchListByClient.length > 0 ? branchListByClient.map((d) => (
                                <TableRow>
                                  <TableCell style={{ width: "130px" }}>{d.branch}</TableCell>
                                  <TableCell style={{ paddingLeft: "57px" }}>{d.closingBalance}</TableCell>

                                  {/* <div style={{position:"absolute",top:"38px",left:"350px"}}>
                        <input
          style={{ width: '112px' }}
          placeholder="Enter Revenue"
          type="number"
        />                        </div> */}


                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.apr} onChange={(e) => handleChangeMonthValue('apr', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.may} onChange={(e) => handleChangeMonthValue('may', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.jun} onChange={(e) => handleChangeMonthValue('jun', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.jul} onChange={(e) => handleChangeMonthValue('jul', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.aug} onChange={(e) => handleChangeMonthValue('aug', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.sep} onChange={(e) => handleChangeMonthValue('sep', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.oct} onChange={(e) => handleChangeMonthValue('oct', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.nov} onChange={(e) => handleChangeMonthValue('nov', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.dec} onChange={(e) => handleChangeMonthValue('dec', d, e)} type="number" /></TableCell>

                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.jan} onChange={(e) => handleChangeMonthValue('jan', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.feb} onChange={(e) => handleChangeMonthValue('feb', d, e)} type="number" /></TableCell>
                                  <TableCell style={{ width: '40px' }}><input style={{ width: '42px' }} value={d.mar} onChange={(e) => handleChangeMonthValue('mar', d, e)} type="number" /></TableCell>

                                </TableRow>
                              )) : <p> </p>}


                            </TableBody>
                            <div style={{ display: 'inline-flex', marginTop: "10px" }}>
                              <button style={{ background: 'aqua', height: '30px', float: 'right', borderRadius: '5px' }} onClick={handleClick}>Add Branch</button>
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'right',
                                  horizontal: 'right',
                                }}
                              >
                                <select value={selectedBranch} onChange={selectBran}>
                                  <option>Select</option>
                                  {selectedBranches != '' ?
                                    selectedBranches.map((z) => (
                                      <option value={z}>{z}</option>
                                    )) :
                                    branchData.map((m) => (
                                      <option value={m.branchName}>{m.branchName}</option>
                                    ))}
                                </select>
                              </Popover>
                              <button style={{ background: 'aqua', width: '80px', height: '30px', borderRadius: '5px', marginLeft: '90%', position: 'absolute' }} onClick={saveCandidateBudget}>Save</button>
                            </div>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}

            </div>
          }


          <TableCell className="flexcontain"
            style={{ display: 'flex', justifyContent: 'flex-start', padding: "0px" }}
          >
            <div className="-ml-4" >
              <TablePagination

                component="div"
                size="small"
                count={foundClient.clientlist && foundClient.clientlist.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </TableCell>
          <TableCell style={{ display: 'flex', alignItems: "center", width: "100%" }} >
            <span style={{ width: "300px" }}>Total</span>   <p style={{ margin: '0 0 0 185px' }}>{foundClient.allClientClosingBalance}</p>
            <div style={{ display: 'flex', alignItems: "center", marginRight: "25px", marginLeft: "90px", justifyContent: "space-between", width: "100%" }}>
              {foundClient.totalBudgetCount && foundClient.totalBudgetCount.map((d) => (
                <div style={{ width: "40px" }}>
                  <p style={{ width: "40px", }}>{d}</p>

                </div>
              ))}
            </div>
          </TableCell>
        </div>

      </div>
    </>
  );
};

export default Budgeting;
