import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DeleteIcon from "../DeleteIcon.svg";

function PopupClient({ showClientData }) {
  console.log("showClientData", showClientData);
  // const [requisition, setRequisition] = useState(editRequisitionData)

  // const handleChangeRequisitionData = (e) => {
  //   const { name, value } = e.target
  //   console.log(" handleChangeRequisitionData= ", name, value)
  //   setRequisition({ ...requisition,[name]: value })
  // }
  // const handleUpdate = () => {
  //   console.log("HANDLE UPDATE")
  //   // dispatch(putRequisition())
  //   // // setRequisition(defaultRequisition)
  //   // handleClickOpen()
  // }
  return (
    <div
      style={{
        height: "688px",
        width: "732px",

        backgroundColor: "#EEF1F4",
        border: "1px solid #CFDFED",
        fontFamily: "sans-serif",
        margin: " -24px",
        marginTop: "-24px",
      }}
    >
      <div style={{ fontFamily: "sans-serif", margin: "0px 24px" }}>
        <p
          style={{
            color: "#155188",
            fontFamily: "sans-serif",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 500,
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          {" "}
          CLIENT DETAILS
        </p>
        <hr style={{ marginTop: "-4px" }} />
        <div
          style={{
            color: "#525252",
            fontFamily: "sans-serif",
            fontWeight: 500,
            fontSize: "12px",
            lineHeight: "16px",
            display: "flex",
            rowGap: "8px",
            flexFlow: "column",
            marginTop: "16px",
          }}
        >
          <div style={{ display: "flex", columnGap: "40px" }}>
            <p style={{ marginTop: "10px" }}>Client Name</p>
            <TextField
              size="small"
              disabled
              name="clientName"
              style={{
                border: "1px solid #DCE3EA",
                backgroundColor: "#ffffff",
                width: "208px",
                borderRadius: "6px",
              }}
              value={showClientData.clientName}
              variant="outlined"
              autoComplete="off"
            />
          </div>
          <div style={{ display: "flex", columnGap: "12px" }}>
            <p style={{ marginTop: "10px" }}>Account Manager </p>
            <TextField
              size="small"
              disabled
              style={{
                border: "1px solid #DCE3EA",
                backgroundColor: "#ffffff",
                width: "208px",
                borderRadius: "6px",
              }}
              value={showClientData.accountManagerName}
              variant="outlined"
              autoComplete="off"
            />
          </div>
          {showClientData?.spocs?.map((data, index) => (
            <div key={data._id} style={{ display: "flex", columnGap: "8px" }}>
              <p style={{ marginTop: "10px" }}>SPOC {index + 1}.</p>
              <TextField
                disabled
                size="small"
                style={{
                  border: "1px solid #DCE3EA",
                  backgroundColor: "#ffffff",
                  width: "176px",
                  borderRadius: "6px",
                  marginLeft: "50px",
                }}
                value={data.spocName}
                variant="outlined"
                autoComplete="off"
              />
              <TextField
                disabled
                size="small"
                style={{
                  border: "1px solid #DCE3EA",
                  backgroundColor: "#ffffff",
                  width: "176px",
                  borderRadius: "6px",
                }}
                value={data.spocEmail}
                variant="outlined"
                autoComplete="off"
              />
              <TextField
                disabled
                size="small"
                style={{
                  border: "1px solid #DCE3EA",
                  backgroundColor: "#ffffff",
                  width: "176px",
                  borderRadius: "6px",
                }}
                value={data.spocNumber}
                variant="outlined"
                autoComplete="off"
              />
              {/* <img className="w-8 h-8 cursor-pointer mt-1" src={DeleteIcon} /> */}
            </div>
          ))}
        
        </div>
        <div
          style={{
            fontFamily: "sans-serif",
            display: "flex",
            justifyContent: "center",
            marginTop: "32px",
          }}
        >
          <div
            style={{
              width: "560px",
              height: "250px",
              backgroundColor: "#DCE4EE",
            }}
          >
            <p
              style={{
                fontFamily: "sans-serif",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Client Terms and Conditions
            </p>
            <div>
              <Divider />
            </div>
            <div
              style={{
                color: "#525252",
                fontFamily: "sans-serif",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "20px",
                display: "flex",
                flexFlow: "column",
                rowGap: "12px",
                margin: "16px 16px",
              }}
            >
              <div>1. Contracting Margin on Monthly Basis</div>
              <div style={{ display: "flex", gap: "40px" }}>
                <select
                  style={{
                    border: "1px solid #DCE3EA",
                    marginLeft: "5%",
                    height: "40px",
                    width: "200px",
                    borderRadius: "6px",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="contractualMarginType"
                  placeholder="Enter Here"
                  //   onChange={handleinputChange}
                  value={
                    showClientData.contractual
                      ? showClientData.contractual.contractualMarginType
                      : ""
                  }
                  variant="outlined"
                >
                  <option value="">Select Here</option>
                  <option value="Variable in %">Variable in %</option>
                  <option value="Fixed in Rs">Fixed in Rs</option>
                </select>
                <TextField
                  size="small"
                  //required={true}
                  name=""
                  style={{
                    border: "1px solid #DCE3EA",
                    backgroundColor: "#ffffff",
                    width: "200px",
                    borderRadius: "6px",
                  }}
                  placeholder="Enter Amount"
                  //   onChange={handleinputChange}
                  value={
                    showClientData.contractual
                      ? showClientData.contractual.contractualMarginAmount
                      : ""
                  }
                  variant="outlined"
                  autoComplete="off"
                />
              </div>
              <div>2. One Time sourcing fee</div>
              <div style={{ display: "flex", gap: "40px" }}>
                <select
                  style={{
                    border: "1px solid #DCE3EA",
                    marginLeft: "5%",
                    width: "200px",
                    height: "40px",
                    borderRadius: "6px",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="contractualOnTimeSourcingAmountType"
                  placeholder="Enter Here"
                  //   onChange={handleinputChange}
                  value={
                    showClientData.contractual
                      ? showClientData.contractual
                          .contractualOnTimeSourcingAmountType
                      : ""
                  }
                  variant="outlined"
                >
                  <option value="">Select Here</option>
                  <option value="Variable in %">Variable in %</option>
                  <option value="Fixed in Rs">Fixed in Rs</option>
                </select>
                <TextField
                  size="small"
                  //required={true}
                  name=""
                  style={{
                    border: "1px solid #DCE3EA",
                    backgroundColor: "#ffffff",
                    width: "200px",
                    borderRadius: "6px",
                  }}
                  placeholder="Enter Amount"
                  //   onChange={handleinputChange}
                  value={
                    showClientData.contractual
                      ? showClientData.contractual
                          .contractualOnTimeSourcingAmount
                      : ""
                  }
                  variant="outlined"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupClient;
