import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    // apiKey: "AIzaSyCAIfvvGxIXS9j3gvjNktS3FjflbAaseeQ",
    apiKey: "AIzaSyCAIfvvGxIXS9j3gvjNktS3FjflbAaseeQ",
    authDomain: "my-org-planning.firebaseapp.com",
    projectId: "my-org-planning",
    storageBucket: "my-org-planning.appspot.com",
    // messagingSenderId: "1000432293936",
    messagingSenderId: "1000432293936",
    appId: "1:1000432293936:web:60c913dec0c9948686f5f5",

};
export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
