import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  getUserList,
  addUser,
  getEmployeeList,
} from "../../../../redux/action";
import Select from "react-select";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";
const { URL } = require("../../../../config.json");

export default function UpdateUser({ updateUserClose, user }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  let emp_id = localStorage.getItem("employeeId");
  const dispatch = useDispatch();
  const UserList = useSelector((state) => state.clientReducer.userList);
  let roleData = useSelector((state) => state.clientReducer.role);
  let branchData = useSelector((state) => state.clientReducer.branch);
  let companyData = useSelector((state) => state.clientReducer.company);
  let divisionData = useSelector((state) => state.clientReducer.division);
  let employeeList = useSelector((state) => state.clientReducer.employeeList);
  const [password, setPassword] = useState();
  const [email, setEmail] = useState(user.email);
  const [userName, setUserName] = useState(user.userName);
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [leavingDate, setLeavingDate] = useState("");
  const [cTC, setCTC] = useState(user.cTC);
  const [reportingManager, setReportingManager] = useState(
    JSON.stringify(UserList.find((m) => m._id == user.reportingManagerId))
  );
  const [role, setRole] = useState(
    JSON.stringify(roleData.find((m) => m.roleName == user.roleName))
  );
  const [branch, setBranch] = useState(user.branchName);
  const [company, setCompany] = useState(user.company);
  const [selected, setSelected] = useState([]);
  const [division, setDivision] = useState(user.division);

  useEffect(() => {
    setDateOfJoining(formatDateFordateOfJoining(user.dateOfJoining));
  }, [user.dateOfJoining]);
  function formatDateFordateOfJoining(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    setLeavingDate(formatDateForleavingDate(user.leavingDate));
  }, [user.leavingDate]);
  function formatDateForleavingDate(date) {
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    let preselect = user.division.map((n) => n.divisionId);
    setSelected(preselect);
  }, [user.division]);

  function update() {
    axios      .put(`${URL}userlist/userlist`, {
        u_id: user._id,
        password: password,
        roleName: role == undefined ? "" : JSON.parse(role).roleName,
        roleId: role == undefined ? "" : JSON.parse(role)._id,
        email: email,
        dateOfJoining:new Date(dateOfJoining),
        leavingDate: new Date(leavingDate),
        cTC: cTC,
        userName: userName,
        reportingManagerName: reportingManager == undefined ? "" : JSON.parse(reportingManager).userName,
        reportingManagerEmail: reportingManager == undefined ? "" : JSON.parse(reportingManager).email,
        reportingManagerId: reportingManager == undefined ? "" : JSON.parse(reportingManager)._id,
        branchName: branch,
        company: company,
        updatedBy: emp_id,
        division: division,
        status: sendStatus,
      })
      .then((re) => {
        window.alert(re.data.message);
        dispatch(getUserList());
        updateUserClose();
      })
      .catch((error) => {
        window.alert(error.response.data.message);
      });
  }

  const handleClick = (event, name, d) => {
    if (event.target.checked == true) {
      division.push({ divisionName: d.divisionName, divisionId: d._id });
    } else if (event.target.checked == false) {
      let data = division.filter((div, i) => div.divisionId != d.divisionId);
      setDivision(data);
    }

    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const rptManager = UserList.map((l) => ({
    dat: JSON.stringify(l),
    value: l.userName,
    label: l.userName,
  }));

  let expanded = false;

  function divisionSelect() {
    var checkboxes = document.getElementById("updatedivision");
    if (!expanded) {
      checkboxes.style.display = "grid";
      expanded = true;
    } else {
      checkboxes.style.display = "none";
      expanded = false;
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const [sendStatus, setSendStatus] = useState();

  useEffect(() => {
    setSendStatus(user.status);
  }, [user]);

  const handleToggleSendStatus = (e) => {
    setSendStatus(e.target.checked);
  };
  console.log("sendStatus", sendStatus, user);

  return (
    <div
      className="userupdate"
      style={{
        width: "580px",
        // height: "590px",
        padding: "10px 0 10px 0",
        border: "1px solid #CFDFED",
        background: "#EEF1F4",
        margin: "-20px -28px -20px -24px",
      }}
    >
      <CloseIcon
        onClick={updateUserClose}
        style={{ cursor: "pointer", float: "right" }}
      />
      <p
        style={{
          color: "#155188",
          display: "flex",
          justifyContent: "center",
          fontFamily: "sans-serif",
          fontSize: "14px",
          lineHeight: "18px",
          fontWeight: 500,
          marginTop: "18px",
        }}
      >
        UPDATE USER
      </p>
      <hr />

      <div
        className="userdetail"
        style={{ display: "flex", columnGap: "16px", marginTop: "22px" }}
      >
        <div
          style={{
            width: "33%",
            textAlign: "end",
            display: "flex",
            flexDirection: "column",
            marginTop: "-5px",
          }}
        >
          <p>Status</p>
          <p style={{marginTop:"20px"}}>User Name</p>
          <p style={{ marginTop: "25px" }}>Password</p>
          <p style={{ marginTop: "20px" }}>Email</p>
          <p style={{ marginTop: "20px" }}>Reporting Manager</p>
          <p style={{ marginTop: "20px" }}>Role</p>
          <p style={{ marginTop: "20px" }}>Division</p>
          <p style={{ marginTop: "20px" }}>Branch Name</p>
          <p style={{ marginTop: "15px" }}>Company</p>
          <p style={{ marginTop: "20px" }}>Date of Joining</p>
          <p style={{ marginTop: "20px" }}>Leaving Date</p>
          <p style={{ marginTop: "20px" }}>CTC (Annual)</p>
        </div>
        <form
          onSubmit={handleSubmit(update)}
          style={{ display: "flex", flexDirection: "column", rowGap: "18px" }}
        >
          {console.log("inside component", sendStatus)}
          <div>
            <FormGroup style={{}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      sendStatus == "true" || sendStatus == true ? true : false
                    }
                    onChange={(e) => {
                      handleToggleSendStatus(e);
                    }}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </div>

          <input
            {...register("Name", { required: true })}
            placeholder={errors.Name?.type === "required" && "Name is required"}
            aria-invalid={errors.Name ? "true" : "false"}
            type="text"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            value={userName}
            onChange={(d) => setUserName(d.target.value)}
          />

          <input
            type="password"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            {...register("Email", { required: true })}
            placeholder={
              errors.Email?.type === "required" && "Email is required"
            }
            aria-invalid={errors.Email ? "true" : "false"}
            type="email"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            value={email}
            onChange={(d) => setEmail(d.target.value)}
          />

          <Select
            options={rptManager}
            defaultValue={[
              rptManager.find(
                (n) => JSON.parse(n.dat)._id == user.reportingManagerId
              ),
            ]}
            onChange={(e) => setReportingManager(e.dat)}
          />
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-56 h-10 rounded"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name=""
            placeholder="Enter Here"
            variant="outlined"
          >
            <option>Select</option>
            {roleData.map((d) => (
              <option value={JSON.stringify(d)}>{d.roleName}</option>
            ))}
          </select>

          <div>
            <div
              style={{
                borderRadius: "5px",
                height: "30px",
                border: "1px solid black",
                background: "white",
              }}
              onClick={divisionSelect}
            >
              Select Division
            </div>
            <div
              id="updatedivision"
              style={{
                display: "none",
                border: "1px solid black",
                borderRadius: "5px",
                width: "270px",
                height: "150px",
                background: "white",
                position: "absolute",
              }}
            >
              <div style={{ overflow: "auto", display: "grid" }}>
                {divisionData.map((x) => {
                  const isItemSelected = isSelected(x._id);
                  return (
                    <label>
                      {" "}
                      <input
                        type="checkbox"
                        checked={isItemSelected}
                        onChange={(n) => handleClick(n, x._id, x)}
                      />{" "}
                      {x.divisionName}{" "}
                    </label>
                  );
                })}
              </div>
              <div
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  cursor: "pointer",
                  background: "skyblue",
                }}
                onClick={divisionSelect}
              >
                OK
              </div>
            </div>
          </div>
          <select
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name=""
            placeholder="Enter Here"
            variant="outlined"
            value={branch}
            onChange={(d) => setBranch(d.target.value)}
          >
            <option>Select</option>
            {branchData.map((d) => (
              <option value={d.branchName}>{d.branchName}</option>
            ))}
          </select>

          <select
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name=""
            placeholder="Enter Here"
            variant="outlined"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          >
            <option>Select</option>
            {companyData.map((z) => (
              <option value={z.company}>{z.company}</option>
            ))}
          </select>
          {/* dateOfJoining: dateOfJoining,
        leavingDate: leavingDate,
        cTC: cTC, */}
          {/* const [dateOfJoining, setDateOfJoining] = useState();
  const [leavingDate, setLeavingDate] = useState();
  const [cTC, setCTC] = useState(); */}
          <input
            // {...register("Name", { required: true })}
            // placeholder={errors.Name?.type === "required" && "Name is required"}
            // aria-invalid={errors.Name ? "true" : "false"}
            type="date"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            value={dateOfJoining}
            onChange={(d) => setDateOfJoining(d.target.value)}
          />
          <input
            // {...register("Name", { required: true })}
            // placeholder={errors.Name?.type === "required" && "Name is required"}
            // aria-invalid={errors.Name ? "true" : "false"}
            type="date"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            value={leavingDate}
            onChange={(d) => setLeavingDate(d.target.value)}
          />
          <input
            // {...register("Name", { required: true })}
            // placeholder={errors.Name?.type === "required" && "Name is required"}
            // aria-invalid={errors.Name ? "true" : "false"}
            type="number"
            style={{
              border: "1px solid #DCE3EA",
              width: "272px",
              height: "35px",
              borderRadius: "5px",
            }}
            value={cTC}
            onChange={(d) => setCTC(d.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "75px",
              marginTop: "12px",
            }}
          >
            <button
              type="submit"
              style={{
                backgroundColor: "#4594DE",
                width: "70px",
                height: "40px",
                borderRadius: "5px",
                color: "#FFFFFF",
                border: "1px solid #4594DE",
                cursor: "pointer",
              }}
              // onClick={update}
            >
              Save
            </button>
          </div>
        </form>
      </div>

      {/* mobile View */}
      <div
        className="userpopupmobile"
        style={{ display: "none", columnGap: "16px", marginTop: "22px" }}
      >
        <form
          onSubmit={handleSubmit(update)}
          style={{ display: "flex", flexDirection: "column", rowGap: "18px" }}
        >
          <div className="divuser">
            {" "}
            <p className="userd">User Name</p>
            <input
              {...register("Name", { required: true })}
              placeholder={
                errors.Name?.type === "required" && "Name is required"
              }
              aria-invalid={errors.Name ? "true" : "false"}
              type="text"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              value={userName}
              onChange={(d) => setUserName(d.target.value)}
            />
          </div>
          <div className="divuser">
            <p className="userd">Password</p>
            <input
              type="password"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="divuser">
            {" "}
            <p className="userd">Email</p>
            <input
              {...register("Email", { required: true })}
              placeholder={
                errors.Email?.type === "required" && "Email is required"
              }
              aria-invalid={errors.Email ? "true" : "false"}
              type="email"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              value={email}
              onChange={(d) => setEmail(d.target.value)}
            />
          </div>

          <div className="divuser">
            {" "}
            <p className="userd">Reporting Manager</p>
            <Select
              options={rptManager}
              defaultValue={[
                rptManager.find(
                  (n) => JSON.parse(n.dat)._id == user.reportingManagerId
                ),
              ]}
              onChange={(e) => setReportingManager(e.dat)}
            />
          </div>
          <div className="divuser">
            <p className="userd">Role</p>
            <select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              className="w-56 h-10 rounded"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name=""
              placeholder="Enter Here"
              variant="outlined"
            >
              <option>Select</option>
              {roleData.map((d) => (
                <option value={JSON.stringify(d)}>{d.roleName}</option>
              ))}
            </select>
          </div>

          <div>
            <div className="divuser">
              {" "}
              <p className="userd">Division</p>
              <div
                style={{
                  borderRadius: "5px",
                  height: "30px",
                  border: "1px solid black",
                  background: "white",
                }}
                onClick={divisionSelect}
              >
                Select Division
              </div>
              <div
                id="updatedivision"
                style={{
                  display: "none",
                  border: "1px solid black",
                  borderRadius: "5px",
                  width: "270px",
                  height: "150px",
                  background: "white",
                  position: "absolute",
                }}
              >
                <div style={{ overflow: "auto", display: "grid" }}>
                  {divisionData.map((x) => {
                    const isItemSelected = isSelected(x._id);
                    return (
                      <label>
                        {" "}
                        <input
                          type="checkbox"
                          checked={isItemSelected}
                          onChange={(n) => handleClick(n, x._id, x)}
                        />{" "}
                        {x.divisionName}{" "}
                      </label>
                    );
                  })}
                </div>
                <div
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    cursor: "pointer",
                    background: "skyblue",
                  }}
                  onClick={divisionSelect}
                >
                  OK
                </div>
              </div>
            </div>
          </div>
          <div className="divuser">
            {" "}
            <p className="userd">Branch Name</p>
            <select
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name=""
              placeholder="Enter Here"
              variant="outlined"
              value={branch}
              onChange={(d) => setBranch(d.target.value)}
            >
              <option>Select</option>
              {branchData.map((d) => (
                <option value={d.branchName}>{d.branchName}</option>
              ))}
            </select>
          </div>
          <div className="divuser">
            <p className="userd"> Company</p>
            <select
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name=""
              placeholder="Enter Here"
              variant="outlined"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            >
              <option>Select</option>
              {companyData.map((z) => (
                <option value={z.company}>{z.company}</option>
              ))}
            </select>
          </div>
          <div className="divuser">
            {" "}
            <p className="userd">Date of Joining</p>
            <input
              type="date"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              value={dateOfJoining}
              onChange={(d) => setDateOfJoining(d.target.value)}
            />
          </div>
          <div className="divuser">
            {" "}
            <p className="userd">Leaving Date</p>
            <input
              type="date"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              value={leavingDate}
              onChange={(d) => setLeavingDate(d.target.value)}
            />
          </div>
          <div className="divuser">
            {" "}
            <p className="userd">CTC</p>
            <input
              type="number"
              style={{
                border: "1px solid #DCE3EA",
                width: "272px",
                height: "35px",
                borderRadius: "5px",
              }}
              value={cTC}
              onChange={(d) => setCTC(d.target.value)}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "75px",
              marginTop: "1px",
              width: "100%",
              marginBottom: "25px",
            }}
          >
            <button
              type="submit"
              style={{
                backgroundColor: "#4594DE",
                width: "100%",
                height: "40px",
                borderRadius: "5px",
                color: "#FFFFFF",
                border: "1px solid #4594DE",
                cursor: "pointer",
              }}
              // onClick={update}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
